import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    CREATE_PERSON_REQUEST,
    CREATE_PERSON_SUCCESS,
    CREATE_PERSON_FAIL,
    UPDATE_PERSON_REQUEST,
    UPDATE_PERSON_SUCCESS,
    UPDATE_PERSON_FAIL,
    GET_PERSON_REQUEST,
    GET_PERSON_SUCCESS,
    GET_PERSON_FAIL,
    CREATE_PRACTICE_REQUEST,
    CREATE_PRACTICE_SUCCESS,
    CREATE_PRACTICE_FAIL,
    UPDATE_PRACTICE_REQUEST,
    UPDATE_PRACTICE_SUCCESS,
    UPDATE_PRACTICE_FAIL,
    GET_PRACTICE_REQUEST,
    GET_PRACTICE_SUCCESS,
    GET_PRACTICE_FAIL,
    LAST_SEARCH_RESULT_REQUEST,
    LAST_SEARCH_RESULT_SUCCESS,
    LAST_SEARCH_RESULT_FAIL,
    LAST_TEN_SEARCH_RESULT_REQUEST,
    LAST_TEN_SEARCH_RESULT_SUCCESS,
    LAST_TEN_SEARCH_RESULT_FAIL,
    SEARCH_PERSON_PRACTICE_REQUEST,
    SEARCH_PERSON_PRACTICE_SUCCESS,
    SEARCH_PRACTICE_ONLY_SUCCESS,
    SEARCH_PRACTICE_ONLY_SUCCESS2,
    SEARCH_PRACTICE_ONLY_SUCCESS3,
    SEARCH_PERSON_PRACTICE_FAIL,
    OPEN_PRACTICE,
    OPEN_PERSON,
    SWITCH_BRANCH_REQUEST,
    SWITCH_BRANCH_REQUEST_SUCCESS,
    SWITCH_BRANCH_REQUEST_FAILED,

    UNI_SEARCH_REQUEST,
    UNI_SEARCH_SUCCESS,
    UNI_SEARCH_FAILED,
    USER_ME_REQUEST,
    USER_ME_SUCCESS,
    USER_ME_FAIL,

    GET_REPORT_LIST_REQUEST,
    GET_REPORT_LIST_SUCCESS,
    GET_REPORT_LIST_FAIL,

    SEND_VERIFICATION_CODE_REQUEST,
    SEND_VERIFICATION_CODE_SUCCESS,
    SEND_VERIFICATION_CODE_FAIL,

    PMS_DROPDOWN_REQUEST,
    PMS_DROPDOWN_SUCCESS,
    PMS_DROPDOWN_FAIL,    

} from '../constants/userConstants'

// authentication
export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }
            break;
        case USER_LOGIN_SUCCESS:
            return { loading: false, user: action.payload }
            break;
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
            break;
        case USER_LOGOUT:
            return {}
        default:
            return state
            break;
    }
}

// send verification code
export const sendVerificationCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_VERIFICATION_CODE_REQUEST:
            return { loading: true }
            break;
        case SEND_VERIFICATION_CODE_SUCCESS:
            return { loading: false, userCode: action.payload }
            break;
        case SEND_VERIFICATION_CODE_FAIL:
            return { loading: false, error: action.payload }
            break;
        default:
            return state
            break;
    }
}

// pms drop down
export const pmsDropDownReducer = (state = {}, action) => {
    switch (action.type) {
        case PMS_DROPDOWN_REQUEST:
            return { loading: true }
            break;
        case PMS_DROPDOWN_SUCCESS:
            return { loading: false, pms_dropdown: action.payload }
            break;
        case PMS_DROPDOWN_FAIL:
            return { loading: false, error: action.payload }
            break;
        default:
            return state; break;
    }
}

//Person
export const createPersonReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_PERSON_REQUEST:
            return { loadingCreatePerson: true }
            break;
        case CREATE_PERSON_SUCCESS:
            return { loadingCreatePerson: false, personCreate: action.payload }
            break;
        case CREATE_PERSON_FAIL:
            return { loadingCreatePerson: false, errorCreatePerson: action.payload }
            break;
        default:
            return state
            break;
    }
}

export const updatePersonReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PERSON_REQUEST:
            return { loadingUpdatePerson: true }
            break;
        case UPDATE_PERSON_SUCCESS:
            return { loadingUpdatePerson: false, personUpdate: action.payload }
            break;
        case UPDATE_PERSON_FAIL:
            return { loadingUpdatePerson: false, errorUpdatePerson: action.payload }
            break;
        default:
            return state
            break;
    }
}

export const getPersonReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PERSON_REQUEST:
            return { loadingGetPerson: true }
        case GET_PERSON_SUCCESS:
            return { loadingGetPerson: false, personGet: action.payload }
        case GET_PERSON_FAIL:
            return { loadingGetPerson: false, errorGetPerson: action.payload }
        default:
            return state
    }
}

//Practice
export const createPracticeReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_PRACTICE_REQUEST:
            return { loadingCreatePractice: true }
            break;
        case CREATE_PRACTICE_SUCCESS:
            return { loadingCreatePractice: false, practiceCreate: action.payload }
            break;
        case CREATE_PRACTICE_FAIL:
            return { loadingCreatePractice: false, errorCreatePractice: action.payload }
        default:
            return state
            break;
    }
}

export const updatePracticeReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PRACTICE_REQUEST:
            return { loadingUpdatePractice: true }
            break;
        case UPDATE_PRACTICE_SUCCESS:
            return { loadingUpdatePractice: false, practiceUpdate: action.payload }
            break;
        case UPDATE_PRACTICE_FAIL:
            return { loadingUpdatePractice: false, errorUpdatePractice: action.payload }
            break;
        default:
            return state
            break;
    }
}

export const getPracticeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PRACTICE_REQUEST:
            return { loadingGetPractice: true }
        case GET_PRACTICE_SUCCESS:
            return { loadingGetPractice: false, practiceGet: action.payload }
        case GET_PRACTICE_FAIL:
            return { loadingGetPractice: false, errorGetPractice: action.payload }
        default:
            return state
    }
}

//Search practice/person
export const searchReducer = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_PERSON_PRACTICE_REQUEST:
            return { loadingSearch: true }
            break;
        case SEARCH_PERSON_PRACTICE_SUCCESS:
            return { loadingSearch: false, searchResult: action.payload }
            break;
        case SEARCH_PRACTICE_ONLY_SUCCESS:
            return { loadingSearch: false, searchPracticeResult: action.payload }
            break;
        case SEARCH_PRACTICE_ONLY_SUCCESS2:
            return { loadingSearch: false, searchPracticeResult2: action.payload }
            break;
        case SEARCH_PRACTICE_ONLY_SUCCESS3:
            return { loadingSearch: false, searchPracticeResult3: action.payload }
            break;
        case SEARCH_PERSON_PRACTICE_FAIL:
            return { loadingSearch: false, searchError: action.payload }
            break;
        default:
            return state
            break;
    }
}

export const lastSearchedResultReducer = (state = {}, action) => {
    switch (action.type) {
        case LAST_SEARCH_RESULT_REQUEST:
            return { loadingLastSearch: true }
        case LAST_SEARCH_RESULT_SUCCESS:
            return { loadingLastSearch: false, lastSearchResult: action.payload }
        case LAST_SEARCH_RESULT_FAIL:
            return { loadingLastSearch: false, ErrorlastSearch: action.payload }
        default:
            return state
            break;
    }
}

export const lastTenSearchedResultReducer = (state = {}, action) => {
    switch (action.type) {
        case LAST_TEN_SEARCH_RESULT_REQUEST:
            return { loadingLastTenSearch: true }
            break;
        case LAST_TEN_SEARCH_RESULT_SUCCESS:
            return { loadingLastTenSearch: false, lastTenSearchResult: action.payload }
            break;
        case LAST_TEN_SEARCH_RESULT_FAIL:
            return { loadingLastTenSearch: false, ErrorlastTenSearch: action.payload }
            break;
        default:
            return state
            break;
    }
}

//Open practice
export const openPracticeReducer = (state = {}, action) => {
    switch (action.type) {
        case OPEN_PRACTICE:
            return { practiceId: action.payload }
        default:
            return state
    }
}

//Open person
export const openPersonReducer = (state = {}, action) => {
    switch (action.type) {
        case OPEN_PERSON:
            return { personId: action.payload }
        default:
            return state
    }
}

//Switch branch
export const switchBranchReducer = (state = {}, action) => {
    switch (action.type) {
        case SWITCH_BRANCH_REQUEST:
            return { loadingSwitchBranch: true }
            break;
        case CREATE_PRACTICE_SUCCESS:
            return { loadingSwitchBranch: false, switchBranch: action.payload }
            break;
        case CREATE_PRACTICE_FAIL:
            return { loadingSwitchBranch: false, ErrorSwitchBranch: action.payload }
        default:
            return state
            break;
    }
}

// Uni Qualification Reducer
export const uniQualificationReducer = (state = {}, action) => {
    switch (action.type) {
        case UNI_SEARCH_REQUEST:
            return { loading: true }
            break;
        case UNI_SEARCH_SUCCESS:
            return { loading: false, uni_data: action.payload }
            break;
        case UNI_SEARCH_FAILED:
            return { loading: false, uni_data: action.payload }
            break;
        default:
            return state
    }
}

// Me 
export const userMeReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_ME_REQUEST:
            return { loading: true }
            break;
        case USER_ME_SUCCESS:
            return { loading: false, userMe: action.payload }
            break;
        case USER_ME_FAIL:
            return { loading: false, error: action.payload }
            break;
        default:
            return state
            break;
    }
}

// report list by user 
export const getReportListReducer = (state = { reportList: [] }, action) => {
    switch (action.type) {
        case GET_REPORT_LIST_REQUEST:
            return { loading: true }
            break;
        case GET_REPORT_LIST_SUCCESS:
            return { loading: false, reportList: action.payload }
            break;
        case GET_REPORT_LIST_FAIL:
            return { loading: false, error: action.payload }
            break;
        default:
            return state
            break;
    }
}