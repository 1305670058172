import React, { useState, useEffect } from "react";
import deleteIcon from "../assets/icons/delete.svg";
import { Row, Col, Form, Image, Button, Modal } from "react-bootstrap";
import Select from "react-select";
// Importing Constants from constants/adq for react-select
import { vet, practice, entity, operators, title_suggestion, jobtype_suggestion, species_suggestion, salary_band_suggestions } from '../constants/advanceQueryBuilder'
import { areas } from "../constants/constants";
import {selectStyle} from '../styling/select-style'

const QueriesInputOr = ({ curr, dispatched, index, type, ind }) => {
    // for disoplaying field of multiselect
    const [showMultiSelect, setShowMultiSelect] = useState(true)
    const [multiselectValue, setMultiselectValue] = useState([])
    const [modalShow,setModalShow] =useState(false)
    const [date1,setDate1] = useState()
    const [date2,setDate2] = useState()
    //useEffect for changing the input and reseting the value
    useEffect(() => {
        let multi_select_fields = ['title', 'v_job_types', 'v_species', 'areas', 'sm_salary_band']
        if (multi_select_fields.includes(curr.where)){
         setShowMultiSelect(true)}
        else{
        setShowMultiSelect(false)

    }
    }, [curr.where])

    // useEffect(() => {
    //     if (true) {
    //         let temp = []
    //         multiselectValue.forEach((x) => {
    //             temp.push(x.value)
    //         })

    //         dispatched({ type: 'VALUE_FIELD_OR', val: temp.join(), index: index, ind: ind, val2: multiselectValue })
    //     }
    // }, [multiselectValue])

    const handleMultiSelectValue = (e) => {
        setMultiselectValue(e)
        let  temp=[]
        e.forEach((x)=>{
            temp.push(x.value)
        })
         dispatched({type:'VALUE_FIELD_OR',val:temp.join(), index:index,ind:ind ,val2:e})
    }

    const handleCombineDate=()=>{
        dispatched({ type: 'VALUE_FIELD', val:`${date1}___${date2}` , index: index, ind: ind })
        setModalShow(false)
}

    useEffect(()=>{
        if(curr.param === 'BETWEEN' || curr.param === 'NOT BETWEEN'){
            setModalShow(true)
        }
    },[curr.param])

    const handleWhere=()=>{
        dispatched({type:'RESET VALUE VAL2 OR',index:index,ind:ind})
        setMultiselectValue([])

    }

    return (
        <>
         {modalShow &&
                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='ignore-onclickoutside'
                    style={{ zIndex: '999999' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Date Between
              </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            type="date"
                            name="date1"
                            className="date-icon"
                            value={date1}
                            onChange={(e) => { setDate1(e.target.value) }}
                        />
                        <Form.Control
                            type="date"
                            name="date2"
                            className="date-icon"
                            value={date2}
                            onChange={(e) => { setDate2(e.target.value) }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCombineDate} >Set Date</Button>
                    </Modal.Footer>
                </Modal>
            }

            <Row className="g-2">

                <h6 className="mb-1 mt-3">OR</h6>

                <Col >
                    <Select
                        menuPlacement="top"
                        className="basic-single"
                        classNamePrefix="select"
                        styles={selectStyle}
                        name="entity"
                        options={entity}
                        value={entity.find(i => i.value === curr.from)}
                        onChange={(e) => { dispatched({ type: 'ENTITY VALUE OR', entityValue: e.value, index: index, ind: ind }) }}

                    />
                </Col>

                <Col>
                    <Select
                        menuPlacement="top"
                        className="basic-single"
                        classNamePrefix="select"
                        styles={selectStyle}
                        name="where"
                        value={curr.from === 'VetFields' ? vet.find(i => i.value === curr.where) : practice.find(i => i.value === curr.where)}
                        options={curr.from === 'VetFields' ? vet : practice}
                        onChange={(e) => { dispatched({ type: 'WHERE FIELD OR', where: e.value, wheretext: e.label, index: index, ind: ind }); setShowMultiSelect(e.type);handleWhere() }}

                    />
                </Col>
                <Col>
                    <Select
                        menuPlacement="top"
                       className="basic-single"
                       classNamePrefix="select"
                       styles={selectStyle}
                        name="val"
                        options={operators}
                        value={operators.find(i => i.value === curr.param)}
                        onChange={(e) => { dispatched({ type: 'OPERATOR FIELD OR', param: e.value, index: index, ind: ind }) }}

                    />
                </Col>

                <Col>
                    {showMultiSelect ?
                        <Select
                            menuPlacement="top"
                        className="basic-single"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        styles={selectStyle}
                            isMulti
                            options={
                                curr.where === 'title' ? title_suggestion :
                                    curr.where === 'v_job_types' ? jobtype_suggestion :
                                        curr.where === 'v_species' ? species_suggestion :
                                            curr.where === 'areas' ? areas :
                                                curr.where === 'sm_salary_band' ? salary_band_suggestions :
                                                    null
                            }
                            value={curr.val2}
                            onChange={handleMultiSelectValue}

                        /> :
                        <Form.Control
                            type="text"
                            placeholder="Enter value"
                            name='val'
                            value={curr.val}
                            onChange={(e) => { dispatched({ type: 'VALUE_FIELD_OR', val: e.target.value, index: index, ind: ind }) }}

                        />}
                </Col>



                <Col className="d-flex align-items-center">

                    <div className="action-icons">
                        <span
                            className="delete-icon"
                            onClick={() => { dispatched({ type: 'DELETE INPUT FIELD OR', index: index, field: type, ind: ind }) }}
                        >
                            <Image src={deleteIcon} />
                        </span>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default QueriesInputOr;
