import React, { useState, useEffect } from "react";
import Axios from '../adapters/xhr';
import { Button, Card, Col, Container, Form, Row, Table, Image } from "react-bootstrap";//components
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import personModel from "../constants/personModel";
import practiceModel from "../constants/practiceModel";
import IdleTimerContainer from "../components/IdleTimerContainer"
import deleteIcon from "../assets/icons/delete.svg";
import editIcon from "../assets/icons/edit.svg";
import Swal from "sweetalert2";
import { confirmationAlert, swAlert } from '../helpers/alert';
import InputMask from "react-input-mask";
const ModifyUser = ({ userMe_role }) => {
  const [open, setOpen] = useState(false);
  const [personInput, setPersonInput] = useState(personModel);
  const [practiceInput, setPracticeInput] = useState(practiceModel);
  const [modifyUser, setModifyUser] = useState(false)
  const [addUser, setAdduser] = useState(false)
  const [users, setUsers] = useState([])
  const [userDetail, setUserDetail] = useState({ name: "", email: "", role: "", password: "", password_confirmation: "",tel:"" })
  const [updateUser, setUpdateUser] = useState({ name: "", email: "", role: "5" })
  const [data, setData] = useState(true)

  useEffect(() => {

    const getUserData = async () => {
      let { data } = await Axios.get(`/user`);
      setUsers(data.data)
    }
    getUserData()

  }, [data])

  const handleUserDetail = (item) => {
    setModifyUser(true)
    setAdduser(false)
    setUserDetail({ id: item.id, name: item.name, email: item.email, role: item.role,tel:item.tel,password:"",password_confirmation:"" })
  }

  const handleAddnewUser = () => {

    setUserDetail({ name: "", email: "", role: "5", password: "", password_confirmation: "",tel:"" })
    setModifyUser(false)
    setAdduser(true);

  }

  const handleOnChange = (e) => {

    const { name, value } = e.target
    if (name === "name") {
      setUserDetail(prevState => ({ ...prevState, name: value }))
    }
    if (name === "email") {
      setUserDetail(prevState => ({ ...prevState, email: value }))
    }
    if (name === "role") {
      setUserDetail(prevState => ({ ...prevState, role: value }))
    }
    if (name === "password") {
      setUserDetail(prevState => ({ ...prevState, password: value }))
    }
    if (name === "password_confirmation") {
      setUserDetail(prevState => ({ ...prevState, password_confirmation: value }))
    }
    if (name === "tel") {
        setUserDetail(prevState => ({...prevState, tel: value}))
    }
  }

  const handleSubmitUser = (e) => {
  }

  const handleUpdateUser = async (e) => {
    let payload = { ...userDetail };
      if (userDetail.name.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Enter Your Name",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })
    } else if (userDetail.email.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Enter valid Email address",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })
    }else if (userDetail.tel.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Enter valid Telephone number",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })
    } else {
      await Axios.put(`/user/${userDetail.id}`, payload).then(res => {
        if (res) {
          swAlert(`${res.data.message}`, 'success', true)
        }
      }).catch((err) => {
        let message = err.response.data.message ? err.response.data.message : err.response;
          swAlert(`${message}`, 'error')
      })

    }

    setData(!data)
  }

  const handleCreateUser = async (e) => {

    let payload = { ...userDetail };
    if (userDetail.name.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Enter Your Name",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })
    } else if (userDetail.name === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Enter Your Name",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })

    } else if (userDetail.email.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Enter valid Email address",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })
    } else if (userDetail.tel.length === 0) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Enter valid Telephone number",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })
    }

    else if (userDetail.password.length < 6) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please Enter password of atleast 6 character",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })
    }
    else if (userDetail.password_confirmation !== userDetail.password) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Password does not match",
        showConfirmButton: false,
        timer: 3000,
      }).then(() => {

      })

    } else {
      let data = await Axios.post(`/auth/register`, payload).then(res => {
        if (res) {
          swAlert(`${res.data.message}`, 'success', true)
        }
      }).catch((err) => {
        let message = err.response.data.email ? err.response.data.email[0] : err.response;
        swAlert(`${message}`, 'error')
      })

      setData(!data)
    }
    setData(!data)

  }

  const handleDeleteUser = async (item) => {
    confirmationAlert('Are you sure you want to delete this user?', 'question').then(async (result) => {
      if (result.isConfirmed) {
        await Axios.delete(`/user/${item.id}`).then(res => {
          if (res.data.status) {
            swAlert(`${res.data.message}`, 'success', true)
          }
        }).catch((err) => {
          swAlert(`${err.message}`, true)
        })

        setData(!data)

      }
    })

  }
  const resetState = () => {
    setPersonInput(personModel)
    setPracticeInput(practiceModel)
  }

  return (
    <><IdleTimerContainer></IdleTimerContainer>
      <div
        onClick={() => {

          setOpen(!open);
        }}
      >
      </div>


      <Row className="gx-3">

        <Col>
          <Card className="ml-5 custom-card practice-card">
            <div className="container py-3">
              <Row>
                <Col className="d-flex align-items-center justify-content-between">
                  <h4>Modify Users</h4>
                  <Button
                    onClick={() => {
                      handleAddnewUser();
                    }}
                    className="theme-btn-secondary my-2"
                  >
                    <span className="plus-icon">+</span>
                        Add User
                      </Button>
                </Col>
              </Row>
            </div>

            <div className="container mb-4">
              <Row>
                <Col>
                  <div className="custom-table">
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th width="25%" className="text-center">Access Level</th>
                          <th width="25%" className="text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((item, ind) => (
                          <>
                            <tr key={ind}>
                              <td>{item.name}</td>
                              <td className="text-center">
                                {
                                  item.role === 1 ? 'Super Admin' : item.role === 2 ? 'Admin' : item.role === 5 ? 'User' : item.role === 3 ? 'Search and Update' : item.role === 4 ? 'Search and Export' : item.role === 6 ? 'People Match' : item.role
                                }
                              </td>
                              <td>
                                <div className="action-icons">
                                  <span
                                    className="edit-icon"
                                    onClick={() => {
                                      handleUserDetail(item);
                                    }}
                                  >
                                    <Image src={editIcon} />
                                  </span>
                                  <span onClick={() => { handleDeleteUser(item) }} className="delete-icon">
                                    <Image src={deleteIcon} />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>

        <Col>

          {(addUser || modifyUser) &&
            <Card>
              <div className="container py-3">
                <Row className="my-3">
                  <Col className="d-flex align-items-center justify-content-between">
                    <h4>Create/Update user</h4>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="name"
                          value={userDetail.name}
                          onChange={handleOnChange}
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          autoComplete="off"
                          type="email"
                          name="email"
                          value={userDetail.email}
                          onChange={handleOnChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Role</Form.Label>
                        <Form.Control onChange={handleOnChange} name="role" as="select" value={userDetail.role}>
                          <option value="5">Read Only</option>
                          <option value="1">Super Admin</option>
                          <option value="2">Admin</option>
                          <option value="3">Search and Update</option>
                          <option value="4">Search and Export</option>
                          <option value="6">People Match</option>

                        </Form.Control>
                      </Form.Group>

                        <Form.Group>
                            <Form.Label>Telephone number</Form.Label>
                            <InputMask
                                mask={['+', '4', '4', /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/]}
                                value={userDetail.tel}
                                name="tel"
                                className="form-control"
                                onChange={handleOnChange}
                            />
                        </Form.Group>

                          <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              required
                              autoComplete="new-password"
                              type="password"
                              name="password"
                              placeholder=""
                              onChange={handleOnChange}
                              value={userDetail.password}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              required
                              autoComplete="nope"
                              autoComplete="off"
                              type="password"
                              name="password_confirmation"
                              placeholder=""
                              onChange={handleOnChange}
                              value={userDetail.password_confirmation}
                            />
                          </Form.Group>

                      {modifyUser && <Button onClick={(e) => { handleUpdateUser(e) }} className="theme-btn-primary my-4">Update User</Button>}
                      {addUser && <Button onClick={(e) => { handleCreateUser(e) }} className="theme-btn-primary my-4">Create New User</Button>}

                    </Form>
                  </Col>
                </Row>
              </div>

            </Card>}

        </Col>
      </Row>

    </>
  );
};

export default ModifyUser;
