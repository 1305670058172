import React, {useState, useEffect} from "react";
import Axios from "../adapters/xhr";
import {Button, Card, Col, Container, Form, Row, Table, Image} from "react-bootstrap";
import {handleDate} from "../helpers/helpers";
import {swAlert} from "../helpers/alert";

const MassUpload = () => {

    const handleUploadClick = async () => {
        try {
            let formData = new FormData();
            let file = document.getElementById('file').files[0];
            formData.append('file', file)
            let response = await Axios.post(`/upload/csv/file`, formData, {headers: {'content-type': 'multipart/form-data'}})
            if (response.data.status) {
                swAlert(response.data.message, 'success')
            } else {
                swAlert(response.data.message, 'error')
            }
            document.getElementById('file').value = null;
        } catch (e) {
            document.getElementById('file').value = null
            swAlert(e, 'error')
        }
    }
    return <>
        <div>
            <Row className="gx-3">
                <Col className="col-6">
                    <Card>
                        <Card.Body className="py-4 px-3">
                            <Row>
                                <Col lg={12}>
                                    <h3 className="mb-3">Mass Upload</h3>
                                </Col>
                                <Col lg={12}>
                                    <Form>
                                        <Form.Group>
                                            <h6>Select File</h6>
                                            {/*<Form.File name="upload_file" onChange={handleChangeFile}/>*/}
                                            <input type="file" id="file"/>
                                        </Form.Group>
                                    </Form>
                                </Col>

                                <Col lg={12}>
                                    <Button className="btn theme-btn-primary my-2"
                                            onClick={() => handleUploadClick()}>Upload</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </>
}
export default MassUpload;
