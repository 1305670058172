export const salaryBand = [
    {label: "Up to £10,000", value: "up to 10000"},
    {label: "£10,000 - £14,999", value: "10000 - 14999"},
    {label: "£15,000 - £19,999", value: "15000 - 19999"},
    {label: "£20,000 - £24,999", value: "20000 - 24999"},
    {label: "£25,000 - £29,999", value: "25000 - 29999"},
    {label: "£30,000 - £39,999", value: "30000 - 39999"},
    {label: "£40,000 - £49,999", value: "40000 - 49999"},
    {label: "£50,000 - £59,999", value: "50000 - 59999"},
    {label: "£60,000 - £69,999", value: "60000 - 69999"},
    {label: "£70,000 - £79,999", value: "70000 - 79999"},
    {label: "£80,000 - £89,999", value: "80000 - 89999"},
    {label: "£90,000 - £99,999", value: "90000 - 99999"},
    {label: "£100,000 or more", value: "100000 or more"},
];

export const jobType = [
    {label: "Senior Partner", value: "Senior Partner"},
    {label: "Buyer", value: "Buyer"},
    {label: "Partner", value: "Partner"},
    {label: "Locum Veterinary Surgeon", value: "Locum Veterinary Surgeon"},
    {label: " Student Vet", value: " Student Vet"},
    {label: "Head Nurse", value: "Head Nurse"},
    {label: "Nurse", value: "Nurse"},
    {label: " Locum Nurse", value: " Locum Nurse"},
    {label: "Student Veterinary Nurse", value: "Student Veterinary Nurse"},
    {label: "Practice Manager", value: "Practice Manager"},
    {label: "Admin", value: "Admin"},
];

export const areas = [
    {label: "Aberdeenshire", value: "Aberdeenshire"},
    {label: "Abertawe", value: "Abertawe"},
    {label: "Anglesey", value: "Anglesey"},
    {label: "Angus", value: "Angus"},
    {label: "Argyll", value: "Argyll"},
    {label: "Argyll & Bute", value: "Argyll & Bute"},
    {label: "Ayrshire", value: "Ayrshire"},
    {label: "Banffshire", value: "Banffshire"},
    {label: "Bedfordshire", value: "Bedfordshire"},
    {label: "Berwickshire", value: "Berwickshire"},
    {label: "Berkshire", value: "Berkshire"},
    {label: "Blaenau Gwent", value: "Blaenau Gwent"},
    {label: "Bridgend", value: "Bridgend"},
    {label: "Bristol", value: "Bristol"},
    {label: "Buckinghamshire", value: "Buckinghamshire"},
    {label: "Caerdydd", value: "Caerdydd"},
    {label: "Caerphilly", value: "Caerphilly"},
    {label: "Caithness", value: "Caithness"},
    {label: "Cambridgeshire", value: "Cambridgeshire"},
    {label: "Carmarthenshire", value: "Carmarthenshire"},
    {label: "Ceredigion", value: "Ceredigion"},
    {label: "Channel Isles", value: "Channel Isles"},
    {label: "Cheshire", value: "Cheshire"},
    {label: "Clackmannanshire", value: "Clackmannanshire"},
    {label: "Clwyd", value: "Clwyd"},
    {label: "Co Antrim", value: "Co Antrim"},
    {label: "Co Armagh", value: "Co Armagh"},
    {label: "Co Down", value: "Co Down"},
    {label: "Co Dublin", value: "Co Dublin"},
    {label: "Co Fermanagh", value: "Co Fermanagh"},
    {label: "Co Londonderry", value: "Co Londonderry"},
    {label: "Co Tyrone", value: "Co Tyrone"},
    {label: "Co Westmeath", value: "Co Westmeath"},
    {label: "Co Wexford", value: "Co Wexford"},
    {label: "Conwy", value: "Conwy"},
    {label: "Cornwall", value: "Cornwall"},
    {label: "Cumbria", value: "Cumbria"},
    {label: "Denbighshire", value: "Denbighshire"},
    {label: "Derbyshire", value: "Derbyshire"},
    {label: "Devon", value: "Devon"},
    {label: "Dorset", value: "Dorset"},
    {label: "Dumfries & Galloway", value: "Dumfries & Galloway"},
    {label: "Dumfriesshire", value: "Dumfriesshire"},
    {label: "Dunbartonshire", value: "Dunbartonshire"},
    {label: "Durham", value: "Durham"},
    {label: "Dyfed", value: "Dyfed"},
    {label: "East Ayrshire", value: "East Ayrshire"},
    {label: "East Lothian", value: "East Lothian"},
    {label: "East Sussex", value: "East Sussex"},
    {label: "East Yorkshire", value: "East Yorkshire"},
    {label: "Eilean Siar", value: "Eilean Siar"},
    {label: "Essex", value: "Essex"},
    {label: "Fife", value: "Fife"},
    {label: "Flintshire", value: "Flintshire"},
    {label: "Gloucestershire", value: "Gloucestershire"},
    {label: "Greater London", value: "Greater London"},
    {label: "Greater Manchester", value: "Greater Manchester"},
    {label: "Guernsey", value: "Guernsey"},
    {label: "Gwent", value: "Gwent"},
    {label: "Gwynedd", value: "Gwynedd"},
    {label: "Hampshire", value: "Hampshire"},
    {label: "Herefordshire", value: "Herefordshire"},
    {label: "Hertfordshire", value: "Hertfordshire"},
    {label: "Highland", value: "Highland"},
    {label: "Inverclyde", value: "Inverclyde"},
    {label: "Inverness-shire", value: "Inverness-shire"},
    {label: "Isle of Arran", value: "Isle of Arran"},
    {label: "Isle of Benbecula", value: "Isle of Benbecula"},
    {label: "Isle of Bute", value: "Isle of Bute"},
    {label: "Isle of Islay", value: "Isle of Islay"},
    {label: "Isle of Lewis", value: "Isle of Lewis"},
    {label: "Isle of Man", value: "Isle of Man"},
    {label: "Isle of North Uist", value: "Isle of North Uist"},
    {label: "Isle of Skye", value: "Isle of Skye"},
    {label: "Isle of Tiree", value: "Isle of Tiree"},
    {label: "Isle of Wight", value: "Isle of Wight"},
    {label: "Isles of Scilly", value: "Isles of Scilly"},
    {label: "Jersey", value: "Jersey"},
    {label: "Kent", value: "Kent"},
    {label: "Kincardineshire", value: "Kincardineshire"},
    {label: "Kinross-shire", value: "Kinross-shire"},
    {label: "Kirkcudbrightshire", value: "Kirkcudbrightshire"},
    {label: "Lanarkshire", value: "Lanarkshire"},
    {label: "Lancashire", value: "Lancashire"},
    {label: "Leicestershire", value: "Leicestershire"},
    {label: "Lincolnshire", value: "Lincolnshire"},
    {label: "Merseyside", value: "Merseyside"},
    {label: "Mid Glamorgan", value: "Mid Glamorgan"},
    {label: "Middlesex", value: "Middlesex"},
    {label: "Midlothian", value: "Midlothian"},
    {label: "Monmouthshire", value: "Monmouthshire"},
    {label: "Morayshire", value: "Morayshire"},
    {label: "Nairnshire", value: "Nairnshire"},
    {label: "Newport", value: "Newport"},
    {label: "Norfolk", value: "Norfolk"},
    {label: "North Ayrshire", value: "North Ayrshire"},
    {label: "North East Lincolnshire", value: "North East Lincolnshire"},
    {label: "North Lanarkshire", value: "North Lanarkshire"},
    {label: "North Lincolnshire", value: "North Lincolnshire"},
    {label: "North Somerset", value: "North Somerset"},
    {label: "North Yorkshire", value: "North Yorkshire"},
    {label: "Northamptonshire", value: "Northamptonshire"},
    {label: "Northumberland", value: "Northumberland"},
    {label: "Nottinghamshire", value: "Nottinghamshire"},
    {label: "Orkney", value: "Orkney"},
    {label: "Orkney Islands", value: "Orkney Islands"},
    {label: "Outer Hebrides", value: "Outer Hebrides"},
    {label: "Oxfordshire", value: "Oxfordshire"},
    {label: "Peeblesshire", value: "Peeblesshire"},
    {label: "Pembrokeshire", value: "Pembrokeshire"},
    {label: "Perth & Kinross", value: "Perth & Kinross"},
    {label: "Perthshire", value: "Perthshire"},
    {label: "Powys", value: "Powys"},
    {label: "Renfrewshire", value: "Renfrewshire"},
    {label: "Ross-shire", value: "Ross-shire"},
    {label: "Roxburghshire", value: "Roxburghshire"},
    {label: "Rutland", value: "Rutland"},
    {label: "Scottish Borders", value: "Scottish Borders"},
    {label: "Selkirkshire", value: "Selkirkshire"},
    {label: "Shetland Islands", value: "Shetland Islands"},
    {label: "Shropshire", value: "Shropshire"},
    {label: "Somerset", value: "Somerset"},
    {label: "South Ayrshire", value: "South Ayrshire"},
    {label: "South Glamorgan", value: "South Glamorgan"},
    {label: "South Lanarkshire", value: "South Lanarkshire"},
    {label: "South Yorkshire", value: "South Yorkshire"},
    {label: "Staffordshire", value: "Staffordshire"},
    {label: "Stirlingshire", value: "Stirlingshire"},
    {label: "Suffolk", value: "Suffolk"},
    {label: "Surrey", value: "Surrey"},
    {label: "Torfaen", value: "Torfaen"},
    {label: "Tyne & Wear", value: "Tyne & Wear"},
    {label: "Warwickshire", value: "Warwickshire"},
    {label: "West Dunbartonshire", value: "West Dunbartonshire"},
    {label: "West Glamorgan", value: "West Glamorgan"},
    {label: "West Lothian", value: "West Lothian"},
    {label: "West Midlands", value: "West Midlands"},
    {label: "West Sussex", value: "West Sussex"},
    {label: "West Yorkshire", value: "West Yorkshire"},
    {label: "Wigtownshire", value: "Wigtownshire"},
    {label: "Wiltshire", value: "Wiltshire"},
    {label: "Worcestershire", value: "Worcestershire"},
    {label: "Wrexham", value: "Wrexham"},
];

export const areas2 = [
    {label: "USA Canada", value: "USA Canada"},
    {label: "Europe", value: "Europe"},
    {label: "Australia New Zealand and Asia", value: "Australia New Zealand and Asia"},
];

export const Titles = [
    {label: "Title", value: "title"},
    {label: "ID", value: "vet.id"},
    {value: "initials", label: "Initials"},
    {value: "forename", label: "Forename"},
    {value: "middlenames", label: "Middle names"},
    {value: "surname", label: "Surname"},
    {value: "maiden", label: "Maiden name"},
    {value: "gender", label: "Gender"},
    {value: "working_hours", label: "Working Hours"},
    {value: "v_job_types", label: "Job types"},
    {value: "v_job_types_o", label: "Job types Other"},
    {value: "u_suffix", label: "Suffix"},
    {value: "u_cert", label: "Certs and Dips"},
    {value: "u_uni", label: "University"},
    {value: "u_year", label: "Uni Year"},
    {value: "u_country", label: "Uni Country"},
    {value: "v_species", label: "Species"},
    {value: "v_species_o", label: "Species Other"},
    {value: "v_rcvsnum", label: "RCVS Number"},
    {value: "v_vpma", label: " VMG"},
    {value: "v_address_1", label: "Address 1"},
    {value: "v_address_2", label: "Address 2"},
    {value: "v_address_3", label: "Address 3"},
    {value: "v_town", label: "Town"},
    {value: "v_county", label: "County"},
    {value: "v_postcode", label: "Postcode"},
    {value: "v_country", label: "Country"},
    {value: "v_tel", label: "Tel"},
    {value: "v_email", label: "Primary email"},
    {value: "v_work_email", label: "Secondary email"},
    {value: "sso_email", label: "SSO Email"},
    {value: "lvf", label: "LVF"},
    {value: "v_pma", label: "PMA"},
    {value: "v_sdm", label: "Direct mail"},
    {value: "v_svt", label: "Vet Times"},
    {value: "v_svnt", label: "VN Times"},
    {value: "v_svbj", label: "VBJ"},
    {value: "v_svtl", label: "Livestock"},
    {value: "v_svte", label: "Equine"},
    {value: "v_notes", label: "Notes"},
    {value: "v_svt_request", label: "Request type Vet Times"},
    {value: "v_svt_request_date", label: "Request date Vet Times"},
    {value: "v_svt_abc_name", label: "Vet Times ABC name"},
    {value: "v_svt_abc_answer", label: "Vet Times ABC answer"},
    {value: "v_svnt_request", label: "Request type VN Times"},
    {value: "v_svnt_request_date", label: " Request date VN Times"},
    {value: "v_svnt_abc_name", label: "VN Times ABC name"},
    {value: "v_svnt_abc_answer", label: "VN Times ABC answer"},
    {value: "v_svbj_request", label: "Request type VBJ"},
    {value: "v_svbj_request_date", label: "Request date VBJ"},
    {value: "v_svbj_abc_name", label: "VBJ ABC name"},
    {value: "v_svbj_abc_answer", label: "VBJ ABC answer"},
    {value: "v_sevbd_request", label: "VBD Request type"},
    {value: "v_sevbd_request_date", label: "VBD Request date"},
    {value: "v_s3rd_request", label: "3rd party Request type"},
    {value: "v_s3rd_request_date", label: "3rd party Request date"},
    {value: "v_vnews_d_request", label: "Vettimes d Request type"},
    {value: "v_vnews_d_request_date", label: "Vettimes d Request date"},
    {value: "v_vnews_request", label: "Vettimes w Request type"},
    {value: "v_vnews_request_date", label: "Vettimes w Request date"},
    {value: "v_vjnw_request", label: "Vet Times Jobs w Request type"},
    {value: "v_vjnw_request_date", label: "Vet Times Jobs w Request date"},
    {value: "v_vjnm_request", label: "Vet Times Jobs m Request type"},
    {value: "v_vjnm_request_date", label: "Vet Times Jobs m Request date"},
    {
        value: "v_vt_jobs_marketing_request",
        label: "VT Jobs Marketing Request Type",
    },
    {
        value: "v_vt_jobs_marketing_request_date",
        label: "VT Jobs Marketing Request Date",
    },
    {
        value: "vn_monthly_newsletter_request",
        label: "VN Newsletter m Request Type",
    },
    {
        value: "vn_monthly_newsletter_request_date",
        label: "VN Newsletter m Request Date",
    },
    {value: "updatedby", label: "Updated by"},
    {value: "date", label: "Updated date"},
    {value: "v_sevbd", label: "VBD email"},
    {value: "v_s3rd", label: "3rd party"},
    {value: "v_vnews_d", label: "Vettimes d"},
    {value: "v_vnews", label: "Vettimes w"},
    {value: "v_vjnw", label: "Vet Times Jobs w"},
    {value: "v_vjnm", label: "Vet Times Jobs m"},
    {value: "v_vt_jobs_marketing", label: "VT Jobs Marketing"},
    {value: "vn_monthly_newsletter", label: "VN Newsletter m"},
    {value: "v_sso", label: "SSO"},
    {value: "login_at", label: "Last logged at"},
    {value: "vtapp_sso", label: "VT app SSO"},
    {value: "vtapp_date", label: "VT app Login"},
    {value: "cpd_sso", label: "CPD SSO"},
    {value: "cpd_date", label: "CPD login"},
    {value: "marketplace_sso", label: "Marketplace SSO"},
    {value: "marketplace_date", label: "Marketplace login"},
    {value: "vt_sso", label: "VT SSO"},
    {value: "vt_date", label: "VT login"},
    {value: "vtjobs_sso", label: "VT jobs SSO"},
    {value: "vtjobs_date", label: "VT jobs login"},
    {value: "v_interests", label: "Interests"},
    {value: "ind_type", label: "Sub Type"},
    {value: "ind_vt_start", label: "Vet Times Start date"},
    {value: "ind_vt_end", label: "Vet Times End date"},
    {value: "ind_vt_cost", label: "Vet Times Cost"},
    {value: "ind_vn_start", label: "VN Times Start date"},
    {value: "ind_vn_end", label: "VN Times End date"},
    {value: "ind_vn_cost", label: "VN Times Cost"},
    {value: "ind_vbj_start", label: "VBJ Start date"},
    {value: "ind_vbj_end", label: "VBJ End date"},
    {value: "ind_vbj_cost", label: "VBJ Cost"},
    {value: "ind_agent", label: "Agent"},
    {value: "history", label: "History"},
    {value: "p_updated", label: "Profile Updated"},
    {value: "p_date", label: "Profile Update Date"},
    {value: "academy_sso", label: "Academy SSO"},
    {value: "academy_date", label: "Academy login"},
    {value: "residence", label: "Country Residence"},
    // { value: "phone_number", label: "SM Phone Number" },
    // { value: "contact_method", label: "SM Contact Method" },
    // { value: "rtw", label: "SM Right to work in UK" },
    // { value: "rcvs_2", label: "SM RCVS Skills Match" },
    // { value: "s_postcode", label: "SM Postcode" },
    // { value: "sm_address_1", label: "SM Address 1" },
    // { value: "sm_address_2", label: "SM Address 2" },
    // { value: "sm_address_3", label: "SM Address 3" },
    // { value: "sm_town", label: "SM Town" },
    // { value: "sm_county", label: "SM County" },
    // { value: "distance", label: "SM Distance" },
    // { value: "relocate", label: "SM Relocate" },
    // { value: "prof", label: "SM Job Type" },
    // { value: "pmsother", label: "SM PMS Other" },
    // { value: "areas", label: "SM Locations" },
    // { value: "position", label: "SM Position" },
    // { value: "species_type", label: "SM Species Type" },
    // { value: "pms", label: "SM PMS" },
    // { value: "main_experience", label: "SM Main Experience" },
    // { value: "consultation_general", label: "SM Consultation General" },
    // { value: "consultation_10min", label: "SM Consultation 10 mins" },
    // { value: "castration_dog_cat", label: "SM Castration Dog and Cat" },
    // { value: "castration_rabbit", label: "SM Castration Rabbit" },
    // { value: "spay_dog_cat", label: "SM Spay Dog and Cat" },
    // { value: "spay_rabbit", label: "SM Spay Rabbit" },
    // { value: "c_section", label: "SM C Section" },
    // { value: "extractions_dental", label: "SM Extractions Dental" },
    // { value: "ex_lap", label: "SM Ex lap" },
    // { value: "amputation_limb", label: "SM Amputation Limb" },
    // { value: "removal_lump", label: "SM Removal Lump" },
    // {
    //     value: "radiograph_interpretation",
    //     label: "SM Radiograph Interpretation",
    // },
    // { value: "radiograph_taking", label: "SM Radiograph Taking" },
    // { value: "lab_interpreting_results", label: "SM Interpreting Lab Results" },
    // { value: "emergency_medicine", label: "SM Emergency Medicine" },
    // { value: "ultrasonography", label: "SM Ultrasonography" },
    // { value: "consultation_euthanasia", label: "SM Consultation Euthanasia" },
    // { value: "surgery_orthaepedic", label: "SM Surgery Orthaepedic" },
    // {
    //     value: "investigation_dermatology",
    //     label: "SM Investigation Dermatology",
    // },
    // { value: "ophthalmology", label: "SM Ophthalmology" },
    // { value: "investigation_cardiology", label: "SM Investigation Cardiology" },
    // { value: "investigation_neurology", label: "SM Investigation Neurology" },
    // { value: "cat_spay", label: "SM Cat Spay" },
    // { value: "bitch_spay", label: "SM Bitch Spay" },
    // { value: "clinical_examination", label: "SM Clinical Examination" },
    // { value: "fertility_work", label: "SM Fertility Work" },
    // { value: "lda_surgery", label: "SM LDA Surgery" },
    // { value: "caesareans", label: "SM Caesareans" },
    // { value: "blood_sampling", label: "SM Blood Sampling" },
    // { value: "tb_testing", label: "SM TB Testing" },
    // { value: "tb_per_day", label: "SM TB per day" },
    // { value: "farm_experience", label: "SM Farm Experience" },
    // { value: "investigation_lameness", label: "SM Investigation Lameness" },
    // { value: "dentistry", label: "SM Dentistry" },
    // {
    //     value: "investigation_pre_purchase",
    //     label: "SM Investigation Pre Purchase",
    // },
    // { value: "stud_work", label: "SM Stud Work" },
    // { value: "al_work", label: "SM Al Work" },
    // { value: "anaesthesia", label: "SM Anaesthesia" },
    // { value: "radiology", label: "SM Radiology" },
    // { value: "consultation_nurse", label: "SM Consultaion Nurse" },
    // { value: "blood_samples_taking", label: "SM Blood Samples Taking" },
    // { value: "catheter_insertion", label: "SM Catheter Insertion" },
    // { value: "anaesthetics_monitoring", label: "SM Anaesthetics Monitoring" },
    // { value: "animal_restraint", label: "SM Animal Restaint" },
    // { value: "reception_skills", label: "SM Reception Skills" },
    // { value: "schedule_3_procedures", label: "SM Schedule 3 procedures" },
    // { value: "cv", label: "SM CV" },
    // { value: "sm_profile", label: "SM Profile" },
    // { value: "sm_date", label: "SM Profile Date" },
    // { value: "sm_salary_band", label: "SM Salary band" },
    // { value: "sm_next_role", label: "SM Preferred next role" },
    {
        value: "companion_animal_subs",
        label: "CPD companion animal subscription",
    },
    {value: "exotics_subs", label: "CPD exotics subscription"},
    {value: "equine_subs", label: "CPD equine subscription"},
    {value: "livestock_subs", label: "CPD livestock subscription"},
    {value: "business_subs", label: "CPD business subscription"},
    {value: "well_being_subs", label: "CPD well-being subscription"},
    {value: "para_date1", label: "CPD Parasitology 1st viewed"},
    {value: "para_date2", label: "CPD Parasitology last viewed"},
    {value: "pman_date1", label: "CPD Pain management 1st viewed"},
    {value: "pman_date2", label: "CPD Pain management last viewed"},
    {value: "derma_date1", label: "CPD Dermatology 1st viewed"},
    {value: "derma_date2", label: "CPD Dermatology last viewed"},
    {value: "onco_date1", label: "CPD Oncology 1st viewed"},
    {value: "onco_date2", label: "CPD Oncology last viewed"},
    {value: "spets_date1", label: "CPD Senior pets 1st viewed"},
    {value: "spets_date2", label: "CPD Senior Pets last viewed"},
    {
        value: "gastro_date1",
        label: "CPD Gastrointestine Disorders 1st viewed",
    },
    {
        value: "gastro_date2",
        label: "CPD Gastrointestine Disorders last viewed",
    },
    {value: "cardio_date1", label: "CPD Cardiology 1st viewed"},
    {value: "cardio_date2", label: "CPD Cardiology last viewed"},
    {value: "nutrition_date1", label: "CPD Nutrition 1st viewed"},
    {value: "nutrition_date2", label: "CPD Nutrition last viewed"},
    {value: "rabbits_date1", label: "CPD Rabbits 1st viewed"},
    {value: "rabbits_date2", label: "CPD Rabbits last viewed"},
    {value: "rodents_date1", label: "CPD Rodents 1st viewed"},
    {value: "rodents_date2", label: "CPD Rodents last viewed"},
    {value: "lameness_date1", label: "CPD Lameness 1st viewed"},
    {value: "lameness_date2", label: "CPD Lameness last viewed"},
    {
        value: "equine_respiratory_date1",
        label: "CPD Respiratory diseases (equine) 1st viewed",
    },
    {
        value: "equine_respiratory_date2",
        label: "CPD Respiratory diseases (equine) last viewed",
    },
    {value: "phealth_date1", label: "CPD Preventive Health 1st viewed"},
    {value: "phealth_date2", label: "CPD Preventive Health last viewed"},
    {value: "calf_health_date1", label: "CPD Calf health 1st viewed"},
    {value: "calf_health_date2", label: "CPD Calf health last viewed"},
    {value: "mastitis_date1", label: "CPD Mastitis 1st viewed"},
    {value: "mastitis_date2", label: "CPD Mastitis last viewed"},
    {
        value: "livestock_respiratory_date1",
        label: "CPD Respiratory diseases (livestock) 1st viewed",
    },
    {
        value: "livestock_respiratory_date2",
        label: "CPD Respiratory diseases (livestock) last viewed",
    },
    {value: "parasites_date1", label: "CPD Parasites 1st viewed"},
    {value: "parasites_date2", label: "CPD Parasites last viewed"},
    {value: "finance_date1", label: "CPD Finance 1st viewed"},
    {value: "finance_date2", label: "CPD Finance last viewed"},
    {value: "client_care_date1", label: "CPD Client care 1st viewed"},
    {value: "client_care_date2", label: "CPD Client care last viewed"},
    {value: "technology_date1", label: "CPD Technology 1st viewed"},
    {value: "technology_date2", label: "CPD Technology last viewed"},
    {
        value: "train_and_develop_date1",
        label: "CPD Training and development 1st viewed",
    },
    {
        value: "train_and_develop_date2",
        label: "CPD Training and development last viewed",
    },
    {
        value: "endocrinology_date1",
        label: "Endocrinology 1st viewed"
    },
    {
        value: "endocrinology_date2",
        label: "Endocrinology last viewed"
    },
    {
        value: "anaesthesia_date1",
        label: "Anaesthesia and analgesia 1st viewed"
    },
    {
        value: "anaesthesia_date2",
        label: "Anaesthesia and analgesia last viewed"
    },
    {
        value: "ophthalmology_date1",
        label: "Ophthalmology 1st viewed"
    },
    {
        value: "ophthalmology_date2",
        label: "Ophthalmology last viewed"
    },
    {
        value: "oral_care_date1",
        label: "Oral care 1st viewed"
    },
    {
        value: "oral_care_date2",
        label: "Oral care last viewed"
    },
    {
        value: "wound_management_date1",
        label: "Wound management 1st viewed"
    },
    {
        value: "wound_management_date2",
        label: "Wound management last viewed"
    },
    {
        value: "birds_date1",
        label: "Birds 1st viewed"
    },
    {
        value: "birds_date2",
        label: "Birds last viewed"
    },
    {
        value: "obesity_date1",
        label: "Obesity 1st viewed"
    },
    {
        value: "obesity_date2",
        label: "Obesity last viewed"
    },
    {
        value: "imaging_date1",
        label: "Imaging 1st viewed"
    },
    {
        value: "imaging_date2",
        label: "Imaging last viewed"
    },
    {
        value: "reproduction_date1",
        label: "Reproduction 1st viewed"
    },
    {
        value: "reproduction_date2",
        label: "Reproduction last viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea 1st viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea last viewed"
    },
    {
        value: "lamb_date1",
        label: "Lamb health 1st viewed"
    },
    {
        value: "lamb_date2",
        label: "Lamb health last viewed"
    },

    //        Digital Edition Section Fields
    {value: 'v_svtl_digi', label: 'Livestock Vet Times Digi'},
    {value: 'v_svte_digi', label: 'Equine Vet Times Digi'},
    {value: 'v_svt_digi', label: 'Vet Times Digi'},
    {value: 'v_svt_digi_request', label: 'Request type Vet Times Digi'},
    {value: 'v_svt_digi_request_date', label: 'Request date Vet Times Digi'},
    {value: 'v_svt_digi_abc_name', label: 'Vet Times Digi ABC name'},
    {value: 'v_svt_digi_abc_answer', label: 'Vet Times Digi ABC answer'},

    {value: 'v_svnt_digi', label: 'VN Times Digi'},
    {value: 'v_svnt_digi_request', label: 'Request type VN Times Digi'},
    {value: 'v_svnt_digi_request_date', label: 'Request date VN Times Digi'},
    {value: 'v_svnt_digi_abc_name', label: 'VN Times Digi ABC name'},
    {value: 'v_svnt_digi_abc_answer', label: 'VN Times Digi ABC answer'},

    {value: 'v_svbj_digi', label: 'VBJ Digi'},
    {value: 'v_svbj_digi_request', label: 'Request type VBJ Digi'},
    {value: 'v_svbj_digi_request_date', label: 'Request date VBJ Digi'},
    {value: 'v_svbj_digi_abc_name', label: 'VBJ Digi ABC name'},
    {value: 'v_svbj_digi_abc_answer', label: 'VBJ Digi ABC answer'},

    {value: 'ind_vt_digi_start', label: 'Vet Times Digi Start date'},
    {value: 'ind_vt_digi_end', label: 'Vet Times Digi End date'},
    {value: 'ind_vt_digi_cost', label: 'Vet Times Digi Cost'},

    {value: 'ind_vn_digi_start', label: 'VN Times Digi Start date'},
    {value: 'ind_vn_digi_end', label: 'VN Times Digi End date'},
    {value: 'ind_vn_digi_cost', label: 'VN Times Digi Cost'},

    {value: 'ind_vbj_digi_start', label: 'VBJ Digi Start date'},
    {value: 'ind_vbj_digi_end', label: 'VBJ Digi End date'},
    {value: 'ind_vbj_digi_cost', label: 'VBJ Digi Cost'},
];

export const contains = [
    {value: "LIKE", label: "Contains"},
    {value: "NOT LIKE", label: "Does not contain"},
    {value: "START", label: "Starts with"},
    {value: "END", label: "Ends with"},
    {value: "=", label: "Matches"},
    {value: "<>", label: "Does not match"},
    {value: "IS NULL", label: "Is blank/null"},
    {value: "IS NOT NULL", label: "Is not blank/null"},
    {value: ">", label: "More than"},
    {value: "<", label: "Less than"},
    {value: "BETWEEN", label: "Date between"},
    {value: "NOT BETWEEN", label: "Date not between"},
];

export const databaseList = [
    // { label: "Lat/Long Practices (.csv)", method: "preset_export", option : "latlong_pratices" },
    // { label: "Lat/Long Individuals (.csv)", method: "preset_export", option : "latlong_individuals" },
    {label: "ABC Audit – VT", method: "abc", option: "vt"},
    {label: "ABC Audit – VNT", method: "abc", option: "vn"},
    {label: "All - Practices and Individuals)", method: "all_people_practice", option: "all_people_practice"},
    {label: "Branches and Mains", method: "branch_and_main"},
    {label: "Individuals", method: "people_join", option: ""},
    {label: "Individuals (.sql)", method: "whole", option: "vet"},
    {label: "Practices (.sql)", method: "whole", option: "practice"},
    {label: "Accounts (.sql)", method: "whole", option: "users"},
    {label: "Vetfile Backup", method: "whole", option: ""},
    {label: "Vetfile Stats", method: "/querybuilder/performexport/report", option: ""},
]

export const expHorses = [{label: "Leisure horses", value: "Leisure horses"},
    {label: "Competition horses", value: "Competition horses"},
    {label: "Racehorses", value: "Racehorses"}
]

export const positions = [{label: "All", value: "all"}, {label: "Permanent", value: "permanent"}, {label: "Fixed", value: "fixed"}, {label: "Locum", value: "locum"}]

export const species = [{label: "Small Animal", value: "small"}, {label: "Large Animal", value: "large"}, {label: "Equine", value: "equine"}]

export const yesAndNo = [
    {label: 'Yes', value: 'Yes'},
    {label: 'No', value: 'No'}
]

export const animalType = [
    {label: 'Small Animals', value: 'Small Animals'},
    {label: 'Mixed Animals', value: 'Mixed Animals'},
    {label: 'Equine', value: 'Equine'},
    {label: 'Farm Animals', value: 'Farm Animals'},
    {label: 'Companion Animal', value: 'Companion Animal'},
    {label: 'University/College', value: 'University/College'},
    {label: 'Zoo/Wildlife', value: 'Zoo/Wildlife'},
    {label: 'Vetfile', value: 'Vetfile'}
]

export const industryType = [
    {
        label: 'Industry', value: 'Industry',
    }
]

export const dropDownreports = [
    {label: 'All', value: 'all'},
    {label: 'My Report', value: 'my_report'},
    {label: 'Shared', value: 'shared'},
    {label: 'Vetfile', value: 'vetfile'},
    {label: 'Direct mail', value: 'direct_email'}
]

export const dropDownSenderNames = [
    {label: 'Vet Times', value: 'Vet Times'},
    {label: 'VN Times', value: 'VN Times'},
    {label: 'VBJ', value: 'VBJ'},
    {label: 'PeopleMatch', value: 'PeopleMatch'},
    {label: 'Vetfile', value: 'Vetfile'}
]

export const dropDownEventsRegistration = [
    {label: 'VN HH Dec 24', value: 'VN HH Dec 24'},
    {label: 'VN HH Nov 24', value: 'VN HH Nov 24'},
    {label: 'VN HH Oct 24', value: 'VN HH Oct 24'},
    {label: 'VN HH Sep 24', value: 'VN HH Sep 24'},
    {label: 'VN HH Aug 24', value: 'VN HH Aug 24'},
    {label: 'VN HH Jul 24', value: 'VN HH Jul 24'},
    {label: 'VN HH Jun 24', value: 'VN HH Jun 24'},
    {label: 'VN HH May 24', value: 'VN HH May 24'},
    {label: 'VN HH Apr 24', value: 'VN HH Apr 24'},
    {label: 'VN HH Mar 24', value: 'VN HH Mar 24'},
    {label: 'VN HH Feb 24', value: 'VN HH Feb 24'},
    {label: 'VN HH Jan 24', value: 'VN HH Jan 24'},
    {label: 'VN HH Dec 23', value: 'VN HH Dec 23'},
    {label: 'VN HH Nov 23', value: 'VN HH Nov 23'},
    {label: 'VN HH Oct 23', value: 'VN HH Oct 23'},
    {label: 'VN HH Sep 23', value: 'VN HH Sep 23'},
    {label: 'VN HH Aug 23', value: 'VN HH Aug 23'},
    {label: 'VN HH Jul 23', value: 'VN HH Jul 23'},
    {label: 'VN HH Jun 23', value: 'VN HH Jun 23'},
    {label: 'VN HH May 23', value: 'VN HH May 23'},
    {label: 'VN HH Apr 23', value: 'VN HH Apr 23'},
    {label: 'VN HH Mar 23', value: 'VN HH Mar 23'},
    {label: 'VN HH Feb 23', value: 'VN HH Feb 23'},
    {label: 'VN HH Jan 23', value: 'VN HH Jan 23'},
    {label: 'VN HH Dec 22', value: 'VN HH Dec 22'},
    {label: 'VN HH Nov 22', value: 'VN HH Nov 22'},
    {label: 'VN HH Oct 22', value: 'VN HH Oct 22'},
    {label: 'VN HH Sep 22', value: 'VN HH Sep 22'},
    {label: 'VN HH Aug 22', value: 'VN HH Aug 22'},
    {label: 'VN HH Jul 22', value: 'VN HH Jul 22'},
    {label: 'VN HH Jun 22', value: 'VN HH Jun 22'},
    {label: 'VN HH May 22', value: 'VN HH May 22'},
    {label: 'VN HH Apr 22', value: 'VN HH Apr 22'},
    {label: 'VN HH Mar 22', value: 'VN HH Mar 22'},
    {label: 'VN HH Feb 22', value: 'VN HH Feb 22'},
    {label: 'VN HH Jan 22', value: 'VN HH Jan 22'},
    {label: 'VN HH Dec 21', value: 'VN HH Dec 21'},
    {label: 'VN HH Nov 21', value: 'VN HH Nov 21'},
    {label: 'VN HH Oct 21', value: 'VN HH Oct 21'},
    {label: 'VN HH Sep 21', value: 'VN HH Sep 21'},
    {label: 'VN HH Aug 21', value: 'VN HH Aug 21'},
    {label: 'VN HH Jul 21', value: 'VN HH Jul 21'},
    {label: 'VN HH Jun 21', value: 'VN HH Jun 21'},
    {label: 'VN HH May 21', value: 'VN HH May 21'},
    {label: 'VN HH Apr 21', value: 'VN HH Apr 21'},
    {label: 'VN HH Mar 21', value: 'VN HH Mar 21'},
    {label: 'VN HH Feb 21', value: 'VN HH Feb 21'},
    {label: 'VN HH Jan 21', value: 'VN HH Jan 21'},
    {label: 'VN HH Dec 20', value: 'VN HH Dec 20'},
    {label: 'VN HH Nov 20', value: 'VN HH Nov 20'},
    {label: 'VN HH Oct 20', value: 'VN HH Oct 20'},
]

export const dropDownEventsCongresses = [
    {label: 'VMG 23', value: 'VMG 23'},
    {label: 'SPVS 23', value: 'SPVS 23'},
    {label: 'RVC Careers Fair 23', value: 'RVC Careers Fair 23'},
    {label: 'Cambridge Careers Fair 23', value: 'Cambridge Careers Fair 23'},
    {label: 'Glasgow Careers Fair 23', value: 'Glasgow Careers Fair 23'},
    {label: 'BSAVA 23', value: 'BSAVA 23'},
    {label: 'Bristol Careers Fair 23', value: 'Bristol Careers Fair 23'},
    {label: 'Nottingham Careers Fair 23', value: 'Nottingham Careers Fair 23'},
    {label: 'Liverpool Careers Fair 23', value: 'Liverpool Careers Fair 23'},
    {label: 'Edinburgh Careers Fair 23', value: 'Edinburgh Careers Fair 23'},
    {label: 'BVA Live 23', value: 'BVA Live 23'},
    {label: 'Vet Trust 23', value: 'Vet Trust 23'},
    {label: 'BEVA 23', value: 'BEVA 23'},
    {label: 'ALBA 23', value: 'ALBA 23'},
    {label: 'Vet Dynamics 23', value: 'Vet Dynamics 23'},
    {label: 'BVNA 23', value: 'BVNA 23'},
    {label: 'BVRA 23', value: 'BVRA 23'},
    {label: 'BCVA 23', value: 'BCVA 23'},
    {label: 'Vets Now Leeds 23', value: 'Vets Now Leeds 23'},
    {label: 'London Vet Show 23', value: 'London Vet Show 23'},
    {label: 'Surrey Careers Fair 23', value: 'Surrey Careers Fair 23'},
    {label: 'VMG 24', value: 'VMG 24'},
    {label: 'SPVS 24', value: 'SPVS 24'},
    {label: 'RVC Careers Fair 24', value: 'RVC Careers Fair 24'},
    {label: 'Cambridge Careers Fair 24', value: 'Cambridge Careers Fair 24'},
    {label: 'Glasgow Careers Fair 24', value: 'Glasgow Careers Fair 24'},
    {label: 'BSAVA 24', value: 'BSAVA 24'},
    {label: 'Bristol Careers Fair 24', value: 'Bristol Careers Fair 24'},
    {label: 'Nottingham Careers Fair 24', value: 'Nottingham Careers Fair 24'},
    {label: 'Liverpool Careers Fair 24', value: 'Liverpool Careers Fair 24'},
    {label: 'Edinburgh Careers Fair 24', value: 'Edinburgh Careers Fair 24'},
    {label: 'BVA Live 24', value: 'BVA Live 24'},
    {label: 'Vet Trust 24', value: 'Vet Trust 24'},
    {label: 'BEVA 24', value: 'BEVA 24'},
    {label: 'ALBA 24', value: 'ALBA 24'},
    {label: 'Vet Dynamics 24', value: 'Vet Dynamics 24'},
    {label: 'BVNA 24', value: 'BVNA 24'},
    {label: 'BVRA 24', value: 'BVRA 24'},
    {label: 'BCVA 24', value: 'BCVA 24'},
    {label: 'Vets Now Leeds 24', value: 'Vets Now Leeds 24'},
    {label: 'London Vet Show 24', value: 'London Vet Show 24'},
    {label: 'Surrey Careers Fair 24', value: 'Surrey Careers Fair 24'},
    {label: 'VMG 25', value: 'VMG 25'},
    {label: 'SPVS 25', value: 'SPVS 25'},
    {label: 'RVC Careers Fair 25', value: 'RVC Careers Fair 25'},
    {label: 'Cambridge Careers Fair 25', value: 'Cambridge Careers Fair 25'},
    {label: 'Glasgow Careers Fair 25', value: 'Glasgow Careers Fair 25'},
    {label: 'BSAVA 25', value: 'BSAVA 25'},
    {label: 'Bristol Careers Fair 25', value: 'Bristol Careers Fair 25'},
    {label: 'Nottingham Careers Fair 25', value: 'Nottingham Careers Fair 25'},
    {label: 'Liverpool Careers Fair 25', value: 'Liverpool Careers Fair 25'},
    {label: 'Edinburgh Careers Fair 25', value: 'Edinburgh Careers Fair 25'},
    {label: 'BVA Live 25', value: 'BVA Live 25'},
    {label: 'Vet Trust 25', value: 'Vet Trust 25'},
    {label: 'BEVA 25', value: 'BEVA 25'},
    {label: 'ALBA 25', value: 'ALBA 25'},
    {label: 'Vet Dynamics 25', value: 'Vet Dynamics 25'},
    {label: 'BVNA 25', value: 'BVNA 25'},
    {label: 'BVRA 25', value: 'BVRA 25'},
    {label: 'BCVA 25', value: 'BCVA 25'},
    {label: 'Vets Now Leeds 25', value: 'Vets Now Leeds 25'},
    {label: 'London Vet Show 25', value: 'London Vet Show 25'},
    {label: 'Surrey Careers Fair 25', value: 'Surrey Careers Fair 25'},
    {label: 'Harper & Keele Careers Fair 24', value: 'Harper & Keele Careers Fair 24'},
    {label: 'Harper & Keele Careers Fair 25', value: 'Harper & Keele Careers Fair 25'},
    {label: 'London Vet Show 22', value: 'London Vet Show 22'},
]

export const oralCareKitOptions = [
    {value: "2015", label: "2015", fieldName1: "2015 OCK Req name", fieldName2: "2015 OCK Req email", f1Value: 'ock_req_name', f2Value: 'ock_req_email'},
    {value: "2016", label: "2016", fieldName1: "2016 OCK Req name", fieldName2: "2016 OCK Req email", f1Value: 'ock_req_name_2016', f2Value: 'ock_req_email_2016'},
    {value: "2017", label: "2017", fieldName1: "2017 OCK Req name", fieldName2: "2017 OCK Req email", f1Value: 'ock_req_name_2017', f2Value: 'ock_req_email_2017'},
    {value: "2018", label: "2018", fieldName1: "2018 OCK Req name", fieldName2: "2018 OCK Req email", f1Value: 'ock_req_name_2018', f2Value: 'ock_req_email_2018'},
    {value: "2019", label: "2019", fieldName1: "2019 OCK Req name", fieldName2: "2019 OCK Req email", f1Value: 'ock_req_name_2019', f2Value: 'ock_req_email_2019'},
    {value: "2020", label: "2020", fieldName1: "2020 OCK Req name", fieldName2: "2020 OCK Req email", f1Value: 'ock_req_name_2020', f2Value: 'ock_req_email_2020'},
    {value: "2021", label: "2021", fieldName1: "2021 OCK Req name", fieldName2: "2021 OCK Req email", f1Value: 'ock_req_name_2021', f2Value: 'ock_req_email_2021'},
    {value: "2022", label: "2022", fieldName1: "2022 OCK Req name", fieldName2: "2022 OCK Req email", f1Value: 'ock_req_name_2022', f2Value: 'ock_req_email_2022'},
    {value: "2023", label: "2023", fieldName1: "2023 OCK Req name", fieldName2: "2023 OCK Req email", f1Value: 'ock_req_name_2023', f2Value: 'ock_req_email_2023'},
    {value: "2024", label: "2024", fieldName1: "2024 OCK Req name", fieldName2: "2024 OCK Req email", f1Value: 'ock_req_name_2024', f2Value: 'ock_req_email_2024'}
]

export const corporate_fields = ['pethome', 'vetsnow', 'cvs', 'vetpartners', 'medivet', 'linnaeus', 'ivc'];

export const buying_fields = ['mwi_group', 'vetcel', 'vetdynamics', 'vetshare', 'vetsure', 'xlvets'];


export const wp_countries = [
    {label: "England", value: "England"},
    {label: "Scotland", value: "Scotland"},
    {label: "Wales", value: "Wales"},
    {label: "Northern Ireland", value: "Northern Ireland"},
    {label: "Channel Islands", value: "Channel Islands"},
    {label: "United States", value: "United States"},
    {label: "Afghanistan", value: "Afghanistan"},
    {label: "Albania", value: "Albania"},
    {label: "Algeria", value: "Algeria"},
    {label: "American Samoa", value: "American Samoa"},
    {label: "Andorra", value: "Andorra"},
    {label: "Angola", value: "Angola"},
    {label: "Anguilla", value: "Anguilla"},
    {label: "Antarctica", value: "Antarctica"},
    {label: "Antigua And Barbuda", value: "Antigua And Barbuda"},
    {label: "Argentina", value: "Argentina"},
    {label: "Armenia", value: "Armenia"},
    {label: "Aruba", value: "Aruba"},
    {label: "Australia", value: "Australia"},
    {label: "Austria", value: "Austria"},
    {label: "Azerbaijan", value: "Azerbaijan"},
    {label: "Bahamas", value: "Bahamas"},
    {label: "Bahrain", value: "Bahrain"},
    {label: "Bangladesh", value: "Bangladesh"},
    {label: "Barbados", value: "Barbados"},
    {label: "Belarus", value: "Belarus"},
    {label: "Belgium", value: "Belgium"},
    {label: "Belize", value: "Belize"},
    {label: "Benin", value: "Benin"},
    {label: "Bermuda", value: "Bermuda"},
    {label: "Bhutan", value: "Bhutan"},
    {label: "Bolivia", value: "Bolivia"},
    {label: "Bosnia And Herzegovina", value: "Bosnia And Herzegovina"},
    {label: "Botswana", value: "Botswana"},
    {label: "Bouvet Island", value: "Bouvet Island"},
    {label: "Brazil", value: "Brazil"},
    {label: "British Indian Ocean Territory", value: "British Indian Ocean Territory"},
    {label: "Brunei Darussalam", value: "Brunei Darussalam"},
    {label: "Bulgaria", value: "Bulgaria"},
    {label: "Burkina Faso", value: "Burkina Faso"},
    {label: "Burundi", value: "Burundi"},
    {label: "Cambodia", value: "Cambodia"},
    {label: "Cameroon", value: "Cameroon"},
    {label: "Canada", value: "Canada"},
    {label: "Cape Verde", value: "Cape Verde"},
    {label: "Cayman Islands", value: "Cayman Islands"},
    {label: "Central African Republic", value: "Central African Republic"},
    {label: "Chad", value: "Chad"},
    {label: "Chile", value: "Chile"},
    {label: "China", value: "China"},
    {label: "Christmas Island", value: "Christmas Island"},
    {label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands"},
    {label: "Colombia", value: "Colombia"},
    {label: "Comoros", value: "Comoros"},
    {label: "Congo", value: "Congo"},
    {label: "Congo, The Democratic Republic Of The", value: "Congo, The Democratic Republic Of The"},
    {label: "Cook Islands", value: "Cook Islands"},
    {label: "Costa Rica", value: "Costa Rica"},
    {label: "Cote D'Ivoire", value: "Cote D'Ivoire"},
    {label: "Croatia (Local Name: Hrvatska)", value: "Croatia (Local Name: Hrvatska)"},
    {label: "Cuba", value: "Cuba"},
    {label: "Cyprus", value: "Cyprus"},
    {label: "Czech Republic", value: "Czech Republic"},
    {label: "Denmark", value: "Denmark"},
    {label: "Djibouti", value: "Djibouti"},
    {label: "Dominica", value: "Dominica"},
    {label: "Dominican Republic", value: "Dominican Republic"},
    {label: "East Timor", value: "East Timor"},
    {label: "Ecuador", value: "Ecuador"},
    {label: "Egypt", value: "Egypt"},
    {label: "El Salvador", value: "El Salvador"},
    {label: "Equatorial Guinea", value: "Equatorial Guinea"},
    {label: "Eritrea", value: "Eritrea"},
    {label: "Estonia", value: "Estonia"},
    {label: "Ethiopia", value: "Ethiopia"},
    {label: "Falkland Islands (Malvinas)", value: "Falkland Islands (Malvinas)"},
    {label: "Faroe Islands", value: "Faroe Islands"},
    {label: "Fiji", value: "Fiji"},
    {label: "Finland", value: "Finland"},
    {label: "France", value: "France"},
    {label: "France, Metropolitan", value: "France, Metropolitan"},
    {label: "French Guiana", value: "French Guiana"},
    {label: "French Polynesia", value: "French Polynesia"},
    {label: "French Southern Territories", value: "French Southern Territories"},
    {label: "Gabon", value: "Gabon"},
    {label: "Gambia", value: "Gambia"},
    {label: "Georgia", value: "Georgia"},
    {label: "Germany", value: "Germany"},
    {label: "Ghana", value: "Ghana"},
    {label: "Gibraltar", value: "Gibraltar"},
    {label: "Greece", value: "Greece"},
    {label: "Greenland", value: "Greenland"},
    {label: "Grenada", value: "Grenada"},
    {label: "Guadeloupe", value: "Guadeloupe"},
    {label: "Guam", value: "Guam"},
    {label: "Guatemala", value: "Guatemala"},
    {label: "Guinea", value: "Guinea"},
    {label: "Guinea-Bissau", value: "Guinea-Bissau"},
    {label: "Guyana", value: "Guyana"},
    {label: "Haiti", value: "Haiti"},
    {label: "Heard And Mc Donald Islands", value: "Heard And Mc Donald Islands"},
    {label: "Holy See (Vatican City State)", value: "Holy See (Vatican City State)"},
    {label: "Honduras", value: "Honduras"},
    {label: "Hong Kong", value: "Hong Kong"},
    {label: "Hungary", value: "Hungary"},
    {label: "Iceland", value: "Iceland"},
    {label: "India", value: "India"},
    {label: "Indonesia", value: "Indonesia"},
    {label: "Iran (Islamic Republic Of)", value: "Iran (Islamic Republic Of)"},
    {label: "Iraq", value: "Iraq"},
    {label: "Ireland", value: "Ireland"},
    {label: "Israel", value: "Israel"},
    {label: "Italy", value: "Italy"},
    {label: "Jamaica", value: "Jamaica"},
    {label: "Japan", value: "Japan"},
    {label: "Jordan", value: "Jordan"},
    {label: "Kazakhstan", value: "Kazakhstan"},
    {label: "Kenya", value: "Kenya"},
    {label: "Kiribati", value: "Kiribati"},
    {label: "Korea, Democratic People's Republic Of", value: "Korea, Democratic People's Republic Of"},
    {label: "Korea, Republic Of", value: "Korea, Republic Of"},
    {label: "Kuwait", value: "Kuwait"},
    {label: "Kyrgyzstan", value: "Kyrgyzstan"},
    {label: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic"},
    {label: "Latvia", value: "Latvia"},
    {label: "Lebanon", value: "Lebanon"},
    {label: "Lesotho", value: "Lesotho"},
    {label: "Liberia", value: "Liberia"},
    {label: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya"},
    {label: "Liechtenstein", value: "Liechtenstein"},
    {label: "Lithuania", value: "Lithuania"},
    {label: "Luxembourg", value: "Luxembourg"},
    {label: "Macau", value: "Macau"},
    {label: "Macedonia, Former Yugoslav Republic Of", value: "Macedonia, Former Yugoslav Republic Of"},
    {label: "Madagascar", value: "Madagascar"},
    {label: "Malawi", value: "Malawi"},
    {label: "Malaysia", value: "Malaysia"},
    {label: "Maldives", value: "Maldives"},
    {label: "Mali", value: "Mali"},
    {label: "Malta", value: "Malta"},
    {label: "Marshall Islands", value: "Marshall Islands"},
    {label: "Martinique", value: "Martinique"},
    {label: "Mauritania", value: "Mauritania"},
    {label: "Mauritius", value: "Mauritius"},
    {label: "Mayotte", value: "Mayotte"},
    {label: "Mexico", value: "Mexico"},
    {label: "Micronesia, Federated States Of", value: "Micronesia, Federated States Of"},
    {label: "Moldova, Republic Of", value: "Moldova, Republic Of"},
    {label: "Monaco", value: "Monaco"},
    {label: "Mongolia", value: "Mongolia"},
    {label: "Montserrat", value: "Montserrat"},
    {label: "Morocco", value: "Morocco"},
    {label: "Mozambique", value: "Mozambique"},
    {label: "Myanmar", value: "Myanmar"},
    {label: "Namibia", value: "Namibia"},
    {label: "Nauru", value: "Nauru"},
    {label: "Nepal", value: "Nepal"},
    {label: "Netherlands", value: "Netherlands"},
    {label: "Netherlands Antilles", value: "Netherlands Antilles"},
    {label: "New Caledonia", value: "New Caledonia"},
    {label: "New Zealand", value: "New Zealand"},
    {label: "Nicaragua", value: "Nicaragua"},
    {label: "Niger", value: "Niger"},
    {label: "Nigeria", value: "Nigeria"},
    {label: "Niue", value: "Niue"},
    {label: "Norfolk Island", value: "Norfolk Island"},
    {label: "Northern Mariana Islands", value: "Northern Mariana Islands"},
    {label: "Norway", value: "Norway"},
    {label: "Oman", value: "Oman"},
    {label: "Pakistan", value: "Pakistan"},
    {label: "Palau", value: "Palau"},
    {label: "Panama", value: "Panama"},
    {label: "Papua New Guinea", value: "Papua New Guinea"},
    {label: "Paraguay", value: "Paraguay"},
    {label: "Peru", value: "Peru"},
    {label: "Philippines", value: "Philippines"},
    {label: "Pitcairn", value: "Pitcairn"},
    {label: "Poland", value: "Poland"},
    {label: "Portugal", value: "Portugal"},
    {label: "Puerto Rico", value: "Puerto Rico"},
    {label: "Qatar", value: "Qatar"},
    {label: "Reunion", value: "Reunion"},
    {label: "Romania", value: "Romania"},
    {label: "Russian Federation", value: "Russian Federation"},
    {label: "Rwanda", value: "Rwanda"},
    {label: "Saint Kitts And Nevis", value: "Saint Kitts And Nevis"},
    {label: "Saint Lucia", value: "Saint Lucia"},
    {label: "Saint Vincent And The Grenadines", value: "Saint Vincent And The Grenadines"},
    {label: "Samoa", value: "Samoa"},
    {label: "San Marino", value: "San Marino"},
    {label: "Sao Tome And Principe", value: "Sao Tome And Principe"},
    {label: "Saudi Arabia", value: "Saudi Arabia"},
    {label: "Senegal", value: "Senegal"},
    {label: "Seychelles", value: "Seychelles"},
    {label: "Sierra Leone", value: "Sierra Leone"},
    {label: "Singapore", value: "Singapore"},
    {label: "Slovakia (Slovak Republic)", value: "Slovakia (Slovak Republic)"},
    {label: "Slovenia", value: "Slovenia"},
    {label: "Solomon Islands", value: "Solomon Islands"},
    {label: "Somalia", value: "Somalia"},
    {label: "South Africa", value: "South Africa"},
    {label: "South Georgia, South Sandwich Islands", value: "South Georgia, South Sandwich Islands"},
    {label: "Spain", value: "Spain"},
    {label: "Sri Lanka", value: "Sri Lanka"},
    {label: "St. Helena", value: "St. Helena"},
    {label: "St. Pierre And Miquelon", value: "St. Pierre And Miquelon"},
    {label: "Sudan", value: "Sudan"},
    {label: "Suriname", value: "Suriname"},
    {label: "Svalbard And Jan Mayen Islands", value: "Svalbard And Jan Mayen Islands"},
    {label: "Swaziland", value: "Swaziland"},
    {label: "Sweden", value: "Sweden"},
    {label: "Switzerland", value: "Switzerland"},
    {label: "Syrian Arab Republic", value: "Syrian Arab Republic"},
    {label: "Taiwan", value: "Taiwan"},
    {label: "Tajikistan", value: "Tajikistan"},
    {label: "Tanzania, United Republic Of", value: "Tanzania, United Republic Of"},
    {label: "Thailand", value: "Thailand"},
    {label: "Togo", value: "Togo"},
    {label: "Tokelau", value: "Tokelau"},
    {label: "Tonga", value: "Tonga"},
    {label: "Trinidad And Tobago", value: "Trinidad And Tobago"},
    {label: "Tunisia", value: "Tunisia"},
    {label: "Turkey", value: "Turkey"},
    {label: "Turkmenistan", value: "Turkmenistan"},
    {label: "Turks And Caicos Islands", value: "Turks And Caicos Islands"},
    {label: "Tuvalu", value: "Tuvalu"},
    {label: "Uganda", value: "Uganda"},
    {label: "Ukraine", value: "Ukraine"},
    {label: "United Arab Emirates", value: "United Arab Emirates"},
    {label: "United States Minor Outlying Islands", value: "United States Minor Outlying Islands"},
    {label: "Uruguay", value: "Uruguay"},
    {label: "Uzbekistan", value: "Uzbekistan"},
    {label: "Vanuatu", value: "Vanuatu"},
    {label: "Venezuela", value: "Venezuela"},
    {label: "Viet Nam", value: "Viet Nam"},
    {label: "Virgin Islands (British)", value: "Virgin Islands (British)"},
    {label: "Virgin Islands (U.S.)", value: "Virgin Islands (U.S.)"},
    {label: "Wallis And Futuna Islands", value: "Wallis And Futuna Islands"},
    {label: "Western Sahara", value: "Western Sahara"},
    {label: "Yemen", value: "Yemen"},
    {label: "Yugoslavia", value: "Yugoslavia"},
    {label: "Zambia", value: "Zambia"},
    {label: "Zimbabwe", value: "Zimbabwe"},
]



