import React, { useState, useEffect } from "react";
import { Form, Button, Image } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import deleteIcon from "../assets/icons/delete.svg";
import { vet, practice } from "../constants/advanceQueryBuilder"
import Axios from '../adapters/xhr';
import Select from "react-select";
import { selectStyle } from '../styling/select-style'

const MassUpdateSidebar = ({ entity, handleShowResult,closeMassUpdateSidebar }) => {

  var [inputField, setInputField] = useState([{ fieldType: "title", option: "set to blank", value: "" }])

  const addAnotherFieldHandler = () => {
    setInputField([...inputField, { fieldType: "", option: "set to blank", value: "", button: "remove" }])
  }

  const setInputFieldHandler = (index, e) => {
    inputField[index].fieldType = e

    let temp = inputField[index].fieldType;
    setInputField([...inputField])
  }

  const setInputOptionHandler = (index, value) => {
    inputField[index].option = value
    setInputField([...inputField])
  }

  const setInputValue = (index, value) => {
    inputField[index].value = value
    setInputField([...inputField])
  }

  const removeInputField = (ind) => {
    inputField.splice(ind, 1)
    setInputField([...inputField])
  }

  useEffect(() => {
  }, [inputField])

  const handleMassUpdate = async () => {
    let payload = {
      'entity': entity,
      'fields': []
    }

    inputField.forEach(x => {
      let data = {
        action: x['option'],
        field: x['fieldType'].value,
        value: x['value']
      }

      payload.fields.push(data)
    });

    await Axios.put(`/vet/mass/update`, payload, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${localStorage.token}`
      },
    });
    //handleShowResult()
      closeMassUpdateSidebar()


  }

  return (
    <>
      <PerfectScrollbar>
        <h4 className="my-3 px-4">
          <span>Mass update all results from yourasdf search query</span>
        </h4>
        <span className="px-4 sidebar-text-sm mb-3 d-block">
          You can only select fields that appear in search query.
        </span>

        {inputField.map((input, ind) => (
          <>
            <div className="px-4 alice-blue-bg py-2 mb-3">
              <label className="form-label">Select Field</label>
              {/*<Form.Control onChange={(e) => { setInputFieldHandler(ind, e.target.value) }} name="fieldType" className="form-select" as="select" placeholder="title" value={inputField[ind].fieldType}>*/}

              {/*  {entity === "VetFields" ? (vet.map((item) => (<option value={item.value} >{item.label}</option>))) : practice.map((item) => (<option value={item.value} >{item.label}</option>))}*/}
              {/*</Form.Control>*/}

                  <Select
                      className="basic-single"
                      classNamePrefix="select"
                      name="fieldType"
                      styles={selectStyle}
                      options={entity === "VetFields" ? vet : practice}
                      value={inputField[ind].fieldType}
                      onChange={(e) => { setInputFieldHandler(ind,e) }}

                  />


              <label className="form-label">Select Option</label>
              <Form.Control onChange={(e) => { setInputOptionHandler(ind, e.target.value) }} name="option" className="form-select" as="select" value={input.option}>
                <option value="set to blank">Set to blank</option>
                <option value="new">New Value</option>
                <option value="begin">Add new value to beginning</option>
                <option value="end">Add new value to ending</option>
                <option value="replace">Replace part of current value</option>
                <option value="date">New Date (DD/MM/YYYY)</option>
              </Form.Control>

              {input.option !== "set to blank" && input.option !== "date" &&
                <>
                  <Form.Label>Set Value</Form.Label>
                  <Form.Control onChange={(e) => { setInputValue(ind, e.target.value) }} name="value" type="text" value={input.value} className="form-control" />
                </>
              }
              {input.option === "date" &&
                <>
                  <Form.Label>Set Date</Form.Label>
                  <Form.Control onChange={(e) => { setInputValue(ind, e.target.value) }} name="value" type="date" value={input.value} className="form-control" />
                </>
              }


              {input.button === "remove" &&
                (<Button onClick={() => { removeInputField(ind) }} type="submit" className="btn ps-0 theme-btn-outline-secondary ms-auto my-2 d-flex align-items-center">
                  <span an className="delete-icon me-3 ms-1">
                    <Image src={deleteIcon} />
                  </span>
                    Remove this block
                </Button>)}
            </div>
          </>
        ))}
        <div className="px-4 mt-2">
          <Button onClick={addAnotherFieldHandler} type="submit" className="btn theme-btn-primary my-2">
            <span className="plus-icon">+</span> Add another field
          </Button>
        </div>
        <hr className="m-4" />
        <h4 className="mb-3 px-4">
          <span>Important</span>
        </h4>
        <span className="px-4 sidebar-text-sm d-block">
          After completing the mass update the system will create a backup of this query search. This will be stored on
          the system until midnight (00:00). To undo the mass update, use the Revert changes icon on the left hand nav
          bar and follow the instructions. If mass update contains both business and people windows, then both tables
          will be stored. You can only revert from the latest mass update
        </span>
        <div className="px-4 mb-4">
          <Button onClick={handleMassUpdate} type="submit" className="btn w-100 theme-btn-secondary my-4">
            Mass update all records
          </Button>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default MassUpdateSidebar;
