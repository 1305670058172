import React, { useState,useEffect } from "react";
import { Button, Col, Row, Card, Table ,Pagination, Collapse} from "react-bootstrap";
//perfect-scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import QueriesInput from "./QueriesInput";
import Axios from "../adapters/xhr";
//react-redux
import { useDispatch, useSelector } from "react-redux";
import { search, openPractice, openPerson,handleSendPersonId } from "../actions/userActions";
import GlobalLoader from "./GlobalLoader";
import ExportBuilderSidebar from "./ExportBuilderSidebar";
import useOnclickOutside from "react-cool-onclickoutside";
import MassUpdateSidebar from "./MassUpdateSidebar";
const AdvanceQuery = () => {
    const [loading , setLoading] = useState(false)
    const [showPagination , setShowPagination] = useState(false)
    const [respData, setRespData] = useState({});
    const [dataPerPage] = useState(100)
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage , setNextPage] = useState(null)
    const [prevPage , setPrevPage] = useState()
    const [lastPage,setLastPage] = useState()
    const [firstPage,setFirstPage]= useState(1);
    const [open, setOpen] = useState(false);
    const [mass, setMass] = useState(false);
    const [addInputs, setInputs] = useState([
        {
            and: {
                from: "VetFields",
                where: "title",
                wheretext: "Title",
                param: "LIKE",
                val: "",
            },
            or: [],
        },
    ]);
    const dispatch = useDispatch();
    useEffect(()=>{
        if (respData.data) {
            showResult()
        }
    },[currentPage])

    const addInputsHandler = () => {
        setInputs([
            ...addInputs,
            {
                and: {
                    from: "VetFields",
                    where: "title",
                    wheretext: "Title",
                    param: "LIKE",
                    val: "",
                    button: "remove",
                },
                or: [],
            },
        ]);
    };

    const removeInputHandler = (index) => {
        let newarray = addInputs.filter((curr, ind) => index != ind);
        setInputs(newarray);
    };

    const showResult = async () => {
        setLoading(true)
        let payload = { query: addInputs };
        let resp = await Axios.post(`/query-bulder?page=${currentPage}`, payload);
        setLoading(false)
        setShowPagination(true)
        setRespData(resp);
        // setNextPage(respData.data.results.next_page_url)
        // setPrevPage(respData.data.results.prev_page_url)
        setLastPage(resp.data.results.last_page)
    };

    const resetHandler = () => {
        setShowPagination(false)
        setRespData({});
        setInputs([
            {
                and: {
                    from: "VetFields",
                    where: "title",
                    wheretext: "Title",
                    param: "LIKE",
                    val: "",
                },
                or: [],
            },
        ]);
    };

   const handleSendPracticeId = (dataId) => {
    dispatch(openPractice(dataId));
};
const handleSendPersonId = (dataId) => {
    dispatch(openPerson(dataId));
};
let items=[]
let numbers=[]
for (let number = 1; number <= lastPage; number++) {
  items.push(
    <Pagination.Item onClick={()=>{setCurrentPage(number)  }} key={number} active={number === currentPage}>
      {number}
    </Pagination.Item>,
  );
}
if(currentPage===1){
for (let num = currentPage+1 ; num <= currentPage+7 ; num++){
        numbers.push(num)}}

else if(currentPage===lastPage || currentPage >= lastPage-7){
    for (let num = lastPage-1 ; num >= currentPage-7 ; num--){
        numbers.push(num)}
        numbers.reverse()}
else{
    for (let num = currentPage ; num <= currentPage+7 ; num++){
        numbers.push(num)
}
    }
    const ref = useOnclickOutside(() => {
        setOpen(false);
        setMass(false);
       });

     const showExportSidebar = () => {
         setOpen(true);
     }

     const showMassSidebar = () => {
         setMass(true);
     }
    return (
        <>

 {open && <div ref={ref} id="addPractice"> ? (
            <Collapse in={open}>
            <div id="addPractice">
               <ExportBuilderSidebar />

                     </div>

            </Collapse>

        )</div>}

        {mass && <div ref={ref} id="addPractice"> ? (
            <Collapse in={mass}>
            <div id="addPractice">
               <MassUpdateSidebar />

                     </div>

            </Collapse>

        )</div>}
            <Row className="advance-query-builder">
                {/* <GlobalLoader/> */}
                <Col lg={6}>
                    <Card className="mt-4">
                        <Card.Body>
                            <Row>
                                <Col lg={12}>
                                    <h3 className="mb-3">Advance Query Builder</h3>
                                    {addInputs.map((input, index) => (
                                        <QueriesInput
                                            input={input}
                                            index={index}
                                            removeInput={removeInputHandler}
                                        />
                                    ))}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="d-flex mt-3">
                                        <div className="me-2">
                                            <Button
                                                className="btn theme-btn-primary"
                                                onClick={addInputsHandler}
                                            >
                                                And
                                            </Button>
                                        </div>
                                        <div className="me-2">
                                            <Button className="btn theme-btn-primary">
                                                Perform Radius Search
                                            </Button>
                                        </div>
                                        <div className="me-2">
                                            <Button
                                                className="btn theme-btn-outline-primary"
                                                onClick={resetHandler}
                                            >
                                                Reset Builder
                                            </Button>
                                        </div>
                                        <div className="">
                                            <Button
                                                className="btn theme-btn-secondary"
                                                onClick={showResult}
                                            >
                                                Show Results
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>



                <Col lg={6}>
                    <Card className="mt-4 custom-card">
                        <Card.Body>
                            <div className="d-flex justify-content-between mb-4 align-items-center">
                                {
                                    respData.data &&
                                        <h4 className="mb-0">Total results found: <span className="theme-blue-text">{respData.data.count}</span></h4>
                                }
                                {
                                    respData.data &&
                                    <div className="button-group">
                                        <Button onClick={showMassSidebar} className="btn theme-btn-outline-primary ms-2">Mass update</Button>
                                        <Button className="btn theme-btn-outline-primary ms-2">Mass Delete</Button>
                                        <Button className="btn theme-btn-outline-primary ms-2">Mass move</Button>
                                        <Button onClick={showExportSidebar} className="btn theme-btn-outline-primary ms-2">Export Builder</Button>
                                    </div>
                                }
                            </div>

                            {loading ==true ?  <GlobalLoader/>  : null}

                            <PerfectScrollbar className="custom-table">
                            <Table>
                                <thead>
                                {/*<tr>
                                        { {respData.data &&
                                            Object.entries(
                                                respData.data.data.fields
                                            ).map((f) => <th>{f[1]}</th>)} }
                                    </tr>
                                     <tr>
                                        {/* {respData.data &&
                                            Object.keys(
                                                respData.fields
                                            ).map((f) => <th>{f}</th>)}
                                    </tr>*/}
                                    <tr>
                                    {respData.data &&
                                            Object.keys(
                                                respData.data.results.data[0]
                                            ).map((f) => <th>{f}</th>)}
                                     </tr>
                                </thead>
                                <tbody>
                                    {/* {respData.data &&
                                        respData.data.results.map((f) => (
                                            <tr>
                                                <td>{f.id}</td>
                                                <td>{f.title}</td>
                                                <td>{f.forename}</td>
                                                <td>{f.surname}</td>
                                                <td>{f.v_job_types}</td>
                                            </tr>
                                        ))} */}
                                         {respData.data &&
                                       respData.data.results.data.map((f) => (
                                             <tr onClick={()=>{handleSendPersonId(f.id);handleSendPracticeId(f.id);}}>{Object.values(f).map(x=>  <td>{x}</td>)}</tr>
                                        ))}
                                </tbody>


                            </Table>
                            </PerfectScrollbar>



                            { showPagination &&
                              (<Pagination size="sm" className="advance-query-pagination mt-5">
                              <Pagination.First active={1===currentPage} onClick={()=>{setCurrentPage(1)}} className="pagination-first" />
                              <Pagination.Prev />
                              <Pagination.Item active={1===currentPage} onClick={()=>{setCurrentPage(1)}}>{1}</Pagination.Item>
                              <Pagination.Ellipsis />
                                  {numbers.map((i)=>(  <Pagination.Item active={i===currentPage} onClick={()=>{setCurrentPage(i)}} >{i}</Pagination.Item>   ))}
                             <Pagination.Ellipsis />
                         <Pagination.Item active={lastPage===currentPage} onClick={()=>{setCurrentPage(lastPage)}}>{lastPage}</Pagination.Item>
                      <Pagination.Next />
                   <Pagination.Last active={lastPage==currentPage} onClick={()=>{setCurrentPage(lastPage)}} className="pagination-last" />
                 </Pagination>)}
                        </Card.Body>






                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default AdvanceQuery;
