import Swal from 'sweetalert2';

// confirmation alert
export const confirmationAlert = (title, type = '', confirm = true, cancel = true, confirm_text = 'Yes') => {
    return Swal.fire({
        position: 'center',
        title: title,
        icon: type && type,
        showConfirmButton: confirm,
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirm_text,
        showCancelButton: cancel,
        cancelButtonColor: '#d33'
    });
}

// alert
export const swAlert = (title, type = 'success', confirm = false, cancel = false, confirm_text = 'Ok', timer = 3000) => {
    return Swal.fire({
        position: 'center',
        title: title,
        icon: type,
        showConfirmButton: confirm,
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirm_text,
        showCancelButton: cancel,
        cancelButtonColor: '#d33',
        timer: timer
    });
}