import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonById } from '../actions/userActions';
//react-bootstrap
import { Form } from 'react-bootstrap'
//perfect-scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Axios from '../adapters/xhr';
import dayjs from 'dayjs';
var weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

const PaidSubscription = ({ setReportBuilderState, setModifyUserState }) => {
    const dispatch = useDispatch()
    const [expiredData, setExpiredData] = useState([]);
    const [expireDataDate, setExpireDataDate] = useState(new Date());
    const [todayData, setTodayData] = useState([]);
    const [todayDataDate, setTodayDataDate] = useState(new Date());
    const [weekData, setWeekData] = useState([]);
    const [weekFirstDate, setWeekFirstDate] = useState(new Date());
    const [weekLastDate, setWeekLastDate] = useState(new Date());
    const [twoWeekData, setTwoWeekData] = useState([]);
    const [prevWeekFirstDate, setPrevWeekFirstDate] = useState(new Date());
    const [monthData, setMonthData] = useState([]);
    const [monthFirstDate, setMonthFirstDate] = useState(new Date());
    const [monthLastDayy, setMonthLastDay] = useState(new Date());
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        setLoading(true)
        let firstDay = dayjs(weekFirstDate).startOf('week').toDate()
        setWeekFirstDate(firstDay);
        let lastDay = dayjs(weekLastDate).endOf('week').toDate();
        setWeekLastDate(lastDay);
        let prevWeekFirst = dayjs().weekday(-7)
        setPrevWeekFirstDate(prevWeekFirst.$d);
        let monthFirstDay = dayjs(monthFirstDate).startOf('month').toDate();
        setMonthFirstDate(monthFirstDay);
        let monthLastDay = dayjs(monthLastDayy).endOf('month').toDate();
        setMonthLastDay(monthLastDay)
        console.log(firstDay, lastDay, prevWeekFirst, monthFirstDay, monthLastDay);
        try {
            let response = await Axios.get('/paid/subscriptions');
            console.log(response);
            setExpiredData(response.data.data.expired.data);
            setTodayData(response.data.data.today.data);
            setWeekData(response.data.data.week.data);
            setTwoWeekData(response.data.data.twoWeeks.data);
            setMonthData(response.data.data.month.data);
            setLoading(false)
        } catch (err) {
            console.log(err.response)
            setLoading(false)
        }
    }, [])

    const handleGetPersonById = (id) => {
        dispatch(getPersonById(id))
        setReportBuilderState(false)
        setModifyUserState(false)
    }

    return (
        <>
            <PerfectScrollbar>
                <Form>
                    <h4 className="mt-3 px-3">
                        <span>Paid subscription expires</span>
                    </h4>

                    {loading == true ? '' : <span className="px-3 last-10-viewed mb-3 d-block">List of paid subscription expires</span>}
                    {expiredData.map((curr, ind) => {
                        return (
                            <>
                                {
                                    curr.ind_vt_end && curr.ind_vt_end < dayjs(expireDataDate).format('YYYY-MM-DD') ||
                                        curr.ind_vbj_end && curr.ind_vbj_end < dayjs(expireDataDate).format('YYYY-MM-DD') ||
                                        curr.ind_vn_end && curr.ind_vn_end < dayjs(expireDataDate).format('YYYY-MM-DD')
                                        ?
                                        <div key={ind} className="paid-subscription" onClick={() => handleGetPersonById(curr.id)}>
                                            <p> {curr.forename !== null ? curr.forename : ''} {curr.surname} </p>
                                            <div className="tags">
                                                {curr.ind_vt_end && curr.ind_vt_end < dayjs(expireDataDate).format('YYYY-MM-DD') ?
                                                    <span></span>
                                                    : null}
                                                {curr.ind_vbj_end && curr.ind_vbj_end < dayjs(expireDataDate).format('YYYY-MM-DD') ? <span></span> : null}

                                                {curr.ind_vn_end && curr.ind_vn_end < dayjs(expireDataDate).format('YYYY-MM-DD') ? <span ></span> : null}

                                            </div>
                                        </div> : ''
                                }
                            </>
                        )
                    })}

                    {loading == true ? '' : <span className="px-3 last-10-viewed mb-3 d-block">List of paid subscriptions due to expire after today</span>}
                    {todayData.map((curr, ind) => {
                        return (
                            <>
                                {
                                    curr.ind_vt_end && curr.ind_vt_end === dayjs(todayDataDate).format('YYYY-MM-DD') ||
                                        curr.ind_vbj_end && curr.ind_vbj_end === dayjs(todayDataDate).format('YYYY-MM-DD') ||
                                        curr.ind_vn_end && curr.ind_vn_end === dayjs(todayDataDate).format('YYYY-MM-DD')
                                        ?
                                        <div key={ind} className="paid-subscription" onClick={() => handleGetPersonById(curr.id)}>
                                            <p> {curr.forename !== '' || curr.forename !== null ? curr.forename : ''} {curr.surname !== '' || curr.surname !== null ? curr.surname : ''} </p>
                                            <div className="tags">
                                                {
                                                    curr.ind_vt_end && curr.ind_vt_end === dayjs(todayDataDate).format('YYYY-MM-DD') ?
                                                        <span></span> : null
                                                }
                                                {
                                                    curr.ind_vbj_end && curr.ind_vbj_end === dayjs(todayDataDate).format('YYYY-MM-DD') ?
                                                        <span ></span> : null
                                                }
                                                {
                                                    curr.ind_vn_end && curr.ind_vn_end === dayjs(todayDataDate).format('YYYY-MM-DD') ?
                                                        <span ></span> : null
                                                }
                                            </div>
                                        </div> : ''
                                }
                            </>
                        )
                    })}

                    {loading == true ? '' : <span className="px-3 last-10-viewed mb-3 d-block">List of paid subscriptions due to expire in a week</span>}
                    {weekData.map((curr, ind) => {
                        return (
                            <>
                                {
                                    curr.ind_vt_end /* && (curr.ind_vt_end >= dayjs(weekFirstDate).format('YYYY-MM-DD') && curr.ind_vt_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) */ ||
                                        curr.ind_vbj_end /* && (curr.ind_vbj_end >= dayjs(weekFirstDate).format('YYYY-MM-DD') && curr.ind_vbj_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) */ ||
                                        curr.ind_vn_end /* && (curr.ind_vn_end >= dayjs(weekFirstDate).format('YYYY-MM-DD') && curr.ind_vn_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) */
                                        ?
                                        <div key={ind} className="paid-subscription" onClick={() => handleGetPersonById(curr.id)}>
                                            <p> {curr.forename !== '' || curr.forename !== null ? curr.forename : ''} {curr.surname !== '' || curr.surname !== null ? curr.surname : ''} </p>
                                            <div className="tags">
                                                {
                                                    curr.ind_vt_end && (curr.ind_vt_end >= dayjs(weekFirstDate).format('YYYY-MM-DD') && curr.ind_vt_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ?
                                                        <span></span> : null
                                                }
                                                {
                                                    curr.ind_vbj_end && (curr.ind_vbj_end >= dayjs(weekFirstDate).format('YYYY-MM-DD') && curr.ind_vbj_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ?
                                                        <span></span> : null
                                                }
                                                {
                                                    curr.ind_vn_end && (curr.ind_vn_end >= dayjs(weekFirstDate).format('YYYY-MM-DD') && curr.ind_vn_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ?
                                                        <span></span> : null
                                                }
                                            </div>
                                        </div> : ''
                                }
                            </>

                        )
                    })}

                    {loading == true ? '' : <span className="px-3 last-10-viewed mb-3 d-block">List of paid subscriptions due to expire in 2 weeks</span>}
                    {twoWeekData.map((curr, ind) => {
                        return (

                            <>
                                {
                                    curr.ind_vt_end ||
                                        curr.ind_vbj_end ||
                                        curr.ind_vn_end
                                        ?
                                        <div key={ind} className="paid-subscription" onClick={() => handleGetPersonById(curr.id)}>
                                            <p> {curr.forename !== '' || curr.forename !== null ? curr.forename : ''} {curr.surname !== '' || curr.surname !== null ? curr.surname : ''} </p>
                                        </div>
                                        : ''
                                }
                                {/* {
                                    curr.ind_vt_end && (curr.ind_vt_end >= dayjs(prevWeekFirstDate).format('YYYY-MM-DD') && curr.ind_vt_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ||
                                        curr.ind_vbj_end && (curr.ind_vbj_end >= dayjs(prevWeekFirstDate).format('YYYY-MM-DD') && curr.ind_vbj_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ||
                                        curr.ind_vn_end && (curr.ind_vn_end >= dayjs(prevWeekFirstDate).format('YYYY-MM-DD') && curr.ind_vn_end <= dayjs(weekLastDate).format('YYYY-MM-DD'))
                                        ?
                                        <div key={ind} className="paid-subscription">
                                            <p> {curr.forename !== '' || curr.forename !== null ? curr.forename : ''} {curr.surname !== '' || curr.surname !== null ? curr.surname : ''} </p>
                                            <div className="tags">
                                                {
                                                    curr.ind_vt_end && (curr.ind_vt_end >= dayjs(prevWeekFirstDate).format('YYYY-MM-DD') && curr.ind_vt_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ?
                                                        <span></span> : null
                                                }
                                                {
                                                    curr.ind_vbj_end && (curr.ind_vbj_end >= dayjs(prevWeekFirstDate).format('YYYY-MM-DD') && curr.ind_vbj_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ?
                                                        <span></span> : null
                                                }
                                                {
                                                    curr.ind_vn_end && (curr.ind_vn_end >= dayjs(prevWeekFirstDate).format('YYYY-MM-DD') && curr.ind_vn_end <= dayjs(weekLastDate).format('YYYY-MM-DD')) ?
                                                        <span></span> : null
                                                }
                                            </div>
                                        </div> : ''
                                } */}
                            </>
                        )
                    })}

                    {loading == true ? '' : <span className="px-3 last-10-viewed mb-3 d-block">List of paid subscriptions due to expire in a month</span>}
                    {monthData.map((curr, ind) => {
                        return (
                            <>
                                {
                                    curr.ind_vt_end || curr.ind_vbj_end || curr.ind_vn_end
                                        ?
                                        <div key={ind} className="paid-subscription" onClick={() => handleGetPersonById(curr.id)}>
                                            <p> {curr.forename !== null ? curr.forename : ''} {curr.surname} </p>
                                        </div>
                                        : ''
                                }
                                {
                                    // curr.ind_vt_end && (curr.ind_vt_end >= dayjs(monthFirstDate).format('YYYY-MM-DD') && curr.ind_vt_end <= dayjs(monthLastDayy).format('YYYY-MM-DD')) ||
                                    //     curr.ind_vbj_end && (curr.ind_vbj_end >= dayjs(monthFirstDate).format('YYYY-MM-DD') && curr.ind_vbj_end <= dayjs(monthLastDayy).format('YYYY-MM-DD')) ||
                                    //     curr.ind_vn_end && (curr.ind_vn_end >= dayjs(monthFirstDate).format('YYYY-MM-DD') && curr.ind_vn_end <= dayjs(monthLastDayy).format('YYYY-MM-DD'))
                                    //     ?
                                    //     <div key={ind} className="paid-subscription">
                                    //         <p> {curr.forename !== null ? curr.forename : ''} {curr.surname} </p>
                                    //         <div className="tags">
                                    //             {
                                    //                 curr.ind_vt_end && (curr.ind_vt_end >= dayjs(monthFirstDate).format('YYYY-MM-DD') && curr.ind_vt_end <= dayjs(monthLastDayy).format('YYYY-MM-DD')) ?
                                    //                     <span></span> : null
                                    //             }
                                    //             {
                                    //                 curr.ind_vbj_end && (curr.ind_vbj_end >= dayjs(monthFirstDate).format('YYYY-MM-DD') && curr.ind_vbj_end <= dayjs(monthLastDayy).format('YYYY-MM-DD')) ?
                                    //                     <span></span> : null
                                    //             }
                                    //             {
                                    //                 curr.ind_vn_end && (curr.ind_vn_end >= dayjs(monthFirstDate).format('YYYY-MM-DD') && curr.ind_vn_end <= dayjs(monthLastDayy).format('YYYY-MM-DD')) ?
                                    //                     <span></span> : null
                                    //             }
                                    //         </div>
                                    //     </div> : ''
                                }
                            </>
                        )
                    })}
                </Form>
            </PerfectScrollbar>
        </>
    )
}

export default PaidSubscription;
