import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter, useHistory } from 'react-router-dom';
//react-redux
import { useDispatch, useSelector } from 'react-redux'
import { createPractice, getPracticeById } from '../actions/userActions'
//react-bootstrap
import { Button, Form, Image } from "react-bootstrap";
//practice objects
import practiceModel from "../constants/practiceModel";
//helper functions
import { practice_active_branch, practice_industry_branch, practice_type_vetfile } from "../helpers/validation";
//perfect-scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
//search-icon
import searchIcon from "../assets/icons/search-icon.svg";
import cancelIcon from "../assets/icons/cancel-icon.svg";
import addressIcon from "../assets/icons/map-pin.svg";
//components
import ErrorMessage from './ErrorMessage'
import ToastUI from "./ToastUI";
import useOnclickOutside from "react-cool-onclickoutside";
import MaskedInput from "react-text-mask";

const AddPractice = (props) => {
  const history = useHistory()
  //use-state-hooks
  const [practiceInput, setPracticeInput] = useState(practiceModel)
  const [error, setError] = useState({})
  const [postcode, setPostcode] = useState("")
  const [postcodeMenuShow, setPostCodeMenuShow] = useState(false)
  const [disableTypes, setDisableTypes] = useState(false);
  const [address, setAddress] = useState({})
  const [data, setData] = useState({})
  //redux
  const dispatch = useDispatch()
  const { loadingCreatePractice, practiceCreate, errorCreatePractice } = useSelector(state => state.createPracticeReducer)
  const { loadingGetPractice, practiceGet, errorGetPractice } = useSelector(state => state.getPracticeReducer)
  const { lastSearchResult } = useSelector(state => state.lastSearchedResultReducer)

  //useEffect
  useEffect(() => {
    practiceCreate
      && (practiceCreate.office === "Practice Main" || practiceCreate.office === "Industry Main")
      && dispatch(getPracticeById(practiceCreate.data.id))
  }, [practiceCreate])

  useEffect(() => {
    setPracticeInput(prevState => ({
        ...prevState,
        name:'',
        address_1: '',
        address_2: '',
        address_3: '',
        town: '',
        county: '',
        country: '',
        postcode: '',
        tel: '',
        email: '',
        website: ''
    }))
  }, [practiceCreate])

    useEffect(() => {

        setDisableTypes(false)

        let type = practiceInput.type.length > 0 ? practiceInput.type.split(",") : [];
        let vetfile_type = type.includes('Vetfile');


        if(vetfile_type){
            setPracticeInput(prevState => ({...prevState,type:'Vetfile'}))
            setDisableTypes(true)
            // setPracticeInput((prev) => ({...prev, type: 'Vetfile'}))
        } else {
            setDisableTypes(false)
        }

    }, [practiceInput.type])

  //address finder
  const handlePostcodeChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPostCodeMenuShow(true)
    setPostcode(value)
  }
  const ref = useOnclickOutside(() => {
    setPostCodeMenuShow(false)
  });

  const handlePostcodeSubmit = async () => {
    setPostCodeMenuShow(true)
    const api_key = 'htcb_PTDuEiH1jU-6sz0vA24263'
    const { data } = await axios.get(
      `https://api.getAddress.io/find/${postcode}?api-key=${api_key}&expand=true`
    );

    const arr = []
    data.addresses.forEach((curr, ind) => {
      arr.push({
        display: curr.formatted_address.join(" "),
        address1: curr.line_1,
        address2: curr.line_2,
        address3: curr.line_3,
        town: curr.town_or_city,
        county: curr.county,
        country: curr.country,
        postcode: data.postcode
      })
    })
    setData({ arr });
  }

  const handleSetAddress = (data) => {
    setPracticeInput(prevState => ({
      ...prevState,
      address_1: data.address1,
      address_2: data.address2,
      address_3: data.address3,
      town: data.town,
      county: data.county,
      country: data.country,
      postcode: data.postcode
    }))
    setData({})
  }

  //practice stuff
  const handlePracticeChange = (e) => {
    const { name, value } = e.target

    // if (value === "Vetfile") {
    //   let temp = practiceInput
    //   temp = {
    //     ...practiceInput,
    //     type: "Vetfile"
    //   }
    //
    //   setError(practice_type_vetfile(temp))
    // }

    setPracticeInput(prevState => ({
      ...prevState,
      [name]: value
    }))

      if (name === "type") {
          let type = practiceInput.type.length > 0 ? practiceInput.type.split(",") : []

          if (type.find(curr => curr === value)) {
              let str = type.filter(curr => curr != value).join()

              setPracticeInput(prevState => ({
                  ...prevState,
                  type: str
              }))
          } else {
              type.push(value)
              let str = type.length > 0 ? type.join() : ""
              setPracticeInput(prevState => ({
                  ...prevState,
                  type: str
              }))
          }
      }
  }

  const handlePracticeSubmit = (e) => {
    e.preventDefault()
    let mainId = ''

    if (practiceGet
      && (practiceInput.office === 'Practice Branch'
        || practiceInput.office === 'Industry Branch')
      && (practiceGet.data.office === 'Practice Main'
        || practiceGet.data.office === 'Industry Main')
    ) {
      mainId = practiceGet.data.id
    } else if (lastSearchResult
      && (practiceInput.office === 'Practice Branch'
        || practiceInput.office === 'Industry Branch')
      && (lastSearchResult.data.practice.office === 'Practice Main'
        || lastSearchResult.data.practice.office === 'Industry Main')
    ) {
      mainId = lastSearchResult.data.practice.id
    }

    const temp = practiceInput

    if (practiceInput.type === '') {
      (practiceInput.office === 'Industry Main' ||
        practiceInput.office === 'Industry Branch')
        ? practiceInput.type = 'Industry'
        : practiceInput.type = null
    }

    let errors = {}

    if ((practiceInput.office === 'Practice Branch' ||
      practiceInput.office === 'Practice Main')
      && practiceInput.type !== 'Vetfile') {
      errors = practice_active_branch(temp)
    } else if ((practiceInput === 'Industry Main' ||
      practiceInput.office === 'Industry Branch')
      && practiceInput.type !== 'Vetfile') {
      errors = practice_industry_branch(temp)
    } else if (practiceInput.type === 'Vetfile') {
      errors = practice_type_vetfile(temp)
    } else if (practiceInput.office === '') {
      errors = { office: 'Required' }
    }

    if(practiceInput.address_2 === ''){
      practiceInput.address_2 = null
    }

    if(practiceInput.address_3 === ''){
      practiceInput.address_3 = null
    }

    if(practiceInput.email === ''){
      practiceInput.email = null
    }

    if(practiceInput.website === ''){
      practiceInput.website = null
    }
    if(practiceInput.tel === ''){
      practiceInput.tel = null
    }


    setError(errors)

    if (Object.entries(errors).length === 0) {
      let dataBody = {
        name: temp.name,
        office: temp.office,
        postcode: temp.postcode,
        address_1: temp.address_1,
        address_2: temp.address_2,
        address_3: temp.address_3,
        town: temp.town,
        tel: temp.tel,
        country: temp.country,
        county: temp.county,
        type: temp.type,
        email: temp.email,
        website: temp.website,
        direct_mail: "Yes"
      }
      if (mainId !== "") {
        dataBody.main = mainId
      }
      if (practiceInput.office === "Practice Main" || practiceInput.office === "Practice Branch") {
        dataBody.direct_mail = "Yes"
      }

      dispatch(createPractice(dataBody))
      //props.history.push('/')
     // history.push('/')
        props.setClosePopUp()
    }
  }

  return (
    <>
      <PerfectScrollbar>
        <Form onSubmit={handlePracticeSubmit}>
          {practiceCreate && (
            <div
              style={{
                position: "absolute",
                top: 3,
                right: 0,
                left: 0,
              }}
            >
              <ToastUI header="Vetfile" body="Practice created successfully." />
            </div>
          )}
          <h4 className="my-3 px-4">
            <span>Create a new practice</span>
          </h4>
          <div className="create-practice px-4">
            <Form.Label>
              Select Business Type
              <span className="jazzberryJam-text ms-1">*</span>
            </Form.Label>
            <Form.Control
              name="office"
              as="select"
              size="sm"
              className={(!practiceInput.office && error.office) ? "invalid form-select" : "form-select"}
              onChange={handlePracticeChange}
            >
              <option value="">Select business type</option>
              <option
                value="Practice Main"
              >
                Practice Main
              </option>
              <option
                value="Practice Branch"
                disabled={practiceGet
                  ? (practiceGet.data.office === "Practice Branch"
                    || practiceGet.data.office === "Industry Branch")
                  : lastSearchResult
                    ? (lastSearchResult.data.practice.office === "Practice Branch"
                      || lastSearchResult.data.practice.office === "Industry Branch")
                    : false}
              >
                Practice Branch
              </option>
              <option
                value="Industry Main"
              >
                Industry Main
              </option>
              <option
                value="Industry Branch"
                disabled={practiceGet
                  ? (practiceGet.data.office === "Practice Branch"
                    || practiceGet.data.office === "Industry Branch")
                  : lastSearchResult
                    ? (lastSearchResult.data.practice.office === "Practice Branch"
                      || lastSearchResult.data.practice.office === "Industry Branch")
                    : false}
              >
                Industry Branch
              </option>
            </Form.Control>
            {!practiceInput.office && <ErrorMessage error={error.office} />}

            <Form.Label>Find your Address</Form.Label>
            <div className="find-address">
              <span className="address-icon" onClick={handlePostcodeSubmit} ><Image src={addressIcon} />search</span>
              <Form.Control
                name=""
                type="text"
                size="sm"
                placeholder="Enter your Postcode"
                autoComplete="nope"
                onChange={handlePostcodeChange}
              />
              {postcodeMenuShow && data.hasOwnProperty("arr") && Array.from(data.arr).length > 0 &&
                <div ref={ref} className="address-dropdown">
                  <PerfectScrollbar>
                    {Array.from(data.arr).map((curr, index) =>
                      <p key={index} onClick={() => { handleSetAddress(curr) }}>{curr.display}</p>
                    )}
                  </PerfectScrollbar>
                </div>
              }
            </div>
          </div>
          <div className="detail-information px-4">
            <h4 className="mt-4 mb-3">
              <span>Detail Information</span>
            </h4>
            <Form.Label>Business Name<span className="jazzberryJam-text ms-1">*</span></Form.Label>
            <Form.Control
              name="name"
              type="text"
              size="sm"
              value={practiceInput.name}
              autoComplete="nope"
              className={(!practiceInput.name && error.name) ? "invalid" : ""}
              onChange={handlePracticeChange}
            />
            {!practiceInput.name && <ErrorMessage error={error.name} />}
            <Form.Label>
              { }
              Address Line 1<span className="jazzberryJam-text ms-1">*</span>
            </Form.Label>
            <Form.Control
              name="address_1"
              type="text"
              size="sm"
              autoComplete="nope"
              className={(!practiceInput.address_1 && error.address_1) ? "invalid" : ""}
              value={practiceInput.address_1}
              onChange={handlePracticeChange}
            />
            {!practiceInput.address_1 && <ErrorMessage error={error.address_1} />}

            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              name="address_2"
              type="text"
              size="sm"
              autoComplete="nope"
              value={practiceInput.address_2}
              onChange={handlePracticeChange}
            />
            <Form.Label>Address Line 3</Form.Label>
            <Form.Control
              name="address_3"
              type="text"
              size="sm"
              autoComplete="nope"
              value={practiceInput.address_3}
              onChange={handlePracticeChange}
            />
            <div>
              <div>
                <Form.Label>Town<span className="jazzberryJam-text ms-1">*</span></Form.Label>
                <Form.Control
                  name="town"
                  type="text"
                  size="sm"
                  value={practiceInput.town.toUpperCase()}
                  autoComplete="nope"
                  className={(!practiceInput.town && error.town) ? "invalid" : ""}
                  onChange={handlePracticeChange}
                />
                {!practiceInput.town && <ErrorMessage error={error.town} />}
              </div>
              <div>
                <Form.Label>County<span className="jazzberryJam-text ms-1">*</span></Form.Label>
                <Form.Control
                  name="county"
                  type="text"
                  size="sm"
                  autoComplete="nope"
                  value={practiceInput.county.charAt(0).toUpperCase() + practiceInput.county.slice(1)}
                  onChange={handlePracticeChange}
                  className={(!practiceInput.county && error.county) ? "invalid" : ""}
                />
                {!practiceInput.county && <ErrorMessage error={error.county} />}
              </div>
            </div>
            <Form.Label>Postcode<span className="jazzberryJam-text ms-1">*</span></Form.Label>
            <Form.Control
              name="postcode"
              type="text"
              size="sm"
              autoComplete="nope"
              value={practiceInput.postcode.toUpperCase()}
              className={(!practiceInput.postcode && error.postcode) ? "invalid" : ""}
              onChange={handlePracticeChange}
            />
            {!practiceInput.postcode && <ErrorMessage error={error.postcode} />}
            <Form.Label>Country</Form.Label>
            <Form.Control
              name="country"
              type="text"
              size="sm"
              autoComplete="nope"
              value={practiceInput.country.charAt(0).toUpperCase() + practiceInput.country.slice(1)}
              className={(!practiceInput.country && error.country) ? "invalid" : ""}
              onChange={handlePracticeChange}
            />
            {!practiceInput.country && <ErrorMessage error={error.country} />}
            <Form.Label>Telephone Number<span className="jazzberryJam-text ms-1">*</span></Form.Label>
              {/*<Form.Control
              name="tel"
              type="text"
              size="sm"
              value={practiceInput.tel}
              autoComplete="nope"
              className={(!practiceInput.tel && error.tel) ? "invalid" : ""}
              onChange={handlePracticeChange}
            />*/}
              <MaskedInput
                  mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " "]}
                  guide={false}
                  value={practiceInput.tel}
                  name="tel"
                  className="form-control"
                  onChange={handlePracticeChange}
              />
            {/*{!practiceInput.tel && <ErrorMessage error={error.tel} />}*/}

            {/*<Form.Label>*/}
            {/*  Type<span className="jazzberryJam-text ms-1">*</span>*/}
            {/*</Form.Label>*/}
            {/*<Form.Control*/}
            {/*  name="type"*/}
            {/*  as="select"*/}
            {/*  size="sm"*/}
            {/*  className="form-select"*/}
            {/*  autoComplete="nope"*/}
            {/*  className={(!practiceInput.type && error.type) ? "invalid" : ""}*/}
            {/*  onChange={handlePracticeChange}*/}
            {/*>*/}
            {/*  <option*/}
            {/*    value="Small Animals"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    Small Animals*/}
            {/*  </option>*/}
            {/*  <option*/}
            {/*    value="Mixed Animals"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    Mixed Animals*/}
            {/*  </option>*/}
            {/*  <option value="Equine"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    Equine*/}
            {/*  </option>*/}
            {/*  <option*/}
            {/*    value="Farm Animals"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    Farm Animals*/}
            {/*  </option>*/}
            {/*  <option*/}
            {/*    value="University/College"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    University/College*/}
            {/*  </option>*/}
            {/*  <option*/}
            {/*    value="Companion Animal"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    Companion Animal*/}
            {/*  </option>*/}
            {/*  <option*/}
            {/*    value="Zoo/Wildlife"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    Zoo/Wildlife*/}
            {/*  </option>*/}
            {/*  <option*/}
            {/*    value="Vetfile"*/}
            {/*    disabled={practiceInput.office === "Industry Main" ||*/}
            {/*      practiceInput.office === "Industry Branch"}>*/}
            {/*    Vetfile*/}
            {/*  </option>*/}
            {/*  <option*/}
            {/*    value="Industry"*/}
            {/*    disabled={practiceInput.office === "Practice Main" ||*/}
            {/*      practiceInput.office === "Practice Branch"}*/}
            {/*    selected = {practiceInput.office === "Industry Main" ||*/}
            {/*    practiceInput.office === "Industry Branch"}*/}
            {/*      >*/}
            {/*    Industry*/}
            {/*  </option>*/}
            {/*</Form.Control>*/}


              <Form.Label>Type</Form.Label>
              <Form.Group controlID="small-animal">
                  <Form.Check
                      id="small-animal"
                      name="type"
                      type="checkbox"
                      label="Small Animals"
                      value="Small Animals"
                      checked={practiceInput.type.includes('Small Animals')}
                      className="form-label"
                      disabled={practiceInput.office === "Industry Main" ||
                          practiceInput.office === "Industry Branch" ||
                          disableTypes}
                      onChange={handlePracticeChange}
                  />
              </Form.Group>
              <Form.Group controlID="equine-type">
                  <Form.Check
                      id="equine-type"
                      name="type"
                      type="checkbox"
                      label="Equine"
                      value="Equine"
                      checked={practiceInput.type.includes('Equine')}
                      className="form-label"
                      disabled={practiceInput.office === "Industry Main" ||
                          practiceInput.office === "Industry Branch" ||
                          disableTypes}
                      onChange={handlePracticeChange}
                  />
              </Form.Group>
              <Form.Group controlID="farm-animal">
                  <Form.Check
                      id="farm-animal"
                      name="type"
                      type="checkbox"
                      label="Farm Animals"
                      value="Farm Animals"
                      checked={practiceInput.type.includes('Farm Animals')}
                      className="form-label"
                      disabled={practiceInput.office === "Industry Main" ||
                          practiceInput.office === "Industry Branch" ||
                          disableTypes}
                      onChange={handlePracticeChange}
                  />
              </Form.Group>
              <Form.Group controlID="university-type">
                  <Form.Check
                      id="university-type"
                      name="type"
                      type="checkbox"
                      label="University/College"
                      value="University/College"
                      checked={practiceInput.type.includes('University/College')}
                      className="form-label"
                      disabled={practiceInput.office === "Industry Main" ||
                          practiceInput.office === "Industry Branch" ||
                          disableTypes}
                      onChange={handlePracticeChange}
                  />
              </Form.Group>
              <Form.Group controlID="zoo-type">
                  <Form.Check
                      id="zoo-type"
                      name="type"
                      type="checkbox"
                      label="Zoo/Wildlife"
                      value="Zoo/Wildlife"
                      checked={practiceInput.type.includes('Zoo/Wildlife')}
                      className="form-label"
                      disabled={practiceInput.office === "Industry Main" ||
                          practiceInput.office === "Industry Branch" ||
                          disableTypes}
                      onChange={handlePracticeChange}
                  />
              </Form.Group>
              <Form.Group controlID="vetfile-type">
                  <Form.Check
                      id="vetfile-type"
                      name="type"
                      type="checkbox"
                      label="Vetfile"
                      value="Vetfile"
                      className="form-label"
                      disabled={practiceInput.office === "Industry Main" ||
                          practiceInput.office === "Industry Branch"}
                      onChange={handlePracticeChange}
                  />
              </Form.Group>
              <Form.Group controlID="Industry-type">
                  <Form.Check
                      id="industry-type"
                      name="type"
                      type="checkbox"
                      label="Industry"
                      value="Industry"
                      className="form-label"
                      disabled={practiceInput.office === "Practice Main" ||
                          practiceInput.office === "Practice Branch" ||
                          disableTypes}
                      checked = {practiceInput.office === "Industry Main" ||
                          practiceInput.office === "Industry Branch"}
                      onChange={handlePracticeChange}
                  />
              </Form.Group>



            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              value={practiceInput.email}
              type="text"
              size="sm"
              autoComplete="nope"
              onChange={handlePracticeChange}
            />
            <Form.Label>Website</Form.Label>
            <Form.Control
              name="website"
              value={practiceInput.website}
              type="text"
              size="sm"
              autoComplete="nope"
              onChange={handlePracticeChange}
            />
          </div>

          <div className="px-4">
            <Button type="submit" className="btn theme-btn-primary w-100 my-4">
              Create New Practice
            </Button>
          </div>
        </Form>
      </PerfectScrollbar>
    </>
  );
};

export default withRouter(AddPractice);
