import React,{useRef} from 'react'
import IdleTimer from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import {logout} from '../actions/userActions'
const IdleTimerContainer = () => {
const idleTimerRef = useRef(null)
const dispatch = useDispatch()
const onIdle =()=>{
    dispatch(logout())
}   

return (
        <div>
            <IdleTimer ref={idleTimerRef} timeout={900*1000} onIdle={onIdle}></IdleTimer>            
        </div>
    )
}

export default IdleTimerContainer
