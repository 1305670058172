import { React, useState } from "react";
//Formik
import { Formik, Field, Form, ErrorMessage } from "formik";
//components
import ButtonUI from "./ButtonUI";

const FormUI = ({ cancel, errors, onSubmit, submitBtnText, validationSchema, children, initialValues, ...props }) => {
  return (
    <Formik
      initialValues={ initialValues }
      validationSchema={ validationSchema }
      onSubmit={ onSubmit }
    >
      <Form>
        { children }
        <button type="submit">{ submitBtnText }</button>
      </Form>
    </Formik>
  );
};

export default FormUI;
