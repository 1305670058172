import React from 'react'
import { Route, Redirect } from 'react-router-dom'
//check for logged in user
import isLogin from '../helpers/isLogin'

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        //show the component only when the user is logged in
        //otherwise, redirect the user to / page
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
            : <Redirect to={{
                pathname: '/',
                state: { from: props.location }
            }} />
        )} />
    )
}

export default PrivateRoute