import React, { useEffect, useState } from 'react';
//react-bootstrap
import { Button, Col, Row, FormControl, Form } from 'react-bootstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Axios from '../adapters/xhr';
import { confirmationAlert, swAlert } from '../helpers/alert';
import {selectStyle} from "../styling/select-style";
import {dropDownreports, dropDownSenderNames} from "../constants/constants";
import Swal from "sweetalert2";

const Sms = (props) => {

    const [smsInput, setSmsInput] = useState({ to: '', message: '',sender_name:'' })
    const [placeValue, setPlaceValue] = useState({ forename: '{{forename}}', surname: '{{surname}}' })
    const [smsLength, setSmsLength] = useState(0)
    const [totalRecp, setTotalRecp] = useState(0)

    useEffect(() => {
        getSmsNoList();
    }, [])

    const handleSendSms = async () => {
        if(smsInput.sender_name.length === 0){
            return Swal.fire({
                position: "center",
                icon: "error",
                title: "Please select a Sender name",
                showConfirmButton: false,
                timer: 3000,
            })
        }
        else if (smsInput.message.length === 0) swAlert('Please enter a message', 'error')
        else {
            confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
                if (result.isConfirmed) {
                    let payload = smsInput;
                    let resp = await Axios.post(`/send/sms/report/${props.selectedReport}`, payload);

                    if (resp.status == 200) {
                        swAlert('Message Sent!', 'success')
                        props.setSmsSliderOpen(false)
                    }
                }
            });
        }
    };


    // handle sms inputs
    const handleSmsChange = (e) => {
        const { name, value } = e.target

        setSmsLength(value.length)

        setSmsInput(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handlePlaceholder = (text) => {
        let new_message = (smsInput.message.length === 0) ? '{{' + text + '}}' : smsInput.message.trim() + ' {{' + text + '}}';
        setSmsInput({ message: new_message, to: smsInput.to,sender_name:smsInput.sender_name })
    }

    const getSmsNoList = async () => {
        let resp = await Axios.get(`/report/sms/no/list/${props.selectedReport}`);

        if(resp.status) {
            setTotalRecp(resp.data.data.length)
        }
    }

    return (
        <>
            <div className="my-3 px-4">

                <h4 className="SmsTitle">
                    Send SMS <br />
                    <span className="SmsTitleSpan">Total Recipients: {totalRecp}</span>
                </h4>

                <PerfectScrollbar>
                    <Form className="database-backup">
                        <Form.Label>Sender names:</Form.Label>

                        <Form.Control name="sender_name" as="select" className="form-select" value={smsInput.sender_name} onChange={handleSmsChange}>
                            <option value="">Please select</option>
                            {dropDownSenderNames.map((v) => (
                                <option value={v.value}>{v.label} </option>
                            ))}
                        </Form.Control>

                        <div className>
                            <label className="d-block my-2 SmsLabel-Primary">Available tags</label>

                            <Button
                                onClick={() => { handlePlaceholder('forename') }}
                                className="btn theme-btn-outline-primary me-2 mb-2 btn btn-primary">{placeValue.forename}</Button>

                            <Button onClick={() => { handlePlaceholder('surname') }} className="btn theme-btn-outline-primary me-2 mb-2 btn btn-primary">{placeValue.surname}</Button>

                        </div>

                        {/* <FormControl
                            type="text"
                            name="to"
                            placeholder="Enter a valid comma separated numbers"
                            value={smsInput.to}
                            onChange={handleSmsChange} /> */}

                        <div>
                            <label className="d-block my-2 SmsLabel-Primary">Enter Message</label>
                            <FormControl
                                as="textarea"
                                name="message"
                                value={smsInput.message}
                                className="form-control SmsTextArea"
                                onChange={handleSmsChange} />
                        </div>

                        <div>
                            {/* <a className="text-decoration-underline d-block text-right theme-blue-text" href="javascript:;">View no list</a> */}
                            <label className="d-block my-2 SmsLabel-Secondary">Character count ({smsLength})</label>
                            <p className="SmsLabel-Primary">If you go over 160 characters, the message will still be sent BUT each message will be split. So a message of more than 160 characters will be billed as two SMS message</p>
                        </div>

                        <div>
                            <Button
                                onClick={() => { handleSendSms() }}
                                className="theme-btn-secondary my-2 btn btn-primary">Send SMS to {totalRecp} recipients
                            </Button>
                        </div>

                    </Form>
                </PerfectScrollbar>

            </div>
        </>
    );
};

export default Sms;
