import React, { useState, useEffect } from 'react'
import { Col, Form, Image, Row } from 'react-bootstrap'
import deleteIcon from '../assets/icons/delete.svg';

const RadiusSearch = ({ removeRadiusSearch, radiusSearchState, radiusSearchReq, setRadiusReq ,type,reportSave}) => {
    const [radiusSearch, setRadiusSearch] = useState(radiusSearchState)


  useEffect(()=>{
    reportSave({type:'RADIUS SEARCH UPDATE',radius:radiusSearch})
  },[radiusSearch])


    const handleRadiusSearch = (e) => {

        const { name, value } = e.target

        if (name === 'radius_postcode') {
            setRadiusSearch((prev) => ({ ...prev, [name]: value }));

        } else if (name === 'radius_miles') {
            setRadiusSearch((prev) => ({ ...prev, [name]: value }))
        } else if (name === 'driving') {
            if (value === '') {
                setRadiusSearch((prev) => ({ ...prev, [name]: null }))
            } else {
                setRadiusSearch((prev) => ({ ...prev, [name]: value }))
            }
        }
    }

    return (
        <><h6 className="mb-1 mt-2">PRS</h6>
            <Row className="g-2 mt-1 my-3">
                <Col>
                    <Form.Control
                        type="text"
                        placeholder="Type PostCode"
                        name="radius_postcode"
                        value={radiusSearch.radius_postcode}
                        onChange={handleRadiusSearch}
                    />
                </Col>

                <Col>
                    <Form.Control
                        type="text"
                        name="radius_miles"
                        placeholder="Type Miles"
                        value={radiusSearch.radius_miles}
                        onChange={handleRadiusSearch}
                    />
                </Col>

                {/* <Col>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="driving"
                        value={radiusSearch.driving}
                        //isSearchable={true}
                        options={drive}
                        onChange={(e)=>{setRadiusSearch((prev)=>({...prev,driving:e.target}))}}
                    />
                </Col> */}
                <Col>
                    <Form.Control as="select"
                        name="driving"
                        as="select"
                        className="form-select"
                        value={radiusSearch.driving}
                        onChange={handleRadiusSearch} >
                        <option value=""> none</option>
                        <option value="home">Drive Home</option>
                        <option value="work">Drive Work</option>
                        <option value="smr">SM Drive</option>
                        <option value="smrCrow">SM Crow</option>
                        <option value="pmr">PM Drive</option>
                        <option value="pmrCrow">PM Crow</option>

                    </Form.Control>

                </Col>
                {type!=='report' &&
                    <Col className="d-flex align-items-center">
                    <div className="action-icons">
                        <span
                            className="delete-icon"
                            onClick={() => {
                                setRadiusSearch({ driving: null, radius_miles: "", radius_postcode: "" });
                                removeRadiusSearch();
                            }}
                        >
                            <Image src={deleteIcon} />
                        </span>
                    </div>
                </Col>}
            </Row>
        </>
    )
}

export default RadiusSearch
