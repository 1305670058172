import React, { useEffect, useState } from "react";
//react-redux
import { useDispatch, useSelector } from "react-redux";
import { getPersonById, getPracticeById, lastSearchedResult, lastTenSearchedResult } from "../actions/userActions";
//react-bootstrap
import { Form, Tab, Tabs } from "react-bootstrap";
//perfect-scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { databaseList } from "../constants/constants";
import { Button } from "react-bootstrap";
import axios from "../adapters/xhr";

const ExportBuilder = () => {
  useEffect(() => { }, []);

  const handleClick = async (curr) => {
      const { data } = await axios.get(
      curr.label != 'Vetfile Stats'
        ? `/exporter?method=${curr.method}&opt=${curr.option}`
        : `/querybuilder/export/report`
    );
    let fileName = data ? /(?<=backup\/).*/.exec(data.data.url)[0].slice(11) : '';
    const link = document.createElement('a');
    link.href = data.data.url;
    link.setAttribute('download', fileName);
    // link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <>
      <h4 className="my-3 px-4">
        <span>Backup exporter</span>
      </h4>
      {/* <span className="px-4 last-10-viewed mb-3 d-block">Select</span> */}
      <PerfectScrollbar>
        <Form className="database-backup">

          {databaseList.length > 0 &&
            databaseList.map((curr, ind) => (
              <div key={ind} className="database-backup-export">
                <p className="d-flex justify-content-between py-1 me-3 align-items-center">
                  {curr.label}
                  <span>
                    <Button className="btn-sm theme-btn-secondary " onClick={() => handleClick(curr)}>
                      Export
                    </Button>
                  </span>
                </p>
              </div>
            ))}
        </Form>
      </PerfectScrollbar>
    </>
  );
};

export default ExportBuilder;
