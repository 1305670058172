import React, { useReducer, useEffect } from 'react'

//search Dimenstions AMD OR
import SearchDimensions from './SearchDimensions'
import SearchDimensionOR from './SearchDimensionOR'
function reducer(state, action) {
    switch (action.type) {
        case 'ADD INPUT FIELD':
            return [...state, { and: { from: 'VetFields', where: 'title', wheretext: 'Title', param: 'LIKE', val: '' }, or: [] }]

        case 'ADD INPUT FIELD OR':
            state[action.index].or.push({ from: state[action.index].and.from, where: state[action.index].and.where, wheretext: state[action.index].and.wheretext, param: state[action.index].and.param, val: state[action.index].and.val, val2:state[action.index].and.val2 })
            return [...state]


        case 'DELETE INPUT FIELD':
            return [...state.filter((curr, i) => { return i !== action.index })]

        case 'DELETE INPUT FIELD OR':
            state[action.index].or.splice(action.ind, 1)
            return [...state]

        case 'ENTITY VALUE':
            state[action.index].and.from = action.entityValue
            if (action.entityValue === 'practice_fields') {
                state[action.index].and.where = 'office'
                state[action.index].and.wheretext = 'Office'
            } else {
                state[action.index].and.where = 'title'
                state[action.index].and.wheretext = 'TITLE'
            }
            return [...state]
        case 'ENTITY VALUE OR':
            state[action.index].or[action.ind].from = action.entityValue
            if (action.entityValue === 'practice_fields') {
                state[action.index].or[action.ind].where = 'office'
                state[action.index].or[action.ind].wheretext = 'Office'
            } else {
                state[action.index].or[action.ind].where = 'title'
                state[action.index].or[action.ind].wheretext = 'TITLE'
            }
            return [...state]


        case 'WHERE FIELD':
            state[action.index].and.where = action.where
            state[action.index].and.wheretext = action.wheretext

            return [...state]

        case 'WHERE FIELD OR':
            state[action.index].or[action.ind].where = action.where
            state[action.index].or[action.ind].wheretext = action.wheretext
            return [...state]

        case 'OPERATOR FIELD':
            state[action.index].and.param = action.param
            return [...state]
        case 'OPERATOR FIELD OR':
            state[action.index].or[action.ind].param = action.param
            return [...state]


        case 'VALUE_FIELD':
            state[action.index].and.val = action.val
            state[action.index].and.val2 = action.val2
            return [...state]

        case 'VALUE_FIELD_OR':
            state[action.index].or[action.ind].val2 = action.val2
            state[action.index].or[action.ind].val = action.val
            return [...state]

        case 'RESET VALUE VAL2':
            state[action.index].and.val2 = []
            state[action.index].and.val = ''
            return [...state]

        case 'RESET VALUE VAL2 OR':
            state[action.index].or[action.ind].val2 = []
            state[action.index].or[action.ind].val = ''
            return [...state]



        case 'REPORT BUILDER EDIT QUERY':
            return [...action.initialState]
        default:
            return [...state]


    }
}

const SearchBuilder = ({ reportSave, initialState }) => {
    let [queries, dispatch] = useReducer(reducer, [{ and: { from: 'VetFields', where: 'title', wheretext: 'Title', param: 'LIKE', val: '', val2: [] }, or: [] }])

    useEffect(() => {
        if (initialState.length > 0) {
            dispatch({ type: 'REPORT BUILDER EDIT QUERY', initialState: initialState })
        }
    }, [initialState])
    //reportSave comming from report builder to update the query There
    useEffect(() => {
        reportSave({ type: 'QUERY UPDATE', query: queries })
    }, [queries])
    return (
        <>
            {queries.map((item, index) => {

                return (
                    <>
                        <SearchDimensions item={item} type='AND' dispatch={dispatch} index={index} />
                        {item.or.map((curr, ind) => { return (<SearchDimensionOR curr={curr} type='OR' dispatch={dispatch} index={index} ind={ind} />) })}
                    </>
                )
            })}
            {/* {JSON.stringify(queries,2,null)} */}


        </>
    )
}

export default SearchBuilder
