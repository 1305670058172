import Axios from './xhr'

export const setAuthToken = token => {
    if (token) Axios.defaults.headers.common.Authorization = 'Bearer ' + token
    else delete Axios.defaults.headers.common.Authorization
}

export const getAuthToken = () => {
    return Axios.defaults.headers.common.Authorization ?
        Axios.defaults.headers.common.Authorization.replace('Bearer ', '') :
        false;
}