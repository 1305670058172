import React, { useState, useEffect } from 'react'
import { Col, Form, Image, Row } from 'react-bootstrap'
import deleteIcon from '../assets/icons/delete.svg';

const RadiusSearchADQ = ({ removeRadiusSearch, radiusSearchReq, setRadiusReq, type }) => {
    const [radiusSearch, setRadiusSearch] = useState({ ...radiusSearchReq })

    useEffect(() => {
        setRadiusReq({ ...radiusSearch })
    }, [radiusSearch]);

    const handleRadiusSearch = (e) => {

        const { name, value } = e.target

        if (name === 'radius_postcode') {
            setRadiusReq((prev) => ({ ...prev, [name]: value }));
        } else if (name === 'radius_miles') {
            setRadiusReq((prev) => ({ ...prev, [name]: value }))
        } else if (name === 'driving') {
            if (value === '') {
                setRadiusReq((prev) => ({ ...prev, [name]: null }))
            } else {
                setRadiusReq((prev) => ({ ...prev, [name]: value }))
            }
        }
    }

    return (
        <>
            <Row className="g-2 mt-2">
                <Col>
                    <Form.Control
                        type="text"
                        placeholder="Type PostCode"
                        name="radius_postcode"
                        value={radiusSearchReq.radius_postcode}
                        onChange={handleRadiusSearch}
                    />
                </Col>

                <Col>
                    <Form.Control
                        type="text"
                        name="radius_miles"
                        placeholder="Type Miles"
                        value={radiusSearchReq.radius_miles}
                        onChange={handleRadiusSearch}
                    />
                </Col>

                {/* <Col>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="driving"
                        value={radiusSearch.driving}
                        //isSearchable={true}
                        options={drive}
                        onChange={(e)=>{setRadiusSearch((prev)=>({...prev,driving:e.target}))}}
                    />
                </Col> */}
                <Col>
                    <Form.Control as="select"
                        name="driving"
                        as="select"
                        className="form-select"
                        value={radiusSearchReq.driving}
                        onChange={handleRadiusSearch} >
                        <option value=""> none</option>
                        <option value="home">Drive Home</option>
                        <option value="work">Drive Work</option>
                        <option value="smr">SM Drive</option>
                        <option value="smrCrow">SM Crow</option>
                        <option value="pmr">PM Drive</option>
                        <option value="pmrCrow">PM Crow</option>

                    </Form.Control>

                </Col>
                {type !== 'report' &&
                    <Col className="d-flex align-items-center">
                        <div className="action-icons">
                            <span
                                className="delete-icon"
                                onClick={() => {
                                    setRadiusReq({ driving: null, radius_miles: "", radius_postcode: "" });
                                    removeRadiusSearch();
                                }}
                            >
                                <Image src={deleteIcon} />
                            </span>
                        </div>
                    </Col>}
            </Row>
        </>
    )
}

export default RadiusSearchADQ
