import React, { useEffect, useState } from "react";
import personModel from '../constants/personModel'
import { withRouter, useHistory } from 'react-router-dom'
//redux and actions
import { useDispatch, useSelector } from "react-redux";
import { createPerson, search } from "../actions/userActions";
//react-bootstrap
import { Button, Form, Image, Col, Row } from "react-bootstrap";
//validations
import { person_active_branch, person_industry_branch, person_locum_branch, person_retired_branch } from "../helpers/validation";
//components
import ErrorMessage from "./ErrorMessage";
//perfect-scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//search-icon
import searchIcon from "../assets/icons/search-icon.svg";
import cancelIcon from "../assets/icons/cancel-icon.svg";

import BeatLoader from "react-spinners/BeatLoader";

const AddPerson = (props) => {
  const history = useHistory();
  //use-state-hooks
  const [personInput, setPersonInput] = useState(personModel);
  const [error, setError] = useState({});
  // const [ practiceId, setPracticeId ] = useState("")
  const [bar, openBar] = useState(false)
 // const [personOpen, setPersonOpen] = useState(false)
  const [Keyword, setKeyword] = useState('')
  const [data, setData] = useState({ practices: [] })
  const [group, setGroup] = useState("")
  const [searchRes, setSearchRes] = useState("")
  const [office, setOffice] = useState("")

  //react-redux
  const dispatch = useDispatch()
  const { loadingCreatePerson, errorPersonCreate } = useSelector(state => state.createPersonReducer)
  const { loadingGetPractice, practiceGet, errorGetPractice } = useSelector(state => state.getPracticeReducer)
  const { lastSearchResult } = useSelector(state => state.lastSearchedResultReducer)
  const { searchPracticeResult3 } = useSelector(state => state.searchReducer)

  useEffect(() => {
    if (searchPracticeResult3) {
      setData({
        practices: searchPracticeResult3 ? searchPracticeResult3.data.practices : [],
      })


      openBar(true)

    }
  }, [searchPracticeResult3])

  useEffect(() => {
    if (practiceGet) {
      setPersonInput(prevState => ({
        ...prevState,
        practice_id: String(practiceGet.data.id)
      }))
      setOffice(practiceGet.data.office)
    } else {
      setPersonInput(prevState => ({
        ...prevState,
        practice_id: lastSearchResult && String(lastSearchResult.data.practice.id)
      }))
      setOffice(lastSearchResult.data.practice.office)
    }
  }, [lastSearchResult, practiceGet])

  useEffect(() => {

  }, [dispatch, Keyword])

  //search stuff
  const handleKeywordChange = (e) => {
    e.preventDefault()

    setKeyword(e.target.value)
    if (Keyword.length >= 2) {
      dispatch(search(Keyword, "pers-add"))
    } else {
      openBar(false)
    }
  }
  //search stuff
  const handleKeywordCancel = () => {
    if (bar) {
      openBar(false)
    } else {
      setKeyword("");
      setSearchRes("");
    }


  }

  const handleSetPracticeId = (id, ofc) => {
    setPersonInput(prevState => ({
      ...prevState,
      practice_id: String(id),
    }))

    setOffice(ofc)
  }

    const createInitials = (e) => {
        const { name, value } = e.target;

        setPersonInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        let initials = [];
        let initialsMiddlename = [];
        let initialsForename = [];

        personInput.middlenames.toLowerCase().split(' ').map(function (val,i){
            if(val.includes('-'))
                initialsMiddlename[i] = val.split('-').map(word => word.charAt(0).toUpperCase()).join('-')
            else
                initialsMiddlename[i] = val.charAt(0).toUpperCase()
        })

        personInput.forename.toLowerCase().split(' ').map(function (val,i){
            if(val.includes('-'))
                initialsForename[i] = val.split('-').map(word => word.charAt(0).toUpperCase()).join('-')
            else
                initialsForename[i] = val.charAt(0).toUpperCase()
        })

        // combine the forename with the middle name
        initials = initialsForename.join(' ') + ' ' + initialsMiddlename.join(' ');

        // remove the white spaces with single space
        initials = initials.replace(/\s+/g, ' ');

        // remove the white spaces that is surround (-) sign
        initials = initials.replace(/\s+(?=[-])|(?<=-)\s*/g, '');

        setPersonInput((prevState) => ({
            ...prevState,
            initials: initials.trim(),
        }));
    };

  const handlePersonChange = (e) => {

    const { name, value } = e.target;

    if (office === "Industry Main" || office === "Industry Branch") {
      setPersonInput((prev)=>({...prev,v_job_types: 'O'}))
    }else{
        setPersonInput((prev)=>({...prev,ind_type: 'None'}))
    }
    if (value == 'Mr') {
      setPersonInput((prevState) => ({
          ...prevState,
          gender: 'Male',
      }));
  } else if (['Dr','Mrs', 'Ms', 'Miss'].includes(value)) {
      setPersonInput((prevState) => ({
          ...prevState,
          gender: 'Female',
      }));
  }

    if (name === 'v_job_types') {
      let v_job_types = personInput.v_job_types.length > 0 ? personInput.v_job_types.split("") : [];

      if (v_job_types.find((curr) => curr === value)) {
          let str = v_job_types.filter(curr => curr !== value).join("")
            if(value == 'X' || value == 'C'){
                setPersonInput((prevState) => ({
                    ...prevState,v_species: ""
                }));
            }
          setPersonInput((prevState) => ({
          ...prevState,
          v_job_types: str
        }));
      } else {
              v_job_types.push(value)
        let str = v_job_types.length > 0 ? v_job_types.join("") : ""
        setPersonInput((prevState) => ({
          ...prevState,
          v_job_types: str,
        }));
      }
    } else if (name === 'v_species') {
      let v_species = personInput.v_species.length > 0 ? personInput.v_species.split("") : [];

      if (v_species.find((curr) => curr === value)) {
        let str = v_species.filter(curr => curr != value).join("")
        setPersonInput((prevState) => ({
          ...prevState,
          v_species: str,
        }));
      } else {
        v_species.push(value)
        let str = v_species.length > 0 ? v_species.join("") : ""
        setPersonInput((prevState) => ({
          ...prevState,
          v_species: str,
        }));
      }
    }
    else if (name === 'practice_id') {
      setGroup(e.target.value);
      setKeyword("")
      setPersonInput({
        practice_id: value,
        title: "",
        initials:"",
        forename: "",
        middlenames: "",
        surname: "",
        maiden: "",
        gender: "",
        v_job_types: "",
        v_species: "",
        u_suffix: "",
        u_cert: "",
        u_year: "",
        v_rcvsnum: "",
        other: ""
      });
    }
    else {
      setPersonInput((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handlePersonSubmit = async (e) => {
    e.preventDefault();
    const data = personInput;
    personInput.initials = document.getElementById('initials-id').value
    const {
      practice_id,
      title,
      initials,
      forename,
      middlenames,
      surname,
      maiden,
      gender,
      v_job_types,
      v_species,
      u_suffix,
      u_cert,
      u_year,
      v_rcvsnum,
      v_job_types_o,
      v_species_o
    } = personInput;

    let errors = {}

    if (office === "Industry Main" || office === "Industry Branch") {
      errors = person_industry_branch(data)
    } else if (personInput.practice_id === "1") {
      errors = person_locum_branch(data)
    } else if (personInput.practice_id === '3') {
      errors = person_retired_branch(data)
    } else {
      errors = person_active_branch(data);
    }
    setError(errors);

    if (Object.entries(errors).length === 0) {
      const dataBody = {
        practice_id: practice_id,
        title: title,
        initials: initials,
        forename: forename,
        middlenames: middlenames,
        surname: surname,
        maiden: maiden,
        gender: gender,
        v_job_types: v_job_types,
        v_job_types_o: v_job_types_o,
        v_species: v_species,
        v_species_o: v_species_o,
        u_suffix: u_suffix,
        u_cert: u_cert,
        u_year: u_year,
        v_rcvsnum: v_rcvsnum,
        v_sdm: 'Yes',
      };

      for (var key in dataBody) {
        if(dataBody[key] === ''){
            dataBody[key] = null;
        }
      }

      if (personInput.practice_id == '1' || personInput.practice_id == '3') dataBody.v_pma = 'Home'
      else dataBody.v_pma = 'Work'

      if (personInput.practice_id === '3') { dataBody.v_sdm = 'No' }
      if(office.split(" ")[0] === "Industry"){ dataBody.v_sdm = 'No' }
      dispatch(createPerson(dataBody));
        // history.push('/')
        props.setClosePopUp()
    }
  };
  //console.log("this is personInput",personInput)
  return (
    <>
      {/* Person slider */}
      <PerfectScrollbar>
        <Form onSubmit={handlePersonSubmit}>
          {/* {personCreate && (
            <div
              style={{
                position: "absolute",
                top: 3,
                right: 0,
                left: 0,
              }}
            >
              <ToastUI header="Vetfile" body="Person created successfully." />
            </div>
          )} */}

          <h4 className="my-3 px-4">
            <span>Create a new individual</span>
          </h4>
          <div className="create-individual px-4">
            <Form.Label>
              Practice/Industry<span className="jazzberryJam-text ms-1">*</span>
            </Form.Label>
            <Form.Control
              name="practice_id"

              as="select"
              className="form-select"
              onChange={(e) => { handlePersonChange(e) }}
            >
              <option
                value={practiceGet
                  ? practiceGet.data.id
                  : lastSearchResult
                    ? lastSearchResult.data.vet.practice_id
                    : ""
                }
              > Insert in the active group
              </option>
              {/* <option value="1">Search for the group</option> */}
              {/* <option value="1">Locum only</option>
              <option value="3">Retired only</option>
              <option value="4">Industry only</option> */}
              <option
                value="search_group"
              > Search for the other group</option>
            </Form.Control>
          </div>

          {group === "search_group" && <div className="search-group px-4">
            <h4 className="mt-4 mb-1t">Search for a group
              <span>New individual will be created and pushed to selected practice/industry.</span>
            </h4>

            <Form.Label>Practice or industry</Form.Label>
            <div className="find-address">
              <span className="search-icon" onClick={() => { openBar(true) }}><Image src={searchIcon} /><span className="search-arrow"></span></span>
              <span className="cancel-icon" onClick={handleKeywordCancel}><Image src={cancelIcon} /></span>
              <Form.Control
                type="text"
                size="sm"
                placeholder="search practice or industry"
                autoComplete="nope"
                // value={searchRes !== "" ? searchRes : Keyword}
                value={Keyword}
                onChange={handleKeywordChange}


              />
              {bar && <div className="search-address-dropdown">
                <Row >
                  <Col lg={12}>
                    {data.hasOwnProperty("practices") && data.practices.map((curr, index) =>
                      <div
                        key={index}
                        className="search-results position-relative"
                        // onClick={() => {handleSetPracticeId(curr.id, curr.office); setSearchRes(curr.name); openBar(false)}}
                        onClick={() => { handleSetPracticeId(curr.id, curr.office); setSearchRes(curr.name); setKeyword(curr.name); openBar(false) }}
                      >
                        <div className="search-result mx-2">
                          <p>{curr.name}</p>
                          <span>{curr.address_1} {curr.postcode}</span>
                        </div>
                        <div className="search-divider"></div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>}
            </div>

          </div>}

          <div className="detail-information px-4">
            <h4 className="mt-4 mb-3">
              <span>Detail Information</span>
            </h4>
            <Form.Label>Title<span className="jazzberryJam-text ms-1">*</span></Form.Label>
            <Form.Control
              name="title"
              as="select"
              value={personInput.title}
              className={(!personInput.title && error.title) ? "invalid" : "form-select"}
              onChange={handlePersonChange}
            >
              <option value="">Select title</option>
              <option value="Dr">Dr</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Ms">Ms</option>
              <option value="Miss">Miss</option>
              <option value="Mx">Mx</option>
              <option value="Prof">Prof</option>
              <option value="Major">Major</option>
              <option value="Sir">Sir</option>
              <option value="Brig">Brig</option>
              <option value="Capt">Capt</option>
              <option value="The">The</option>
            </Form.Control>
            {/* {!personInput.title && <ErrorMessage error={error.title} />} */}

            <Form.Label>Initials</Form.Label>
            <Form.Control
              id='initials-id'
              name="initials"
              type="text"
              size="sm"
              readOnly={true}
              value={ personInput.initials}
            />
            <Form.Label>
              Forename<span className="jazzberryJam-text ms-1">*</span>
            </Form.Label>
            <Form.Control
              name="forename"
              type="text"
              value={personInput.forename}
              className={(!personInput.forename && error.forename) ? "invalid" : ""}
              onChange={createInitials}
            />
            {/* {!personInput.forename && <ErrorMessage error={error.forename} />} */}
            <Form.Label>Middle names</Form.Label>
            <Form.Control
              name="middlenames"
              type="text"
              value={personInput.middlenames}
              onChange={createInitials}
            />
            {/* <ErrorMessage error={error.middlename}/> */}
            <Form.Label>
              Surname<span className="jazzberryJam-text ms-1">*</span>
            </Form.Label>
            <Form.Control
              name="surname"
              type="text"
              value={personInput.surname}
              className={(!personInput.surname && error.surname) ? "invalid" : ""}
              onChange={handlePersonChange}
            />
            {/* {!personInput.surname && <ErrorMessage error={error.surname} />} */}
            <Form.Label>Maiden name</Form.Label>
            <Form.Control
              name="maiden"
              type="text"
              value={personInput.maiden}
              className={(!personInput.maiden && error.maiden) ? "invalid" : ""}
              onChange={handlePersonChange}
            />
            {/* {!personInput.maiden && (
              <ErrorMessage error={error.maiden} />
            )} */}
            <Form.Label>Gender<span className="jazzberryJam-text ms-1">*</span></Form.Label>
            <Form.Control
              name="gender"
              as="select"
              value={personInput.gender}
              className={(!personInput.gender && error.gender) ? "invalid" : "form-select"}
              onChange={handlePersonChange}
            >
              <option value="">Select gender</option>
              {(['Dr','Mx','Mr','Prof','Major','Sir','Brig','Capt','The'].includes(personInput.title) ) && <option value="Male">Male</option>}
              {(['Dr','Mx','Mrs','Miss','Ms','Prof','Major','Sir','Brig','Capt','The'].includes(personInput.title) ) && <option value="Female">Female</option>}
            </Form.Control>
            {/* {!personInput.gender && <ErrorMessage error={error.gender} />} */}

            {/* Job types for active branch */}
            {(personInput.practice_id !== "1"
              && personInput.practice_id !== "2"
              && personInput.practice_id !== "3"
              && office !== "Industry Main"
              && office !== "Industry Branch") && (
                <>
                  <h4 className="mt-4 mb-3">
                    <span>Job types</span>
                  </h4>
                  <span className="text-w-500 mb-2 required-note d-block">
                    At least one job type is required.
                  </span>

                  <Form.Group controlID="v_job_types_Senior_Partner">
                    <Form.Check
                      id="v_job_types_Senior_Partner"
                      name="v_job_types"
                      type="checkbox"
                      label="(Z) Senior Partner"
                      checked={personInput.v_job_types.includes('Z')}
                      value="Z"
                      className="form-label"
                      onChange={handlePersonChange}
                    />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Buyer">
                  <Form.Check
                    id="v_job_types_Buyer"
                    name="v_job_types"
                    type="checkbox"
                    label="(A) Buyer"
                    checked={personInput.v_job_types.includes('A')}
                    value="A"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Partner">
                  <Form.Check
                    id="v_job_types_Partner"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('B')}
                    label="(B) Partner"
                    value="B"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Veterinary_Surgeon">
                  <Form.Check
                    id="v_job_types_Veterinary_Surgeon"
                    name="v_job_types"
                    type="checkbox"
                    label="(C) Veterinary Surgeon"
                    checked={personInput.v_job_types.includes('C')}
                    value="C"
                    className="form-label"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(
                      (curr) =>
                        curr === "S" ||
                        curr === "E" ||
                        curr === "D" ||
                        curr === "G" ||
                        curr === "R" ||
                        curr === "X" ||
                        curr === "Y"
                    ) || (personInput.v_jobtypes_o && personInput.value == "C")}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Locum_Veterinary_Surgeon">
                  <Form.Check
                    id="v_job_types_Locum_Veterinary_Surgeon"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('X')}
                    label="(X) Locum Veterinary Surgeon"
                    value="X"
                    className="form-label"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(curr =>
                      curr === "Y" ||
                      curr === "C" ||
                      curr === "S" ||
                      curr === "E" ||
                      curr === "N" ||
                      curr === "G" ||
                      curr === "R"
                    ) || (personInput.v_jobtypes_o && personInput.value == "X")}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Student_Vet">
                  <Form.Check
                    id="v_job_types_Student_Vet"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('S')}
                    label="(S) Student Vet"
                    value="S"
                    className="form-label"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(
                      (curr) =>
                        curr === "C" ||
                        curr === "E" ||
                        curr === "D" ||
                        curr === "G" ||
                        curr === "R" ||
                        curr === "X" ||
                        curr === "Y"
                    ) || (personInput.v_jobtypes_o && personInput.value == "S")}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Head_Nurse">
                  <Form.Check
                    id="v_job_types_Head_Nurse"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('E')}
                    label="(E) Head Nurse"
                    value="E"
                    className="form-label"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(
                      (curr) =>
                        curr === "C" ||
                        curr === "S" ||
                        curr === "D" ||
                        curr === "G" ||
                        curr === "R" ||
                        curr === "X" ||
                        curr === "Y"

                    ) || (personInput.v_jobtypes_o && personInput.value == "E")}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Nurse">
                  <Form.Check
                    id="v_job_types_Nurse"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('D')}
                    label="(D) Nurse"
                    className="form-label"
                    value="D"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(
                      (curr) =>
                        curr === "C" ||
                        curr === "S" ||
                        curr === "E" ||
                        curr === "G" ||
                        curr === "R" ||
                        curr === "X" ||
                        curr === "Y"
                    ) || (personInput.v_jobtypes_o && personInput.value == "D")}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Locum_Nurse">
                  <Form.Check
                    id="v_job_types_Locum_Nurse"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('Y')}
                    label="(Y) Locum Nurse"
                    value="Y"
                    className="form-label"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(
                      (curr) =>
                        curr === "C" ||
                        curr === "X" ||
                        curr === "S" ||
                        curr === "E" ||
                        curr === "D" ||
                        curr === "G" ||
                        curr === "R"
                    ) || (personInput.v_jobtypes_o && personInput.value == "Y")}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Trainee_Nurse">
                  <Form.Check
                    id="v_job_types_Trainee_Nurse"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('G')}
                    label="(G) Student Veterinary Nurse"
                    value="G"
                    className="form-label"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(
                      (curr) =>
                        curr === "C" ||
                        curr === "S" ||
                        curr === "E" ||
                        curr === "D" ||
                        curr === "R" ||
                        curr === "X" ||
                        curr === "Y"
                    ) || (personInput.v_jobtypes_o && personInput.value == "G")}
                  />

                  </Form.Group>

                  <Form.Group controlID="v_job_types_VNA">
                  <Form.Check
                    id="v_job_types_VNA"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('R')}
                    label="(R) VNA"
                    className="form-label"
                    value="R"
                    onChange={handlePersonChange}
                    disabled={personInput.v_job_types.split("").find(
                      (curr) =>
                        curr === "C" ||
                        curr === "S" ||
                        curr === "E" ||
                        curr === "D" ||
                        curr === "G" ||
                        curr === "X" ||
                        curr === "Y"
                    ) || (personInput.v_jobtypes_o && personInput.value == "R")}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Practice_Manager">
                  <Form.Check
                    id="v_job_types_Practice_Manager"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('P')}
                    label="(P) Practice Manager"
                    value="P"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Admin">
                  <Form.Check
                    id="v_job_types_Admin"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('F')}
                    label="(F) Admin"
                    value="F"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_Reception">
                  <Form.Check
                    id="v_job_types_Reception"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('H')}
                    label="(H) Reception"
                    value="H"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                  </Form.Group>

                  <Form.Group controlID="v_job_types_o_Other">
                  <Form.Control
                    id="v_job_types_o_Other"
                    name="v_job_types_o"
                    type="text"
                    label="Other"
                    value={personInput.v_job_types_o}
                    placeholder="Other"
                    className="form-label"
                    onChange={handlePersonChange}

                  //disabled={personInput.v_job_types.length > 0 }
                  />
                  </Form.Group>

                </>
              )}

            {/* Locum only */}
            {personInput.practice_id === "1" && (
              <>
                <h4 className="mt-4 mb-3">
                  <span>Job types</span>
                </h4>
                <span className="text-w-500 mb-2 required-note d-block">
                  At least one job type is required.
                </span>

                <Form.Group controlID="v_job_types_Locum_Veterinary_Surgeon">
                <Form.Check
                  id="v_job_types_Locum_Veterinary_Surgeon"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('X')}
                  className="form-label"
                  label="(X) Locum Veterinary Surgeon"
                  value="X"
                  onChange={handlePersonChange}
                />
                </Form.Group>

                <Form.Group controlID="v_job_types_Locum_Nurse">
                <Form.Check
                  id="v_job_types_Locum_Nurse"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('Y')}
                  label="(Y) Locum Nurse"
                  value="Y"
                  className="form-label"
                  onChange={handlePersonChange}
                />
                </Form.Group>

                <Form.Group controlID="v_job_types_o">
                <Form.Control
                  id="v_job_types_o"
                  name="v_job_types_o"
                  type="text"
                  value={personInput.v_job_types_o}
                  placeholder="Other"
                  onChange={handlePersonChange}
                />
                </Form.Group>

                {/* <Form.Check
                  name="v_job_types"
                  type="checkbox"
                  label="Other"
                  value="O"
                  className="form-label"
                  onChange={handlePersonChange}
                /> */}
              </>
            )}

            {/* Retired only */}
            {personInput.practice_id === "3" && (
              <>
                <h4 className="mt-4 mb-3">
                  <span>Job types</span>
                </h4>
                <span className="text-w-500 mb-2 required-note d-block">
                  At least one job type is required.
                </span>

                <Form.Group controlID="v_job_types_Senior_Partner">
                <Form.Check
                  id="v_job_types_Senior_Partner"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('Z')}
                  label="(Z) Senior Partner"
                  value="Z"
                  className="form-label"
                  onChange={handlePersonChange}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Buyer">
                  <Form.Check
                    id="v_job_types_Buyer"
                    name="v_job_types"
                    type="checkbox"
                    checked={personInput.v_job_types.includes('A')}
                    label="(A) Buyer"
                    value="A"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>
                <Form.Group controlID="v_job_types_Partner">
                    <Form.Check
                      id="v_job_types_Partner"
                      name="v_job_types"
                      type="checkbox"
                      checked={personInput.v_job_types.includes('B')}
                      label="(B) Partner"
                      value="B"
                      className="form-label"
                      onChange={handlePersonChange}
                    />
                </Form.Group>
                <Form.Group controlID="v_job_types_Veterinary_Surgeon">
                <Form.Check
                  id="v_job_types_Veterinary_Surgeon"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('C')}
                  label="(C) Veterinary Surgeon"
                  value="C"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(
                    (curr) =>
                      curr === "S" ||
                      curr === "E" ||
                      curr === "D" ||
                      curr === "G" ||
                      curr === "R" ||
                      curr === "X" ||
                      curr === "Y"
                  )}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Locum_Veterinary_Surgeon">
                <Form.Check
                  id="v_job_types_Locum_Veterinary_Surgeon"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('X')}
                  label="(X) Locum Veterinary Surgeon"
                  value="X"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(curr =>
                    curr === "Y" ||
                    curr === "C" ||
                    curr === "S" ||
                    curr === "E" ||
                    curr === "N" ||
                    curr === "G" ||
                    curr === "R"
                  ) || (personInput.value == "X")}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Student_Vet">
                <Form.Check
                  id="v_job_types_Student_Vet"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('S')}
                  label="(S) Student Vet"
                  value="S"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(
                    (curr) =>
                      curr === "C" ||
                      curr === "E" ||
                      curr === "D" ||
                      curr === "G" ||
                      curr === "R" ||
                      curr === "X" ||
                      curr === "Y"
                  )}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Head_Nurse">
                <Form.Check
                  id="v_job_types"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('E')}
                  label="(E) Head Nurse"
                  value="E"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(
                    (curr) =>
                      curr === "C" ||
                      curr === "S" ||
                      curr === "D" ||
                      curr === "G" ||
                      curr === "R"
                  )}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Nurse">
                <Form.Check
                  id="v_job_types_Nurse"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('D')}
                  label="(D) Nurse"
                  value="D"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(
                    (curr) =>
                      curr === "C" ||
                      curr === "S" ||
                      curr === "E" ||
                      curr === "G" ||
                      curr === "R"
                  )}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Locum_Nurse">
                <Form.Check
                  id="v_job_types_Locum_Nurse"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('Y')}
                  label="(Y) Locum Nurse"
                  value="Y"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(
                    (curr) =>
                      curr === "C" ||
                      curr === "X" ||
                      curr === "S" ||
                      curr === "E" ||
                      curr === "D" ||
                      curr === "G" ||
                      curr === "R"
                  ) || (personInput.value == "Y")}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Trainee_Nurse">
                <Form.Check
                  id="v_job_types_Trainee_Nurse"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('G')}
                  label="(G) Student Veterinary Nurse"
                  value="G"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(
                    (curr) =>
                      curr === "C" ||
                      curr === "S" ||
                      curr === "E" ||
                      curr === "D"
                  )}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Trainee_Nurse">
                <Form.Check
                  id="v_job_types_Trainee_Nurse"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('R')}
                  label="(R) VNA"
                  value="R"
                  className="form-label"
                  onChange={handlePersonChange}
                  disabled={personInput.v_job_types.split("").find(
                    (curr) =>
                      curr === "C" ||
                      curr === "S" ||
                      curr === "E" ||
                      curr === "D" ||
                      curr === "G"

                  )}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Practice_Manager">
                <Form.Check
                  id="v_job_types_Practice_Manager"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('P')}
                  label="(P) Practice Manager"
                  value="P"
                  className="form-label"
                  onChange={handlePersonChange}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Admin">
                <Form.Check
                  id="v_job_types_Admin"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('F')}
                  label="(F) Admin"
                  value="F"
                  className="form-label"
                  onChange={handlePersonChange}
                />
                </Form.Group>
                <Form.Group controlID="v_job_types_Reception">
                <Form.Check
                  id="v_job_types_Reception"
                  name="v_job_types"
                  type="checkbox"
                  checked={personInput.v_job_types.includes('H')}
                  label="(H) Reception"
                  value="H"
                  className="form-label"
                  onChange={handlePersonChange}
                />
                </Form.Group>

                <Form.Group controlID="v_job_types_o">
                <Form.Control
                  id="v_job_types_o"
                  name="v_job_types_o"
                  placeholder="Other"
                  type="text"
                  value={personInput.v_job_types_o}
                  onChange={handlePersonChange}
                />
                </Form.Group>

                {/* <Form.Check
                  name="v_job_types"
                  type="checkbox"
                  label="Other"
                  value="O"
                  className="form-label"
                  onChange={handlePersonChange}
                /> */}

              </>
            )}
            {((personInput.practice_id === "0" &&
              personInput.v_job_types.split("").find((curr) => curr === "O")) ||
              (personInput.practice_id === "2" &&
                personInput.v_job_types.split("").find((curr) => curr === "O")) ||
              (personInput.practice_id === "3" &&
                personInput.v_job_types.split("").find((curr) => curr === "O"))) && (
                <Form.Control
                  name="other"
                  type="text"
                  className="mt-3"
                  placeholder="Other job type"
                  onChange={handlePersonChange}
                />
              )}

            {
              (office === "Industry Main" || office === "Industry Branch") &&
              <>
                <Form.Label>Other Job Type</Form.Label>
                <Form.Control
                  name="v_job_types_o"
                  className={(!personInput.v_job_types_o && error.v_job_types_o) ? "invalid" : ""}
                  value={personInput.v_job_types_o}
                  onChange={handlePersonChange}
                />
              </>
            }

            {/* Species */}
            {(((personInput.practice_id !== "1" && personInput.practice_id !== "2" && personInput.practice_id !== "3") &&
              personInput.v_job_types.split("").some(
                (curr) => curr === "C" || curr === "X"
              )) ||
              (personInput.practice_id === "1" &&
                personInput.v_job_types.split("").some(
                  (curr) => curr === "X" ))) && (
                <>
                  <h4 className="mt-4 mb-3">
                    <span>Species</span>
                  </h4>
                  {/* <span className="text-w-500 mb-2 required-note d-block">
                  At least one job type is required.
                </span> */}

                <Form.Group controlID="v_species_Dairy_cattle">
                  <Form.Check
                    id="v_species_Dairy_cattle"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('D')}
                    label="(D) Dairy cattle"
                    value="D"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Beef_cattle">
                  <Form.Check
                      id="v_species_Beef_cattle"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('B')}
                    label="(B) Beef cattle"
                    value="B"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Poultry">
                  <Form.Check
                    id="v_species_Poultry"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('T')}
                    label="(T) Poultry"
                    value="T"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Sheep_goats">
                  <Form.Check
                    id="v_species_Sheep_goats"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('S')}
                    label="(S) Sheep, goats"
                    value="S"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Birds">
                  <Form.Check
                    id="v_species_Birds"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('G')}
                    label="(G) Birds"
                    value="G"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Dogs">
                  <Form.Check
                    id="v_species_Dogs"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('C')}
                    label="(C) Dogs"
                    value="C"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Cats">
                  <Form.Check
                    id="v_species_Cats"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('F')}
                    label="(F) Cats"
                    value="F"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Pigs">
                  <Form.Check
                    id="v_species_Pigs"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('P')}
                    label="(P) Pigs"
                    value="P"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Horses">
                  <Form.Check
                    id="v_species_Horses"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('H')}
                    label="(H) Horses"
                    value="H"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Reptiles">
                  <Form.Check
                    id="v_species_Reptiles"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('X')}
                    label="(X) Reptiles"
                    value="X"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Rodents">
                  <Form.Check
                    id="v_species_Rodents"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('E')}
                    label="(E) Rodents"
                    value="E"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Zoo_wildlife_fish">
                  <Form.Check
                    id="v_species_Zoo_wildlife_fish"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('Z')}
                    label="(Z) Zoo, wildlife, fish"
                    value="Z"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_Rabbits">
                  <Form.Check
                    id="v_species_Rabbits"
                    name="v_species"
                    type="checkbox"
                    checked={personInput.v_species.includes('R')}
                    label="(R) Rabbits"
                    value="R"
                    className="form-label"
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                <Form.Group controlID="v_species_o">
                  <Form.Control
                    id="v_species_o"
                    name="v_species_o"
                    type="text"
                    value={personInput.v_species_o}
                    placeholder="Other species"
                    //disabled={personInput.v_species_o.length > 0 }
                    onChange={handlePersonChange}
                  />
                </Form.Group>

                  {/*
                {!personInput.v_species.length > 0 && (
                  <ErrorMessage error={error.v_species} />
                )} */}
                </>
              )}


            {(((personInput.practice_id !== "1" && personInput.practice_id !== "2" && personInput.practice_id !== "3") &&
              personInput.v_job_types.split("").some(
                (curr) => curr === "C" || curr === "E" || curr === "D" || curr === "X" || curr === "Y"
              )) ||
              (personInput.practice_id === "1" &&
                personInput.v_job_types.split("").some((curr) => curr === "X" || curr === "Y")) ||
              (personInput.practice_id === "3" &&
                personInput.v_job_types.split("").some(
                  (curr) => curr === "C" || curr === "E" || curr === "D" || curr === "X" || curr === "Y"
                ))) && (
                <>
                  <h4 className="mt-4 mb-3">
                    <span>Qualification</span>
                  </h4>
                  {/* <span>Please complete</span> */}
                  <Form.Label>Suffix</Form.Label>
                  <Form.Control
                    name="u_suffix"
                    type="text"
                    value={personInput.u_suffix}
                    onChange={handlePersonChange}
                  />
                  <Form.Label>Certificates and diplomas</Form.Label>
                  <Form.Control
                    name="u_cert"
                    value={personInput.u_cert}
                    type="text"
                    onChange={handlePersonChange}
                  />
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    name="u_year"
                    as="select"
                    className="form-select"
                    onChange={handlePersonChange}
                  >
                    <option value="">Select year</option>
                    {Array.from({ length: 91 }, (_, i) => 2030-i).map((curr, ind) => (
                      <option key={ind} value={String(curr)}>{curr}</option>
                    ))
                    }
                  </Form.Control>
                  <Form.Label>RCVS number</Form.Label>
                  <Form.Control
                    name="v_rcvsnum"
                    value={personInput.v_rcvsnum}
                    type="text"
                    onChange={handlePersonChange}
                  />
                  {(!personInput.u_suffix ||
                    !personInput.u_cert ||
                    !personInput.v_rcvsnum ||
                    !personInput.u_year) && (
                      <ErrorMessage error={error.qualification} />
                    )}
                </>
              )}
          </div>

          <div className="px-4">
            <Button type="submit" className="btn theme-btn-primary w-100 my-4">
              Create New Individual <BeatLoader color="#ffffff" loading={loadingCreatePerson === true} css="" size={5} />
            </Button>
          </div>
        </Form>
      </PerfectScrollbar>
    </>
  );
};

export default withRouter(AddPerson);
