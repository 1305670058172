import React from 'react'
import { Route, Redirect } from 'react-router-dom'
//check fro logged in user
import isLogin from '../helpers/isLogin'

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    return (
        //restricted = false meaning public route
        //restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isLogin() && restricted ?
                <Redirect to='/dashboard' />
            : <Component {...props} />
        )} />
    )
}

export default PublicRoute