import { React, useEffect, useReducer, useState } from "react";
//react-bootstrap
import {
    Button,
    Form,
    FormControl,
    Nav,
    Navbar,
    Col,
    Container,
    Row,
    Image,
    Collapse,
    ListGroup,
} from "react-bootstrap";
//react-redux
import { useDispatch, useSelector } from "react-redux";

import {search, openPractice, openPerson, lastSearchedResult, resetSearchResult} from "../actions/userActions";
//actions
import { logout } from "../actions/userActions";
// vetfile logo
import VetfileLogo from "../assets/Vetfile-main-logo.svg";
import personModel from "../constants/personModel";
import practiceModel from "../constants/practiceModel";
//components
import AddPerson from "./AddPerson";
import AddPractice from "./AddPractice";
//perfect-scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
//search-icon
import searchIcon from "../assets/icons/search-icon.svg";
import cancelIcon from "../assets/icons/cancel-icon.svg";
import searchResult from "../assets/icons/search-result.svg";
import LastViewed from './LastViewed'
import GlobalLoader from "./GlobalLoader";
import ExportBuilder from "./ExportBuilder";
import AdvanceQuery from "./AdvanceQuery"
import useOnclickOutside from "react-cool-onclickoutside";
import AdvanceQueryBuilder from "./AdvanceQueryBuilder";
import Swal from "sweetalert2";

const Header = (props) => {
    const [open, setOpen] = useState({
        coll: false,
        practiceOpen: false,
        personOpen: false,
        lastViewedOpen: false,
        exportBuilder: false
    });
    const [bar, openBar] = useState(false);
    const [cls, setClass] = useState("")
    const [Keyword, setKeyword] = useState("");
    const [openSidebar, setOpenSidebar] = useState(false);
    const [data, setData] = useState({
        practices: [],
        vets: [],
    });
    //dispatch
    const dispatch = useDispatch();
    const { searchResult } = useSelector((state) => state.searchReducer);

    useEffect(() => {

        if (open.personOpen === true || open.practiceOpen === true || open.lastViewedOpen === true || open.exportBuilder === true) {
            setOpen({ coll: props.open, practiceOpen: false, personOpen: false, lastViewedOpen: false, exportBuilder: false })
        }
        if (bar == true) {
            openBar(false);
        }
    }, [props.open, props.searchOpen]);

    useEffect(() => {
        if (Keyword.length >= 3) {
            dispatch(search(Keyword, "all"));
        } else {
            openBar(false);
        }
    }, [dispatch, Keyword]);

    useEffect(() => {
        if (searchResult) {
            if (searchResult.hasOwnProperty('data')) {
                    setData({
                        practices: searchResult.data.practices ? searchResult.data.practices : [],
                        vets: searchResult.data.vets ? searchResult.data.vets : [],
                    });
                    openBar(true);
            }
        }
    }, [searchResult]);

    const handleKeywordChange = (e) => {
        e.preventDefault();

        const { value } = e.target;

        setKeyword(value);
    };

    const handleSendPracticeId = (dataId) => {
        dispatch(openPractice(dataId));
        dispatch(resetSearchResult());
        setKeyword('')
        //    props.resetState();
    };
    const handleSendPersonId = (dataId) => {
        dispatch(openPerson(dataId));
        dispatch(resetSearchResult());
        setKeyword('')
        //props.resetState();
    };



    const ref = useOnclickOutside(() => {
        //setOpenSidebar(false);
        setClass('toggle')

    });
    const openQuerySideBar = () => {
        setOpenSidebar(true)
    }

    // useEffect(() => {
    //     if(open.personOpen===false) {
    //         setPersonInput(personModel)
    //     }
    // }, [open])
    const messageOnClick = () => {
        if (props.showPersonMessage || props.showPracticeMessage) {
            Swal.fire({ title: 'Message', text: 'Changes has been made on vet or practice window. Dont forget to save changes if needed.', confirmButtonText: 'Understood' })
            props.showPersonMsgFunc(false)
            props.practiceMessageFunc(false);
        }
    }
    const setClosePopUp = () => {
        setOpen({personOpen:false,practiceOpen:false});
    }

    return (
        <>
            <header>
                <GlobalLoader />
                <Container fluid>
                    {openSidebar && <div ref={ref} id="addPractice"> ? (
            <Collapse in={openSidebar}>
                            <div id="advance-query-builder" className={`${cls != '' ? 'toggle' : ''}`}>
                                <AdvanceQueryBuilder cls={cls} setClass={setClass} userMe_role={props.userMe_role} />
                            </div>
                        </Collapse>

        )</div>}
                    <Row onClick={messageOnClick}>
                        <Navbar className="align-items-center justify-content-between">
                            <Col lg={8}>
                                <Row className="p-0 align-items-center">
                                    <Col lg={3}>
                                        <Navbar.Brand>
                                            <Image src={VetfileLogo} width={110} />
                                        </Navbar.Brand>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="align-items-center d-flex">
                                            <Form className="d-flex position-relative header-search-bar">
                                                <div className="position-relative header-search w-100">
                                                    <span className="search-icon" ><Image src={searchIcon} /><span className="search-arrow"></span></span>
                                                    <span className="cancel-icon" onClick={() => { openBar(false); setKeyword("") }}><Image src={cancelIcon} /></span>
                                                    <FormControl
                                                        type="text"
                                                        placeholder="Search"
                                                        className="mr-sm-2 mb-0 search-bar"
                                                        onFocus={() => setOpen({  personOpen: false,practiceOpen:false })}
                                                        onChange={handleKeywordChange}
                                                        value={Keyword}
                                                    />
                                                </div>
                                                {bar && <div className="header-search-dropdown">
                                                    <Row className="px-4">
                                                        <Col lg={7}>
                                                            {data.hasOwnProperty("practices") && data.practices.map((curr, index) =>
                                                                <div key={index} className="search-results position-relative">
                                                                    <div
                                                                        className={curr.office.split(' ')[0] === 'Industry' ? "search-result me-2  practice-industry-paw" : "search-result me-2"}
                                                                        onClick={() => {
                                                                            handleSendPracticeId(curr.id)
                                                                            openBar(false)
                                                                        }}
                                                                    >
                                                                        <p>{curr.name}</p>
                                                                        <span>{curr.address_1}, {curr.postcode}</span>
                                                                    </div>
                                                                    <div className="search-divider"></div>
                                                                </div>
                                                            )}
                                                        </Col>

                                                        <Col lg={5}>
                                                            {data.hasOwnProperty("vets") && data.vets.map((curr, index) =>
                                                                <div key={index} className="search-users">
                                                                    <div
                                                                        className={curr.office.split(' ')[0]=== 'Industry' ? "search-result me-2  practice-industry-paw" : "search-result me-2"}
                                                                        onClick={() => {
                                                                            handleSendPersonId(curr.id)
                                                                            openBar(false)
                                                                        }}
                                                                    >
                                                                        <p className="header-search-username">{curr.forename} {curr.surname}</p>
                                                                        <span className="header-search-address">{curr.v_address_1} {curr.postcode}</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    {/* <PerfectScrollbar>
                                            {data.hasOwnProperty("practices") && data.practices.map((curr, index) =>
                                                <p key={index}>
                                                    <span>{curr.address_1}, </span>
                                                    <span>{curr.office}</span>
                                                </p>
                                            )}
                                        </PerfectScrollbar> */}
                                                </div>}
                                                {/* <Button className="advanced-btn">Advanced</Button> */}
                                            </Form>
                                            {/* <h6 className="ms-3 mb-0 cursor-pointer" onClick={()=> {openQuerySideBar()}}>Advance Query Builder</h6> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4} className="d-flex">
                                <Nav className="ms-auto">

                                    {(props.userMe_role == 1 || props.userMe_role == 2 || props.userMe_role == 3) && <Nav.Link onClick={() => setOpen({ personOpen: !open.personOpen, practiceOpen: false })}>Add Person</Nav.Link>}
                                    {(props.userMe_role == 1 || props.userMe_role == 2 || props.userMe_role == 3) && <Nav.Link onClick={() => setOpen({ personOpen: false, practiceOpen: !open.practiceOpen })}>Add Practice</Nav.Link>}
                                    <Nav.Link onClick={() => setOpen({ personOpen: false, practiceOpen: false, lastViewedOpen: !open.lastViewedOpen })}>Last Viewed</Nav.Link>

                                </Nav>
                                <Nav.Link href="#" className="logout ms-5" onClick={() => { dispatch(logout()) }}>Logout</Nav.Link>
                            </Col>
                        </Navbar>
                    </Row>
                </Container>
            </header>
            <Collapse in={open.personOpen || open.practiceOpen || open.lastViewedOpen || open.exportBuilder}>
                <div id="addPractice" >
                    <Container className="p-0">
                        {/* Practice slider */}
                        {open.practiceOpen && <AddPractice  setClosePopUp={setClosePopUp}  practiceInput={props.practiceInput} setPracticeInput={props.setPracticeInput} />}
                        {/* Person slider */}
                        {open.personOpen && <AddPerson setClosePopUp={setClosePopUp} personInput={props.personInput} setPersonInput={props.setPersonInput} />}
                        {/* Last viewed slider */}
                        {open.lastViewedOpen && (<LastViewed setModifyUserState={props.setModifyUserState} setReportBuilderState={props.setReportBuilderState} />)}
                        {/* temporary export Builder*/}
                        {open.exportBuilder && (<ExportBuilder />)}
                    </Container>
                </div>
            </Collapse>
        </>
    );
};

export default Header;
