export const beefCattle = [{ label: 'Management and diseases', value: '1' }]

export const bird = [{ label: 'Avian species', value: '2' }, { label: 'Exotics – avian species', value: '3' }]

export const cats = [{ label: 'Cardiovascular/respiratory disorders', value: '4' }, { label: 'Clinical nutrition', value: '5' }, { label: 'Critical care', value: '6' }, { label: 'Endocrine/metabolic disorders', value: '7' }, { label: 'Gastrointestinal/urogenital disorders', value: '8' }, { label: 'General husbandry', value: '9' }, { label: 'Infectious diseases', value: '10' }, { label: 'Neurological', value: '11' }, { label: 'Ophthalmological disorders', value: '12' }]

export const cattle = [{ label: 'Mastitis/lameness', value: '13' }, { label: 'Nutrition/husbandry', value: '14' }, { label: 'Reproduction', value: '15' }, { label: 'Youngstock and infectious diseases', value: '16' }]

export const dairyCattle = [{ label: 'Health and fertility', value: '17' }, { label: 'Nutrition/production', value: '18' }]

export const dogs = [{ label: 'Cardiovascular/respiratory disorders,', value: '19' }, { label: 'Clinical nutrition', value: '20' }, { label: 'Critical care', value: '21' }, { label: 'Endocrine/metabolic disorders', value: '22' }]

export const dogs2 = [{ label: 'Gastrointestinal/urogenital disorders', value: '23' }, { label: 'Infectious diseases', value: '24' }, { label: 'Neurological/neuromuscular disease', value: '25' }, { label: 'Ophthalmological disorders', value: '26' }]

export const general = [{ label: 'Equine – practice', value: '27' }, { label: 'Large animal – practice', value: '28' }, { label: 'Mixed animal – practice', value: '29' }, { label: 'Production animal (practice)', value: '30' }, { label: 'Referral', value: '31' }, { label: 'Small animal – practice', value: '32' }]

export const horses = [{ label: 'Anaesthesia and analgesia', value: '33' }, { label: 'Cardiovascular/respiratory diseases', value: '34' }, { label: 'Dentistry', value: '35' }, { label: 'Dermatology', value: '36' }, { label: 'Foal and yearling', value: '37' }, { label: 'Gastrointestinal disease', value: '38' }, { label: 'General medicine', value: '39' }, { label: 'Intensive care', value: '40' }, { label: 'Lameness', value: '41' }, { label: 'Neuromuscular/behaviour', value: '42' }, { label: 'Practice', value: '43' }, { label: 'Reproduction', value: '44' }, { label: 'Surgery', value: '45' }, { label: 'Musculoskeletal', value: '131' }]

export const lawInfo = [{ label: 'Animal welfare science, ethics and law', value: '46' }, { label: 'Clinical governance', value: '47' }, { label: 'Public health', value: '48' }]

export const others = [{ label: 'Companion animals', value: '49' }, { label: 'Laboratory animals', value: '50' }, { label: 'Mixed animal', value: '51' }]

export const pig = [{ label: 'Infectious diseases', value: '52' }, { label: 'Non-infectious diseases', value: '53' }, { label: 'Production management', value: '54' }, { label: 'Reproductive management', value: '55' }]

export const poultry = [{ label: 'Disease diagnosis, prevention/treatment', value: '56' }, { label: 'Husbandry/management', value: '57' }, { label: 'Production animal practice', value: '58' }, { label: 'Other', value: '59' }]

export const practiceManagement = [{ label: 'Client care', value: '60' }, { label: 'Customer service', value: '61' }, { label: 'Finance', value: '62' }, { label: 'General practice', value: '63' }, { label: 'Leadership', value: '64' }, { label: 'Practice management', value: '65' }, { label: 'Recruitment', value: '66' }, { label: 'Training & development', value: '67' }, { label: 'Workplace', value: '68' }]

export const rabbits = [{ label: 'Rabbits', value: '69' }]

export const reptiles = [{ label: 'Exotics – reptiles', value: '70' }, { label: 'Exotics medicine', value: '71' }]

export const rodents = [{ label: 'Exotics small mammals – general', value: '72' }, { label: 'Exotics medicine', value: '73' }, { label: 'Exotics small mammals – husbandry and management', value: '74' }]

export const sheep = [{ label: 'Camelids', value: '75' }, { label: 'Diseases', value: '76' }, { label: 'General medicine', value: '77' }, { label: 'Goats', value: '78' }, { label: 'Lamb diseases and parasitic diseases', value: '79' }, { label: 'Practice', value: '80' }, { label: 'Reproduction, pregnancy and parturition', value: '81' }]

export const specialisms = [{ label: 'Anaesthesia and analgesia', value: '82' }, { label: 'Behaviour', value: '83' }, { label: 'Cardiology', value: '84' }, { label: 'Cardiovascular/respiratory', value: '85' }, { label: 'Clinical nutrition', value: '86' }, { label: 'Critical care', value: '87' }, { label: 'Dentistry', value: '88' }, { label: 'Dermatological disorders', value: '89' }, { label: 'Diagnostic', value: '90' }, { label: 'Diagnostic imaging', value: '91' }, { label: 'Diagnostic pathology', value: '92' }, { label: 'Emergency and critical care medicine', value: '93' }, { label: 'Endocrine disorders', value: '94' }, { label: 'Endocrinology', value: '95' }, { label: 'Evidence-based veterinary medicine', value: '96' }, { label: 'Gastroenterology', value: '97' }, { label: 'Gastrointestinal/urogenital disorders', value: '98' }, { label: 'Hydrotherapy', value: '99' }, { label: 'Hygiene', value: '100' }, { label: 'Imaging', value: '101' }, { label: 'Internal medicine', value: '102' }, { label: 'Microbiology', value: '103' }, { label: 'Neurological medicine', value: '104' }, { label: 'Neurology', value: '105' }, { label: 'Neuromuscular disorders', value: '106' }, { label: 'Neurosurgery', value: '107' }, ]

export const specialisms2 = [ { label: 'Nursing', value: '108' }, { label: 'Nutrition', value: '109' }, { label: 'Oncology', value: '110' }, { label: 'Ophthalmologic disorders', value: '111' }, { label: 'Ophthalmology', value: '112' }, { label: 'Orthopaedics – surgery', value: '113' }, { label: 'Parasitology', value: '114' }, { label: 'Pathology', value: '115' }, { label: 'Pharmacology', value: '116' }, { label: 'Physiotherapy', value: '117' }, { label: 'Radiology', value: '118' }, { label: 'Reproduction', value: '119' }, { label: 'Respiratory', value: '120' }, { label: 'Soft tissue', value: '121' }, { label: 'Surgery', value: '122' }, { label: 'Toxicology', value: '123' }, { label: 'Ultrasonography', value: '124' }, { label: 'Pain management', value: '129' }, { label: 'Musculoskeletal – Small animal', value: '130' } ]

export const zooWildlifeFish = [{ label: 'Wildlife', value: '125' }, { label: 'Fish', value: '126' }, { label: 'Zoo animals/medicine', value: '127' }, { label: 'Zoonoses and infectious diseases', value: '128' }]