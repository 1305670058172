import React, { useEffect, useState } from 'react'
import { Button, Form, FormControl, Image, FormLabel } from 'react-bootstrap'
import Select from 'react-select';
import deleteIcon from '../../assets/icons/delete.svg';
import { pmsDropdown } from '../../actions/userActions'
import { swAlert, confirmationAlert } from '../../helpers/alert';
import PerfectScrollbar from "react-perfect-scrollbar";
//react-redux
import { useDispatch, useSelector } from 'react-redux'
import Axios from '../../adapters/xhr';
import Swal from 'sweetalert2';
import { selectStyle } from '../../styling/select-style'

const CustomizeDropDown = (props) => {

    // var [inputField, setInputField] = useState([{ fieldType: "title", option: "set to blank", value: "" }])

    const [pmsDropDown, setPMSDropDown] = useState([])
    const [selectedPMS, setSelectedPMS] = useState([])
    const [fieldError, setFieldError] = useState([])
    const [customizeInput, setCustomizeInput] = useState({ new_value: '', set_value: '' })
    const [newValueBlock, setNewValueBlock] = useState([])
    const [newVAlueValidation, setNewVAlueValidation] = useState(false)
    const addAnotherFieldHandler = () => {
        setNewValueBlock([...newValueBlock, { set_value: '', button: "remove" }])
    }

    //redux
    const dispatch = useDispatch()
    const { pms_dropdown } = useSelector(state => state.pmsDropDownReducer)

    useEffect(() => {
        dispatch(pmsDropdown());
    }, [dispatch])

    useEffect(() => {
        if (pms_dropdown) {
            let pms_select = [];
            pms_dropdown.data && pms_dropdown.data.forEach(el => {
                pms_select.push({ 'label': el.value, 'value': el.id })
            });
            setPMSDropDown(pms_select);
        }
    }, [pms_dropdown])

    // handle customize inputs
    const handleInputsChange = (e) => {
        const { name, value } = e.target
        setCustomizeInput(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleNewBlockInputChange = (e, index) => {
        const { name, value } = e.target
        newValueBlock[index].set_value = value
        setNewValueBlock([...newValueBlock])
    }

    const removeInputField = (ind) => {
        newValueBlock.splice(ind, 1)
        setNewValueBlock([...newValueBlock])
    }

    const handleValidationRemoveBlock = () => {
        let error = []
        newValueBlock.forEach((curr, ind) => {
            if (curr.set_value.length === 0) error = [...error, { ind: ind, error: 'Required' }]
        })

        return error;
    }

    const handleUpdate = async () => {
        setNewVAlueValidation(false);
        console.log(selectedPMS.value, customizeInput);
        if ((selectedPMS.value && selectedPMS.label != '...') && customizeInput.new_value === '') setNewVAlueValidation(true);
        else {
            let error = handleValidationRemoveBlock()
            setFieldError([...error])
            if ((error.length === 0 && newValueBlock.length > 0) && (selectedPMS.value && customizeInput.new_value > 0)) {
                let payload = { id: selectedPMS.value, new_value: customizeInput.new_value }
                let new_values = []
                newValueBlock.forEach((curr) => {
                    new_values.push({ key: 'pms', value: curr.set_value })
                })
                let payload2 = { new_values }
                await Axios.put(`custom/dropdown/pms`, payload);
                await Axios.post(`add/custom/dropdown/values`, payload2)
                swAlert('PMS Updated!', 'success',false, false, 'Ok',1500)
                dispatch(pmsDropdown())
                resetCustomizeDropdown()
            } else if ((selectedPMS.value && customizeInput.new_value) && (newValueBlock.length === 0)) {
                let payload = { id: selectedPMS.value, new_value: customizeInput.new_value }

                let resp = await Axios.put(`custom/dropdown/pms`, payload);

                if (resp.data.status){ 
                    swAlert('PMS Updated!','success',false,false,'Ok',1500)
                }
                dispatch(pmsDropdown())
                resetCustomizeDropdown()
            } else if ((newValueBlock.length > 0 && error.length === 0) && (!selectedPMS.value && !customizeInput.new_value)) {
                let new_values = []
                newValueBlock.forEach((curr) => {
                    new_values.push({ key: 'pms', value: curr.set_value })
                })
                let payload = { new_values }
                let resp = await Axios.post(`add/custom/dropdown/values`, payload)

                if (resp.data.status){
                    swAlert('PMS Updated!','success', false,  false,'Ok',1500)
                }
                dispatch(pmsDropdown())
                resetCustomizeDropdown()
            }
        }
    }

    const handleDelele = async () => {
        let { lebel, value } = selectedPMS;
        
        if (selectedPMS.value && selectedPMS.label !== '...') {
            confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
                if (result.isConfirmed) {
                    await Axios.delete(`delete/custom/dropdown/value/${value}`);
                    swAlert('PMS Deleted!', 'success',false,  false,'Ok',1500)
                    dispatch(pmsDropdown());
                    props.setOpen(true)
                    props.setOptions('customize')

                }
            });
        }
    }

    const resetCustomizeDropdown = () => {
        setSelectedPMS([]);
        setCustomizeInput({ new_value: '', set_value: '' });
    }

    return (
        <>
            <PerfectScrollbar>
                <Form>

                    <h4 className="my-3 px-4">
                        <span>Customize dropdowns</span>
                    </h4>

                    <span className="px-4 revert-changes mb-3 d-block">Practice Management System</span>

                    <div className="create-individual px-4 mb-2">
                        <FormLabel className="form-label">Select Field</FormLabel>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            styles={selectStyle}
                            name="pms_dropdown"
                            options={pmsDropDown}
                            value={selectedPMS}
                            onChange={setSelectedPMS}
                        />

                        <FormLabel className="form-label">New value</FormLabel>
                        <FormControl
                            name="new_value" type="text"
                            className={newVAlueValidation && 'invalid'}
                            value={customizeInput.new_value}
                            onChange={handleInputsChange} />
                    </div>

                    {newValueBlock.map((curr, index) => (

                        <div key={index} className="create-individual px-4">
                            <FormLabel className="form-label">Set value</FormLabel>
                            <FormControl
                                name="set-value"
                                type="text"
                                className={fieldError.find(x => x.ind === index) && 'invalid'}
                                value={newValueBlock[index].set_value}
                                onChange={(e) => { handleNewBlockInputChange(e, index) }}
                            />

                            <button onClick={() => { removeInputField(index) }} type="button" className="btn ps-0 theme-btn-outline-secondary ms-auto my-2 d-flex align-items-center btn btn-primary">
                                <span className="delete-icon me-3 ms-1">
                                    <Image src={deleteIcon} />
                                </span>Remove this block
                            </button>
                        </div>
                    ))
                    }

                    <div className="px-4 mt-2">
                        <button onClick={addAnotherFieldHandler} type="button" className="btn theme-btn-primary my-2 btn btn-primary">
                            <span className="plus-icon">+</span> Add another field
                        </button>
                    </div>

                    <div className="px-4">
                        <button type="button" className="btn w-100 theme-btn-secondary my-2 btn btn-primary" onClick={handleUpdate}>Update</button>
                    </div>

                    <div className="px-4 mb-4">
                        <button type="button" className="btn w-100 theme-btn-secondary btn btn-danger" onClick={handleDelele}>Delete selected value</button>
                    </div>
                </Form>
            </PerfectScrollbar>
        </>
    )
}

export default CustomizeDropDown;
