import React, { useEffect, useState } from 'react'
//react-redux
import { useDispatch, useSelector } from 'react-redux'
import { getPersonById, getPracticeById, lastSearchedResult, lastTenSearchedResult } from '../actions/userActions'
//react-bootstrap
import { Col, Form, ListGroup, Row, Tab, Tabs } from 'react-bootstrap'
//perfect-scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

const LastViewed = ({ setModifyUserState, setReportBuilderState }) => {
    //usestate hooks
    const [key, setKey] = useState('practice')
    const [data, setData] = useState({
        practice: [],
        veterinary: []
    })

    //react-redux
    const dispatch = useDispatch()
    const { lastTenSearchResult } = useSelector(state => state.lastTenSearchedResultReducer)

    useEffect(() => {
        dispatch(lastTenSearchedResult())
    }, [])

    useEffect(() => {
        lastTenSearchResult && setData({ practice: lastTenSearchResult.data.practice, veterinary: lastTenSearchResult.data.veterinary })
    }, [lastTenSearchResult])

    const handleGetPracticeById = (id) => {
        dispatch(getPracticeById(id))

        let prac_list = lastTenSearchResult.data.practice;

        prac_list.filter(function (value, index) {
            if (value.id == id) { delete prac_list[index]; prac_list.splice(0, 0, value) };
            return;
        });

        setData({ practice: prac_list, veterinary: lastTenSearchResult.data.veterinary })
        setReportBuilderState(false)
        setModifyUserState(false)
    }

    const handleGetPersonById = (id) => {
        dispatch(getPersonById(id))

        let vet_list = lastTenSearchResult.data.veterinary;
        vet_list.filter(function (value, index) {
            if (value.id == id) { delete vet_list[index]; vet_list.splice(0, 0, value) };
            return;
        });

        setData({ practice: lastTenSearchResult.data.practice, veterinary: vet_list })
        setReportBuilderState(false)
        setModifyUserState(false)
    }

    return (
        <>
            <PerfectScrollbar>

                {/* <Form>

                    <h4 className="my-3 px-3">
                        <span>Last Viewed</span>
                    </h4>

                    <Tabs className="my-3 px-3"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >

                        <Tab eventKey="practice" title="PRACTICES" className="last-viewed">
                            <span className="px-3 last-10-viewed mb-3 d-block">Last 10 viewed</span>
                            {data.practice.length > 0 && data.practice.map((curr, ind) => (
                                <div className="last-view" onClick={() => handleGetPracticeById(curr.id)}>
                                    <p>{curr.name}</p>
                                </div>
                            ))}
                        </Tab>

                        <Tab eventKey="people" title="PEOPLE" className="last-viewed">
                            <span className="px-3 last-10-viewed mb-3 d-block">Last 10 viewed</span>
                            {data.veterinary.length > 0 && data.veterinary.map((curr, ind) => (
                                <div className="last-view" onClick={() => handleGetPersonById(curr.id)}>
                                    <p>{curr.forename} {curr.surname}</p>
                                </div>
                            ))}
                        </Tab>

                    </Tabs>
                </Form> */}

                <Form>

                    <h4 className="my-3 px-3">
                        <span>Last Viewed</span>
                    </h4>

                    <Row className="gx-0">
                        <Col lg={12}>
                            <ListGroup className="last-viewed">

                                <span className="list-group-header px-3">Practices</span>

                                <ListGroup.Item className="LastViewed_ListGroupItem">
                                    <span className="last-10-viewed mb-2 d-block">Last 10 viewed</span>
                                    {data.practice.length > 0 && data.practice.map((curr, ind) => (
                                        <div className="last-view" key={curr.id} onClick={() => handleGetPracticeById(curr.id)}>
                                            <p>{curr.name}</p>
                                        </div>
                                    ))}
                                </ListGroup.Item>

                            </ListGroup>
                        </Col>

                        <Col lg={12}>
                            <ListGroup className="last-viewed">
                                <span className="list-group-header px-3">People</span>

                                <ListGroup.Item className="LastViewed_ListGroupItem">

                                    <span className="last-10-viewed mb-2 d-block">Last 10 viewed</span>

                                    {data.veterinary.length > 0 && data.veterinary.map((curr, ind) => (
                                        <div className="last-view" key={curr.id} onClick={() => handleGetPersonById(curr.id)}>
                                            <p>{curr.forename} {curr.surname}</p>
                                        </div>
                                    ))}
                                </ListGroup.Item>

                            </ListGroup>
                        </Col>
                    </Row>
                </Form>

            </PerfectScrollbar>
        </>
    )
}

export default LastViewed
