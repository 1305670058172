let practiceModel = {
    abc_answer: '',
    abc_name: '',
    address_1: '',
    address_2: '',
    address_3: '',
    branch_data: [],
    brick_data: '',
    buying: '',
    compcare: '',
    corporate: '',
    country: '',
    county: '',
    //created_at: '',
    cvs: '',
    direct_mail: '',
    email: '',
    fax: '',
    id: '', //Integer
    ivc: '',
    lat: '', //Integer
    linnaeus: '',
    lng: '', //Integer
    main: '', //Integer
    medivet: '',
    name: '',
    no_staff_pra: '',
    ock_req_email: '',
    ock_req_name: '',
    ock_request_date: '',
    office: '',
    oralcare: '',
    oralcare_restock_1: '',
    oralcare_restock_2: '',
    oralcare_restock_3: '',
    ock_req_name_2016: null,
    ock_req_email_2016: null,
    ock_req_name_2017: null,
    ock_req_email_2017: null,
    ock_req_name_2018: null,
    ock_req_email_2018: null,
    ock_req_name_2019: null,
    ock_req_email_2019: null,
    ock_req_name_2020: null,
    ock_req_email_2020: null,
    ock_req_name_2021: null,
    ock_req_email_2021: null,
    ock_req_name_2022: null,
    ock_req_email_2022: null,
    ock_req_name_2023: null,
    ock_req_email_2023: null,
    pethome: '',
    postcode: '',
    pramansys: '',
    rcvs_acc: '',
    referral: '',
    request_date: '',
    request_type: '',
    send_third: '',
    send_vbd: '',
    sendequine: '',
    sendlivestock: '',
    sendvbj: '',
    sendvnt: '',
    sendvt: '',
    mwi_group: '',
    tel: '',
    town: '',
    training: '',
    type: '',
    updated_at: '',
    updated_by: '',
    updated_date: '',
    v4p: '',
    vetcel: '',
    vet_data: [],
    vetdynamics: '',
    vetpartners: '',
    vetshare: '',
    vetsnow: '',
    vetsure: '',
    website: '',
    wholesaler: '',
    xlvets: '',
    vs: '',
    vn: '',
    branch_vs: '',
    branch_vn: '',
    ooh: ''
}

export default practiceModel
