import { React, useEffect, useState } from 'react';
//react-bootstrap
import { Collapse, Image, Container, Row, Col, Form } from 'react-bootstrap';
//formik
import { ErrorMessage, Field, Formik, useFormik } from 'formik';
//helper functions
import { authentication, codeValidation } from '../helpers/validation';
//react-redux
import { useDispatch, useSelector } from 'react-redux'
//actions
import { login, sendVerificationCode, me, codeVerified } from '../actions/userActions'

import { getAuthToken } from '../adapters/authentication'

//components
import ButtonUI from '../components/ButtonUI';
import FormUI from '../components/FormUI';

//vetfile logo
import vetfileLogo from '../assets/Vetfile-logo.svg';
import ToastUI from '../components/ToastUI';
import MaskedInput from 'react-text-mask';
//axios
import Axios from "../adapters/xhr";
import { swAlert } from '../helpers/alert';

const AuthScreen = ({ location, history }) => {
  const [open, setOpen] = useState(false)
  const [openTwoFactor, setOpenTwoFactor] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState()
  const [twoFactorError, setTwoFactorError] = useState(false)
  const [trustedDevice, setTrustedDevice] = useState(false)
  const { userMe } = useSelector(state => state.userMeReducer)

  //redux
  const dispatch = useDispatch()
  const userLogin = useSelector(state => state.userLogin)
  const userCode = useSelector(state => state.sendVerificationCodeReducer)

  const submitHandler = (email, password) => {
    dispatch(login(email, password))
  }

  const { error, user } = userLogin
  const redirect = location.state ? location.state.from.pathname : '/dashboard'

  // lifecycle hooks
  useEffect(() => {
    if (user) {
      if (user.two_factor) {
        dispatch(sendVerificationCode())
        setOpen(false);
        setOpenTwoFactor(true);
      } else {
        history.push(redirect)
      }
    }
  }, [history, user, redirect])

  /* useEffect(() => {
    if (userMe) localStorage.setItem('user_role', userMe.role)
  }, [userMe]) */

  const handleSetCode = (e) => {
    const { value } = e.target
    setCode(value)
  }

  const handleVerifyCode = async () => {
    setTwoFactorError(false)

    if(code) {
      let payload = {
        code: parseInt(code.replace(/ /g, '')),
        trusted_device: trustedDevice
      };
      
      let resp = await Axios.post(`/verify/twofactor/code`, payload, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
  
      if (resp.data.status) {      
        dispatch(codeVerified())
        history.push(redirect)
      } else {
        setTwoFactorError(true)
      }
    }
    
  }

  const handleTrustedDevice = () => {
    setTrustedDevice(!trustedDevice)
  }

  return (
    <>
      {open === true ? (<div className="overlay" onClick={() => { open == true && setOpen(false); }}></div>) : ''}

      <div
        className="login-screen"
        onClick={() => {
          open == true && setOpen(false);
        }}
      >
        <Container fluid>
          <Row className="justify-content-md-center align-items-center py-5 align-content-between">
            <Col xs lg={11}>
              <div className="vetfile-logo">
                <Image src={vetfileLogo} />
              </div>
            </Col>
            <Col xs lg={11}>
              <div className="">
                <h1>Welcome to Vetfile</h1>
                <h2 className="mt-3 mb-5">The home of all veterinary professionals...</h2>

                <ButtonUI
                  btnText="Start session"
                  onClick={() => setOpen(!open)}
                />
              </div>
            </Col>

            <Col xs lg={11}>
              <div className="copyright">
                <p className="text-white">© Vetfile xxxt (Build 4.01)</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Collapse in={open}>
          <div id="hiddenForm">
            {error &&
              <div
                style={{
                  position: 'absolute',
                  top: 3,
                  right: 0,
                  left: 0,
                }}
              >
                <ToastUI header="Vetfile" body="Incorrect password/email" />
              </div>
            }
            <div className="login-form h-100 align-items-center d-flex">
              <FormUI
                initialValues={{ email, password }}
                validationSchema={authentication}
                submitBtnText="Begin to work"
                onSubmit={(values) => submitHandler(values.email, values.password)}
              >
                <div className="mb-2">
                  <Field
                    id="email"
                    name="email"
                    placeholder="Type your email address"
                    type="text"
                    className="form-control"
                  />
                  <span className="error-text">
                    <ErrorMessage name="email" />
                  </span>
                </div>

                <div className="mb-2">
                  <Field
                    id="password"
                    name="password"
                    placeholder="Password"
                    type="password"
                    className="form-control"
                  />
                  <span className="error-text">
                    <ErrorMessage name="password" />
                  </span>
                </div>
              </FormUI>
            </div>
          </div>
        </Collapse>
      </div>

      <div>
        <Collapse in={openTwoFactor}>
          <div id="hiddenForm">

            {twoFactorError &&
              <div
                style={{
                  position: 'absolute',
                  top: 3,
                  right: 0,
                  left: 0,
                }}
              >
                <ToastUI header="Vetfile" body="Mismatch two-factor code" />
              </div>
            }

            <div className="login-form h-100 align-items-center d-flex">

              <FormUI
                initialValues={{ code }}
                // validationSchema={codeValidation}
                submitBtnText="Verify"
                onSubmit={(values) => handleVerifyCode()}
              >

                <div className="my-4 text-center text-uppercase d-flex">
                  <h5 className="TwoFactorAuthHeading">
                    <i className="fa fa-lock"></i>
                    Two-factor authentication
                  </h5>
                </div>

                <div className="mb-2">
                  <MaskedInput
                    mask={[/[0-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
                    id="code"
                    name="code"
                    placeholder="Enter a verification code"
                    guide={false}
                    value={code}
                    onChange={handleSetCode}
                    className="form-control"
                  />
                  <div className="d-flex justify-content-between">
                    <Form.Group controlId="trusted_device">
                      <Form.Check
                        name="trusted_device"
                        type="checkbox"
                        label="Trusted device"
                        checked={trustedDevice}
                        value={trustedDevice}
                        onChange={handleTrustedDevice}
                      />
                    </Form.Group>
                    {/* <a className="text-decoration-underline text-right theme-blue-text" href="" onClick={handleVerifyCode}>Proceed</a> */}

                  </div>
                  <span className="error-text">
                    <ErrorMessage name="code" />
                  </span>
                </div>

              </FormUI>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default AuthScreen;
