import React, { useEffect, useState } from 'react'
import { Form, Button, ListGroup, ListGroupItem, Row, Col, Container } from 'react-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { vet_export_builder, practice_export_builder } from "../constants/advanceQueryBuilder";

const ReportBuildeFields = ({ reportSave, dataFieldSelected, setAnotherFields }) => {

  const [showFields, setShowFields] = useState(true)
  const [selectedFields, setSelectedFields] = useState([...dataFieldSelected])
  const [selectedVets, setSelectedVets] = useState([])
  const [selectedPrac, setSelectedPrac] = useState([])
  const [selectForPrac, setSelectForPrac] = useState(true)
  const [selectForVet, setSelectForVet] = useState(true)
  const [selectAll, setSelectAll] = useState(true)
  const [vetsChecked, setVetsChecked] = useState(false)
  const [pracChecked, setPracChecked] = useState(false)
  
  useEffect(() => {
    setSelectedFields([...dataFieldSelected])
  }, [dataFieldSelected])

  const handleSelectedFieldPrac = (prac, index) => {
    for (let i = 0; i < selectedFields.length; i++) {
      if (JSON.stringify(selectedFields[i]) === JSON.stringify(prac)) {
        selectedFields.splice(i, 1)
        setSelectedFields([...selectedFields])
        return
      }
    }
    setSelectedFields([...selectedFields, prac])
  }

  const handleSelectedFieldVet = (vet, index) => {
    for (let i = 0; i < selectedFields.length; i++) {
      if (JSON.stringify(selectedFields[i]) === JSON.stringify(vet)) {
        selectedFields.splice(i, 1)
        setSelectedFields([...selectedFields])
        return
      }
    }
    setSelectedFields([...selectedFields, vet])

  }

  const handleSelectVet = () => {
    if (selectForVet) {
      for (let i = 0; i < vet_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(vet_export_builder[i]) === JSON.stringify(x)) {
            selectedFields.splice(ind, 1)
          }
        })
      }
      setSelectedFields((prev) => ([...prev, ...vet_export_builder]))
      setSelectForVet(false)
    }
    else {

      for (let i = 0; i < vet_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(vet_export_builder[i]) === JSON.stringify(x)) {
            selectedFields.splice(ind, 1)
          }
        })
      }
      setSelectedFields([...selectedFields])
      setSelectForVet(true)
    }

  }

  const handleSelectPractice = () => {
    if (selectForPrac) {
      for (let i = 0; i < practice_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(practice_export_builder[i]) === JSON.stringify(x)) {
            selectedFields.splice(ind, 1)
          }
        })
      }
      setSelectedFields((prev) => ([...prev, ...practice_export_builder]))
      setSelectForPrac(false)

    }
    else {
      for (let i = 0; i < vet_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(practice_export_builder[i]) === JSON.stringify(x)) {
            selectedFields.splice(ind, 1)
          }
        })
      }
      setSelectedFields([...selectedFields])
      setSelectForPrac(true)
    }
  }

  const handleAddToReport = () => {
    reportSave({ type: 'ADD DATA FIELDS', dataFields: selectedFields })
    setAnotherFields(false)
  }

  return (
    <>
      <PerfectScrollbar>
        <h4 className="my-3 px-4">
          <span>Add data fields to report</span>
        </h4>

        <span className="px-4 mb-3 d-block sidebar-text-sm">
          By clicking the export button your export will begin with all the fields you have selected from this window.
                </span>

        {/* <div className="h5 mt-4 mb-3 px-4">
          <Form.Check
            name="show_fields"
            type="checkbox"
            label="Show all available fields?"
            value={showFields}
            className="form-label"
            onClick={() => setShowFields(!showFields)}
          />
        </div> */}

        {showFields &&
          <>
            <Container>
              <Row>

                <Col className="denim-bg">
                  <Form.Check
                    type="checkbox"
                    onClick={handleSelectPractice}
                    id="autoSizingCheckPrac"
                    className="mb-1 mt-1 text-white"
                    label="Practice"
                    name="Practice"
                    checked={vetsChecked || !selectForPrac}
                  />
                </Col>

                <Col className="denim-bg">

                  <Form.Check
                    type="checkbox"
                    onClick={handleSelectVet}
                    id="autoSizingCheckVet"
                    className="mb-1 mt-1 text-white"
                    label="Vets"
                    name="Vets"
                    checked={pracChecked || !selectForVet}

                  />
                </Col>

              </Row>

              <Row className="export-builder">
              <Col className="p-0">
              {practice_export_builder.map((prac,index)=>{
                 if(prac.value === 'separate-heading'){
                  return  <ListGroup.Item> <h6> {prac.label}</h6> </ListGroup.Item>
                }else{
                  return (<ListGroup.Item as= 'div' className="" key={index}  active={selectedFields.find((i)=>{ return JSON.stringify(i) === JSON.stringify(prac)  })}  onClick={()=>{handleSelectedFieldPrac(prac,index)}}>{prac.label}</ListGroup.Item>)
                }
              })}
              </Col>
              <Col className="p-0">
              {vet_export_builder.map((vet, index)=>{
                if(vet.value === 'separate-heading'){ 
                return  <ListGroup.Item> <h6>{vet.label}</h6> </ListGroup.Item>
                }else{
                  return (<ListGroup.Item as='div' className="" key={index} active={selectedFields.find((i)=>{ return JSON.stringify(i) === JSON.stringify(vet)})} onClick={()=>{handleSelectedFieldVet(vet,index)}} >{vet.label}</ListGroup.Item>)
                }
              })}

              </Col>
          </Row>
            </Container>

            <div className="px-4">
              <Button type="submit" onClick={handleAddToReport} className="btn theme-btn-primary w-100 my-4">
                Add to report
                            </Button>
            </div>

          </>
        }
      </PerfectScrollbar>
    </>
  );
};

export default ReportBuildeFields;
