import React, { useState } from 'react'
import Axios from '../adapters/xhr';
import { Form, FormControl, FormLabel } from 'react-bootstrap'
import PerfectScrollbar from "react-perfect-scrollbar";
import {confirmationAlert, swAlert} from "../helpers/alert";
import {updatePersonByID} from "../actions/userActions";
import axios from "axios";
import ErrorMessage from "./ErrorMessage";

const ChangePassword = (props) => {

    const [fieldError, setFieldError] = useState(false)
    const [passError, setPassError] = useState(false)
    const [passErrorText, setPassErrorText] = useState("")
    const [passwordBlock, setPasswordBlock] = useState(false)
    const [emailBlock, setEmailBlock] = useState(false)
    const [emailOrPasswordBlock, setEmailOrPasswordBlock] = useState(false)
    const [personDetail, setPersonDetail] = useState({ email: "", newEmail: "", password: "", password_confirmation: "",forename:"",userID:""})

    const handlePersonGet = () => {
        setEmailBlock(false)
        setPasswordBlock(false)
        setEmailOrPasswordBlock(false);
        const getUserData = async () => {
             let payload = { 'email':personDetail.email }
             let response = await Axios.post(`getUser/from/sso`, payload)
            if(!response.data.userFound){
                setEmailOrPasswordBlock(false);
                setFieldError(true);
            }else{
                setEmailOrPasswordBlock(true);
                setFieldError(false);
                setPersonDetail(prevState => ({ ...prevState, forename: response.data.name }))
                setPersonDetail(prevState => ({ ...prevState, userID: response.data.id }))
            }
        }
        getUserData()
    }

    const handlePersonUpdate = () => {
        if(personDetail.password.trim() === '' ){
            setPassError(true);
            setPassErrorText("Please fill password fields")
            return false
        }
        if(personDetail.password_confirmation.trim() === ''){
            setPassError(true);
            setPassErrorText("Please fill password fields")
            return false
        }
        if(personDetail.password.trim() !== ''
            && personDetail.password_confirmation.trim() !== ''
            && personDetail.password.trim() === personDetail.password_confirmation.trim()
        ){
            setPassError(false);
            setPassErrorText("")
            const UpdateUserData = async () => {
                let payload = { 'userID':personDetail.userID,'password':personDetail.password }
                let response = await Axios.post(`getUser/from/sso`, payload)
                if(response.data.userUpdated){
                    swAlert('User password updated.', 'success');
                }else{
                    swAlert("User password doesn't updated.", "success");
                }
            }
            UpdateUserData();
        }else{
            setPassError(true);
            setPassErrorText("Password doesn't match")
            return false
        }
    }

    const handleEmailUpdate = () => {
        const emailRegex=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(emailRegex.test(personDetail.newEmail) === false){
            swAlert('Please type a valid email address', 'error')
            return false;
        }
        const UpdateUserEmail = async () => {
            let payload = { 'userID':personDetail.userID,'newEmail':personDetail.newEmail,'email':personDetail.email }
            let response = await Axios.post(`getUser/from/sso`, payload)
            if(response.data.userEmailUpdated){
                swAlert('User Email updated.', 'success');
            }else{
                swAlert("User email already exist.", "error");
            }
        }
        UpdateUserEmail();
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        if (name === "email") {
            setPersonDetail(prevState => ({ ...prevState, email: value }))
        }
        if (name === "password") {
            setPersonDetail(prevState => ({ ...prevState, password: value }))
        }
        if (name === "password_confirmation") {
            setPersonDetail(prevState => ({ ...prevState, password_confirmation: value }))
        }
    }

    const handleOnChangeEmail = (e) => {
        const { name, value } = e.target
        if (name === "newEmail") {
            setPersonDetail(prevState => ({ ...prevState, newEmail: value }))
        }
    }


    return (
        <>
            <PerfectScrollbar>
                <Form>

                    <h4 className="my-3 px-4">
                        <span>Change Password or Email</span>
                    </h4>

                    <div className="create-individual px-4 mb-2">

                        <FormLabel className="form-label">Enter an email</FormLabel>
                        <FormControl
                            name="email"
                            type="email"
                            onChange={handleOnChange}
                            className={fieldError && 'invalid'}
                            />
                        {fieldError && <ErrorMessage error={"Email not found on SSO"} />}

                        {emailOrPasswordBlock ?
                            <div>
                                <FormLabel className="form-label">Forename</FormLabel>
                                <FormControl
                                    name="forename"
                                    type="text"
                                    value={personDetail.forename}
                                    disabled={true}
                                    // className={fieldError && 'invalid'}
                                />
                            </div> : null
                        }

                    </div>
                    <div className="px-4 mt-2">
                        <button onClick={handlePersonGet} type="button" className="btn theme-btn-primary my-2 btn btn-primary">
                            Search
                        </button>
                    </div>

                    {emailOrPasswordBlock ?
                        <div className="px-4 mt-2">
                            <button onClick={() => { setEmailBlock(true); setPasswordBlock(false)  }} type="button" className="btn theme-btn-primary my-2 btn btn-primary">
                                Change email
                            </button>
                            <button onClick={() => { setPasswordBlock(true); setEmailBlock(false) }} type="button" className="btn theme-btn-primary my-2 btn btn-primary">
                                Change password
                            </button>
                        </div> : null
                    }

                    {passwordBlock ?
                    <div className="create-individual px-4">
                        <FormLabel className="form-label">New Password</FormLabel>
                        <FormControl
                            name="password"
                            type="text"
                            onChange={handleOnChange}
                            className={passError && 'invalid'}
                        />
                        <FormLabel className="form-label">Confirm Password</FormLabel>
                        <FormControl
                            name="password_confirmation"
                            type="text"
                            onChange={handleOnChange}
                            className={passError && 'invalid'}

                        />
                        {passError && <ErrorMessage error={passErrorText} />}
                        <div className="px-4">
                            <button onClick={handlePersonUpdate} type="button" className="btn w-100 theme-btn-secondary my-2 btn btn-primary">Change Password</button>
                        </div>
                        </div> : null
                    }

                    {emailBlock ?
                        <div className="create-individual px-4">
                            <FormLabel className="form-label">New Email</FormLabel>
                            <FormControl
                                name="newEmail"
                                type="text"
                                onChange={handleOnChangeEmail}
                            />
                            <div className="px-4">
                                <button onClick={handleEmailUpdate} type="button" className="btn w-100 theme-btn-secondary my-2 btn btn-primary">
                                    Change Email
                                </button>
                            </div>
                        </div> : null
                    }

                </Form>
            </PerfectScrollbar>
        </>
    )
}

export default ChangePassword;
