//yup
import * as Yup from 'yup'
import { practice } from '../constants/advanceQueryBuilder'

//authentication validation
export const authentication = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email')
        .required("Required"),
    password: Yup.string().required('Required'),
})

//authentication validation
export const codeValidation = Yup.object().shape({
    code: Yup.string().required('Required')
})

//Person active branch validation
export const person_active_branch = (data) => {
    let error = {}

    if (data.title.length <= 0) {
        error.title = "Required"
    }
    if (data.forename.length <= 0) {
        error.forename = "Required"
    }
    if (data.surname.length <= 0) {
        error.surname = "Required"
    }
    if (data.gender.length <= 0) {
        error.gender = "Required"
    }
    if (data.v_job_types.length <= 0) {
        error.v_job_types = "Required"
    }
    if (data.v_job_types.split(",").indexOf("C") != -1 &&
        data.v_species.length <= 0
        ) {
        error.v_species = "Required for Veterinary Surgeon"
    }
    if (data.v_job_types.split("").find(curr => curr==="C" || curr==="E" || curr==="D") &&
        (data.u_suffix.length <= 0 ||
         data.u_year.length <= 0
        //  data.v_rcvsnum.length <= 0
         )
        ) {
        error.qualification = "Required for Veterinary Surgeon or Nurse or Head Nurse"
    }

    return error
}

//Person locum only validation
export const person_locum_branch = (data) => {
    let error = {}

    if (data.title.length <= 0) {
        error.title = "Required"
    }
    if (data.forename.length <= 0) {
        error.forename = "Required"
    }
    if (data.surname.length <= 0) {
        error.surname = "Required"
    }
    if (data.gender.length <= 0) {
        error.gender = "Required"
    }
    if (((data.v_job_types.length <= 0) && (data.v_job_types_o.length <= 0))) {
        error.v_job_types_o = "Required"
    }
    if (data.v_job_types.indexOf("X") != -1 && data.v_species.length <= 0) {
        error.v_species = "Required"
    }
    if (data.v_job_types.length > 0 &&
        (data.u_suffix.length <= 0 ||
         data.u_year.length <= 0
        )
       ) {
        error.qualification = "Required for Locum Veterinary Surgeon or Locum Nurse."
    }

    return error
}

//Person retired only validation
export const person_retired_branch = (data) => {
    let error = {}

    if (data.title.length <= 0) {
        error.title = "Required"
    }
    if (data.forename.length <= 0) {
        error.forename = "Required"
    }
    if (data.surname.length <= 0) {
        error.surname = "Required"
    }
    if (data.gender.length <= 0) {
        error.gender = "Required"
    }
    if (data.v_job_types.length <= 0 && data.v_job_types_o.length <= 0) {
        error.v_job_types = "Required"
    }

    return error
}

//Person industry only validation
export const person_industry_branch = (data) => {
    let error = {}

    if (data.title.length <= 0) {
        error.title = "Required"
    }
    if (data.forename.length <= 0) {
        error.forename = "Required"
    }
    if (data.surname.length <= 0) {
        error.surname = "Required"
    }
    if (data.gender.length <= 0) {
        error.gender = "Required"
    }
    if (data.v_job_types_o <= 0)  {
        error.v_job_types_o = "Required"
    }
    // if (data.v_job_types.length <= 0 && data.other.length <= 0) {
    //     error.v_job_types = "Required"
    // }

    return error
}

//Practice main/branch validation
export const practice_active_branch = (data) => {
    let error = {}

    if (data.office.length <= 0) {
        error.office = "Required"
    }
    if (data.name.length <= 0) {
        error.name = "Required"
    }
    if (data.address_1.length <= 0) {
        error.address_1 = "Required"
    }
    if (data.town.length <= 0) {
        error.town = "Required"
    }
    if (data.county.length <= 0) {
        error.county = "Required"
    }
    if (data.country.length <= 0) {
        error.country = "Required"
    }
    if (data.postcode.length <= 0) {
        error.postcode = "Required"
    }
    // if (data.tel.length <= 0) {
    //     error.tel = "Required"
    // }

    return error
}

//Practice industry
export const practice_industry_branch = (data) => {
    let error = {}

    if (data.office.length <= 0) {
        error.office = "Required"
    }
    if (data.address_1.length <= 0) {
        error.address_1 = "Required"
    }
    if (data.town.length <= 0) {
        error.town = "Required"
    }
    if (data.country.length <= 0) {
        error.country = "Required"
    }

    return error
}

//type vetfile
export const practice_type_vetfile = (data) => {
    let error = {}

    if (data.name.length <= 0) {
        error.name = "Required"
    }
    if (data.office.length <= 0) {
        error.office = "Required"
    }

    return error
}

export const suffix_update_save =(personInput)=>{
    let  error={}
    if((personInput.practice_id !== 4 && personInput.practice_id !== 9000)) {
        if (personInput.v_job_types.split("").find(curr =>
            curr === "C" ||
            curr === "X" ||
            curr === "E" ||
            curr === "D" ||
            curr === "Y"
        ) && personInput.u_suffix.length <= 0 && personInput.practice_data.office.split(' ')[0] !== 'Industry') {
            error.Suffixname = "Required"
        }
    }
    return error

}

//Validation for save without audit
export const save_without_audit = (personInput) => {
    let error = [];

    if(personInput.practice_id ===1 && personInput.v_pma==='Work'){
        error.text = 'This person is Locum or Retired. Please change PMA to Home.'
    } else if (personInput.practice_data.office.split(' ')[0] !== 'Industry') {
        if (personInput.v_job_types.length === 0 && personInput.v_job_types_o.length === 0) {
            error.text = 'At least one job type is required.'
        } else if (
            (personInput.practice_id !== 3 && personInput.practice_id !== 4 && personInput.practice_id !== 9000 && personInput.practice_id !== 10000) &&
            ((personInput.v_job_types.includes('C') && (personInput.v_species.length===0 && personInput.v_species_o.length===0))
                    ||(personInput.v_job_types.includes('X') && (personInput.v_species.length===0 && personInput.v_species_o.length===0)))) {
            error.text = 'C or X job types requires at least one species.'
        } else if (personInput.practice_id !== 3 && personInput.v_pma === "Home" && (personInput.v_address_1.length === 0 || personInput.v_town.length ===0 || personInput.v_county.length ===0 || personInput.v_postcode.length === 0 ) ) {
            error.text = 'PMA is set to Home and home address is blank.'
            error.address='Required'
            error.town='Required'
            error.county='Required'
            error.postcode='Required'
        }
    } else if (personInput.practice_id !== 3 && personInput.v_pma === "Home" && (personInput.v_address_1.length === 0  ) ) {
        error.text = 'PMA is set to Home and home address is blank.'
        error.address='Required'
        //error.town='Required'
       // error.county='Required'
       // error.postcode='Required'
    }
    if(personInput.practice_data.office.split(' ')[0] === 'Industry' && personInput.v_job_types_o.length === 0){
        error.text = 'Other Job Type is required.'
    }
    if((personInput.practice_data.office.split(' ')[0] !== 'Industry' && personInput.v_pma === 'Work' && personInput.v_address_1 !== '') && personInput.v_postcode === ''){
        error.text = 'Postcode is required'
        error.postcode='Required'
    }

    return error;
}

//Validation for save with audit
export const save_with_audit = (personInput) => {
    let error = {};

    if (personInput.practice_id ===1 && personInput.v_pma==='Work') {
        error.text = "This person is Locum or Retired. Please change PMA to Home."
    } else if (personInput.practice_data.office.split(' ')[0] !== 'Industry') {
        if (personInput.v_job_types.length === 0 && personInput.v_job_types_o.length === 0) {
            error.text = 'At least one job type is required.'
        } else if (
            (personInput.practice_id !== 3 && personInput.practice_id !== 4 && personInput.practice_id !== 9000 && personInput.practice_id !== 10000) &&
            ((personInput.v_job_types.includes('C') && (personInput.v_species.length===0 && personInput.v_species_o.length===0))
                ||(personInput.v_job_types.includes('X') && (personInput.v_species.length===0 && personInput.v_species_o.length===0)))) {
            error.text = 'C or X job types requires at least one species.'
        } else if (personInput.practice_id !== 3 && personInput.v_pma === "Home" && (personInput.v_address_1.length === 0 || personInput.v_town.length ===0 || personInput.v_county.length ===0 || personInput.v_postcode.length === 0 ) ) {
            error.text = "PMA is set to Home and home address is blank.";
            error.address='Required'
            error.town='Required'
            error.county='Required'
            error.postcode='Required'
        }
    } else if (personInput.practice_id !== 3 && personInput.v_pma === "Home" && (personInput.v_address_1.length === 0  ) ) {
        error.text = 'PMA is set to Home and home address is blank.'
        error.address='Required'
        //error.town='Required'
        // error.county='Required'
        // error.postcode='Required'
    }
    if(personInput.practice_data.office.split(' ')[0] === 'Industry' && personInput.v_job_types_o.length === 0){
        error.text = 'Other Job Type is required.'
    }
    if((personInput.practice_data.office.split(' ')[0] !== 'Industry' && personInput.v_pma === 'Work' && personInput.v_address_1 !== '') && personInput.v_postcode === ''){
        error.text='Postcode is required.'
        error.postcode='Required'
    }
    return error;
}

export const moveToLocumValidation = (personInput) =>{
    let error={}
    if(personInput.v_address_1.length === 0){
        error.address='Required'
    } if(personInput.v_town.length === 0){
        error.town = 'Required'
    } if(personInput.v_county.length ===0){
        error.county = 'Required'
    } if(personInput.v_postcode.length ===0){
        error.postcode = 'Required'
    }

    return error;
}

export const practiceBeforeSaveUpdateValidation =(practiceInput)=>{
    let error={}
    if(practiceInput.name.length ===0){
        error.pname='Required'
    }
    if(practiceInput.address_1.length === 0){
        error.paddress1 = 'Required'
    }
    if(practiceInput.county.length === 0){
        error.pcounty = 'Required'
    }
    if(practiceInput.town.length === 0){
        error.ptown = 'Required'
    }
    if(practiceInput.postcode.length === 0){
        error.ppostcode= 'Required'
    }
    // if(practiceInput.tel.length === 0){
    //     error.ptel = 'Required'
    // }
    return error
}

