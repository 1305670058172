import React, {useEffect,useState} from 'react'
//react-bootstrap
import { Toast } from 'react-bootstrap'

const ToastUI = ({ header, body }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (body) {
            setShow(true);
        }
      }, [])

    return (
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Header>
                <strong className="mr-auto">{ header }</strong>
            </Toast.Header>
            <Toast.Body>{ body }</Toast.Body>
        </Toast>
    )
}

export default ToastUI
