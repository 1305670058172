import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { Button } from "react-bootstrap";
import { confirmationAlert, swAlert } from '../helpers/alert';
import Axios from '../adapters/xhr';

const MassMove = (props) => {

    useEffect(() => {
    }, [])

    const handleClick = (action) => {

        confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
            if (result.isConfirmed) {

                let payload = { move_to: action }

                await Axios.post(`/vet/mass/move/practice`, payload, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if (res.data.status) {
                        swAlert(`${res.data.message}`, 'success', true)
                    }
                }).catch((err) => {
                    console.log(err.response.data.message);
                    swAlert(`${err.message}`, true)
                })

            }
        })
    }

    return (
        <>
            <Form>

                <h4 className="my-3 px-4">
                    <span>Mass move</span>
                </h4>

                <span className="px-4 last-10-viewed mb-3 d-block">Please select a location where all people will be moved</span>

                <div className="list-view-export ">
                    <p className="d-flex justify-content-between my-2 me-3 align-items-center">Retired
                        <span>
                            <Button className="btn-sm theme-btn-primary " onClick={() => handleClick('retired')}>
                                Move to retired
                            </Button>
                        </span>
                    </p>

                    <p className="d-flex justify-content-between my-2 me-3 align-items-center">Pigeon Hole
                        <span>
                            <Button className="btn-sm theme-btn-primary" onClick={() => handleClick('pigeon')}>
                                Move to pigeon
                            </Button>
                        </span>
                    </p>
                </div>

            </Form>
        </>
    )
}

export default MassMove;
