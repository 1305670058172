import React, { useState, useEffect } from "react";
import deleteIcon from "../../assets/icons/delete.svg";
import { Row, Col, Form, Image, Button, Modal } from "react-bootstrap";
import Select from "react-select";
// Importing Constants from constants/adq for react-select 
import {vet,practice,entity,operators, title_suggestion, jobtype_suggestion,species_suggestion} from '../../constants/advanceQueryBuilder'
import {selectStyle} from '../../styling/select-style'

const SearchDimensionOR = ({ curr,dispatch,index,type,ind}) => {
    // for disoplaying field of multiselect
    const [showMultiSelect,setShowMultiSelect] = useState(true)
    const [multiselectValue,setMultiselectValue] = useState([])

    const [modalShow,setModalShow] =useState(false)
    const [date1,setDate1] = useState()
    const [date2,setDate2] = useState()

    //useEffect for changing the input and reseting the value 
    useEffect(()=>{
        if(curr.where === 'title' || curr.where ==='v_job_types' || curr.where === 'v_species'){
            setShowMultiSelect(true)
        }else{
            setShowMultiSelect(false)
           
        }
    },[curr.where])

    useEffect(()=>{
        if(curr.param === 'BETWEEN' || curr.param === 'NOT BETWEEN'){
            setModalShow(true)
        }
    },[curr.param])

    // useEffect(()=>{
    //     if(true){
    //     let  temp=[]
    //     multiselectValue.forEach((x)=>{
    //         temp.push(x.value)
    //     })

    //     dispatch({type:'VALUE_FIELD_OR',val:temp.join(), index:index ,ind:ind,val2:multiselectValue})}
    // },[multiselectValue])

    const handleMultiSelectValue=(e)=>{
        setMultiselectValue(e) 
        let  temp=[]
        e.forEach((x)=>{
            temp.push(x.value)
        })

        dispatch({type:'VALUE_FIELD_OR',val:temp.join(), index:index ,ind:ind,val2:e})
    }
    
    const handleWhere=()=>{
        setMultiselectValue([])
        dispatch({type:'RESET VALUE VAL2 OR',index:index,ind:ind})

    }

    const handleCombineDate=()=>{

        dispatch({ type: 'VALUE_FIELD', val:`${date1}___${date2}` , index: index, ind: ind }) 
        setModalShow(false)
    }

    return (
        <>
             {modalShow  &&
                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='ignore-onclickoutside'
                    style={{ zIndex: '999999' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Date Between
                     </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            className='date-icon'
                            type="date"
                            name="date1"
                            value={date1}
                            onChange={(e) => { setDate1(e.target.value) }}
                        />
                        <Form.Control
                            className='date-icon'
                            type="date"
                            name="date2"
                            value={date2}
                            onChange={(e) => { setDate2(e.target.value) }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCombineDate} >Set Date</Button>
                    </Modal.Footer>
                </Modal>
            }
           
            <Row className="g-2">

            <h6 className="mb-1 mt-3">OR</h6>
        
                <Col >
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="entity"
                        styles={selectStyle}
                        options={entity}
                        value={entity.find(i=>i.value === curr.from)}
                        onChange={(e)=>{dispatch({type:'ENTITY VALUE OR', entityValue:e.value ,index:index,ind:ind})}}
                      
                    />
                </Col>

                <Col>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="where"
                        styles={selectStyle}
                        value={curr.from === 'VetFields' ? vet.find(i=>i.value===curr.where) : practice.find(i=>i.value===curr.where)}
                        options={curr.from === 'VetFields' ? vet : practice}
                        onChange={(e)=>{dispatch({type:'WHERE FIELD OR', where:e.value,wheretext:e.label ,index:index,ind:ind});setShowMultiSelect(e.type); handleWhere() }}
                       
                    />
                </Col>
                <Col>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="val"
                        styles={selectStyle}
                        options={operators}
                        value={operators.find(i=>i.value === curr.param)}
                        onChange={(e)=>{dispatch({type:'OPERATOR FIELD OR', param:e.value,index:index,ind:ind})}}
                        
                    />
                </Col>

                 <Col>
                   {    showMultiSelect ?
                        <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="val"
                        styles={selectStyle}
                        isMulti
                        closeMenuOnSelect={false}
                        options={curr.where === 'title'? title_suggestion : curr.where === 'v_job_types' ? jobtype_suggestion :curr.where === 'v_species'? species_suggestion :null}
                        value={curr.val2}
                        onChange={handleMultiSelectValue}
                        
                    /> :
                    <Form.Control
                        type="text"
                        placeholder="Enter value"
                        name='val'
                        value={curr.val}
                        onChange={(e)=>{dispatch({type:'VALUE_FIELD_OR',val:e.target.value,index:index, ind:ind})} }

                    />}      
                </Col>
                
                
                
                <Col className="d-flex">
                      
                          <div className="action-icons">
                          <span
                              className="delete-icon"
                              onClick={()=>{dispatch({type:'DELETE INPUT FIELD OR',index:index,field:type,ind:ind})}}
                          >
                              <Image src={deleteIcon} />
                          </span>
                        </div>    
                </Col>
            </Row>
        </>
    );
};

export default SearchDimensionOR;