import Axios from '../adapters/xhr'
//set authentication
import { getAuthToken, setAuthToken } from '../adapters/authentication'
import Swal from "sweetalert2";
import { confirmationAlert, swAlert } from '../helpers/alert'
//constants
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    CREATE_PERSON_REQUEST,
    CREATE_PERSON_SUCCESS,
    CREATE_PERSON_FAIL,
    UPDATE_PERSON_REQUEST,
    UPDATE_PERSON_SUCCESS,
    UPDATE_PERSON_FAIL,
    GET_PERSON_REQUEST,
    GET_PERSON_SUCCESS,
    GET_PERSON_FAIL,
    CREATE_PRACTICE_REQUEST,
    CREATE_PRACTICE_SUCCESS,
    CREATE_PRACTICE_FAIL,
    UPDATE_PRACTICE_REQUEST,
    UPDATE_PRACTICE_SUCCESS,
    UPDATE_PRACTICE_FAIL,
    GET_PRACTICE_REQUEST,
    GET_PRACTICE_SUCCESS,
    GET_PRACTICE_FAIL,
    LAST_SEARCH_RESULT_REQUEST,
    LAST_SEARCH_RESULT_SUCCESS,
    LAST_SEARCH_RESULT_FAIL,
    LAST_TEN_SEARCH_RESULT_REQUEST,
    LAST_TEN_SEARCH_RESULT_SUCCESS,
    LAST_TEN_SEARCH_RESULT_FAIL,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAIL,
    SEARCH_PERSON_PRACTICE_REQUEST,
    SEARCH_PERSON_PRACTICE_SUCCESS,
    SEARCH_PRACTICE_ONLY_SUCCESS,
    SEARCH_PRACTICE_ONLY_SUCCESS2,
    SEARCH_PRACTICE_ONLY_SUCCESS3,
    SEARCH_PERSON_PRACTICE_FAIL,
    OPEN_PRACTICE,
    OPEN_PERSON,
    SWITCH_BRANCH_REQUEST,
    SWITCH_BRANCH_REQUEST_SUCCESS,
    SWITCH_BRANCH_REQUEST_FAILED,

    UNI_SEARCH_REQUEST,
    UNI_SEARCH_SUCCESS,
    UNI_SEARCH_FAILED,
    USER_ME_REQUEST,
    USER_ME_SUCCESS,
    USER_ME_FAIL,

    GET_REPORT_LIST_REQUEST,
    GET_REPORT_LIST_SUCCESS,
    GET_REPORT_LIST_FAIL,

    SEND_VERIFICATION_CODE_REQUEST,
    SEND_VERIFICATION_CODE_SUCCESS,
    SEND_VERIFICATION_CODE_FAIL,

    PMS_DROPDOWN_REQUEST,
    PMS_DROPDOWN_SUCCESS,
    PMS_DROPDOWN_FAIL,

} from '../constants/userConstants'

// authentication
export const login = (email, password) => async dispatch => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST })

        const { data } = await Axios.post('/auth/login', { email, password })

        if (data.two_factor) setAuthToken(data.token)
        else {
            localStorage.setItem('token', data.token)

            setAuthToken(localStorage.token)

            dispatch(lastSearchedResult())

            dispatch(getReportList())

            dispatch(pmsDropdown())
        }

        dispatch({ type: USER_LOGIN_SUCCESS, payload: data })

    } catch (err) {
        dispatch({ type: USER_LOGIN_FAIL, payload: err })
    }
}

export const sendVerificationCode = () => async dispatch => {
    try {
        dispatch({ type: SEND_VERIFICATION_CODE_REQUEST })

        const { data } = await Axios.post('/send/twofactor/code')

        dispatch({ type: SEND_VERIFICATION_CODE_SUCCESS, payload: data })
    } catch (error) {
        const { message } = error

        if (message === 'Unauthorized.') dispatch(logout())

        dispatch({ type: SEND_VERIFICATION_CODE_FAIL, payload: error })
    }
}

export const pmsDropdown = () => async dispatch => {
    try {
        dispatch({ type: PMS_DROPDOWN_REQUEST })

        const { data } = await Axios.get('/custom/dropdown/pms')

        dispatch({ type: PMS_DROPDOWN_SUCCESS, payload: data })
    } catch (error) {
        const { message } = error

        if (message === 'Unauthorized.') dispatch(logout())

        dispatch({ type: PMS_DROPDOWN_FAIL, payload: error })
    }
}

export const refreshToken = () => async dispatch => {
    try {
        dispatch({ type: REFRESH_TOKEN_REQUEST })

        const { data } = await Axios.post('/auth/refresh')

        dispatch({ type: REFRESH_TOKEN_SUCCESS, payload: data })

        localStorage.setItem('token', data.token)

        setAuthToken(localStorage.token)

        dispatch(lastSearchedResult())

        dispatch(getReportList())

        dispatch(pmsDropdown())
    } catch (error) {
        const message = error.response.data.message

        if (message === 'Unauthorized.') dispatch(logout())

        dispatch({ type: REFRESH_TOKEN_FAIL, payload: error })
    }
}

export const logout = () => async dispatch => {
    localStorage.removeItem('token')
    localStorage.removeItem('user_role')

    dispatch({ type: USER_LOGOUT })

    document.location.href = '/'
}

export const codeVerified = () => dispatch => {
    localStorage.setItem('token', getAuthToken())

    dispatch(lastSearchedResult())
    dispatch(getReportList())
    dispatch(pmsDropdown())
}

//person
export const createPerson = (dataBody) => async dispatch => {
    try {
        dispatch({
            type: CREATE_PERSON_REQUEST
        })
        const { data } = await Axios.post('/vet', dataBody)
        dispatch({
            type: CREATE_PERSON_SUCCESS,
            payload: data
        })
        Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Person created successfully.',
            showConfirmButton: false,
            timer: 1500
        })
        dispatch(getPersonById(data.data.id))
    } catch (error) {
        const message = error.response.data.message
        if (message === "Unauthorized.") {
            // dispatch(logout())
        }
        dispatch({
            type: CREATE_PERSON_FAIL,
            payload: error
        })
    }
}

export const updatePerson = (dataId, dataBody, pracId, text) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_PERSON_REQUEST
        })
        // const { data } = await Axios.put(`/vet/${dataId}`, dataBody)
        const { data } = await Axios.put(`/vet/${dataId}`, dataBody)
        dispatch({
            type: UPDATE_PERSON_SUCCESS,
            payload: data
        })
        dispatch(getPersonById(dataId))

        if(data.message !== ""){
            text = text + '. ' + data.message;
        }
        Swal.fire({
            position: "center",
            icon: "success",
            title: text,
            showConfirmButton: false,
            timer: 1500,
        })
    } catch (error) {
        const message = error.response.data.message
        swAlert(message,'error');
        if (message === 'Unauthorized.') {
            // dispatch(logout())
        }
        dispatch({
            type: UPDATE_PERSON_FAIL,
            payload: error
        })
    }
}

// update person/vet by ID
export const updatePersonByID = (dataId, dataBody, message) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_PERSON_REQUEST
        })
        const { data } = await Axios.put(`/vet/${dataId}`, dataBody)
        dispatch({
            type: SWITCH_BRANCH_REQUEST_SUCCESS,
            payload: data
        })
        dispatch(getPersonById(dataId))
    } catch (error) {
        const message = error.response.data.message
        if (message === 'Unauthorized.') // dispatch(logout())
            dispatch({
                type: UPDATE_PERSON_FAIL,
                payload: error
            })
    }
}

export const getPersonById = (dataId) => async dispatch => {
    try {
        if (dataId) {
            dispatch({
                type: GET_PERSON_REQUEST
            })
            const { data } = await Axios.get(`/vet/${dataId}?from_search=true`)
            dispatch({
                type: GET_PERSON_SUCCESS,
                payload: data
            })
        }
    } catch (error) {
        const message = error.response.data.message
        if (message === "Unauthorized.") {
            // dispatch(logout())
        }
        dispatch({
            type: GET_PERSON_FAIL,
            payload: error
        })
    }
}

//practice
export const createPractice = (dataBody) => async dispatch => {
    try {
        dispatch({
            type: CREATE_PRACTICE_REQUEST
        })
        const { data } = await Axios.post('/practice', dataBody)
        dispatch({
            type: CREATE_PRACTICE_SUCCESS,
            payload: data
        })
        dispatch(getPracticeById(data.data.id))
    } catch (error) {
        const message = error.response.data.message
        if (message === 'Unauthorized.') {
            // dispatch(logout())
        }
        dispatch({
            type: CREATE_PRACTICE_FAIL,
            payload: error
        })
    }
}

// Switch Branch Action
export const switchBranchReducer = (dataId, dataBody) => async dispatch => {
    try {
        dispatch({
            type: SWITCH_BRANCH_REQUEST
        })
        const { data } = await Axios.post(`/switch/practice/branch/${dataId}`, dataBody)
        dispatch({
            type: SWITCH_BRANCH_REQUEST_SUCCESS,
            payload: data
        })
        dispatch(getPersonById(data.data.id))
    } catch (error) {
        const message = error.response.data.message
        if (message === "Unauthorized.") {
            // dispatch(logout())
        }
        dispatch({
            type: SWITCH_BRANCH_REQUEST_FAILED,
            payload: error
        })

    }

}

export const updatePractice = (dataId, dataBody) => async dispatch => {
    try {
        dispatch({
            type: UPDATE_PRACTICE_REQUEST
        })
        const result = await Axios.put(`/practice/${dataId}`, dataBody)
        const { data } = await Axios.get(`/practice/${dataId}?from_search=true`)

        dispatch({
            type: UPDATE_PRACTICE_SUCCESS,
            payload: data
        })
        dispatch({
            type: GET_PRACTICE_SUCCESS,
            payload: data
        })

        swAlert('Saved successfully', 'success')
    } catch (error) {
        const message = error.response.data.message
        if (message === "Unauthorized.") {
            // dispatch(logout())
        }
        dispatch({
            type: UPDATE_PRACTICE_FAIL,
            payload: error
        })
    }
}

//
export const getPracticeById = (dataId) => async dispatch => {
    try {
        dispatch({
            type: GET_PRACTICE_REQUEST
        })
        const { data } = await Axios.get(`/practice/${dataId}?from_search=true`)
        dispatch({
            type: GET_PRACTICE_SUCCESS,
            payload: data
        })
    } catch (error) {
        const message = error.response.data.message
        if (message === "Unauthorized.") {
            // dispatch(logout())
        }
        dispatch({
            type: GET_PRACTICE_FAIL,
            payload: error
        })
    }
}

//Search practice/person
export const search = (dataBody, mthd) => async dispatch => {
    try {
        dispatch({
            type: SEARCH_PERSON_PRACTICE_REQUEST
        })
        const { data } = await Axios.get(`/search?method=all&keyword=${dataBody}`)
        if (mthd === "all") {
            dispatch({
                type: SEARCH_PERSON_PRACTICE_SUCCESS,
                payload: data
            })
        } else if (mthd === "prac") {
            dispatch({
                type: SEARCH_PRACTICE_ONLY_SUCCESS,
                payload: data
            })
        } else if (mthd === "pers") {
            dispatch({
                type: SEARCH_PRACTICE_ONLY_SUCCESS2,
                payload: data
            })
        } else if (mthd === "pers-add") {
            dispatch({
                type: SEARCH_PRACTICE_ONLY_SUCCESS3,
                payload: data
            })
        }
    } catch (error) {
        const message = error.response.data.message
        if (message === "Unauthorized.") {
            // dispatch(logout())
        }
        dispatch({
            type: SEARCH_PERSON_PRACTICE_FAIL,
            payload: error
        })
    }
}

export const lastSearchedResult = () => async dispatch => {
    try {
        dispatch({ type: LAST_SEARCH_RESULT_REQUEST })

        const { data } = await Axios.get('/last/search')

        dispatch({ type: LAST_SEARCH_RESULT_SUCCESS, payload: data })
    } catch (error) {
        const { message } = error

        if (message === 'Unauthorized.') // dispatch(logout())

            dispatch({ type: LAST_SEARCH_RESULT_FAIL, payload: error })
    }
}

export const lastTenSearchedResult = () => async dispatch => {
    try {
        dispatch({
            type: LAST_TEN_SEARCH_RESULT_REQUEST
        })
        const { data } = await Axios.get('/last/ten/search')
        dispatch({
            type: LAST_TEN_SEARCH_RESULT_SUCCESS,
            payload: data
        })
    } catch (error) {
        const message = error.response.data.message
        if (message === "Unauthorized.") {
            // dispatch(logout())
        }
        dispatch({
            type: LAST_TEN_SEARCH_RESULT_FAIL,
            payload: error
        })
    }
}

//Open practice
export const openPractice = (dataId) => async dispatch => {
    dispatch({
        type: OPEN_PRACTICE,
        payload: dataId
    })
}

//Open person
export const openPerson = (dataId) => async dispatch => {
    dispatch({
        type: OPEN_PERSON,
        payload: dataId
    })
}

export const resetSearchResult = () => async dispatch => {
    dispatch({
        type: SEARCH_PERSON_PRACTICE_SUCCESS,
        payload: []
    })
}

// Uni Qualification Search
export const uniQualificationSearch = (keys) => async dispatch => {

    try {
        dispatch({ type: UNI_SEARCH_REQUEST })

        const { data } = await Axios.get(`/qualification/uni/search?keyword=${keys}`)

        dispatch({
            type: UNI_SEARCH_SUCCESS,
            payload: data
        })
    }
    catch (err) {

        dispatch({
            type: UNI_SEARCH_FAILED,
            payload: err
        })
    }

}

export const me = () => async dispatch => {
    try {
        dispatch({ type: USER_ME_REQUEST })

        const { data } = await Axios.post(`/auth/me`)

        if (data.role) localStorage.setItem('user_role', data.role)

        dispatch({ type: USER_ME_SUCCESS, payload: data })
    } catch (error) {
        const { message } = error

        if (message === 'Unauthorized.') { dispatch(logout()) }

        dispatch({ type: USER_ME_FAIL, payload: error })
    }
}

export const getReportList = () => async dispatch => {
    try {
        dispatch({ type: GET_REPORT_LIST_REQUEST })

        const { data } = await Axios.get('/report/builder?method=all')

        dispatch({ type: GET_REPORT_LIST_SUCCESS, payload: data })
    } catch (error) {
        const { message } = error

        if (message === 'Unauthorized.') dispatch(logout())

        dispatch({ type: GET_REPORT_LIST_FAIL, payload: error })
    }
}
