import { combineReducers } from 'redux'

import {
    userLoginReducer,
    createPersonReducer,
    updatePersonReducer,
    getPersonReducer,
    createPracticeReducer,
    updatePracticeReducer,
    getPracticeReducer,
    searchReducer,
    openPracticeReducer,
    openPersonReducer,
    lastSearchedResultReducer,
    lastTenSearchedResultReducer,
    switchBranchReducer,
    uniQualificationReducer,
    userMeReducer,
    getReportListReducer,
    sendVerificationCodeReducer,
    pmsDropDownReducer,
} from './userReducers'

const reducer = combineReducers({
    userLogin: userLoginReducer,
    createPersonReducer: createPersonReducer,
    updatePersonReducer: updatePersonReducer,
    getPersonReducer: getPersonReducer,
    createPracticeReducer: createPracticeReducer,
    updatePracticeReducer: updatePracticeReducer,
    getPracticeReducer: getPracticeReducer,
    searchReducer: searchReducer,
    openPracticeReducer: openPracticeReducer,
    openPersonReducer: openPersonReducer,
    lastSearchedResultReducer: lastSearchedResultReducer,
    lastTenSearchedResultReducer: lastTenSearchedResultReducer,
    switchBranchReducer: switchBranchReducer,
    uniSearch :uniQualificationReducer,
    userMeReducer: userMeReducer,
    getReportListReducer: getReportListReducer,
    sendVerificationCodeReducer: sendVerificationCodeReducer,
    pmsDropDownReducer: pmsDropDownReducer,
})

export default reducer