import React from "react";
import PropTypes from "prop-types";
//react-bootstrap
import { Button } from "react-bootstrap";

const ButtonUI = ({ variant, size, className, btnText, onClick, ...props }) => {
  const handleClick = (evt) => {
    onClick();
  };

  return (
    <>
      <Button className="btn theme-btn-primary" variant="theme-primary" onClick={handleClick}>
        {btnText}
      </Button>
    </>
  );
};

export default ButtonUI;
