let roles, contact, subscription, request, online, industry, skills_match, small_animal, farm_animal, equine, nursing, cpd_companion_animal, cpd_exotics, cpd_equine, cpd_livestock, cpd_bussiness = []

export const entity = [{ label: 'Individual', value: 'VetFields' }, { label: 'Practice', value: 'practice_fields' }]

export const vet = [
    {
        value: 'vet.id',
        label: 'ID'
    },
    {
        value: 'title',
        label: 'Title',
        type: 'multiselect'
    },

    {
        value: 'initials',
        label: 'Initials'
    },
    {
        value: 'forename',
        label: 'Forename'
    },
    {
        value: 'middlenames',
        label: 'Middle names'
    },
    {
        value: 'surname',
        label: 'Surname'
    },
    {
        value: 'maiden',
        label: 'Maiden name'
    },
    {
        value: 'gender',
        label: 'Gender'
    },
    {
        value: 'working_hours',
        label: 'Working Hours'
    },
    {
        value: 'v_job_types',
        label: 'Job types',
        type: 'multiselect'
    },
    {
        value: 'v_job_types_o',
        label: 'Job types Other'
    },
    {
        value: 'u_suffix',
        label: 'Suffix'
    },
    {
        value: 'u_cert',
        label: 'Certs and Dips'
    },
    {
        value: 'u_uni',
        label: 'University'
    },
    {
        value: 'u_year',
        label: 'Uni Year'
    },
    {
        value: 'u_country',
        label: 'Uni Country'
    },
    {
        value: 'v_species',
        label: 'Species',
        type: 'multiselect'
    },
    {
        value: 'v_species_o',
        label: 'Species Other'
    },
    {
        value: 'v_rcvsnum',
        label: 'RCVS Number'
    },
    {
        value: 'v_vpma',
        label: 'VMG'
    },
    {
        value: 'v_address_1',
        label: 'Address 1'
    },
    {
        value: 'v_address_2',
        label: 'Address 2'
    },
    {
        value: 'v_address_3',
        label: 'Address 3'
    },
    {
        value: 'v_town',
        label: 'Town'
    },
    {
        value: 'v_county',
        label: 'County'
    },
    {
        value: 'v_postcode',
        label: 'Postcode'
    },
    {
        value: 'v_country',
        label: 'Country'
    },
    {
        value: 'v_sms',
        label: 'SMS'
    },
    {
        value: 'v_tel',
        label: 'Tel'
    },
    {
        value: 'v_email',
        label: 'Primary email'
    },
    {
        value: 'v_work_email',
        label: 'Secondary email'
    },
    {value: 'primary_pma', label: 'Primary PMA'},
    {value: 'secondary_pma', label: 'Secondary PMA'},
    {
        value: 'sso_email',
        label: 'SSO Email'
    },
    {
        value: 'lvf',
        label: 'LVF'
    },
    {
        value: 'v_pma',
        label: 'PMA'
    },
    {
        value: 'v_sdm',
        label: 'Direct mail'
    },
    {
        value: 'v_svt',
        label: 'Vet Times'
    },
    {
        value: 'v_svnt',
        label: 'VN Times'
    },
    {
        value: 'v_svbj',
        label: 'VBJ'
    },
    {
        value: 'v_svtl',
        label: 'Livestock'
    },
    {
        value: 'v_svte',
        label: 'Equine'
    },
    {
        value: 'v_notes',
        label: 'Notes'
    },
    {
        value: 'v_svt_request',
        label: 'Request type Vet Times'
    },
    {
        value: 'v_svt_request_date',
        label: 'Request date Vet Times'
    },
    {
        value: 'v_svt_abc_name',
        label: 'Vet Times ABC name'
    },
    {
        value: 'v_svt_abc_answer',
        label: 'Vet Times ABC answer'
    },
    {
        value: 'v_svnt_request',
        label: 'Request type VN Times'
    },
    {
        value: 'v_svnt_request_date',
        label: 'Request date VN Times'
    },
    {
        value: 'v_svnt_abc_name',
        label: 'VN Times ABC name'
    },
    {
        value: 'v_svnt_abc_answer',
        label: 'VN Times ABC answer'
    },
    {
        value: 'v_svbj_request',
        label: 'Request type VBJ'
    },
    {
        value: 'v_svbj_request_date',
        label: 'Request date VBJ'
    },
    {
        value: 'v_svbj_abc_name',
        label: 'VBJ ABC name'
    },
    {
        value: 'v_svbj_abc_answer',
        label: 'VBJ ABC answer'
    },
    {
        value: 'v_sevbd_request',
        label: 'VBD Request type'
    },
    {
        value: 'v_sevbd_request_date',
        label: 'VBD Request date'
    },
    {
        value: 'v_s3rd_request',
        label: '3rd party Request type'
    },
    {
        value: 'v_s3rd_request_date',
        label: '3rd party Request date'
    },
    {
        value: 'v_vnews_d_request',
        label: 'Vettimes d Request type'
    },
    {
        value: 'v_vnews_d_request_date',
        label: 'Vettimes d Request date'
    },
    {
        value: 'v_vnews_request',
        label: 'Vettimes w Request type'
    },
    {
        value: 'v_vnews_request_date',
        label: 'Vettimes w Request date'
    },
    {
        value: 'v_vjnw_request',
        label: 'Vet Times Jobs w Request type'
    },
    {
        value: 'v_vjnw_request_date',
        label: 'Vet Times Jobs w Request date'
    },
    {
        value: 'v_vjnm_request',
        label: 'Vet Times Jobs m Request type'
    },
    {
        value: 'v_vjnm_request_date',
        label: 'Vet Times Jobs m Request date'
    },
    {
        value: 'v_vt_jobs_marketing_request',
        label: 'VT Jobs Marketing Request Type'
    },
    {
        value: 'v_vt_jobs_marketing_request_date',
        label: 'VT Jobs Marketing Request Date'
    },
    {
        value: 'vn_monthly_newsletter_request',
        label: 'VN Newsletter m Request Type'
    },
    {
        value: 'vn_monthly_newsletter_request_date',
        label: 'VN Newsletter m Request Date'
    },
    {
        value: 'updatedby',
        label: 'Updated by'
    },
    {
        value: 'date',
        label: 'Updated date'
    },
    {
        value: 'v_sevbd',
        label: 'VBD email'
    },
    {
        value: 'v_s3rd',
        label: '3rd party'
    },
    {
        value: 'v_vnews_d',
        label: 'Vettimes d'
    },
    {
        value: 'v_vnews',
        label: 'Vettimes w'
    },
    {
        value: 'v_vjnw',
        label: 'Vet Times Jobs w'
    },
    {
        value: 'v_vjnm',
        label: 'Vet Times Jobs m'
    },
    {
        value: 'v_vt_jobs_marketing',
        label: 'VT Jobs Marketing'
    },
    {
        value: 'vn_monthly_newsletter',
        label: 'VN Newsletter m'
    },
    {
        value: 'v_sso',
        label: 'SSO'
    },
    {
        value: 'login_at',
        label: 'Last logged at'
    },
    {
        value: 'vtapp_sso',
        label: 'VT app SSO'
    },
    {
        value: 'vtapp_date',
        label: 'VT app Login'
    },
    {
        value: 'cpd_sso',
        label: 'CPD SSO'
    },
    {
        value: 'cpd_date',
        label: 'CPD login'
    },
    {
        value: 'marketplace_sso',
        label: 'Marketplace SSO'
    },
    {
        value: 'marketplace_date',
        label: 'Marketplace login'
    },
    {
        value: 'vt_sso',
        label: 'VT SSO'
    },
    {
        value: 'vt_date',
        label: 'VT login'
    },
    {
        value: 'vtjobs_sso',
        label: 'VT jobs SSO'
    },
    {
        value: 'vtjobs_date',
        label: 'VT jobs login'
    },
    {
        value: 'v_interests',
        label: 'Interests'
    },
    {
        value: 'ind_type',
        label: 'Sub Type'
    },
    {
        value: 'ind_vt_start',
        label: 'Vet Times Start date'
    },
    {
        value: 'ind_vt_end',
        label: 'Vet Times End date'
    },
    {
        value: 'ind_vt_cost',
        label: 'Vet Times Cost'
    },
    {
        value: 'ind_vn_start',
        label: 'VN Times Start date'
    },
    {
        value: 'ind_vn_end',
        label: 'VN Times End date'
    },
    {
        value: 'ind_vn_cost',
        label: 'VN Times Cost'
    },
    {
        value: 'ind_vbj_start',
        label: 'VBJ Start date'
    },
    {
        value: 'ind_vbj_end',
        label: 'VBJ End date'
    },
    {
        value: 'ind_vbj_cost',
        label: 'VBJ Cost'
    },
    {
        value: 'ind_agent',
        label: 'Agent'
    },
    {
        value: 'history',
        label: 'History'
    },
    {
        value: 'p_updated',
        label: 'Profile Updated'
    },
    {
        value: 'p_date',
        label: 'Profile Update Date'
    },
    {
        value: 'academy_sso',
        label: 'Academy SSO'
    },
    {
        value: 'academy_date',
        label: 'Academy login'
    },
    {
        value: 'residence',
        label: 'Country Residence'
    },
    // {
    //     value: 'phone_number',
    //     label: 'SM Phone Number'
    // },
    // {
    //     value: 'contact_method',
    //     label: 'SM Contact Method'
    // },
    // {
    //     value: 'rtw',
    //     label: 'SM Right to work in UK'
    // },
    // {
    //     value: 'rcvs_2',
    //     label: 'SM RCVS Skills Match'
    // },
    // {
    //     value: 's_postcode',
    //     label: 'SM Postcode'
    // },
    // {
    //     value: 'sm_address_1',
    //     label: 'SM Address 1'
    // },
    // {
    //     value: 'sm_address_2',
    //     label: 'SM Address 2'
    // },
    // {
    //     value: 'sm_address_3',
    //     label: 'SM Address 3'
    // },
    // {
    //     value: 'sm_town',
    //     label: 'SM Town'
    // },
    // {
    //     value: 'sm_county',
    //     label: 'SM County'
    // },
    // {
    //     value: 'distance',
    //     label: 'SM Distance'
    // },
    // {
    //     value: 'relocate',
    //     label: 'SM Relocate'
    // },
    // {
    //     value: 'prof',
    //     label: 'SM Job Type'
    // },
    // {
    //     value: 'pmsother',
    //     label: 'SM PMS Other'
    // },
    // {
    //     value: 'areas',
    //     label: 'SM Locations',
    //     type: 'multiselect'
    // },
    // {
    //     value: 'position',
    //     label: 'SM Position'
    // },
    // {
    //     value: 'species_type',
    //     label: 'SM Species Type'
    // },
    // {
    //     value: 'pms',
    //     label: 'SM PMS'
    // },
    // {
    //     value: 'main_experience',
    //     label: 'SM Main Experience'
    // },
    // {
    //     value: 'consultation_general',
    //     label: 'SM Consultation General'
    // },
    // {
    //     value: 'consultation_10min',
    //     label: 'SM Consultation 10 mins'
    // },
    // {
    //     value: 'castration_dog_cat',
    //     label: 'SM Castration Dog and Cat'
    // },
    // {
    //     value: 'castration_rabbit',
    //     label: 'SM Castration Rabbit'
    // },
    // {
    //     value: 'spay_dog_cat',
    //     label: 'SM Spay Dog and Cat'
    // },
    // {
    //     value: 'spay_rabbit',
    //     label: 'SM Spay Rabbit'
    // },
    // {
    //     value: 'c_section',
    //     label: 'SM C Section'
    // },
    // {
    //     value: 'extractions_dental',
    //     label: 'SM Extractions Dental'
    // },
    // {
    //     value: 'ex_lap',
    //     label: 'SM Ex lap'
    // },
    // {
    //     value: 'amputation_limb',
    //     label: 'SM Amputation Limb'
    // },
    // {
    //     value: 'removal_lump',
    //     label: 'SM Removal Lump'
    // },
    // {
    //     value: 'radiograph_interpretation',
    //     label: 'SM Radiograph Interpretation'
    // },
    // {
    //     value: 'radiograph_taking',
    //     label: 'SM Radiograph Taking'
    // },
    // {
    //     value: 'lab_interpreting_results',
    //     label: 'SM Interpreting Lab Results'
    // },
    // {
    //     value: 'emergency_medicine',
    //     label: 'SM Emergency Medicine'
    // },
    // {
    //     value: 'ultrasonography',
    //     label: 'SM Ultrasonography'
    // },
    // {
    //     value: 'consultation_euthanasia',
    //     label: 'SM Consultation Euthanasia'
    // },
    // {
    //     value: 'surgery_orthaepedic',
    //     label: 'SM Surgery Orthaepedic'
    // },
    // {
    //     value: 'investigation_dermatology',
    //     label: 'SM Investigation Dermatology'
    // },
    // {
    //     value: 'ophthalmology',
    //     label: 'SM Ophthalmology'
    // },
    // {
    //     value: 'investigation_cardiology',
    //     label: 'SM Investigation Cardiology'
    // },
    // {
    //     value: 'investigation_neurology',
    //     label: 'SM Investigation Neurology'
    // },
    // {
    //     value: 'cat_spay',
    //     label: 'SM Cat Spay'
    // },
    // {
    //     value: 'bitch_spay',
    //     label: 'SM Bitch Spay'
    // },
    // {
    //     value: 'clinical_examination',
    //     label: 'SM Clinical Examination'
    // },
    // {
    //     value: 'fertility_work',
    //     label: 'SM Fertility Work'
    // },
    // {
    //     value: 'lda_surgery',
    //     label: 'SM LDA Surgery'
    // },
    // {
    //     value: 'caesareans',
    //     label: 'SM Caesareans'
    // },
    // {
    //     value: 'blood_sampling',
    //     label: 'SM Blood Sampling'
    // },
    // {
    //     value: 'tb_testing',
    //     label: 'SM TB Testing'
    // },
    // {
    //     value: 'tb_per_day',
    //     label: 'SM TB per day'
    // },
    // {
    //     value: 'farm_experience',
    //     label: 'SM Farm Experience'
    // },
    // {
    //     value: 'investigation_lameness',
    //     label: 'SM Investigation Lameness'
    // },
    // {
    //     value: 'dentistry',
    //     label: 'SM Dentistry'
    // },
    // {
    //     value: 'investigation_pre_purchase',
    //     label: 'SM Investigation Pre Purchase'
    // },
    // {
    //     value: 'stud_work',
    //     label: 'SM Stud Work'
    // },
    // {
    //     value: 'al_work',
    //     label: 'SM Al Work'
    // },
    // {
    //     value: 'anaesthesia',
    //     label: 'SM Anaesthesia'
    // },
    // {
    //     value: 'radiology',
    //     label: 'SM Radiology'
    // },
    // {
    //     value: 'consultation_nurse',
    //     label: 'SM Consultaion Nurse'
    // },
    // {
    //     value: 'blood_samples_taking',
    //     label: 'SM Blood Samples Taking'
    // },
    // {
    //     value: 'catheter_insertion',
    //     label: 'SM Catheter Insertion'
    // },
    // {
    //     value: 'anaesthetics_monitoring',
    //     label: 'SM Anaesthetics Monitoring'
    // },
    // {
    //     value: 'animal_restraint',
    //     label: 'SM Animal Restaint'
    // },
    // {
    //     value: 'reception_skills',
    //     label: 'SM Reception Skills'
    // },
    // {
    //     value: 'schedule_3_procedures',
    //     label: 'SM Schedule 3 procedures'
    // },
    // {
    //     value: 'cv',
    //     label: 'SM CV'
    // },
    // {
    //     value: 'sm_profile',
    //     label: 'SM Profile'
    // },
    // {
    //     value: 'sm_date',
    //     label: 'SM Profile Date'
    // },
    // {
    //     value: 'sm_salary_band',
    //     label: 'SM Salary band',
    //     type: 'multiselect'
    // },
    // {
    //     value: 'sm_next_role',
    //     label: 'SM Preferred next role'
    // },
    {
        value: 'companion_animal_subs',
        label: 'CPD companion animal subscription'
    },
    {
        value: 'exotics_subs',
        label: 'CPD exotics subscription'
    },
    {
        value: 'equine_subs',
        label: 'CPD equine subscription'
    },
    {
        value: 'livestock_subs',
        label: 'CPD livestock subscription'
    },
    {
        value: 'business_subs',
        label: 'CPD business subscription'
    },
    {
        value: 'well_being_subs',
        label: 'CPD well-being subscription'
    },
    {
        value: 'para_date1',
        label: 'CPD Parasitology 1st viewed'
    },
    {
        value: 'para_date2',
        label: 'CPD Parasitology last viewed'
    },
    {
        value: 'pman_date1',
        label: 'CPD Pain management 1st viewed'
    },
    {
        value: 'pman_date2',
        label: 'CPD Pain management last viewed'
    },
    {
        value: 'derma_date1',
        label: 'CPD Dermatology 1st viewed'
    },
    {
        value: 'derma_date2',
        label: 'CPD Dermatology last viewed'
    },
    {
        value: 'onco_date1',
        label: 'CPD Oncology 1st viewed'
    },
    {
        value: 'onco_date2',
        label: 'CPD Oncology last viewed'
    },
    {
        value: 'spets_date1',
        label: 'CPD Senior pets 1st viewed'
    },
    {
        value: 'spets_date2',
        label: 'CPD Senior Pets last viewed'
    },
    {
        value: 'gastro_date1',
        label: 'CPD Gastrointestine Disorders 1st viewed'
    },
    {
        value: 'gastro_date2',
        label: 'CPD Gastrointestine Disorders last viewed'
    },
    {
        value: 'cardio_date1',
        label: 'CPD Cardiology 1st viewed'
    },
    {
        value: 'cardio_date2',
        label: 'CPD Cardiology last viewed'
    },
    {
        value: 'nutrition_date1',
        label: 'CPD Nutrition 1st viewed'
    },
    {
        value: 'nutrition_date2',
        label: 'CPD Nutrition last viewed'
    },
    {   value: 'ecc_date1',
        label: 'CPD Emergency and critical care 1st viewed'
    },
    {   value: 'ecc_date2',
        label: 'CPD Emergency and critical care last viewed'
    },
    {   value: 'neurology_date1',
        label: 'CPD Neurology 1st viewed'
    },
    {   value: 'neurology_date2',
        label: 'CPD Neurology last viewed'
    },
    {   value: 'urology_date1',
        label: 'CPD Urology 1st viewed'
    },
    {   value: 'urology_date2',
        label: 'CPD Urology last viewed'
    },
    {   value: 'alabama_rot_date1',
        label: 'CPD Alabama rot 1st viewed'
    },
    {   value: 'alabama_rot_date2',
        label: 'CPD Alabama rot last viewed'
    },
    {   value: 'fip_date1',
        label: 'CPD FIP 1st viewed'
    },
    {   value: 'fip_date2',
        label: 'CPD FIP last viewed'
    },
    {
        value: 'rabbits_date1',
        label: 'CPD Rabbits 1st viewed'
    },
    {
        value: 'rabbits_date2',
        label: 'CPD Rabbits last viewed'
    },
    {
        value: 'rodents_date1',
        label: 'CPD Rodents 1st viewed'
    },
    {
        value: 'rodents_date2',
        label: 'CPD Rodents last viewed'
    },
    {   value: 'guinea_p_date1',
        label: 'CPD Guinea Pigs 1st viewed'
    },
    {   value: 'guinea_p_date2',
        label: 'CPD Guinea Pigs last viewed'
    },
    {   value: 'reptiles_date1',
        label: 'CPD Reptiles 1st viewed'
    },
    {   value: 'reptiles_date2',
        label: 'CPD Reptiles last viewed'
    },
    {
        value: 'lameness_date1',
        label: 'CPD Lameness 1st viewed'
    },
    {
        value: 'lameness_date2',
        label: 'CPD Lameness last viewed'
    },
    {
        value: 'equine_respiratory_date1',
        label: 'CPD Respiratory diseases (equine) 1st viewed'
    },
    {
        value: 'equine_respiratory_date2',
        label: 'CPD Respiratory diseases (equine) last viewed'
    },
    {
        value: 'phealth_date1',
        label: 'CPD Preventive Health 1st viewed'
    },
    {
        value: 'phealth_date2',
        label: 'CPD Preventive Health last viewed'
    },
    {
        value: 'calf_health_date1',
        label: 'CPD Calf health 1st viewed'
    },
    {
        value: 'calf_health_date2',
        label: 'CPD Calf health last viewed'
    },
    {
        value: 'mastitis_date1',
        label: 'CPD Mastitis 1st viewed'
    },
    {
        value: 'mastitis_date2',
        label: 'CPD Mastitis last viewed'
    },
    {
        value: 'livestock_respiratory_date1',
        label: 'CPD Respiratory diseases (livestock) 1st viewed'
    },
    {
        value: 'livestock_respiratory_date2',
        label: 'CPD Respiratory diseases (livestock) last viewed'
    },
    {
        value: 'parasites_date1',
        label: 'CPD Parasites 1st viewed'
    },
    {
        value: 'parasites_date2',
        label: 'CPD Parasites last viewed'
    },
    {
        value: 'finance_date1',
        label: 'CPD Finance 1st viewed'
    },
    {
        value: 'finance_date2',
        label: 'CPD Finance last viewed'
    },
    {
        value: 'client_care_date1',
        label: 'CPD Client care 1st viewed'
    },
    {
        value: 'client_care_date2',
        label: 'CPD Client care last viewed'
    },
    {
        value: 'technology_date1',
        label: 'CPD Technology 1st viewed'
    },
    {
        value: 'technology_date2',
        label: 'CPD Technology last viewed'
    },
    {
        value: 'train_and_develop_date1',
        label: 'CPD Training and development 1st viewed'
    },
    {
        value: 'train_and_develop_date2',
        label: 'CPD Training and development last viewed'
    },
    {
        value:'wb_teams_date1',
        label:'CPD Well-being for your team 1st viewed'
    },
    {
        value:'wb_teams_date2',
        label:'CPD Well-being for your team last viewed'
    },
    {
        value:'wb_you_date1',
        label:'CPD Well-being for you 1st viewed'
    },
    {
        value:'wb_you_date2',
        label:'CPD Well-being for you last viewed'
    },
    {
        value: "endocrinology_date1",
        label: "Endocrinology 1st viewed"
    },
    {
        value: "endocrinology_date2",
        label: "Endocrinology last viewed"
    },
    {
        value: "anaesthesia_date1",
        label: "Anaesthesia and analgesia 1st viewed"
    },
    {
        value: "anaesthesia_date2",
        label: "Anaesthesia and analgesia last viewed"
    },
    {
        value: "ophthalmology_date1",
        label: "Ophthalmology 1st viewed"
    },
    {
        value: "ophthalmology_date2",
        label: "Ophthalmology last viewed"
    },
    {
        value: "oral_care_date1",
        label: "Oral care 1st viewed"
    },
    {
        value: "oral_care_date2",
        label: "Oral care last viewed"
    },
    {
        value: "wound_management_date1",
        label: "Wound management 1st viewed"
    },
    {
        value: "wound_management_date2",
        label: "Wound management last viewed"
    },
    {
        value: "birds_date1",
        label: "Birds 1st viewed"
    },
    {
        value: "birds_date2",
        label: "Birds last viewed"
    },
    {
        value: "obesity_date1",
        label: "Obesity 1st viewed"
    },
    {
        value: "obesity_date2",
        label: "Obesity last viewed"
    },
    {
        value: "imaging_date1",
        label: "Imaging 1st viewed"
    },
    {
        value: "imaging_date2",
        label: "Imaging last viewed"
    },
    {
        value: "reproduction_date1",
        label: "Reproduction 1st viewed"
    },
    {
        value: "reproduction_date2",
        label: "Reproduction last viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea 1st viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea last viewed"
    },
    {
        value: "lamb_date1",
        label: "Lamb health 1st viewed"
    },
    {
        value: "lamb_date2",
        label: "Lamb health last viewed"
    },
    {
        value:'v_registration_email',
        label:'VN HH Registration email address'
    },
    {
        value:'v_registration',
        label:'VN HH Registration',
        type:'multiselect'
    },
    {
        value:'v_attended',
        label:'VN HH Attended',
        type:'multiselect'
    },
    {
        value:'v_congresses',
        label:'Congresses',
        type:'multiselect'
    },
    {
        value:'v_vet_panel',
        label:'Vet Panel'
    },
    {
        value:'v_vn_panel',
        label:'VN Panel'
    },
    {
        value:'v_practice_manager_panel',
        label:'Practice Manager Panel'
    },
    {
        value:'v_email_opt_in',
        label:'Email Opt In'
    },

    {value: 'pm_name', label: 'PM name'},
    {value: 'pm_email', label: 'PM email'},
    {value: 'pm_phone', label: 'PM phone'},
    {value: 'pm_next_role', label: 'PM next role'},
    {value: 'pm_postcode', label: 'PM postcode'},
    {value: 'pm_address_1', label: 'PM address_1'},
    {value: 'pm_address_2', label: 'PM address_2'},
    {value: 'pm_address_3', label: 'PM address_3'},
    {value: 'pm_town', label: 'PM town'},
    {value: 'pm_distance', label: 'PM distance'},
    {value: 'pm_relocate', label: 'PM relocate'},
    {value: 'pm_working_outside', label: 'PM working outside'},
    {value: 'pm_looking_for_work', label: 'PM looking for work'},
    {value: 'pm_ideal_rota', label: 'PM ideal rota'},
    {value: 'pm_salary_band', label: 'PM salary band'},
    {value: 'pm_year_rcvs', label: ' PM year rcvs'},
    {value: 'pm_ideal_role', label: 'PM ideal role'},
    {value: 'pm_important_next_role', label: 'PM important next role'},
    {value: 'pm_university', label: 'PM university'},
    {value: 'pm_county', label: 'PM county'},
    {value: 'pm_country', label: 'PM country'},
    {value: 'pm_clinical_interest', label: 'PM clinical interest'},
    {value: 'pm_skill_develop', label: 'PM skill develop'},
    {value: 'pm_work_in_uk', label: 'PM work in uk'},
    {value: 'pm_areas', label: 'PM areas'},
    {value: 'pm_outside_areas', label: 'PM outside areas'},
    {value: 'pm_year_qualification', label: 'PM year qualification'},
    {value: 'pm_cv', label: 'PM cv'},
    {value: 'pm_profile', label: 'PM profile'},
    {value: 'pm_job_type', label: 'PM job type'},
    {value: 'pm_profile_created_date', label: 'PM profile created date'},
    {value: 'pm_profile_modified', label: 'PM profile modified'},
    {value: 'pm_interested', label: 'PM opt in'},
    {value: 'pm_interest_date', label: 'PM opt in date'},
    {value: 'pm_soft_opt_in', label: 'PM soft opt-in'},
    {value: 'pm_soft_opt_in_date', label: 'PM soft opt-in date'},
    {value: 'record_created', label: 'Created at'},



//        Digital Edition Section Fields
        {value: 'v_svtl_digi', label: 'Livestock Vet Times Digi'},
        {value: 'v_svte_digi', label: 'Equine Vet Times Digi'},
        {value: 'v_svt_digi', label: 'Vet Times Digi'},
        {value: 'v_svt_digi_request', label: 'Request type Vet Times Digi'},
        {value: 'v_svt_digi_request_date', label: 'Request date Vet Times Digi'},
        {value: 'v_svt_digi_abc_name', label: 'Vet Times Digi ABC name'},
        {value: 'v_svt_digi_abc_answer', label: 'Vet Times Digi ABC answer'},

        {value: 'v_svnt_digi', label: 'VN Times Digi'},
        {value: 'v_svnt_digi_request', label: 'Request type VN Times Digi'},
        {value: 'v_svnt_digi_request_date', label: 'Request date VN Times Digi'},
        {value: 'v_svnt_digi_abc_name', label: 'VN Times Digi ABC name'},
        {value: 'v_svnt_digi_abc_answer', label: 'VN Times Digi ABC answer'},

        {value: 'v_svbj_digi', label: 'VBJ Digi'},
        {value: 'v_svbj_digi_request', label: 'Request type VBJ Digi'},
        {value: 'v_svbj_digi_request_date', label: 'Request date VBJ Digi'},
        {value: 'v_svbj_digi_abc_name', label: 'VBJ Digi ABC name'},
        {value: 'v_svbj_digi_abc_answer', label: 'VBJ Digi ABC answer'},

        {value: 'ind_vt_digi_start', label: 'Vet Times Digi Start date'},
        {value: 'ind_vt_digi_end', label: 'Vet Times Digi End date'},
        {value: 'ind_vt_digi_cost', label: 'Vet Times Digi Cost'},

        {value: 'ind_vn_digi_start', label: 'VN Times Digi Start date'},
        {value: 'ind_vn_digi_end', label: 'VN Times Digi End date'},
        {value: 'ind_vn_digi_cost', label: 'VN Times Digi Cost'},

        {value: 'ind_vbj_digi_start', label: 'VBJ Digi Start date'},
        {value: 'ind_vbj_digi_end', label: 'VBJ Digi End date'},
        {value: 'ind_vbj_digi_cost', label: 'VBJ Digi Cost'},


]

export const practice = [

    {
        value: 'practice.id',
        label: 'ID'
    },
    {
        value: 'office',
        label: 'Office'
    },

    {
        value: 'name',
        label: 'Name'
    },
    {
        value: 'address_1',
        label: 'Address 1'
    },
    {
        value: 'address_2',
        label: 'Address 2'
    },
    {
        value: 'address_3',
        label: 'Address 3'
    },
    {
        value: 'town',
        label: 'Town'
    },
    {
        value: 'county',
        label: 'County'
    },
    {
        value: 'postcode',
        label: 'Postcode'
    },
    {
        value: 'country',
        label: 'Country'
    },
    {
        value: 'brick',
        label: 'Brick'
    },
    {
        value: 'type',
        label: 'Type'
    },
    {
        value: 'tel',
        label: 'Tel.'
    },
    {
        value: 'direct_mail',
        label: 'Direct Mail'
    },
    {
        value: 'fax',
        label: 'Fax'
    },
    {
        value: 'website',
        label: 'Web'
    },
    {
        value: 'email',
        label: 'Email'
    },
    {
        value: 'wholesaler',
        label: 'Wholesaler'
    },
    {
        value: 'pramansys',
        label: 'Pra Man Sys'
    },
    {
        value: 'referral',
        label: 'Referral'
    },
    {
        value: 'no_staff_pra',
        label: 'No Staff Prac'
    },
    {
        value: 'corporate',
        label: 'Corporate'
    },
    {
        value: 'buying',
        label: 'Buying'
    },
    {
        value: 'rcvs_acc',
        label: 'RCVS Accred'
    },
    {
        value: 'pethome',
        label: 'Pets at Home Group'
    },
    {
        value: 'vetsnow',
        label: 'Vets Now'
    },
    {
        value: 'cvs',
        label: 'CVS'
    },
    {
        value: 'ivc',
        label: 'IVC'
    },
    {
        value: 'linnaeus',
        label: 'Linnaeus'
    },
    {
        value: 'medivet',
        label: 'Medivet'
    },
    {
        value: 'vetpartners',
        label: 'Vet Partners'
    },
    {
        value: 'vetdynamics',
        label: 'VetDynamics'
    },
    {
        value: 'xlvets',
        label: 'XL Vets'
    },
    {
        value: 'mwi_group',
        label: 'MWI Group'
    },
    {
        value: 'vetcel',
        label: 'Vetcel'
    },
    {
        value: 'vetshare',
        label: 'VetShare'
    },
    {
        value: 'vetsure',
        label: 'Vetsure'
    },
    {
        value: 'send_vbd',
        label: 'Send VBD'
    },
    {
        value: 'send_third',
        label: '3rd party'
    },
    {
        value: 'abc_name',
        label: 'ABC name'
    },
    {
        value: 'abc_answer',
        label: 'ABC answer'
    },
    {
        value: 'request_type',
        label: 'Request type'
    },
    {
        value: 'request_date',
        label: 'Request date'
    },
    {
        value: 'updated_date',
        label: 'Updated date'
    },
    {
        value: 'updated_by',
        label: 'Updated by'
    },
    {
        value: 'sendvt',
        label: 'Vet Times'
    },
    {
        value: 'sendvnt',
        label: 'VNT'
    },
    {
        value: 'sendvbj',
        label: 'VBJ'
    },
    {
        value: 'sendlivestock',
        label: 'Livestock'
    },
    {
        value: 'sendequine',
        label: 'Equine'
    },
    {
        value: 'training',
        label: 'Training'
    },
    {
        value: 'vs',
        label: 'VS'
    },
    {
        value: 'vn',
        label: 'VN'
    },
    {
        value: 'branch_vs',
        label: 'Branch VS'
    },
    {
        value: 'branch_vn',
        label: 'Branch VN'
    },
    {
        value: 'oralcare',
        label: 'Oral Care Kits'
    },
    {
        value: 'ock_request_date',
        label: 'OCK Request Date'
    },
    {
        value: 'oralcare_restock_1',
        label: 'Oral Care Restock 1'
    },
    {
        value: 'oralcare_restock_2',
        label: 'Oral Care Restock 2'
    },
    {
        value: 'oralcare_restock_3',
        label: 'Mars POS'
    },
    // {
    //     value: 'ock_req_name',
    //     label: 'OCK Req Name'
    // },
    // {
    //     value: 'ock_req_email',
    //     label: 'OCK Req Email'
    // }
    { value:'ock_req_name',label:"2015 OCK Req name"},
{value:'ock_req_email',label:"2015 OCK Req email"},
{value:'ock_req_name_2016',label:"2016 OCK Req name"},
{value:'ock_req_email_2016',label:"2016 OCK Req email"},
{value:'ock_req_name_2017',label:"2017 OCK Req name"},
{value:'ock_req_email_2017',label:"2017 OCK Req email"},
{value:'ock_req_name_2018',label:"2018 OCK Req name"},
{value:'ock_req_email_2018',label:"2018 OCK Req email"},
{value:'ock_req_name_2019',label:"2019 OCK Req name"},
{value:'ock_req_email_2019',label:"2019 OCK Req email"},
{value:'ock_req_name_2020',label:"2020 OCK Req name"},
{value:'ock_req_email_2020',label:"2020 OCK Req email"},
{value:'ock_req_name_2021',label:"2021 OCK Req name"},
{value:'ock_req_email_2021',label:"2021 OCK Req email"},
{value:'ock_req_name_2022',label:"2022 OCK Req name"},
{value:'ock_req_email_2022',label:"2022 OCK Req email"},
{value:'ock_req_name_2023',label:"2023 OCK Req name"},
{value:'ock_req_email_2023',label:"2023 OCK Req email"},
{value:'ock_req_name_2024',label:"2024 OCK Req name"},
{value:'ock_req_email_2024',label:"2024 OCK Req email"},
{value:'ooh',label:"OOH"},
]

export const title_suggestion = [
    { value: 'dr', label: 'Dr' },
    { value: 'mr', label: 'Mr' },
    { value: 'mrs', label: 'Mrs' },
    { value: 'ms', label: 'Ms' },
    { value: 'miss', label: 'Miss' },
    { value: 'mx', label: 'Mx' },
    { value: 'prof', label: 'Prof' },
    { value: 'major', label: 'Major' },
    { value: 'sir', label: 'Sir' },
    { value: 'brig', label: 'Brig' },
    { value: 'capt', label: 'Capt' },
    { value: 'the', label: 'The' },
]

export const jobtype_suggestion = [

    { value: 'z', label: 'Senior Partner' },
    { value: 'a', label: 'Buyer' },
    { value: 'b', label: 'Partner' },
    { value: 'c', label: 'Veterinary Surgeon' },
    { value: 'x', label: 'Locum Veterinary Surgeon' },
    { value: 's', label: 'Student Vet' },
    { value: 'e', label: 'Head Nurse' },
    { value: 'd', label: 'Nurse' },
    { value: 'y', label: 'Locum Nurse' },
    { value: 'g', label: 'Student Veterinary Nurse' },
    { value: 'r', label: 'VNA' },
    { value: 'p', label: 'Practice Manager' },
    { value: 'f', label: 'Admin' },
    { value: 'h', label: 'Reception' },
    { value: 'j', label: 'Recruiter' },
]

export const species_suggestion = [
    { value: 'd', label: 'Dairy Cattle' },
    { value: 'b', label: 'Beef Cattle' },
    { value: 't', label: 'Poultry' },
    { value: 's', label: 'Sheep, Goats' },
    { value: 'g', label: 'Birds' },
    { value: 'c', label: 'Dogs' },
    { value: 'f', label: 'Cats' },
    { value: 'p', label: 'Pigs' },
    { value: 'h', label: 'Horses' },
    { value: 'x', label: 'Reptiles' },
    { value: 'e', label: 'Rodents' },
    { value: 'z', label: 'Zoo, Wildlife, Fish' },
    { value: 'r', label: 'Rabbits' }
]

export const location_suggestions = [
    { value: 'd', label: 'Dairy Cattle' },
    { value: 'b', label: 'Beef Cattle' },
    { value: 't', label: 'Poultry' },
    { value: 's', label: 'Sheep, Goats' },
    { value: 'g', label: 'Birds' },
    { value: 'c', label: 'Dogs' },
    { value: 'f', label: 'Cats' },
    { value: 'p', label: 'Pigs' },
    { value: 'h', label: 'Horses' },
    { value: 'x', label: 'Reptiles' },
    { value: 'e', label: 'Rodents' },
    { value: 'z', label: 'Zoo, Wildlife, Fish' },
    { value: 'r', label: 'Rabbits' }
]

export const salary_band_suggestions = [
    { label: 'Up to £10,000', value: 'up to 10000' },
    { label: '£10,000 - £14,999', value: '10000 - 14999' },
    { label: '£15,000 - £19,999', value: '15000 - 19999' },
    { label: '£20,000 - £24,999', value: '20000 - 24999' },
    { label: '£25,000 - £29,999', value: '25000 - 29999' },
    { label: '£30,000 - £39,999', value: '30000 - 39999' },
    { label: '£40,000 - £49,999', value: '40000 - 49999' },
    { label: '£50,000 - £59,999', value: '50000 - 59999' },
    { label: '£60,000 - £69,999', value: '60000 - 69999' },
    { label: '£70,000 - £79,999', value: '70000 - 79999' },
    { label: '£80,000 - £89,999', value: '80000 - 89999' },
    { label: '£90,000 - £99,999', value: '90000 - 99999' },
    { label: '£100,000 or more', value: '100000 or more' },
];

export const drive = [
    { label: 'Drive Home', value: 'home' },
    { label: 'Drive Work', value: 'work' },
    { label: 'SM Drive', value: 'smr' },
    { label: 'SM Crow', value: 'smrCrow' },
    { label: 'PM Drive', value: 'pmr' },
    { label: 'PM Crow', value: 'pmrCrow' }
]

export const operators = [
    { value: 'LIKE', label: 'Contains' },
    { value: ' LIKE', label: 'Free Text - Contains' },
    { value: 'NOT LIKE', label: 'Does not contain' },
    { value: ' NOT LIKE', label: 'Free Text - Does not contain' },
    { value: 'START', label: 'Starts with' },
    { value: 'END', label: 'Ends with' },
    { value: '=', label: 'Matches' },
    { value: ' =', label: 'Free Text - Matches' },
    { value: '<>', label: 'Does not match' },
    { value: ' <>', label: 'Free Text - Does not match' },
    { value: 'IS NULL', label: 'Is blank/null' },
    { value: 'IS NOT NULL', label: 'Is not blank/null' },
    { value: '>', label: 'More than' },
    { value: '<', label: 'Less than' },
    { value: 'BETWEEN', label: 'Date between' },
    { value: 'NOT BETWEEN', label: 'Date not between' },
];

export const practice_vet = [{
    value: 'title',
    label: 'Title',

},
{
    value: 'vet.id as VID',
    label: 'ID'
},
{
    value: 'initials',
    label: 'Initials'
},
{
    value: 'forename',
    label: 'Forename',

},
{
    value: 'middlenames',
    label: 'Middle names'
},
{
    value: 'surname',
    label: 'Surname'
},
{
    value: 'maiden',
    label: 'Maiden name'
},
{
    value: 'gender',
    label: 'Gender'
},
{
    value: 'working_hours',
    label: 'Working Hours'
},
{
    value: 'v_job_types',
    label: 'Job types',

},
{
    value: 'v_job_types_o',
    label: 'Job types Other'
},
{
    value: 'u_suffix',
    label: 'Suffix'
},
{
    value: 'u_cert',
    label: 'Certs and Dips'
},
{
    value: 'u_uni',
    label: 'University'
},
{
    value: 'u_year',
    label: 'Uni Year'
},
{
    value: 'u_country',
    label: 'Uni Country'
},
{
    value: 'v_species',
    label: 'Species',

},
{
    value: 'v_species_o',
    label: 'Species Other'
},
{
    value: 'v_rcvsnum',
    label: 'RCVS Number'
},
{
    value: 'v_vpma',
    label: 'VMG'
},
{
    value: 'v_address_1',
    label: 'Address 1'
},
{
    value: 'v_address_2',
    label: 'Address 2'
},
{
    value: 'v_address_3',
    label: 'Address 3'
},
{
    value: 'v_town',
    label: 'Town'
},
{
    value: 'v_county',
    label: 'County'
},
{
    value: 'v_postcode',
    label: 'Postcode'
},
{
    value: 'v_country',
    label: 'Country'
},
{
    value: 'v_tel',
    label: 'Tel'
},
{
    value: 'v_email',
    label: 'Primary email'
},
{
    value: 'v_work_email',
    label: 'Secondary email'
},
    {value: 'primary_pma', label: 'Primary PMA'},
    {value: 'secondary_pma', label: 'Secondary PMA'},
{
    value: 'v_sms',
    label: 'SMS'
},
{
    value: 'sso_email',
    label: 'SSO Email'
},
{
    value: 'lvf',
    label: 'LVF'
},
{
    value: 'v_pma',
    label: 'PMA'
},
{
    value: 'v_sdm',
    label: 'Direct mail'
},
{
    value: 'v_svt',
    label: 'Vet Times'
},
{
    value: 'v_svnt',
    label: 'VN Times'
},
{
    value: 'v_svbj',
    label: 'VBJ'
},
{
    value: 'v_svtl',
    label: 'Livestock'
},
{
    value: 'v_svte',
    label: 'Equine'
},
{
    value: 'v_notes',
    label: 'Notes'
},
{
    value: 'v_svt_request',
    label: 'Request type Vet Times'
},
{
    value: 'v_svt_request_date',
    label: 'Request date Vet Times'
},
{
    value: 'v_svt_abc_name',
    label: 'Vet Times ABC name'
},
{
    value: 'v_svt_abc_answer',
    label: 'Vet Times ABC answer'
},
{
    value: 'v_svnt_request',
    label: 'Request type VN Times'
},
{
    value: 'v_svnt_request_date',
    label: 'Request date VN Times'
},
{
    value: 'v_svnt_abc_name',
    label: 'VN Times ABC name'
},
{
    value: 'v_svnt_abc_answer',
    label: 'VN Times ABC answer'
},
{
    value: 'v_svbj_request',
    label: 'Request type VBJ'
},
{
    value: 'v_svbj_request_date',
    label: 'Request date VBJ'
},
{
    value: 'v_svbj_abc_name',
    label: 'VBJ ABC name'
},
{
    value: 'v_svbj_abc_answer',
    label: 'VBJ ABC answer'
},
{
    value: 'v_sevbd_request',
    label: 'VBD Request type'
},
{
    value: 'v_sevbd_request_date',
    label: 'VBD Request date'
},
{
    value: 'v_s3rd_request',
    label: '3rd party Request type'
},
{
    value: 'v_s3rd_request_date',
    label: '3rd party Request date'
},
{
    value: 'v_vnews_d_request',
    label: 'Vettimes d Request type'
},
{
    value: 'v_vnews_d_request_date',
    label: 'Vettimes d Request date'
},
{
    value: 'v_vnews_request',
    label: 'Vettimes w Request type'
},
{
    value: 'v_vnews_request_date',
    label: 'Vettimes w Request date'
},
{
    value: 'v_vjnw_request',
    label: 'Vet Times Jobs w Request type'
},
{
    value: 'v_vjnw_request_date',
    label: 'Vet Times Jobs w Request date'
},
{
    value: 'v_vjnm_request',
    label: 'Vet Times Jobs m Request type'
},
{
    value: 'v_vjnm_request_date',
    label: 'Vet Times Jobs m Request date'
},
{
    value: 'v_vt_jobs_marketing_request',
    label: 'VT Jobs Marketing Request Type'
},
{
    value: 'v_vt_jobs_marketing_request_date',
    label: 'VT Jobs Marketing Request Date'
},
{
    value: 'vn_monthly_newsletter_request',
    label: 'VN Newsletter m Request Type'
},
{
    value: 'vn_monthly_newsletter_request_date',
    label: 'VN Newsletter m Request Date'
},
{
    value: 'updatedby',
    label: 'Updated by'
},
{
    value: 'date',
    label: 'Updated date'
},
{
    value: 'v_sevbd',
    label: 'VBD email'
},
{
    value: 'v_s3rd',
    label: '3rd party'
},
{
    value: 'v_vnews_d',
    label: 'Vettimes d'
},
{
    value: 'v_vnews',
    label: 'Vettimes w'
},
{
    value: 'v_vjnw',
    label: 'Vet Times Jobs w'
},
{
    value: 'v_vjnm',
    label: 'Vet Times Jobs m'
},
{
    value: 'v_vt_jobs_marketing',
    label: 'VT Jobs Marketing'
},
{
    value: 'vn_monthly_newsletter',
    label: 'VN Newsletter m'
},
{
    value: 'v_sso',
    label: 'SSO'
},
{
    value: 'login_at',
    label: 'Last logged at'
},
{
    value: 'vtapp_sso',
    label: 'VT app SSO'
},
{
    value: 'vtapp_date',
    label: 'VT app Login'
},
{
    value: 'cpd_sso',
    label: 'CPD SSO'
},
{
    value: 'cpd_date',
    label: 'CPD login'
},
{
    value: 'marketplace_sso',
    label: 'Marketplace SSO'
},
{
    value: 'marketplace_date',
    label: 'Marketplace login'
},
{
    value: 'vt_sso',
    label: 'VT SSO'
},
{
    value: 'vt_date',
    label: 'VT login'
},
{
    value: 'vtjobs_sso',
    label: 'VT jobs SSO'
},
{
    value: 'vtjobs_date',
    label: 'VT jobs login'
},
{
    value: 'v_interests',
    label: 'Interests'
},
{
    value: 'ind_type',
    label: 'Sub Type'
},
{
    value: 'ind_vt_start',
    label: 'Vet Times Start date'
},
{
    value: 'ind_vt_end',
    label: 'Vet Times End date'
},
{
    value: 'ind_vt_cost',
    label: 'Vet Times Cost'
},
{
    value: 'ind_vn_start',
    label: 'VN Times Start date'
},
{
    value: 'ind_vn_end',
    label: 'VN Times End date'
},
{
    value: 'ind_vn_cost',
    label: 'VN Times Cost'
},
{
    value: 'ind_vbj_start',
    label: 'VBJ Start date'
},
{
    value: 'ind_vbj_end',
    label: 'VBJ End date'
},
{
    value: 'ind_vbj_cost',
    label: 'VBJ Cost'
},
{
    value: 'ind_agent',
    label: 'Agent'
},
{
    value: 'history',
    label: 'History'
},
{
    value: 'p_updated',
    label: 'Profile Updated'
},
{
    value: 'p_date',
    label: 'Profile Update Date'
},
{
    value: 'academy_sso',
    label: 'Academy SSO'
},
{
    value: 'academy_date',
    label: 'Academy login'
},
{
    value: 'residence',
    label: 'Country Residence'
},
// {
//     value: 'phone_number',
//     label: 'SM Phone Number'
// },
// {
//     value: 'contact_method',
//     label: 'SM Contact Method'
// },
// {
//     value: 'rtw',
//     label: 'SM Right to work in UK'
// },
// {
//     value: 'rcvs_2',
//     label: 'SM RCVS Skills Match'
// },
// {
//     value: 's_postcode',
//     label: 'SM Postcode'
// },
// {
//     value: 'sm_address_1',
//     label: 'SM Address 1'
// },
// {
//     value: 'sm_address_2',
//     label: 'SM Address 2'
// },
// {
//     value: 'sm_address_3',
//     label: 'SM Address 3'
// },
// {
//     value: 'sm_town',
//     label: 'SM Town'
// },
// {
//     value: 'sm_county',
//     label: 'SM County'
// },
// {
//     value: 'distance',
//     label: 'SM Distance'
// },
// {
//     value: 'relocate',
//     label: 'SM Relocate'
// },
// {
//     value: 'prof',
//     label: 'SM Job Type'
// },
// {
//     value: 'pmsother',
//     label: 'SM PMS Other'
// },
// {
//     value: 'areas',
//     label: 'SM Locations'
// },
// {
//     value: 'position',
//     label: 'SM Position'
// },
// {
//     value: 'species_type',
//     label: 'SM Species Type'
// },
// {
//     value: 'pms',
//     label: 'SM PMS'
// },
// {
//     value: 'main_experience',
//     label: 'SM Main Experience'
// },
// {
//     value: 'consultation_general',
//     label: 'SM Consultation General'
// },
// {
//     value: 'consultation_10min',
//     label: 'SM Consultation 10 mins'
// },
// {
//     value: 'castration_dog_cat',
//     label: 'SM Castration Dog and Cat'
// },
// {
//     value: 'castration_rabbit',
//     label: 'SM Castration Rabbit'
// },
// {
//     value: 'spay_dog_cat',
//     label: 'SM Spay Dog and Cat'
// },
// {
//     value: 'spay_rabbit',
//     label: 'SM Spay Rabbit'
// },
// {
//     value: 'c_section',
//     label: 'SM C Section'
// },
// {
//     value: 'extractions_dental',
//     label: 'SM Extractions Dental'
// },
// {
//     value: 'ex_lap',
//     label: 'SM Ex lap'
// },
// {
//     value: 'amputation_limb',
//     label: 'SM Amputation Limb'
// },
// {
//     value: 'removal_lump',
//     label: 'SM Removal Lump'
// },
// {
//     value: 'radiograph_interpretation',
//     label: 'SM Radiograph Interpretation'
// },
// {
//     value: 'radiograph_taking',
//     label: 'SM Radiograph Taking'
// },
// {
//     value: 'lab_interpreting_results',
//     label: 'SM Interpreting Lab Results'
// },
// {
//     value: 'emergency_medicine',
//     label: 'SM Emergency Medicine'
// },
// {
//     value: 'ultrasonography',
//     label: 'SM Ultrasonography'
// },
// {
//     value: 'consultation_euthanasia',
//     label: 'SM Consultation Euthanasia'
// },
// {
//     value: 'surgery_orthaepedic',
//     label: 'SM Surgery Orthaepedic'
// },
// {
//     value: 'investigation_dermatology',
//     label: 'SM Investigation Dermatology'
// },
// {
//     value: 'ophthalmology',
//     label: 'SM Ophthalmology'
// },
// {
//     value: 'investigation_cardiology',
//     label: 'SM Investigation Cardiology'
// },
// {
//     value: 'investigation_neurology',
//     label: 'SM Investigation Neurology'
// },
// {
//     value: 'cat_spay',
//     label: 'SM Cat Spay'
// },
// {
//     value: 'bitch_spay',
//     label: 'SM Bitch Spay'
// },
// {
//     value: 'clinical_examination',
//     label: 'SM Clinical Examination'
// },
// {
//     value: 'fertility_work',
//     label: 'SM Fertility Work'
// },
// {
//     value: 'lda_surgery',
//     label: 'SM LDA Surgery'
// },
// {
//     value: 'caesareans',
//     label: 'SM Caesareans'
// },
// {
//     value: 'blood_sampling',
//     label: 'SM Blood Sampling'
// },
// {
//     value: 'tb_testing',
//     label: 'SM TB Testing'
// },
// {
//     value: 'tb_per_day',
//     label: 'SM TB per day'
// },
// {
//     value: 'farm_experience',
//     label: 'SM Farm Experience'
// },
// {
//     value: 'investigation_lameness',
//     label: 'SM Investigation Lameness'
// },
// {
//     value: 'dentistry',
//     label: 'SM Dentistry'
// },
// {
//     value: 'investigation_pre_purchase',
//     label: 'SM Investigation Pre Purchase'
// },
// {
//     value: 'stud_work',
//     label: 'SM Stud Work'
// },
// {
//     value: 'al_work',
//     label: 'SM Al Work'
// },
// {
//     value: 'anaesthesia',
//     label: 'SM Anaesthesia'
// },
// {
//     value: 'radiology',
//     label: 'SM Radiology'
// },
// {
//     value: 'consultation_nurse',
//     label: 'SM Consultaion Nurse'
// },
// {
//     value: 'blood_samples_taking',
//     label: 'SM Blood Samples Taking'
// },
// {
//     value: 'catheter_insertion',
//     label: 'SM Catheter Insertion'
// },
// {
//     value: 'anaesthetics_monitoring',
//     label: 'SM Anaesthetics Monitoring'
// },
// {
//     value: 'animal_restraint',
//     label: 'SM Animal Restaint'
// },
// {
//     value: 'reception_skills',
//     label: 'SM Reception Skills'
// },
// {
//     value: 'schedule_3_procedures',
//     label: 'SM Schedule 3 procedures'
// },
// {
//     value: 'cv',
//     label: 'SM CV'
// },
// {
//     value: 'sm_profile',
//     label: 'SM Profile'
// },
// {
//     value: 'sm_date',
//     label: 'SM Profile Date'
// },
// {
//     value: 'sm_salary_band',
//     label: 'SM Salary band'
// },
// {
//     value: 'sm_next_role',
//     label: 'SM Preferred next role'
// },
{
    value: 'companion_animal_subs',
    label: 'CPD companion animal subscription'
},
{
    value: 'exotics_subs',
    label: 'CPD exotics subscription'
},
{
    value: 'equine_subs',
    label: 'CPD equine subscription'
},
{
    value: 'livestock_subs',
    label: 'CPD livestock subscription'
},
{
    value: 'business_subs',
    label: 'CPD business subscription'
},
{
    value: 'well_being_subs',
    label: 'CPD well-being subscription'
},
{
    value: 'para_date1',
    label: 'CPD Parasitology 1st viewed'
},
{
    value: 'para_date2',
    label: 'CPD Parasitology last viewed'
},
{
    value: 'pman_date1',
    label: 'CPD Pain management 1st viewed'
},
{
    value: 'pman_date2',
    label: 'CPD Pain management last viewed'
},
{
    value: 'derma_date1',
    label: 'CPD Dermatology 1st viewed'
},
{
    value: 'derma_date2',
    label: 'CPD Dermatology last viewed'
},
{
    value: 'onco_date1',
    label: 'CPD Oncology 1st viewed'
},
{
    value: 'onco_date2',
    label: 'CPD Oncology last viewed'
},
{
    value: 'spets_date1',
    label: 'CPD Senior pets 1st viewed'
},
{
    value: 'spets_date2',
    label: 'CPD Senior Pets last viewed'
},
{
    value: 'gastro_date1',
    label: 'CPD Gastrointestine Disorders 1st viewed'
},
{
    value: 'gastro_date2',
    label: 'CPD Gastrointestine Disorders last viewed'
},
{
    value: 'cardio_date1',
    label: 'CPD Cardiology 1st viewed'
},
{
    value: 'cardio_date2',
    label: 'CPD Cardiology last viewed'
},
{
    value: 'nutrition_date1',
    label: 'CPD Nutrition 1st viewed'
},
{
    value: 'nutrition_date2',
    label: 'CPD Nutrition last viewed'
},
{   value: 'ecc_date1',
        label: 'CPD Emergency and critical care 1st viewed'
},
{   value: 'ecc_date2',
    label: 'CPD Emergency and critical care last viewed'
},
{   value: 'neurology_date1',
    label: 'CPD Neurology 1st viewed'
},
{   value: 'neurology_date2',
    label: 'CPD Neurology last viewed'
},
{   value: 'urology_date1',
    label: 'CPD Urology 1st viewed'
},
{   value: 'urology_date2',
    label: 'CPD Urology last viewed'
},
{   value: 'alabama_rot_date1',
    label: 'CPD Alabama rot 1st viewed'
},
{   value: 'alabama_rot_date2',
    label: 'CPD Alabama rot last viewed'
},
{   value: 'fip_date1',
    label: 'CPD FIP 1st viewed'
},
{   value: 'fip_date2',
    label: 'CPD FIP last viewed'
},
{
    value:'wb_teams_date1',
    label:'CPD Well-being for your team 1st viewed'
},
{
    value:'wb_teams_date2',
    label:'CPD Well-being for your team last viewed'
},
{
    value:'wb_you_date1',
    label:'CPD Well-being for you 1st viewed'
},
{
    value:'wb_you_date2',
    label:'CPD Well-being for you last viewed'
},
    {
        value: "endocrinology_date1",
        label: "Endocrinology 1st viewed"
    },
    {
        value: "endocrinology_date2",
        label: "Endocrinology last viewed"
    },
    {
        value: "anaesthesia_date1",
        label: "Anaesthesia and analgesia 1st viewed"
    },
    {
        value: "anaesthesia_date2",
        label: "Anaesthesia and analgesia last viewed"
    },
    {
        value: "ophthalmology_date1",
        label: "Ophthalmology 1st viewed"
    },
    {
        value: "ophthalmology_date2",
        label: "Ophthalmology last viewed"
    },
    {
        value: "oral_care_date1",
        label: "Oral care 1st viewed"
    },
    {
        value: "oral_care_date2",
        label: "Oral care last viewed"
    },
    {
        value: "wound_management_date1",
        label: "Wound management 1st viewed"
    },
    {
        value: "wound_management_date2",
        label: "Wound management last viewed"
    },
    {
        value: "birds_date1",
        label: "Birds 1st viewed"
    },
    {
        value: "birds_date2",
        label: "Birds last viewed"
    },
    {
        value: "obesity_date1",
        label: "Obesity 1st viewed"
    },
    {
        value: "obesity_date2",
        label: "Obesity last viewed"
    },
    {
        value: "imaging_date1",
        label: "Imaging 1st viewed"
    },
    {
        value: "imaging_date2",
        label: "Imaging last viewed"
    },
    {
        value: "reproduction_date1",
        label: "Reproduction 1st viewed"
    },
    {
        value: "reproduction_date2",
        label: "Reproduction last viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea 1st viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea last viewed"
    },
    {
        value: "lamb_date1",
        label: "Lamb health 1st viewed"
    },
    {
        value: "lamb_date2",
        label: "Lamb health last viewed"
    },
{
    value: 'rabbits_date1',
    label: 'CPD Rabbits 1st viewed'
},
{
    value: 'rabbits_date2',
    label: 'CPD Rabbits last viewed'
},
{
    value: 'rodents_date1',
    label: 'CPD Rodents 1st viewed'
},
{
    value: 'rodents_date2',
    label: 'CPD Rodents last viewed'
},
{
    value: 'lameness_date1',
    label: 'CPD Lameness 1st viewed'
},
{
    value: 'lameness_date2',
    label: 'CPD Lameness last viewed'
},
{
    value: 'equine_respiratory_date1',
    label: 'CPD Respiratory diseases (equine) 1st viewed'
},
{
    value: 'equine_respiratory_date2',
    label: 'CPD Respiratory diseases (equine) last viewed'
},
{
    value: 'phealth_date1',
    label: 'CPD Preventive Health 1st viewed'
},
{
    value: 'phealth_date2',
    label: 'CPD Preventive Health last viewed'
},
{
    value: 'calf_health_date1',
    label: 'CPD Calf health 1st viewed'
},
{
    value: 'calf_health_date2',
    label: 'CPD Calf health last viewed'
},
{
    value: 'mastitis_date1',
    label: 'CPD Mastitis 1st viewed'
},
{
    value: 'mastitis_date2',
    label: 'CPD Mastitis last viewed'
},
{
    value: 'livestock_respiratory_date1',
    label: 'CPD Respiratory diseases (livestock) 1st viewed'
},
{
    value: 'livestock_respiratory_date2',
    label: 'CPD Respiratory diseases (livestock) last viewed'
},
{
    value: 'parasites_date1',
    label: 'CPD Parasites 1st viewed'
},
{
    value: 'parasites_date2',
    label: 'CPD Parasites last viewed'
},
{
    value: 'finance_date1',
    label: 'CPD Finance 1st viewed'
},
{
    value: 'finance_date2',
    label: 'CPD Finance last viewed'
},
{
    value: 'client_care_date1',
    label: 'CPD Client care 1st viewed'
},
{
    value: 'client_care_date2',
    label: 'CPD Client care last viewed'
},
{
    value: 'technology_date1',
    label: 'CPD Technology 1st viewed'
},
{
    value: 'technology_date2',
    label: 'CPD Technology last viewed'
},
{
    value: 'train_and_develop_date1',
    label: 'CPD Training and development 1st viewed'
},
{
    value: 'train_and_develop_date2',
    label: 'CPD Training and development last viewed'
},
{
    value: 'office',
    label: 'Office'
},
{
    value: 'practice.id PID',
    label: 'ID'
},
{
    value: 'name',
    label: 'Name'
},
{
    value: 'address_1',
    label: 'Address 1'
},
{
    value: 'address_2',
    label: 'Address 2'
},
{
    value: 'address_3',
    label: 'Address 3'
},
{
    value: 'town',
    label: 'Town'
},
{
    value: 'county',
    label: 'County'
},
{
    value: 'postcode',
    label: 'Postcode'
},
{
    value: 'country',
    label: 'Country'
},
{
    value: 'brick',
    label: 'Brick'
},
{
    value: 'type',
    label: 'Type'
},
{
    value: 'tel',
    label: 'Tel.'
},
{
    value: 'direct_mail',
    label: 'Direct Mail'
},
{
    value: 'fax',
    label: 'Fax'
},
{
    value: 'website',
    label: 'Web'
},
{
    value: 'email',
    label: 'Email'
},
{
    value: 'wholesaler',
    label: 'Wholesaler'
},
{
    value: 'pramansys',
    label: 'Pra Man Sys'
},
{
    value: 'referral',
    label: 'Referral'
},
{
    value: 'no_staff_pra',
    label: 'No Staff Prac'
},
{
    value: 'corporate',
    label: 'Corporate'
},
{
    value: 'buying',
    label: 'Buying'
},
{
    value: 'rcvs_acc',
    label: 'RCVS Accred'
},
{
    value: 'pethome',
    label: 'Pets at Home Group'
},
{
    value: 'vetsnow',
    label: 'Vets Now'
},
{
    value: 'cvs',
    label: 'CVS'
},
{
    value: 'ivc',
    label: 'IVC'
},
{
    value: 'linnaeus',
    label: 'Linnaeus'
},
{
    value: 'medivet',
    label: 'Medivet'
},
{
    value: 'vetpartners',
    label: 'Vet Partners'
},
{
    value: 'vetdynamics',
    label: 'VetDynamics'
},
{
    value: 'xlvets',
    label: 'XL Vets'
},
{
    value: 'mwi_group',
    label: 'MWI Group'
},
{
    value: 'vetcel',
    label: 'Vetcel'
},
{
    value: 'vetshare',
    label: 'VetShare'
},
{
    value: 'vetsure',
    label: 'Vetsure'
},

{
    value: 'send_vbd',
    label: 'Send VBD'
},
{
    value: 'send_third',
    label: '3rd party'
},
{
    value: 'abc_name',
    label: 'ABC name'
},
{
    value: 'abc_answer',
    label: 'ABC answer'
},
{
    value: 'request_type',
    label: 'Request type'
},
{
    value: 'request_date',
    label: 'Request date'
},
{
    value: 'updated_date',
    label: 'Updated date'
},
{
    value: 'updated_by',
    label: 'Updated by'
},
{
    value: 'sendvt',
    label: 'Practice VT'
},
{
    value: 'sendvnt',
    label: 'Practice VNT'
},
{
    value: 'sendvbj',
    label: 'Practice VBJ'
},
{
    value: 'sendlivestock',
    label: 'Livestock'
},
{
    value: 'sendequine',
    label: 'Equine'
},
{
    value: 'training',
    label: 'Training'
},
{
    value: 'vs',
    label: 'VS'
},
{
    value: 'vn',
    label: 'VN'
},
{
    value: 'branch_vs',
    label: 'Branch VS'
},
{
    value: 'branch_vn',
    label: 'Branch VN'
},
{
    value: 'oralcare',
    label: 'Oral Care Kits'
},
{
    value: 'ock_request_date',
    label: 'OCK Request Date'
},
{
    value: 'oralcare_restock_1',
    label: 'Oral Care Restock 1'
},
{
    value: 'oralcare_restock_2',
    label: 'Oral Care Restock 2'
},
{
    value: 'oralcare_restock_3',
    label: 'Mars POS'
},
// {
//     value: 'ock_req_name',
//     label: 'OCK Req Name'
// },
// {
//     value: 'ock_req_email',
//     label: 'OCK Req Email'
// }
{ value:'ock_req_name',label:"2015 OCK Req name"},
{value:'ock_req_email',label:"2015 OCK Req email"},
{value:'ock_req_name_2016',label:"2016 OCK Req name"},
{value:'ock_req_email_2016',label:"2016 OCK Req email"},
{value:'ock_req_name_2017',label:"2017 OCK Req name"},
{value:'ock_req_email_2017',label:"2017 OCK Req email"},
{value:'ock_req_name_2018',label:"2018 OCK Req name"},
{value:'ock_req_email_2018',label:"2018 OCK Req email"},
{value:'ock_req_name_2019',label:"2019 OCK Req name"},
{value:'ock_req_email_2019',label:"2019 OCK Req email"},
{value:'ock_req_name_2020',label:"2020 OCK Req name"},
{value:'ock_req_email_2020',label:"2020 OCK Req email"},
{value:'ock_req_name_2021',label:"2021 OCK Req name"},
{value:'ock_req_email_2021',label:"2021 OCK Req email"},
{value:'ock_req_name_2022',label:"2022 OCK Req name"},
{value:'ock_req_email_2022',label:"2022 OCK Req email"},
{value:'ock_req_name_2023',label:"2023 OCK Req name"},
{value:'ock_req_email_2023',label:"2023 OCK Req email"},
    {value:'ock_req_name_2024',label:"2024 OCK Req name"},
    {value:'ock_req_email_2024',label:"2024 OCK Req email"},
{value:'v_registration_email',label:"VN HH Registration email address"},
{value:'v_registration',label:"VN HH Registration"},
{value:'v_attended',label:"VN HH Attended"},
{value:'v_congresses',label:"Congresses"},
{value:'v_vet_panel',label:"Vet Panel"},
{value:'v_vn_panel',label:"VN Panel"},
{value:'v_practice_manager_panel',label:"Practice Manager Panel"},
{value:'ooh',label:"OOH"},
{value:'v_email_opt_in',label:"Email Opt In"},
    {value: 'pm_name', label: 'PM name'},
    {value: 'pm_email', label: 'PM email'},
    {value: 'pm_phone', label: 'PM phone'},
    {value: 'pm_next_role', label: 'PM next role'},
    {value: 'pm_postcode', label: 'PM postcode'},
    {value: 'pm_address_1', label: 'PM address_1'},
    {value: 'pm_address_2', label: 'PM address_2'},
    {value: 'pm_address_3', label: 'PM address_3'},
    {value: 'pm_town', label: 'PM town'},
    {value: 'pm_distance', label: 'PM distance'},
    {value: 'pm_relocate', label: 'PM relocate'},
    {value: 'pm_working_outside', label: 'PM working outside'},
    {value: 'pm_looking_for_work', label: 'PM looking for work'},
    {value: 'pm_ideal_rota', label: 'PM ideal rota'},
    {value: 'pm_salary_band', label: 'PM salary band'},
    {value: 'pm_year_rcvs', label: ' PM year rcvs'},
    {value: 'pm_ideal_role', label: 'PM ideal role'},
    {value: 'pm_important_next_role', label: 'PM important next role'},
    {value: 'pm_university', label: 'PM university'},
    {value: 'pm_county', label: 'PM county'},
    {value: 'pm_country', label: 'PM country'},
    {value: 'pm_clinical_interest', label: 'PM clinical interest'},
    {value: 'pm_skill_develop', label: 'PM skill develop'},
    {value: 'pm_work_in_uk', label: 'PM work in uk'},
    {value: 'pm_areas', label: 'PM areas'},
    {value: 'pm_outside_areas', label: 'PM outside areas'},
    {value: 'pm_year_qualification', label: 'PM year qualification'},
    {value: 'pm_cv', label: 'PM cv'},
    {value: 'pm_profile', label: 'PM profile'},
    {value: 'pm_job_type', label: 'PM job type'},
    {value: 'pm_profile_created_date', label: 'PM profile created date'},
    {value: 'pm_profile_modified', label: 'PM profile modified'},
    {value: 'pm_interested', label: 'PM opt in'},
    {value: 'pm_interest_date', label: 'PM opt in date'},
    {value: 'pm_soft_opt_in', label: 'PM soft opt-in'},
    {value: 'pm_soft_opt_in_date', label: 'PM soft opt-in date'},
    {value: 'record_created', label: 'Created at'},


//        Digital Edition Section Fields
    {value: 'v_svtl_digi', label: 'Livestock Vet Times Digi'},
    {value: 'v_svte_digi', label: 'Equine Vet Times Digi'},
    {value: 'v_svt_digi', label: 'Vet Times Digi'},
    {value: 'v_svt_digi_request', label: 'Request type Vet Times Digi'},
    {value: 'v_svt_digi_request_date', label: 'Request date Vet Times Digi'},
    {value: 'v_svt_digi_abc_name', label: 'Vet Times Digi ABC name'},
    {value: 'v_svt_digi_abc_answer', label: 'Vet Times Digi ABC answer'},

    {value: 'v_svnt_digi', label: 'VN Times Digi'},
    {value: 'v_svnt_digi_request', label: 'Request type VN Times Digi'},
    {value: 'v_svnt_digi_request_date', label: 'Request date VN Times Digi'},
    {value: 'v_svnt_digi_abc_name', label: 'VN Times Digi ABC name'},
    {value: 'v_svnt_digi_abc_answer', label: 'VN Times Digi ABC answer'},

    {value: 'v_svbj_digi', label: 'VBJ Digi'},
    {value: 'v_svbj_digi_request', label: 'Request type VBJ Digi'},
    {value: 'v_svbj_digi_request_date', label: 'Request date VBJ Digi'},
    {value: 'v_svbj_digi_abc_name', label: 'VBJ Digi ABC name'},
    {value: 'v_svbj_digi_abc_answer', label: 'VBJ Digi ABC answer'},

    {value: 'ind_vt_digi_start', label: 'Vet Times Digi Start date'},
    {value: 'ind_vt_digi_end', label: 'Vet Times Digi End date'},
    {value: 'ind_vt_digi_cost', label: 'Vet Times Digi Cost'},

    {value: 'ind_vn_digi_start', label: 'VN Times Digi Start date'},
    {value: 'ind_vn_digi_end', label: 'VN Times Digi End date'},
    {value: 'ind_vn_digi_cost', label: 'VN Times Digi Cost'},

    {value: 'ind_vbj_digi_start', label: 'VBJ Digi Start date'},
    {value: 'ind_vbj_digi_end', label: 'VBJ Digi End date'},
    {value: 'ind_vbj_digi_cost', label: 'VBJ Digi Cost'},
]

export const vet_export_builder = [

    {
        value: 'vet.id as VID',
        label: 'ID'
    },
    {
        value: 'title',
        label: 'Title',

    },
    {
        value: 'initials',
        label: 'Initials'
    },
    {
        value: 'forename',
        label: 'Forename'
    },
    {
        value: 'middlenames',
        label: 'Middle names'
    },
    {
        value: 'surname',
        label: 'Surname'
    },
    {
        value: 'maiden',
        label: 'Maiden name'
    },
    {
        value: 'gender',
        label: 'Gender'
    },
    {
        value: 'working_hours',
        label: 'Working Hours'
    },
    {
        value: 'v_sms',
        label: 'SMS'
    },
    {
        value: 'v_email_opt_in',
        label: 'Email Opt In'
    },

    {
        value: 'separate-heading',
        label: 'Roles'
    },
    {
            value: 'v_job_types',
            label: 'Job types',

    },
        {
            value: 'v_job_types_o',
            label: 'Job types Other'
        },
        {
            value: 'u_suffix',
            label: 'Suffix'
        },
        {
            value: 'u_cert',
            label: 'Certs and Dips'
        },
        {
            value: 'u_uni',
            label: 'University'
        },
        {
            value: 'u_year',
            label: 'Uni Year'
        },
        {
            value: 'u_country',
            label: 'Uni Country'
        },
        {
            value: 'v_species',
            label: 'Species',

        },
        {
            value: 'v_species_o',
            label: 'Species Other'
        },
        {
            value: 'v_rcvsnum',
            label: 'RCVS Number'
        },
        {
            value: 'v_vpma',
            label: 'VMG'
        },

        {
            value : 'separate-heading',
            label: 'Contact'
        },
        {
            value: 'v_address_1',
            label: 'Address 1'
        },
        {
            value: 'v_address_2',
            label: 'Address 2'
        },
        {
            value: 'v_address_3',
            label: 'Address 3'
        },
        {
            value: 'v_town',
            label: 'Town'
        },
        {
            value: 'v_county',
            label: 'County'
        },
        {
            value: 'v_postcode',
            label: 'Postcode'
        },
        {
            value: 'v_country',
            label: 'Country'
        },
        {
            value: 'v_tel',
            label: 'Tel'
        },
        {
            value: 'v_email',
            label: 'Primary email'
        },
        {
            value: 'v_work_email',
            label: 'Secondary email'
        },
    {value: 'primary_pma', label: 'Primary PMA'},
    {value: 'secondary_pma', label: 'Secondary PMA'},
        {
            value: 'sso_email',
            label: 'SSO Email'
        },
        {
            value: 'lvf',
            label: 'LVF'
        },

        {
            value:'separate-heading',
            label:'Subscription'
        },
        {
            value: 'v_pma',
            label: 'PMA'
        },
        {
            value: 'v_sdm',
            label: 'Direct mail'
        },
        {
            value: 'v_svt',
            label: 'Vet Times'
        },
        {
            value: 'v_svnt',
            label: 'VN Times'
        },
        {
            value: 'v_svbj',
            label: 'VBJ'
        },
        {
            value: 'v_svtl',
            label: 'Livestock'
        },
        {
            value: 'v_svte',
            label: 'Equine'
        },
        {
            value: 'v_notes',
            label: 'Notes'
        },


        {
            value : 'separate-heading',
            label : 'Request'
        },
        {
            value: 'v_svt_request',
            label: 'Request type Vet Times'
        },
        {
            value: 'v_svt_request_date',
            label: 'Request date Vet Times'
        },
        {
            value: 'v_svt_abc_name',
            label: 'Vet Times ABC name'
        },
        {
            value: 'v_svt_abc_answer',
            label: 'Vet Times ABC answer'
        },
        {
            value: 'v_svnt_request',
            label: 'Request type VN Times'
        },
        {
            value: 'v_svnt_request_date',
            label: 'Request date VN Times'
        },
        {
            value: 'v_svnt_abc_name',
            label: 'VN Times ABC name'
        },
        {
            value: 'v_svnt_abc_answer',
            label: 'VN Times ABC answer'
        },
        {
            value: 'v_svbj_request',
            label: 'Request type VBJ'
        },
        {
            value: 'v_svbj_request_date',
            label: 'Request date VBJ'
        },
        {
            value: 'v_svbj_abc_name',
            label: 'VBJ ABC name'
        },
        {
            value: 'v_svbj_abc_answer',
            label: 'VBJ ABC answer'
        },
        {
            value: 'v_sevbd_request',
            label: 'VBD Request type'
        },
        {
            value: 'v_sevbd_request_date',
            label: 'VBD Request date'
        },
        {
            value: 'v_s3rd_request',
            label: '3rd party Request type'
        },
        {
            value: 'v_s3rd_request_date',
            label: '3rd party Request date'
        },
        {
            value: 'v_vnews_d_request',
            label: 'Vettimes d Request type'
        },
        {
            value: 'v_vnews_d_request_date',
            label: 'Vettimes d Request date'
        },
        {
            value: 'v_vnews_request',
            label: 'Vettimes w Request type'
        },
        {
            value: 'v_vnews_request_date',
            label: 'Vettimes w Request date'
        },
        {
            value: 'v_vjnw_request',
            label: 'Vet Times Jobs w Request type'
        },
        {
            value: 'v_vjnw_request_date',
            label: 'Vet Times Jobs w Request date'
        },
        {
            value: 'v_vjnm_request',
            label: 'Vet Times Jobs m Request type'
        },
        {
            value: 'v_vjnm_request_date',
            label: 'Vet Times Jobs m Request date'
        },
        {
            value: 'v_vt_jobs_marketing_request',
            label: 'VT Jobs Marketing Request Type'
        },
        {
            value: 'v_vt_jobs_marketing_request_date',
            label: 'VT Jobs Marketing Request Date'
        },
        {
            value: 'vn_monthly_newsletter_request',
            label: 'VN Newsletter m Request Type'
        },
        {
            value: 'vn_monthly_newsletter_request_date',
            label: 'VN Newsletter m Request Date'
        },
        {
            value: 'updatedby',
            label: 'Updated by'
        },
        {
            value: 'date',
            label: 'Updated date'
        },


        //        Digital Edition Section Fields
        {value: 'v_svtl_digi', label: 'Livestock Vet Times Digi'},
        {value: 'v_svte_digi', label: 'Equine Vet Times Digi'},
        {value: 'v_svt_digi', label: 'Vet Times Digi'},
        {value: 'v_svt_digi_request', label: 'Request type Vet Times Digi'},
        {value: 'v_svt_digi_request_date', label: 'Request date Vet Times Digi'},
        {value: 'v_svt_digi_abc_name', label: 'Vet Times Digi ABC name'},
        {value: 'v_svt_digi_abc_answer', label: 'Vet Times Digi ABC answer'},

        {value: 'v_svnt_digi', label: 'VN Times Digi'},
        {value: 'v_svnt_digi_request', label: 'Request type VN Times Digi'},
        {value: 'v_svnt_digi_request_date', label: 'Request date VN Times Digi'},
        {value: 'v_svnt_digi_abc_name', label: 'VN Times Digi ABC name'},
        {value: 'v_svnt_digi_abc_answer', label: 'VN Times Digi ABC answer'},

        {value: 'v_svbj_digi', label: 'VBJ Digi'},
        {value: 'v_svbj_digi_request', label: 'Request type VBJ Digi'},
        {value: 'v_svbj_digi_request_date', label: 'Request date VBJ Digi'},
        {value: 'v_svbj_digi_abc_name', label: 'VBJ Digi ABC name'},
        {value: 'v_svbj_digi_abc_answer', label: 'VBJ Digi ABC answer'},

        {value: 'ind_vt_digi_start', label: 'Vet Times Digi Start date'},
        {value: 'ind_vt_digi_end', label: 'Vet Times Digi End date'},
        {value: 'ind_vt_digi_cost', label: 'Vet Times Digi Cost'},

        {value: 'ind_vn_digi_start', label: 'VN Times Digi Start date'},
        {value: 'ind_vn_digi_end', label: 'VN Times Digi End date'},
        {value: 'ind_vn_digi_cost', label: 'VN Times Digi Cost'},

        {value: 'ind_vbj_digi_start', label: 'VBJ Digi Start date'},
        {value: 'ind_vbj_digi_end', label: 'VBJ Digi End date'},
        {value: 'ind_vbj_digi_cost', label: 'VBJ Digi Cost'},

        {
            value :'separate-heading' ,
            label :'Online'
        },
        {
            value: 'v_sevbd',
            label: 'VBD email'
        },
        {
            value: 'v_s3rd',
            label: '3rd party'
        },
        {
            value: 'v_vnews_d',
            label: 'Vettimes d'
        },
        {
            value: 'v_vnews',
            label: 'Vettimes w'
        },
        {
            value: 'v_vjnw',
            label: 'Vet Times Jobs w'
        },
        {
            value: 'v_vjnm',
            label: 'Vet Times Jobs m'
        },
        {
            value: 'v_vt_jobs_marketing',
            label: 'VT Jobs Marketing'
        },
        {
            value: 'vn_monthly_newsletter',
            label: 'VN Newsletter m'
        },
        {
            value: 'v_sso',
            label: 'SSO'
        },
        {
            value: 'login_at',
            label: 'Last logged at'
        },
        {
            value: 'vtapp_sso',
            label: 'VT app SSO'
        },
        {
            value: 'vtapp_date',
            label: 'VT app Login'
        },
        {
            value: 'cpd_sso',
            label: 'CPD SSO'
        },
        {
            value: 'cpd_date',
            label: 'CPD login'
        },
        {
            value: 'marketplace_sso',
            label: 'Marketplace SSO'
        },
        {
            value: 'marketplace_date',
            label: 'Marketplace login'
        },
        {
            value: 'vt_sso',
            label: 'VT SSO'
        },
        {
            value: 'vt_date',
            label: 'VT login'
        },
        {
            value: 'vtjobs_sso',
            label: 'VT jobs SSO'
        },
        {
            value: 'vtjobs_date',
            label: 'VT jobs login'
        },
        {
            value: 'v_interests',
            label: 'Interests'
        },

        {
            value: 'history',
            label: 'History'
        },
        {
            value: 'p_updated',
            label: 'Profile Updated'
        },
        {
            value: 'p_date',
            label: 'Profile Update Date'
        },
        {
            value: 'academy_sso',
            label: 'Academy SSO'
        },
        {
            value: 'academy_date',
            label: 'Academy login'
        },


        {
            value :'separate-heading' ,
            label : 'Industry'
        },
        {
            value: 'ind_type',
            label: 'Sub Type'
        },
        {
            value: 'ind_vt_start',
            label: 'Vet Times Start date'
        },
        {
            value: 'ind_vt_end',
            label: 'Vet Times End date'
        },
        {
            value: 'ind_vt_cost',
            label: 'Vet Times Cost'
        },
        {
            value: 'ind_vn_start',
            label: 'VN Times Start date'
        },
        {
            value: 'ind_vn_end',
            label: 'VN Times End date'
        },
        {
            value: 'ind_vn_cost',
            label: 'VN Times Cost'
        },
        {
            value: 'ind_vbj_start',
            label: 'VBJ Start date'
        },
        {
            value: 'ind_vbj_end',
            label: 'VBJ End date'
        },
        {
            value: 'ind_vbj_cost',
            label: 'VBJ Cost'
        },
        {
            value: 'ind_agent',
            label: 'Agent'
        },

        {
            value :'separate-heading' ,
            label :'Skills Match'
        },
        {
            value: 'residence',
            label: 'Country Residence'
        },
        // {
        //     value: 'phone_number',
        //     label: 'SM Phone Number'
        // },
        // {
        //     value: 'contact_method',
        //     label: 'SM Contact Method'
        // },
        // {
        //     value: 'rtw',
        //     label: 'SM Right to work in UK'
        // },
        // {
        //     value: 'rcvs_2',
        //     label: 'SM RCVS Skills Match'
        // },
        // {
        //     value: 's_postcode',
        //     label: 'SM Postcode'
        // },
        // {
        //     value: 'sm_address_1',
        //     label: 'SM Address 1'
        // },
        // {
        //     value: 'sm_address_2',
        //     label: 'SM Address 2'
        // },
        // {
        //     value: 'sm_address_3',
        //     label: 'SM Address 3'
        // },
        // {
        //     value: 'sm_town',
        //     label: 'SM Town'
        // },
        // {
        //     value: 'sm_county',
        //     label: 'SM County'
        // },
        // {
        //     value: 'distance',
        //     label: 'SM Distance'
        // },
        // {
        //     value: 'relocate',
        //     label: 'SM Relocate'
        // },
        // {
        //     value: 'prof',
        //     label: 'SM Job Type'
        // },
        // {
        //     value: 'pmsother',
        //     label: 'SM PMS Other'
        // },
        // {
        //     value: 'areas',
        //     label: 'SM Locations',
        //
        // },
        // {
        //     value: 'position',
        //     label: 'SM Position'
        // },
        // {
        //     value: 'species_type',
        //     label: 'SM Species Type'
        // },
        // {
        //     value: 'pms',
        //     label: 'SM PMS'
        // },
        // {0
        //     value: 'main_experience',0
        //     label: 'SM Main Experience'
        // },
        // {
        //     value: 'cv',
        //     label: 'SM CV'
        // },
        // {
        //     value: 'sm_profile',
        //     label: 'SM Profile'
        // },
        // {
        //     value: 'sm_date',
        //     label: 'SM Profile Date'
        // },
        // {
        //     value: 'sm_salary_band',
        //     label: 'SM Salary band',
        //
        // },
        // {
        //     value: 'sm_next_role',
        //     label: 'SM Preferred next role'
        // },
        //
        //
        // {
        //     value :'separate-heading' ,
        //     label :'Small Animal'
        // },
        // {
        //     value: 'consultation_general',
        //     label: 'SM Consultation General'
        // },
        // {
        //     value: 'consultation_10min',
        //     label: 'SM Consultation 10 mins'
        // },
        // {
        //     value: 'castration_dog_cat',
        //     label: 'SM Castration Dog and Cat'
        // },
        // {
        //     value: 'castration_rabbit',
        //     label: 'SM Castration Rabbit'
        // },
        // {
        //     value: 'spay_dog_cat',
        //     label: 'SM Spay Dog and Cat'
        // },
        // {
        //     value: 'spay_rabbit',
        //     label: 'SM Spay Rabbit'
        // },
        // {
        //     value: 'c_section',
        //     label: 'SM C Section'
        // },
        // {
        //     value: 'extractions_dental',
        //     label: 'SM Extractions Dental'
        // },
        // {
        //     value: 'ex_lap',
        //     label: 'SM Ex lap'
        // },
        // {
        //     value: 'amputation_limb',
        //     label: 'SM Amputation Limb'
        // },
        // {
        //     value: 'removal_lump',
        //     label: 'SM Removal Lump'
        // },
        // {
        //     value: 'radiograph_interpretation',
        //     label: 'SM Radiograph Interpretation'
        // },
        // {
        //     value: 'radiograph_taking',
        //     label: 'SM Radiograph Taking'
        // },
        // {
        //     value: 'lab_interpreting_results',
        //     label: 'SM Interpreting Lab Results'
        // },
        // {
        //     value: 'emergency_medicine',
        //     label: 'SM Emergency Medicine'
        // },
        // {
        //     value: 'ultrasonography',
        //     label: 'SM Ultrasonography'
        // },
        // {
        //     value: 'consultation_euthanasia',
        //     label: 'SM Consultation Euthanasia'
        // },
        // {
        //     value: 'surgery_orthaepedic',
        //     label: 'SM Surgery Orthaepedic'
        // },
        // {
        //     value: 'investigation_dermatology',
        //     label: 'SM Investigation Dermatology'
        // },
        // {
        //     value: 'ophthalmology',
        //     label: 'SM Ophthalmology'
        // },
        // {
        //     value: 'investigation_cardiology',
        //     label: 'SM Investigation Cardiology'
        // },
        // {
        //     value: 'investigation_neurology',
        //     label: 'SM Investigation Neurology'
        // },
        // {
        //     value: 'cat_spay',
        //     label: 'SM Cat Spay'
        // },
        // {
        //     value: 'bitch_spay',
        //     label: 'SM Bitch Spay'
        // },
        //
        // {
        //     value : 'separate-heading',
        //     label :'Farm Animal'
        // },
        // {
        //     value: 'clinical_examination',
        //     label: 'SM Clinical Examination'
        // },
        // {
        //     value: 'fertility_work',
        //     label: 'SM Fertility Work'
        // },
        // {
        //     value: 'lda_surgery',
        //     label: 'SM LDA Surgery'
        // },
        // {
        //     value: 'caesareans',
        //     label: 'SM Caesareans'
        // },
        // {
        //     value: 'blood_sampling',
        //     label: 'SM Blood Sampling'
        // },
        // {
        //     value: 'tb_testing',
        //     label: 'SM TB Testing'
        // },
        // {
        //     value: 'tb_per_day',
        //     label: 'SM TB per day'
        // },
        // {
        //     value: 'farm_experience',
        //     label: 'SM Farm Experience'
        // },
        // {
        //     value :'separate-heading' ,
        //     label :'Equine'
        // },
        // {
        //     value: 'investigation_lameness',
        //     label: 'SM Investigation Lameness'
        // },
        // {
        //     value: 'dentistry',
        //     label: 'SM Dentistry'
        // },
        // {
        //     value: 'investigation_pre_purchase',
        //     label: 'SM Investigation Pre Purchase'
        // },
        // {
        //     value: 'stud_work',
        //     label: 'SM Stud Work'
        // },
        // {
        //     value: 'al_work',
        //     label: 'SM Al Work'
        // },
        // {
        //     value: 'anaesthesia',
        //     label: 'SM Anaesthesia'
        // },
        // {
        //     value: 'radiology',
        //     label: 'SM Radiology'
        // },
        // {
        //     value :'separate-heading' ,
        //     label :'Nursing'
        // },
        // {
        //     value: 'consultation_nurse',
        //     label: 'SM Consultaion Nurse'
        // },
        // {
        //     value: 'blood_samples_taking',
        //     label: 'SM Blood Samples Taking'
        // },
        // {
        //     value: 'catheter_insertion',
        //     label: 'SM Catheter Insertion'
        // },
        // {
        //     value: 'anaesthetics_monitoring',
        //     label: 'SM Anaesthetics Monitoring'
        // },
        // {
        //     value: 'animal_restraint',
        //     label: 'SM Animal Restaint'
        // },
        // {
        //     value: 'reception_skills',
        //     label: 'SM Reception Skills'
        // },
        // {
        //     value: 'schedule_3_procedures',
        //     label: 'SM Schedule 3 procedures'
        // },
        {
            value : 'separate-heading',
            label : 'CPD Companion Animal'
        },
        {
            value: 'companion_animal_subs',
            label: 'CPD companion animal subscription'
        },
        {
            value: 'exotics_subs',
            label: 'CPD exotics subscription'
        },
        {
            value: 'equine_subs',
            label: 'CPD equine subscription'
        },
        {
            value: 'livestock_subs',
            label: 'CPD livestock subscription'
        },
        {
            value: 'business_subs',
            label: 'CPD business subscription'
        },
        {
            value: 'well_being_subs',
            label: 'CPD well-being subscription'
        },
        {
            value: 'para_date1',
            label: 'CPD Parasitology 1st viewed'
        },
        {
            value: 'para_date2',
            label: 'CPD Parasitology last viewed'
        },
        {
            value: 'pman_date1',
            label: 'CPD Pain management 1st viewed'
        },
        {
            value: 'pman_date2',
            label: 'CPD Pain management last viewed'
        },
        {
            value: 'derma_date1',
            label: 'CPD Dermatology 1st viewed'
        },
        {
            value: 'derma_date2',
            label: 'CPD Dermatology last viewed'
        },
        {
            value: 'onco_date1',
            label: 'CPD Oncology 1st viewed'
        },
        {
            value: 'onco_date2',
            label: 'CPD Oncology last viewed'
        },
        {
            value: 'spets_date1',
            label: 'CPD Senior pets 1st viewed'
        },
        {
            value: 'spets_date2',
            label: 'CPD Senior Pets last viewed'
        },
        {
            value: 'gastro_date1',
            label: 'CPD Gastrointestine Disorders 1st viewed'
        },
        {
            value: 'gastro_date2',
            label: 'CPD Gastrointestine Disorders last viewed'
        },
        {
            value: 'cardio_date1',
            label: 'CPD Cardiology 1st viewed'
        },
        {
            value: 'cardio_date2',
            label: 'CPD Cardiology last viewed'
        },
        {
            value: 'nutrition_date1',
            label: 'CPD Nutrition 1st viewed'
        },
        {
            value: 'nutrition_date2',
            label: 'CPD Nutrition last viewed'
        },
        {   value: 'ecc_date1',
            label: 'CPD Emergency and critical care 1st viewed'
        },
        {   value: 'ecc_date2',
            label: 'CPD Emergency and critical care last viewed'
        },
        {   value: 'neurology_date1',
            label: 'CPD Neurology 1st viewed'
        },
        {   value: 'neurology_date2',
            label: 'CPD Neurology last viewed'
        },
        {   value: 'urology_date1',
            label: 'CPD Urology 1st viewed'
        },
        {   value: 'urology_date2',
            label: 'CPD Urology last viewed'
        },
        {   value: 'alabama_rot_date1',
            label: 'CPD Alabama rot 1st viewed'
        },
        {   value: 'alabama_rot_date2',
            label: 'CPD Alabama rot last viewed'
        },
        {   value: 'fip_date1',
            label: 'CPD FIP 1st viewed'
        },
        {   value: 'fip_date2',
            label: 'CPD FIP last viewed'
        },
        {
            value :'separate-heading' ,
            label :'CPD Exotics'
        },
        {
            value: 'rabbits_date1',
            label: 'CPD Rabbits 1st viewed'
        },
        {
            value: 'rabbits_date2',
            label: 'CPD Rabbits last viewed'
        },
        {
            value: 'rodents_date1',
            label: 'CPD Rodents 1st viewed'
        },
        {
            value: 'rodents_date2',
            label: 'CPD Rodents last viewed'
        },
        {
            value : 'separate-heading' ,
            label :'CPD Equine'
        },
        {
            value: 'lameness_date1',
            label: 'CPD Lameness 1st viewed'
        },
        {
            value: 'lameness_date2',
            label: 'CPD Lameness last viewed'
        },
        {
            value: 'equine_respiratory_date1',
            label: 'CPD Respiratory diseases (equine) 1st viewed'
        },
        {
            value: 'equine_respiratory_date2',
            label: 'CPD Respiratory diseases (equine) last viewed'
        },
        {
            value: 'phealth_date1',
            label: 'CPD Preventive Health 1st viewed'
        },
        {
            value: 'phealth_date2',
            label: 'CPD Preventive Health last viewed'
        },
        {
            value : 'separate-heading',
            label :'CPD Livestock'
        },
        {
            value: 'calf_health_date1',
            label: 'CPD Calf health 1st viewed'
        },
        {
            value: 'calf_health_date2',
            label: 'CPD Calf health last viewed'
        },
        {
            value: 'mastitis_date1',
            label: 'CPD Mastitis 1st viewed'
        },
        {
            value: 'mastitis_date2',
            label: 'CPD Mastitis last viewed'
        },
        {
            value: 'livestock_respiratory_date1',
            label: 'CPD Respiratory diseases (livestock) 1st viewed'
        },
        {
            value: 'livestock_respiratory_date2',
            label: 'CPD Respiratory diseases (livestock) last viewed'
        },
        {
            value: 'parasites_date1',
            label: 'CPD Parasites 1st viewed'
        },
        {
            value: 'parasites_date2',
            label: 'CPD Parasites last viewed'
        },
        {
            value : 'separate-heading',
            label :'CPD Bussiness'
        },
        {
            value: 'finance_date1',
            label: 'CPD Finance 1st viewed'
        },
        {
            value: 'finance_date2',
            label: 'CPD Finance last viewed'
        },
        {
            value: 'client_care_date1',
            label: 'CPD Client care 1st viewed'
        },
        {
            value: 'client_care_date2',
            label: 'CPD Client care last viewed'
        },
        {
            value: 'technology_date1',
            label: 'CPD Technology 1st viewed'
        },
        {
            value: 'technology_date2',
            label: 'CPD Technology last viewed'
        },
        {
            value: 'train_and_develop_date1',
            label: 'CPD Training and development 1st viewed'
        },
        {
            value: 'train_and_develop_date2',
            label: 'CPD Training and development last viewed'
        },
        {
            value : 'separate-heading',
            label :'CPD Livestock'
        },
        {
            value:'wb_teams_date1',
            label:'CPD Well-being for your team 1st viewed'
        },
        {
            value:'wb_teams_date2',
            label:'CPD Well-being for your team last viewed'
        },
        {
            value:'wb_you_date1',
            label:'CPD Well-being for you 1st viewed'
        },
        {
            value:'wb_you_date2',
            label:'CPD Well-being for you last viewed'
        },
    {
        value: "endocrinology_date1",
        label: "Endocrinology 1st viewed"
    },
    {
        value: "endocrinology_date2",
        label: "Endocrinology last viewed"
    },
    {
        value: "anaesthesia_date1",
        label: "Anaesthesia and analgesia 1st viewed"
    },
    {
        value: "anaesthesia_date2",
        label: "Anaesthesia and analgesia last viewed"
    },
    {
        value: "ophthalmology_date1",
        label: "Ophthalmology 1st viewed"
    },
    {
        value: "ophthalmology_date2",
        label: "Ophthalmology last viewed"
    },
    {
        value: "oral_care_date1",
        label: "Oral care 1st viewed"
    },
    {
        value: "oral_care_date2",
        label: "Oral care last viewed"
    },
    {
        value: "wound_management_date1",
        label: "Wound management 1st viewed"
    },
    {
        value: "wound_management_date2",
        label: "Wound management last viewed"
    },
    {
        value: "birds_date1",
        label: "Birds 1st viewed"
    },
    {
        value: "birds_date2",
        label: "Birds last viewed"
    },
    {
        value: "obesity_date1",
        label: "Obesity 1st viewed"
    },
    {
        value: "obesity_date2",
        label: "Obesity last viewed"
    },
    {
        value: "imaging_date1",
        label: "Imaging 1st viewed"
    },
    {
        value: "imaging_date2",
        label: "Imaging last viewed"
    },
    {
        value: "reproduction_date1",
        label: "Reproduction 1st viewed"
    },
    {
        value: "reproduction_date2",
        label: "Reproduction last viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea 1st viewed"
    },
    {
        value: "bvd_date1",
        label: "Bovine viral diarrhoea last viewed"
    },
    {
        value: "lamb_date1",
        label: "Lamb health 1st viewed"
    },
    {
        value: "lamb_date2",
        label: "Lamb health last viewed"
    },
    {
        value :'separate-heading' ,
        label :'Events'
    },
    {
        value:'v_registration_email',
        label:'VN HH Registration email address'
    },
    {
        value:'v_registration',
        label:'VN HH Registration'
    },
    {
        value:'v_attended',
        label:'VN HH Attended'
    },
    {
        value:'v_congresses',
        label:'Congresses'
    },
    {
        value :'separate-heading' ,
        label :'Panels'
    },
    {
        value:'v_vet_panel',
        label:'Vet Panel'
    },
    {
        value:'v_vn_panel',
        label:'VN Panel'
    },
    {
        value:'v_practice_manager_panel',
        label:'Practice Manager Panel'
    },

    {value: 'separate-heading', label: 'People Match'},
    {value: 'pm_name', label: 'PM name'},
    {value: 'pm_email', label: 'PM email'},
    {value: 'pm_phone', label: 'PM phone'},
    {value: 'pm_next_role', label: 'PM next role'},
    {value: 'pm_postcode', label: 'PM postcode'},
    {value: 'pm_address_1', label: 'PM address_1'},
    {value: 'pm_address_2', label: 'PM address_2'},
    {value: 'pm_address_3', label: 'PM address_3'},
    {value: 'pm_town', label: 'PM town'},
    {value: 'pm_distance', label: 'PM distance'},
    {value: 'pm_relocate', label: 'PM relocate'},
    {value: 'pm_working_outside', label: 'PM working outside'},
    {value: 'pm_looking_for_work', label: 'PM looking for work'},
    {value: 'pm_ideal_rota', label: 'PM ideal rota'},
    {value: 'pm_salary_band', label: 'PM salary band'},
    {value: 'pm_year_rcvs', label: ' PM year rcvs'},
    {value: 'pm_ideal_role', label: 'PM ideal role'},
    {value: 'pm_important_next_role', label: 'PM important next role'},
    {value: 'pm_university', label: 'PM university'},
    {value: 'pm_county', label: 'PM county'},
    {value: 'pm_country', label: 'PM country'},
    {value: 'pm_clinical_interest', label: 'PM clinical interest'},
    {value: 'pm_skill_develop', label: 'PM skill develop'},
    {value: 'pm_work_in_uk', label: 'PM work in uk'},
    {value: 'pm_areas', label: 'PM areas'},
    {value: 'pm_outside_areas', label: 'PM outside areas'},
    {value: 'pm_year_qualification', label: 'PM year qualification'},
    {value: 'pm_cv', label: 'PM cv'},
    {value: 'pm_profile', label: 'PM profile'},
    {value: 'pm_job_type', label: 'PM job type'},
    {value: 'pm_profile_created_date', label: 'PM profile created date'},
    {value: 'pm_profile_modified', label: 'PM profile modified'},
    {value: 'pm_interested', label: 'PM opt in'},
    {value: 'pm_interest_date', label: 'PM opt in date'},
    {value: 'pm_soft_opt_in', label: 'PM soft opt-in'},
    {value: 'pm_soft_opt_in_date', label: 'PM soft opt-in date'},
    {value: 'record_created', label: 'Created at'},




]



export const practice_export_builder = [
    {
        value: 'practice.id as PID',
        label: 'ID'
    },
    {
        value: 'office',
        label: 'Office'
    },

    {
        value: 'name',
        label: 'Name'
    },
    {
        value: 'address_1',
        label: 'Address 1'
    },
    {
        value: 'address_2',
        label: 'Address 2'
    },
    {
        value: 'address_3',
        label: 'Address 3'
    },
    {
        value: 'town',
        label: 'Town'
    },
    {
        value: 'county',
        label: 'County'
    },
    {
        value: 'postcode',
        label: 'Postcode'
    },
    {
        value: 'country',
        label: 'Country'
    },
    {
        value: 'brick',
        label: 'Brick'
    },
    {
        value: 'type',
        label: 'Type'
    },
    {
        value: 'tel',
        label: 'Tel.'
    },
    {
        value: 'direct_mail',
        label: 'Direct Mail'
    },
    {
        value: 'fax',
        label: 'Fax'
    },
    {
        value: 'website',
        label: 'Web'
    },
    {
        value: 'email',
        label: 'Email'
    },
    {
        value: 'wholesaler',
        label: 'Wholesaler'
    },
    {
        value: 'pramansys',
        label: 'Pra Man Sys'
    },
    {
        value: 'referral',
        label: 'Referral'
    },
    {
        value: 'no_staff_pra',
        label: 'No Staff Prac'
    },
    {
        value: 'corporate',
        label: 'Corporate'
    },
    {
        value: 'ooh',
        label: 'OOH'
    },
    {
        value :'separate-heading' ,
        label :'Corporate'
    },
    {
        value: 'buying',
        label: 'Buying'
    },
    {
        value: 'rcvs_acc',
        label: 'RCVS Accred'
    },
    {
        value: 'pethome',
        label: 'Pets at Home Group'
    },
    {
        value: 'vetsnow',
        label: 'Vets Now'
    },
    {
        value: 'cvs',
        label: 'CVS'
    },
    {
        value: 'ivc',
        label: 'IVC'
    },
    {
        value: 'linnaeus',
        label: 'Linnaeus'
    },
    {
        value: 'medivet',
        label: 'Medivet'
    },
    {
        value: 'vetpartners',
        label: 'Vet Partners'
    },
    {
        value: 'vetdynamics',
        label: 'VetDynamics'
    },
    {
        value:'separate-heading',
        label:'Buyings'
    },
    {
        value: 'xlvets',
        label: 'XL Vets'
    },
    {
        value: 'mwi_group',
        label: 'MWI Group'
    },
    {
        value: 'vetcel',
        label: 'Vetcel'
    },
    {
        value: 'vetshare',
        label: 'VetShare'
    },
    {
        value: 'vetsure',
        label: 'Vetsure'
    },
    {
        value: 'send_vbd',
        label: 'Send VBD'
    },
    {
        value: 'send_third',
        label: '3rd party'
    },
    {
        value : 'separate-heading',
        label :'Subscriptions'
    },
    {
        value: 'sendvt',
        label: 'Practice VT'
    },
    {
        value: 'sendvnt',
        label: 'Practice VNT'
    },
    {
        value: 'sendvbj',
        label: 'Practice VBJ'
    },
    {
        value: 'sendlivestock',
        label: 'Practice Livestock'
    },
    {
        value: 'sendequine',
        label: 'Practice Equine'
    },
    {
        value: 'training',
        label: 'Training'
    },
    {
        value: 'vs',
        label: 'VS'
    },
    {
        value: 'vn',
        label: 'VN'
    },
    {
        value: 'branch_vs',
        label: 'Branch VS'
    },
    {
        value: 'branch_vn',
        label: 'Branch VN'
    },
    {
        value: 'oralcare',
        label: 'Oral Care Kits'
    },
    {
        value: 'ock_request_date',
        label: 'OCK Request Date'
    },
    {
        value: 'oralcare_restock_1',
        label: 'Oral Care Restock 1'
    },
    {
        value: 'oralcare_restock_2',
        label: 'Oral Care Restock 2'
    },
    {
        value: 'oralcare_restock_3',
        label: 'Mars POS'
    },
    // {
    //     value: 'ock_req_name',
    //     label: 'OCK Req Name'
    // },
    // {
    //     value: 'ock_req_email',
    //     label: 'OCK Req Email'
    // },


{ value:'ock_req_name',label:"2015 OCK Req name"},
{value:'ock_req_email',label:"2015 OCK Req email"},
{value:'ock_req_name_2016',label:"2016 OCK Req name"},
{value:'ock_req_email_2016',label:"2016 OCK Req email"},
{value:'ock_req_name_2017',label:"2017 OCK Req name"},
{value:'ock_req_email_2017',label:"2017 OCK Req email"},
{value:'ock_req_name_2018',label:"2018 OCK Req name"},
{value:'ock_req_email_2018',label:"2018 OCK Req email"},
{value:'ock_req_name_2019',label:"2019 OCK Req name"},
{value:'ock_req_email_2019',label:"2019 OCK Req email"},
{value:'ock_req_name_2020',label:"2020 OCK Req name"},
{value:'ock_req_email_2020',label:"2020 OCK Req email"},
{value:'ock_req_name_2021',label:"2021 OCK Req name"},
{value:'ock_req_email_2021',label:"2021 OCK Req email"},
{value:'ock_req_name_2022',label:"2022 OCK Req name"},
{value:'ock_req_email_2022',label:"2022 OCK Req email"},
{value:'ock_req_name_2023',label:"2023 OCK Req name"},
{value:'ock_req_email_2023',label:"2023 OCK Req email"},
{value:'ock_req_name_2024',label:"2024 OCK Req name"},
{value:'ock_req_email_2024',label:"2024 OCK Req email"},
    {
        value:'separate-heading',
        label:'Requests'
    },
    {
        value: 'abc_name',
        label: 'ABC name'
    },
    {
        value: 'abc_answer',
        label: 'ABC answer'
    },
    {
        value: 'request_type',
        label: 'Request type'
    },
    {
        value: 'request_date',
        label: 'Request date'
    },
    {
        value: 'updated_date',
        label: 'Updated date'
    },
    {
        value: 'updated_by',
        label: 'Updated by'
    },

]

