import React, { useEffect, useState } from 'react'
import { Form, Button, ListGroup, ListGroupItem, Row, Col, Container } from 'react-bootstrap';
import Axios from '../adapters/xhr';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { vet_export_builder, practice_export_builder } from '../constants/advanceQueryBuilder';

const ExportBuilderSidebar = ({ values }) => {
  const [url, setUrl] = useState('')
  const [showFields, setShowFields] = useState(false)
  const [selectedFields, setSelectedFields] = useState([...values])
  const [selectForPrac, setSelectForPrac] = useState(true)
  const [selectForVet, setSelectForVet] = useState(true)
  const [selectAll, setSelectAll] = useState(true)
  const [vetsChecked, setVetsChecked] = useState(false)
  const [pracChecked, setPracChecked] = useState(false)
  const [reportList, setReportList] = useState([])

  useEffect(() => {
  }, [showFields])

  useEffect(() => {
  }, [selectedFields])

  useEffect(() => {
    getReportList()
  }, [])

  const getReportList = async () => {
    let reports = await Axios.get('/report/builder?method=all')
    if (reports.data) setReportList(reports.data.data)
  }

  const handleSelectedFieldPrac = (prac, index) => {
    for (let i = 0; i < selectedFields.length; i++) {
      if (JSON.stringify(selectedFields[i]) === JSON.stringify(prac)) {
        selectedFields.splice(i, 1)
        setSelectedFields([...selectedFields])
        return
      }
    }
    setSelectedFields([...selectedFields, prac])
  }

  const handleSelectedFieldVet = (vet, index) => {
    for (let i = 0; i < selectedFields.length; i++) {
      if (JSON.stringify(selectedFields[i]) === JSON.stringify(vet)) {
        selectedFields.splice(i, 1)
        setSelectedFields([...selectedFields])
        return
      }
    }

    setSelectedFields([...selectedFields, vet])
  }

  const handleSelectVet = () => {
    if (selectForVet) {
      for (let i = 0; i < vet_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(vet_export_builder[i]) === JSON.stringify(x)) selectedFields.splice(ind, 1)
        })
      }

      setSelectedFields((prev) => ([...prev, ...vet_export_builder]))
      setSelectForVet(false)
    } else {
      for (let i = 0; i < vet_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(vet_export_builder[i]) === JSON.stringify(x)) selectedFields.splice(ind, 1)
        })
      }
      setSelectedFields([...selectedFields])
      setSelectForVet(true)
    }

  }

  const handleSelectPractice = () => {
    if (selectForPrac) {
      for (let i = 0; i < practice_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(practice_export_builder[i]) === JSON.stringify(x)) selectedFields.splice(ind, 1)
        })
      }
      setSelectedFields((prev) => ([...prev, ...practice_export_builder]))
      setSelectForPrac(false)

    }
    else {
      for (let i = 0; i < vet_export_builder.length; i++) {
        selectedFields.forEach((x, ind) => {
          if (JSON.stringify(practice_export_builder[i]) === JSON.stringify(x)) selectedFields.splice(ind, 1)
        })
      }
      setSelectedFields([...selectedFields])
      setSelectForPrac(true)
    }

  }

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedFields([...vet_export_builder, ...practice_export_builder])
      setSelectAll(false)
      setVetsChecked(true)
      setPracChecked(true)
    } else {
      setSelectedFields([])
      setSelectAll(true)
      setVetsChecked(false)
      setPracChecked(false)
    }
  }
  const handleCheckforTable = () => {
    let table = ''

    for (let x = 0; x < selectedFields.length; x++) {
      for (let y = 0; y < vet_export_builder.length; y++) {
        if (selectedFields[x].value === vet_export_builder[y].value) table = 'vet'
      }
    }

    for (let x = 0; x < selectedFields.length; x++) {
      for (let y = 0; y < practice_export_builder.length; y++) {
        if (selectedFields[x].value === practice_export_builder[y].value) {
          if (table === 'vet') {
            table = 'vet,practice'
            return table
          } else {
            table = 'practice'
            return table
          }
        }
      }
    }

    return table
  }

  const generateQuery = () => {
    let vetTable = ''
    let pracTable = ''
    let tables = ''
    let vet_fields = []
    let practice_fields = []
    let queryArr = []
    let query

      selectedFields.forEach((curr, i) => {
          if (curr.value !== 'separate-heading') {
              practice_export_builder.filter((x, j) => {
                  if (x.value === curr.value) {
                      practice_fields[j] = `${curr.value},${curr.label}`
                  }
              })
          }
      })
      selectedFields.forEach((curr, i) => {
          if (curr.value !== 'separate-heading') {
              vet_export_builder.filter((x, j) => {
                  if (x.value === curr.value) {
                      vet_fields[j] = `${curr.value},${curr.label}`
                  }
              })
          }
      })

      practice_fields.map(function (val) {queryArr.push(val)})

      vet_fields.map(function (val) {queryArr.push(val)})

      tables = handleCheckforTable()
      query = queryArr.join('::')
      query = query.concat(`&tables=${tables}`)

    return query
  }

  const handleExport = async (e) => {
      e.preventDefault();
      try {
          let response = await Axios.get(`/exporter/reports/${e.target.value}`)
          setUrl(response.data.data.url)
          let link = document.createElement('a');
          link.href = response.data.data.url;
          link.setAttribute('download', response.data.data.url);
          // link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
      } catch (err) {
          console.log(err.response)
      }
  }
  const handleExportNew = async (e) => {

      const query = generateQuery()
      let response = await Axios.get(`/exporter/reports/custom?extra_fields=${query}`)
      setUrl(response.data.data.url)
      let link = document.createElement('a');
      link.href = response.data.data.url;
      link.setAttribute('download', response.data.data.url);
      // link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
  }

  const handleReportList = async (e) => {
    let id = e.target.value;
    let export_data = await Axios.get(`/report/builder/export/${id}`)
    let fileName = export_data ? /(?<=reports\/).*/.exec(export_data.data.data.url)[0].slice(11) : '';
    const link = document.createElement('a');
    link.href = export_data.data.data.url;
    link.setAttribute('download', fileName);
    // link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return (
    <>
      {/*<PerfectScrollbar>*/}
        {/* <h4 className="my-3 px-4">
          { <span>Export Builder</span> }
        </h4> */}
        {/* <span className="px-4 mb-3 d-block sidebar-text-sm">
          Export builder is based on the query you have searched for. You can select from the pre-sets or select custom
          fields to export.
      </span> */}
        <div className="px-4 pb-3 pt-2 alice-blue-bg">
          <h4 className="my-2">
            <span>Pre-sets</span>
          </h4>
          {/* <span className="mb-4 d-block sidebar-text-sm">
            By clicking on a pre-set the export will be performed automatically with the pre-set fields only.
        </span> */}

            {localStorage.getItem('user_role') != 6 &&
            <div>
                <Form.Label>Vetfile</Form.Label>
                <Form.Control name="vetfile" onChange={handleExport} className="form-select" as="select" placeholder="Please select">
                    <option disabled selected>Please select</option>
                    <option value="vtvntvbj">VT/VNT/VBJ</option>
                    <option value="small_animal_split">Small animal split</option>
                    <option value="practice_update">Practice update</option>
                    <option value="interests">Interests</option>
                    <option value="updated">Profile accounts updated</option>
                    <option value="duplicate_email">Duplicate report</option>
                    <option value="sso_unverified_updated">Updated report</option>
                </Form.Control>
            </div>
            }

            <Form.Label>Direct Mailing</Form.Label>
          <Form.Control name="mailing" onChange={handleExport} className="form-select" as="select" placeholder="Please select">
               <option disabled selected>Please select</option>
              {localStorage.getItem('user_role') != 6 && <option value="brick_data">Brick Data</option>}
              {localStorage.getItem('user_role') != 6 && <option value="referrals">Referrals</option>}
              {localStorage.getItem('user_role') != 6 && <option value="corporates">Corporates</option>}
              {localStorage.getItem('user_role') != 6 && <option value="direct_mail">Direct Mail</option>}
              {['1', '2', '4', '6'].includes(localStorage.getItem('user_role')) && <option value="profiles">Skills Match</option>}
              {['1', '2', '4', '6'].includes(localStorage.getItem('user_role')) && <option value="people_match">People Match</option>}
              {['6'].includes(localStorage.getItem('user_role')) && <option value="people_match_data">People Match Data</option>}
              {localStorage.getItem('user_role') != 6 && <option value="facebook">Facebook</option>}
              {localStorage.getItem('user_role') != 6 && <option value="linkedin">Linkedin</option>}
          </Form.Control>
        </div>

        <div className="px-4 pb-3 pt-2 alice-blue-bg">
          <h4 className="my-2">
            <span>Saved Reports</span>
          </h4>

          <Form.Control name="saved-reports" onChange={handleReportList} className="form-select" as="select" placeholder="Please select...">
            <option disabled selected>Please select...</option>
            {
              reportList && reportList.map((curr, ind) => (
                <option key={ind} value={curr.id}>{curr.title}</option>
              ))
            }
          </Form.Control>
        </div>

          {localStorage.getItem('user_role') != 6 &&
          <div>
              <h4 className="my-3 px-4">
                  <span>Custom Fields</span>
              </h4>


              {/* <span className="px-4 mb-3 d-block sidebar-text-sm">
          By clicking the export button your export will begin with all the fields you have selected from this window.
        </span> */}

              <div className="h5 mt-4 mb-3 px-4">
                  <Form.Group controlID="Show_all">
                      <Form.Check
                          name="v_job_types"
                          id="Show_all"
                          type="checkbox"
                          label="Show all available fields?"
                          value={showFields}
                          className="form-label"
                          onClick={() => setShowFields(!showFields)}
                      />
                  </Form.Group>
              </div>

              <div className="px-4">
                  <Button type="submit" onClick={handleExportNew} className="btn theme-btn-primary w-100 my-4">
                      Export Data
                  </Button>
              </div>
          </div>
          }

        {showFields &&
          <>

            <div className="px-4">
              <Button className="btn theme-btn-primary w-100 my-4" onClick={handleSelectAll}>Select All</Button>
            </div>
            <Container>
              <Row>
                <Col className="denim-bg">
                  <Form.Check
                    type="checkbox"
                    onClick={handleSelectPractice}
                    id="autoSizingCheckPrac"
                    className="mb-2 mt-1 text-white"
                    label="Practice"
                    name="Practice"
                    checked={vetsChecked || !selectForPrac}

                  />

                </Col>
                <Col className="denim-bg">

                  <Form.Check
                    type="checkbox"
                    onClick={handleSelectVet}
                    id="autoSizingCheckVet"
                    className="mb-2 mt-1 text-white"
                    label="Vets"
                    name="Vets"
                    checked={pracChecked || !selectForVet}

                  />
                </Col>
              </Row>
              <Row className="export-builder">
                <Col className="p-0">
                  {practice_export_builder.map((prac, index) => {
                    if (prac.value === 'separate-heading') {
                      return <ListGroup.Item> <h6> {prac.label}</h6> </ListGroup.Item>
                    } else {
                      return (<ListGroup.Item as='div' className="" key={index} active={selectedFields.find((i) => { return JSON.stringify(i) === JSON.stringify(prac) })} onClick={() => { handleSelectedFieldPrac(prac, index) }}>{prac.label}</ListGroup.Item>)
                    }
                  })}
                </Col>
                <Col className="p-0">
                  {vet_export_builder.map((vet, index) => {
                    if (vet.value === 'separate-heading') {
                      return <ListGroup.Item> <h6>{vet.label}</h6> </ListGroup.Item>
                    } else {
                      return (<ListGroup.Item as='div' className="" key={index} active={selectedFields.find((i) => { return JSON.stringify(i) === JSON.stringify(vet) })} onClick={() => { handleSelectedFieldVet(vet, index) }} >{vet.label}</ListGroup.Item>)
                    }
                  })}

                </Col>
              </Row>
            </Container>




          </>

        }


      {/*</PerfectScrollbar>*/}
    </>
  );
};

export default ExportBuilderSidebar;
