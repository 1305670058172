import React, { useState, useEffect, useReducer } from "react";
import { Button, Col, Row, Card, Table, Pagination, Collapse, Image, Container, FormControl, Form, } from "react-bootstrap";
//import constants for report_sidebar
import { practice_vet } from '../constants/advanceQueryBuilder'
//Icons
import '../pagination.css'
import arrowDown from "../assets/icons/arrow-down.svg";
import arrowUp from "../assets/icons/arrow-up.svg";
import deleteIcon from "../assets/icons/delete.svg";
import Select from "react-select";
//perfect-scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import RadiusSearch from "./RadiusSearch";
import ReactPaginate from 'react-paginate'
//helper
import Axios from '../adapters/xhr'
//react-redux
import ReportBuildeFields from "./ReportBuildeFields";
import Sms from "./Sms";
import useOnclickOutside from "react-cool-onclickoutside";
import { confirmationAlert, swAlert } from "../helpers/alert";
import { handleDate } from "../helpers/helpers";
import SearchBuilder from "./SearchBuilder/SearchBuilder";
//Constants
// import { personShare } from '../constants/reportBuilder'
import { dropDownreports } from '../constants/constants'
import {selectStyle} from '../styling/select-style'

function reducer(state, action) {
  switch (action.type) {
    case 'QUERY UPDATE':
      return { ...state, query: action.query }
    case 'REPORT TITLE':
      return { ...state, title: action.title }
    case 'RADIUS SEARCH UPDATE':
      return { ...state, radius: action.radius }
    case 'PERSON SHARE':
      return { ...state, shared: action.shared, personSharedValue: action.personSharedValue }
    case 'EDIT REPORT BUILDER':
      return { ...state, shared: action.shared, radius: action.radius, title: action.title, query: action.query, extra_fields: action.extra_fields, dataFields: action.dataFields, personSharedValue: action.personSharedValue }
    case 'ADD DATA FIELDS':
      return { ...state, dataFields: action.dataFields }
    case 'CREATE REPORT STATE':
      state = action.initialCreateReport
      return { ...state }
    default:
      return { ...state }
  }
}

const ReportBuilder = (props) => {
  const [reportState, reportSave] = useReducer(reducer, {
    title: "",
    query: [{
      and: {
        from: "VetFields",
        where: "title",
        wheretext: "Title",
        param: "LIKE",
        val: ""
      },
      or: []
    }],
    radius: {
      driving: null,
      radius_miles: null,
      radius_postcode: null
    },
    extra_fields: [],
    shared: [],
    dataFields: [],
    personSharedValue: []
  })

  const [showPagination, setShowPagination] = useState(false);
  const [queryResult, setQueryResult] = useState([]);
  const [resultCount, setResultCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [anotherFields, setAnotherFields] = useState(false);
  const [resultOrder, setResultOrder] = useState({ order: null });
  const [reportList, setReportList] = useState([{}])
  const [reportUpdate, setReportUpdate] = useState(true)
  const [ReportFormShow, setReportFormShow] = useState(false)
  const [personShareOption, setPersonShareOption] = useState([{}])
  const [initialState, setInitialState] = useState([])
  const [report, setReport] = useState({ 'label': 'All', 'value': 'all' })
  const [editReportId, setEditReportId] = useState()
  const [smsSliderOpen, setSmsSliderOpen] = useState(false)
  const [radiusSearchState, setRadiusSearchState] = useState({ driving: null, radius_miles: null, radius_postcode: null })
  const [reportPre, setReportpre] = useState()
  const [selectedReport, setSelectedReport] = useState(false)
  const [reportBuilderField, setReportBuilderField] = useState()
  const initialCreateReport = {
    title: '', query: [{
      and: { from: 'VetFields', where: 'title', wheretext: 'Title', param: 'LIKE', val: '' },
      or: []
    }],
    radius: {
      driving: null,
      radius_miles: null,
      radius_postcode: null
    },
    extra_fields: [],
    shared: [],
    dataFields: []
  }

  useEffect(() => {
    if (resultOrder.order !== null) {
      handlePreviewReport(report.id)
      handlePriviewShow()
    }
  }, [resultOrder])
  //geting users for sharing
  useEffect(() => {
    const getUsers = async () => {
      let users = await Axios(`/user?fields=id,name`)
      let user_select2_list = []; let user_select2 = [];

      users.data.data.forEach(element => {

        user_select2 = {
          'label': element.name,
          'value': element.id
        };

        user_select2_list.push(user_select2);
      });
      setPersonShareOption(user_select2_list)
    }
    getUsers()
  }, [])

  useEffect(() => {
    if (queryResult.length > 0) {
      //showResult();
    }
  }, [currentPage, resultOrder]);


  useEffect(() => {
    const listReport = async () => {
      let list_reports = await Axios.get(`/report/builder?method=all`);
      setReportList(list_reports.data.data)
    }
    listReport();
  }, [reportUpdate]);


  const handleResultOrder = (name) => {
    if (resultOrder.order === null) {
      setResultOrder({ order: `${name} DESC` });
    } else if (Object.values(resultOrder).toString().includes("DESC")) {
      setResultOrder({ order: `${name} ASC` });
    } else if (Object.values(resultOrder).toString().includes("ASC")) {
      setResultOrder({ order: `${name} DESC` });
    }
  };

  const handleReportSave = async () => {
    if (reportState.title.length === 0) {
      swAlert('Enter report title', 'error')
    } else {

      let payload = reportState
      if (reportState.dataFields.length !== 0) {
        let temp = [];
        reportState.dataFields.forEach((i) => {
          temp.push(i.value)
        })
        payload.extra_fields = temp
      }
      try {
        await Axios.post(`/report/builder`, payload)
        swAlert('Report save', 'success')
        setReportFormShow(false);
      } catch (e) {
        swAlert('Something went wrong', 'error')
      }

      setReportUpdate(!reportUpdate)
    }
  }

  const handlePersonShare = (e) => {
    let tempArr = []
    e.forEach(item => { tempArr.push(item.value) })
    reportSave({ type: 'PERSON SHARE', shared: tempArr, personSharedValue: e })

  }

  const handlePreviewReport = async (id) => {
    setQueryResult([])
    let report = await Axios.get(`/report/builder/${id}`)
    setReportpre(report)
    let payload = { query: report.data.data.query, ...report.data.data.radius, extra_fields: report.data.data.extra_fields, ...resultOrder };
    let { data } = await Axios.post(`/query-bulder?page=${currentPage}`, payload);
    setShowPagination(true)
    setQueryResult(data.results.data)
    setResultCount(data.count)
    setLastPage(data.results.last_page)
  }

  const handlePriviewShow = async () => {
    setQueryResult([])
    let temp = [];
    reportState.dataFields.forEach((x) => {
      temp.push(x.value)
    })
    let payload = { query: reportState.query, ...reportState.radius, extra_fields: temp, ...resultOrder };

    let { data } = await Axios.post(`/query-bulder?page=${currentPage}`, payload);

    setReportpre(report)
    setShowPagination(true)
    setQueryResult(data.results.data)
    setResultCount(data.count)
    setLastPage(data.results.last_page)
  }

  const showResultQuery = async (currPage) => {
    let payload = { query: reportPre.data.data.query, ...reportPre.data.data.radius, extra_fields: reportPre.data.data.extra_fields, ...resultOrder };
    let { data } = await Axios.post(`/query-bulder?page=${currPage}`, payload);
    setShowPagination(true)
    setQueryResult(data.results.data)
    setResultCount(data.count)
    setLastPage(data.results.last_page)
  }
  const showResultCreate = async (currPage) => {
    let payload = { query: reportState.query, ...reportState.radius, extra_fields: reportState.extra_fields, ...resultOrder };
    let { data } = await Axios.post(`/query-bulder?page=${currPage}`, payload);
    setShowPagination(true)
    setQueryResult(data.results.data)
    setResultCount(data.count)
    setLastPage(data.results.last_page)
  }

  const handleDeleteReport = async (id) => {
    confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
      if (result.isConfirmed) {
        await Axios.delete(`/report/builder/${id}`).then(res => {
          setReportUpdate(!reportUpdate)
          if (res.data.status) {
            swAlert(`${res.data.message}`, 'success', true)
          }
        }).catch((err) => {
          swAlert(`${err.message}`, true)
        })
      }
    });
  }

  const handleEditReport = async (id) => {
    let edit = await Axios.get(`/report/builder/${id}`)

    let temp = []
    let persontemp = []
    edit.data.data.extra_fields.forEach((i) => {
      practice_vet.forEach((x) => {
        if (x.value === i) {
          temp.push(x)
        }
      })
    })

    edit.data.data.shared.forEach((i) => {
      personShareOption.forEach((x) => {
        if (x.value === i) {
          persontemp.push(x)
        }
      })
    })

    reportSave({ type: 'EDIT REPORT BUILDER', title: edit.data.data.title, radius: edit.data.data.radius, query: edit.data.data.query, extra_fields: edit.data.data.extra_fields, shared: edit.data.data.shared, dataFields: temp, personSharedValue: persontemp })
    setQueryResult([])
    setInitialState(edit.data.data.query)
    setRadiusSearchState(edit.data.data.radius)
    setReportFormShow(true)
    setEditReportId(id)
  }

  const handleUpdateReport = async (editReportId) => {
    let temp = []
    reportState.dataFields.forEach((i) => {
      temp.push(i.value)
    })

    let payload = { query: reportState.query, radius: reportState.radius, title: reportState.title, shared: reportState.shared, extra_fields: temp };

    try {
      await Axios.put(`/report/builder/${editReportId}`, payload).then(() => {
        swAlert('Report Updated', 'success')
      })
      setReportUpdate(!reportUpdate)
      setReportFormShow(false);
    } catch (err) {
      swAlert('Report Updated Failed', 'error')
    }
  }

  const handleCreateReportForm = async () => {
    reportSave({ type: 'CREATE REPORT STATE', initialCreateReport: initialCreateReport })
    setInitialState([{ and: { from: 'VetFields', where: 'title', wheretext: 'Title', param: 'LIKE', val: '' }, or: [] }])
    setQueryResult([])
    setEditReportId()
    setReportFormShow(true)

  }

  const handleExportReport = async (id) => {
    let export_data = await Axios.get(`/report/builder/export/${id}`)
    let fileName = export_data ? /(?<=reports\/).*/.exec(export_data.data.data.url)[0].slice(11) : '';
    const link = document.createElement('a');
    link.href = export_data.data.data.url;
    link.setAttribute('download', fileName);
    // link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const handlePageClick = (e) => {
    const currPage = e.selected
    showResultQuery(currPage + 1)
  }

  const handlePageClickCreate = (e) => {
    const currPage = e.selected
    showResultCreate(currPage + 1)
  }

  const handleListReport = async (e) => {
    setReport({ 'label': e.label, 'value': e.value });
    let list_report = await Axios.get(`/report/builder?method=${e.value}`)
    if (list_report.data.data) setReportList(list_report.data.data)
  }

  const showAnotherFieldsSidebar = () => {
    setAnotherFields(true);
  }

  //Methods for getting table header value
  const handleTableHeaderValue = (value) => {
    for (let i = 0; i < practice_vet.length; i++) {
      if (value === practice_vet[i].value) {
        return practice_vet[i].label
      }
    }
    return value
  }

  //To close Report Builder sidebar 
  const ref = useOnclickOutside(() => {
    setAnotherFields(false);
    setSmsSliderOpen(false);
  });

  const showSMSsidebar = (report) => {
    setSmsSliderOpen(true);
    setSelectedReport(report)
  }

  if (!ReportFormShow) {
    return (
      <>

        {
          smsSliderOpen && <div ref={ref} id="addPractice"> ? (
          <Collapse in={smsSliderOpen}>
              <div id="addPractice">
                <Sms setSmsSliderOpen={setSmsSliderOpen} selectedReport={selectedReport} />
              </div>
            </Collapse>

          )</div>
        }

        <Row className="report-builder">
          <Col lg={6}>
            <Card className="">
              <Card.Body className="py-4 px-3">
                <Row>
                  <Col lg={12}>
                    <h3 className="mb-3">Reports</h3>
                  </Col>
                  <div className="d-flex align-items-center mb-4">
                    <Col lg={4}>

                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="entity"
                        styles={selectStyle}
                        name=""
                        options={dropDownreports}
                        value={report}
                        onChange={handleListReport}
                      />

                    </Col>

                    <Col lg={8} className="text-end">
                      <Button onClick={() => { handleCreateReportForm() }} className="btn theme-btn-primary my-2">
                        <span className="plus-icon">+</span>Create Report</Button>
                    </Col>

                  </div>

                  <Col>
                    <div className="custom-table">
                      <Table>
                        <thead>
                          <tr>
                            <th width="">Title</th>
                            <th width="15%" className="text-center">Last Edit</th>
                            <th width="15%" className="text-center">Created by</th>
                            <th width="" className="text-center">Actions</th>
                          </tr>
                        </thead>
                        <tbody>

                          {reportList.map((report) => (

                            <tr>
                              <td>{report.title}</td>
                              <td className="text-center">{handleDate(report.updated_at)}</td>
                              <td className="text-center">{report.created_by}</td>

                              <td>
                                <div className="action-icons">
                                  <span>
                                    <Button className="btn theme-btn-outline-primary me-2" onClick={() => handlePreviewReport(report.id)} >Preview</Button>
                                    <Button className="btn theme-btn-outline-primary me-2" onClick={() => { handleEditReport(report.id) }} >Edit</Button>
                                    <Button className="btn theme-btn-outline-primary me-2" onClick={() => { handleExportReport(report.id) }}>Export</Button>
                                    <Button className="btn theme-btn-outline-primary me-2" onClick={() => { showSMSsidebar(report.id) }}>Send SMS</Button>
                                  </span>
                                  <span onClick={() => { handleDeleteReport(report.id) }} className="delete-icon">
                                    <Image src={deleteIcon} />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                </Row>
              </Card.Body>
            </Card>
          </Col>

          {queryResult.length > 0 && (
            <Col lg={6}>
              <Card className="custom-card">
                <Card.Body>
                  {queryResult.length > 0 &&
                    <h4 className="mb-2">Total results found: <span className="theme-blue-text">{resultCount}</span></h4>}
                    <div className='table-wrapper'>
                      <table className="fixed-header-table">
                        <thead>
                          <tr>
                            {queryResult.length > 0 &&
                              Object.keys(queryResult[0]).map((f) => (
                                <th className="position-relative">
                                  {handleTableHeaderValue(f)}
                                  <Button
                                    onClick={() => {
                                      handleResultOrder(f);
                                    }}
                                    className="p-0 table-arrow-up"
                                  >
                                    {" "}
                                    <Image src={arrowUp} />{" "}
                                  </Button>{" "}
                                  <Button
                                    onClick={() => {
                                      handleResultOrder(f);
                                    }}
                                    className="p-0 table-arrow-down"
                                  >
                                    {" "}
                                    <Image src={arrowDown} />{" "}
                                  </Button>
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {queryResult.map((f) => (
                            <tr>
                              {Object.values(f).map((x) => (
                                <td>{x}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                  {showPagination && (
                    <>

                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={lastPage}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination mt-5"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )}

          {resultCount === 0 && (
            <Col lg={6}>
              <div>
                <h4 className="mb-0">
                  <span className="theme-blue-text">No result found</span>
                </h4>
              </div>
            </Col>
          )}
        </Row>
      </>
    )
  }
  else {
    return (
      <>

        {anotherFields && <div ref={ref} id="addPractice"> ? (
        <Collapse in={anotherFields}>
            <div id="addPractice">
              {<ReportBuildeFields reportSave={reportSave} dataFieldSelected={reportState.dataFields} setAnotherFields={setAnotherFields} />}
            </div>
          </Collapse>
      ) </div>}


        <Row className="report-builder gx-3">

          {ReportFormShow &&
            <Col lg={6}>
              <Card className="">
                <Card.Body className="py-4 px-3">
                  <Row>
                    <Col lg={12}>
                      <h3 className="mb-3">Report builder</h3>
                    </Col>

                    <Col lg={8}>
                      <FormControl value={reportState.title}
                        onChange={(e) => { reportSave({ type: 'REPORT TITLE', title: e.target.value }) }}
                        type="text"
                        placeholder="Insert a memorable title here"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mt-3">
                <Card.Body className="py-4 px-3">
                  <Row>
                    <Col lg={12}>
                      <h3 className="mb-3">Add Search Dimensions</h3>
                      {/* add query builder here */}

                      <SearchBuilder reportSave={reportSave} initialState={initialState} />

                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mt-3">
                <Card.Body className="py-4 px-3">
                  <Row>
                    <Col lg={12}>
                      <h3 className="mb-3">Add Data Fields</h3>
                    </Col>
                    <div className="d-flex align-items-center mb-4 ">
                      {/* <Col lg={10}> */}
                      <Col lg={9}>
                        <span className="mb-3">
                          Add additional fields below that you want to appear, these fields will also be exportable. If none selected, The default will be shown (ID, Title, Forename, Surname, Job type)
                        </span>
                      </Col>

                      {/* <Col lg={2} className="text-end"> */}
                      <Col lg={3} className="text-end">
                        <Button type="submit" className="btn theme-btn-primary my-2" onClick={showAnotherFieldsSidebar}>
                          <span className="plus-icon">+</span> Add another field
                      </Button>
                      </Col>

                    </div>
                    <Col lg={12}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isMulti
                        styles={selectStyle}
                        value={reportState.dataFields}
                        onChange={(e) => { reportSave({ type: 'ADD DATA FIELDS', dataFields: e }) }}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mt-3">
                <Card.Body className="py-4 px-3">
                  <Row>
                    <Col lg={12}>
                      <h3 className="mb-3">Radius Search (optional)</h3>
                    </Col>
                    <RadiusSearch
                      reportSave={reportSave}
                      type='report'
                      radiusSearchState={radiusSearchState}
                    />
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mt-3">
                <Card.Body className="py-4 px-3">
                  <Row>
                    <Col lg={12}>
                      <h3 className="mb-2">Save & Sharing (optional)</h3>
                      <span>Select a person that you want to share this report with</span>
                    </Col>
                    <Col lg={12}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isMulti
                        styles={selectStyle}
                        value={reportState.personSharedValue}
                        options={personShareOption}
                        onChange={handlePersonShare}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>


              <Row className="">
                <Col>
                  <div className="d-flex my-4 justify-content-end">
                    <div className="me-2">
                      <Button className="btn theme-btn-secondary mw-150" onClick={handlePriviewShow} >Preview</Button>
                    </div>

                    <div className="me-2">
                      {editReportId ? <Button onClick={() => { handleUpdateReport(editReportId) }} className="btn theme-btn-secondary mw-150" >Update Report</Button> : <Button onClick={handleReportSave} className="btn theme-btn-secondary mw-150" >Save</Button>}

                    </div>

                    <div className="">
                      <Button onClick={() => { setRadiusSearchState({ driving: null, radius_miles: null, radius_postcode: null }); setQueryResult([]); setReportFormShow(false) }} className="btn theme-btn-outline-primary mw-150" >Cancel</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>}

          {queryResult.length > 0 && (
            <Col lg={6}>
              <Card className="custom-card">
                <Card.Body>
                  {queryResult.length > 0 &&
                    <h4 className="mb-2">Total results found: <span className="theme-blue-text">{resultCount}</span></h4>}
                    <div className="table-wrapper">
                      <table className="fixed-header-table" >
                        <thead>
                          <tr>
                            {queryResult.length > 0 &&
                              Object.keys(queryResult[0]).map((f) => (
                                <th className="position-relative">
                                  {handleTableHeaderValue(f)}
                                  <Button
                                    onClick={() => {
                                      handleResultOrder(f);
                                    }}
                                    className="p-0 table-arrow-up"
                                  >

                                    <Image src={arrowUp} />
                                  </Button>{" "}
                                  <Button
                                    onClick={() => {
                                      handleResultOrder(f);
                                    }}
                                    className="p-0 table-arrow-down"
                                  >

                                    <Image src={arrowDown} />
                                  </Button>
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody>
                          {queryResult.map((f) => (
                            <tr>
                              {Object.values(f).map((x) => (
                                <td>{x}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                  {showPagination && (
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={lastPage}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClickCreate}
                      containerClassName={"pagination mt-5"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  )}
                </Card.Body>
              </Card>
            </Col>
          )}
          {resultCount === 0 && (
            <Col lg={6}>
              <div>
                <h4 className="mb-0">
                  <span className="theme-blue-text">No result found</span>
                </h4>
              </div>
            </Col>
          )}

        </Row>
      </>
    );
  }
};

export default ReportBuilder;
