//User login
export const USER_LOGIN_REQUEST= 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS= 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL= 'USER_LOGIN_FAIL'
export const USER_LOGOUT= 'USER_LOGOUT'

//Create person
export const CREATE_PERSON_REQUEST= 'CREATE_PERSON_REQUEST' 
export const  CREATE_PERSON_SUCCESS= 'CREATE_PERSON_SUCCESS'
export const CREATE_PERSON_FAIL= 'CREATE_PERSON_FAIL'

//Update person
export const UPDATE_PERSON_REQUEST= 'UPDATE_PERSON_REQUEST'
export const UPDATE_PERSON_SUCCESS= 'UPDATE_PERSON_SUCCESS'
export const UPDATE_PERSON_FAIL= 'UPDATE_PERSON_FAIL'

//Get person
export const GET_PERSON_REQUEST= 'GET_PERSON_REQUEST'
export const GET_PERSON_SUCCESS= 'GET_PERSON_SUCCESS'
export const GET_PERSON_FAIL= 'GET_PERSON_FAIL'

//Create practice
export const CREATE_PRACTICE_REQUEST= 'CREATE_PRACTICE_REQUEST'
export const CREATE_PRACTICE_SUCCESS= 'CREATE_PRACTICE_SUCCESS'
export const CREATE_PRACTICE_FAIL= 'CREATE_PRACTICE_FAIL'

//Update practice
export const UPDATE_PRACTICE_REQUEST= 'UPDATE_PRACTICE_REQUEST'
export const UPDATE_PRACTICE_SUCCESS= 'UPDATE_PRACTICE_SUCCESS'
export const UPDATE_PRACTICE_FAIL= 'UPDATE_PRACTICE_FAIL'

//Get Practice
export const GET_PRACTICE_REQUEST= 'GET_PRACTICE_REQUEST'
export const GET_PRACTICE_SUCCESS= 'GET_PRACTICE_SUCCESS'
export const GET_PRACTICE_FAIL= 'GET_PRACTICE_FAIL'

//Last searched
export const LAST_SEARCH_RESULT_REQUEST= 'LAST_SEARCH_RESULT_REQUEST'
export const LAST_SEARCH_RESULT_SUCCESS= 'LAST_SEARCH_RESULT_SUCCESS'
export const LAST_SEARCH_RESULT_FAIL= 'LAST_SEARCH_RESULT_FAIL'

//Open Practice
export const OPEN_PRACTICE= 'OPEN_PRACTICE'

//Open Person
export const OPEN_PERSON= 'OPEN_PERSON'

//Search Person/Practice
export const SEARCH_PERSON_PRACTICE_REQUEST= 'SEARCH_PERSON_PRACTICE_REQUEST'
export const SEARCH_PERSON_PRACTICE_SUCCESS= 'SEARCH_PERSON_PRACTICE_SUCCESS'
export const SEARCH_PRACTICE_ONLY_SUCCESS= 'SEARCH_PRACTICE_ONLY_SUCCESS'
export const SEARCH_PRACTICE_ONLY_SUCCESS2= 'SEARCH_PRACTICE_ONLY_SUCCESS2'
export const SEARCH_PRACTICE_ONLY_SUCCESS3= 'SEARCH_PRACTICE_ONLY_SUCCESS3'
export const SEARCH_PERSON_PRACTICE_FAIL= 'SEARCH_PERSON_PRACTICE_FAIL'

//Search last ten Person/Practice
export const LAST_TEN_SEARCH_RESULT_REQUEST= 'LAST_TEN_SEARCH_RESULT_REQUEST'
export const LAST_TEN_SEARCH_RESULT_SUCCESS= 'LAST_TEN_SEARCH_RESULT_SUCCESS'
export const LAST_TEN_SEARCH_RESULT_FAIL= 'LAST_TEN_SEARCH_RESULT_FAIL'

//Refresh page
export const REFRESH_TOKEN_REQUEST= 'REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCESS= 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAIL= 'REFRESH_TOKEN_FAIL'

// Set Branch
export const SWITCH_BRANCH_REQUEST = 'SWITCH_BRANCH_REQUEST'
export const SWITCH_BRANCH_REQUEST_SUCCESS = 'SWITCH_BRANCH_REQUEST_SUCCESS'
export const SWITCH_BRANCH_REQUEST_FAILED = 'SWITCH_BRANCH_REQUEST_FAILED'

// Set Mass move
export const MASS_MOVE_REQUEST = 'MASS_MOVE_REQUEST'
export const MASS_MOVE_REQUEST_SUCCESS = 'MASS_MOVE_REQUEST_SUCCESS'
export const MASS_MOVE_REQUEST_FAILED = 'MASS_MOVE_REQUEST_FAILED'

// Set Mass delete  
export const MASS_DELETE_REQUEST = 'MASS_DELETE_REQUEST'
export const MASS_DELETE_REQUEST_SUCCESS = 'MASS_DELETE_REQUEST_SUCCESS'
export const MASS_DELETE_REQUEST_FAILED = 'MASS_DELETE_REQUEST_FAILED'

// uni Qualification Search 

export const UNI_SEARCH_SUCCESS = 'UNI_SEARCH_SUCCESS'
export const UNI_SEARCH_REQUEST = 'UNI_SEARCH_REQUEST'
export const UNI_SEARCH_FAILED  = 'UNI_SEARCH_FAILED'

// Set User ME
export const USER_ME_REQUEST = 'USER_ME_REQUEST'
export const USER_ME_SUCCESS = 'USER_ME_SUCCESS'
export const USER_ME_FAIL = 'USER_ME_FAIL'

// Set report list
export const GET_REPORT_LIST_REQUEST = 'GET_REPORT_LIST_REQUEST'
export const GET_REPORT_LIST_SUCCESS = 'GET_REPORT_LIST_SUCCESS'
export const GET_REPORT_LIST_FAIL = 'GET_REPORT_LIST_FAIL'

// Set report list
export const SEND_VERIFICATION_CODE_REQUEST = 'SEND_VERIFICATION_CODE_REQUEST'
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS'
export const SEND_VERIFICATION_CODE_FAIL = 'SEND_VERIFICATION_CODE_FAIL'

// Set report list
export const PMS_DROPDOWN_REQUEST = 'PMS_DROPDOWN_REQUEST'
export const PMS_DROPDOWN_SUCCESS = 'PMS_DROPDOWN_SUCCESS'
export const PMS_DROPDOWN_FAIL = 'PMS_DROPDOWN_FAIL'

