import React, { useState, useEffect } from "react";
import {contains, areas, dropDownEventsRegistration, dropDownEventsCongresses} from "../constants/constants";
import { entity, vet, practice, operators, title_suggestion, jobtype_suggestion, species_suggestion, salary_band_suggestions } from "../constants/advanceQueryBuilder"
import deleteIcon from "../assets/icons/delete.svg";
import { Row, Col, Form, Image, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { selectStyle } from '../styling/select-style'

const QueriesInput = ({ item, curr, dispatched, index, type, ind }) => {
    // for disoplaying field of multiselect
    const [showMultiSelect, setShowMultiSelect] = useState(true)
    const [multiselectValue, setMultiselectValue] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [date1, setDate1] = useState()
    const [date2, setDate2] = useState()

    useEffect(() => {
        let multi_select_fields = ['title', 'v_job_types', 'v_species', 'areas', 'sm_salary_band','v_registration','v_attended','v_congresses']
        if (multi_select_fields.includes(item.and.where)) {
            setShowMultiSelect(true)
        }
        else {
            setShowMultiSelect(false)
        }
    }, [item.and.where])

    useEffect(() => {
        let multi_select_fields = ['title', 'v_job_types', 'v_species', 'areas', 'sm_salary_band','v_registration','v_attended','v_congresses']
        if (item.and.param === ' LIKE' || item.and.param === ' NOT LIKE' || item.and.param === ' =' || item.and.param === ' <>') {
            setShowMultiSelect(false)
        }else if (multi_select_fields.includes(item.and.where)) {
            setShowMultiSelect(true)
        }else {
            setShowMultiSelect(false)
        }
    }, [item.and.param])

    // useEffect(() => {
    //     if (true) {
    //         let temp = []
    //         multiselectValue.forEach((x) => {
    //             temp.push(x.value)
    //         })
    //         dispatched({ type: 'VALUE_FIELD', val: temp.join(), index: index, val2: multiselectValue })
    //     }
    // }, [multiselectValue])

    useEffect(() => {
        if (item.and.param === 'BETWEEN' || item.and.param === 'NOT BETWEEN') {
            setModalShow(true)
        }
    }, [item.and.param])

    const handleMultiSelectValue = (e) => {
        setMultiselectValue(e)
        let temp = []
        e.forEach((x) => {
            temp.push(x.value)
        })
        dispatched({ type: 'VALUE_FIELD', val: temp.join(), index: index, val2: e })
    }
    const handleWhere = () => {
        setMultiselectValue([])
        if(showMultiSelect){
            dispatched({ type: 'RESET VALUE VAL2 MUST', index: index })
        }else{
            dispatched({ type: 'RESET VALUE VAL2', index: index })
        }
    }

    const handleCombineDate = () => {

        dispatched({ type: 'VALUE_FIELD', val: `${date1}___${date2}`, index: index, ind: ind })
        setModalShow(false)

    }

    return (
        <>
            {modalShow &&
                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='ignore-onclickoutside'
                    style={{ zIndex: '999999' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Date Between
                     </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            type="date"
                            name="date1"
                            className="date-icon"
                            value={date1}
                            onChange={(e) => { setDate1(e.target.value) }}
                        />
                        <Form.Control
                            type="date"
                            name="date2"
                            className="date-icon"
                            value={date2}
                            onChange={(e) => { setDate2(e.target.value) }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn theme-btn-primary" onClick={handleCombineDate} >Set Date</Button>
                    </Modal.Footer>
                </Modal>
            }

            <Row className="g-2">

                {index !== 0 && <h6 className="mb-1 mt-3">AND</h6>}
                <Col >
                    <Select menuPlacement="top"
                        className="basic-single"
                        classNamePrefix="select"
                        styles={selectStyle}

                        name="entity"
                        options={entity}
                        value={entity.find(i => i.value === item['and'].from)}
                        onChange={(e) => { dispatched({ type: 'ENTITY VALUE', entityValue: e.value, index: index }) }}

                    />
                </Col>

                <Col>
                    <Select
                        menuPlacement="top"
                        className="basic-single"
                        classNamePrefix="select"
                        styles={selectStyle}
                        menuPortalTarget={document.body}
                        name="where"
                        value={item['and'].from === 'VetFields' ? vet.find(i => i.value === item['and'].where) : practice.find(i => i.value === item['and'].where)}
                        options={item['and'].from === 'VetFields' ? vet : practice}
                        onChange={(e) => { dispatched({ type: 'WHERE FIELD', where: e.value, wheretext: e.label, index: index }); setShowMultiSelect(e.type); handleWhere() }}

                    />
                </Col>
                <Col>
                    <Select
                        menuPlacement="top"
                        className="basic-single"
                        classNamePrefix="select"
                        name="val"
                        styles={selectStyle}
                        menuPortalTarget={document.body}
                        options={operators}
                        value={operators.find(i => i.value === item['and'].param)}
                        onChange={(e) => { dispatched({ type: 'OPERATOR FIELD', param: e.value, index: index }) }}

                    />
                </Col>

                <Col>
                    {showMultiSelect ?
                        <Select
                            menuPlacement="top"
                            className="basic-single"
                            classNamePrefix="select"
                            name="val"
                            styles={selectStyle}
                            menuPortalTarget={document.body}
                            isMulti
                            closeMenuOnSelect={false}
                            options={
                                item['and'].where === 'title' ? title_suggestion :
                                    item['and'].where === 'v_job_types' ? jobtype_suggestion :
                                        item['and'].where === 'v_species' ? species_suggestion :
                                            item['and'].where === 'areas' ? areas :
                                                item['and'].where === 'sm_salary_band' ? salary_band_suggestions :
                                                    item['and'].where === 'v_registration' ? dropDownEventsRegistration :
                                                         item['and'].where === 'v_attended' ? dropDownEventsRegistration :
                                                             item['and'].where === 'v_congresses' ? dropDownEventsCongresses :
                                                    null
                            }
                            value={item.and.val2}
                            onChange={handleMultiSelectValue}

                        /> :
                        <Form.Control
                            type="text"
                            placeholder="Enter value"
                            name='val'
                            value={item.and.val}
                            onChange={(e) => { dispatched({ type: 'VALUE_FIELD', val: e.target.value, index: index, ind: ind }) }}

                        />}
                </Col>

                <Col className="d-flex align-items-center">
                    {(index !== 0 && type === 'AND') &&
                        <div className="action-icons">
                            <span
                                className="delete-icon"
                                onClick={() => { dispatched({ type: 'DELETE INPUT FIELD', index, field: type }) }}
                            >
                                <Image src={deleteIcon} />
                            </span>
                        </div>}

                    {type === 'OR' &&
                        <div className="action-icons">
                            <span
                                className="delete-icon"
                                onClick={() => { dispatched({ type: 'DELETE INPUT FIELD OR', index, field: type, ind }) }}
                            >
                                <Image src={deleteIcon} />
                            </span>
                        </div>

                    }
                    <>
                        <Button onClick={() => { dispatched({ type: 'ADD INPUT FIELD' }) }} className="btn theme-btn-grey ms-2">AND</Button>
                        <Button onClick={() => { dispatched({ type: 'ADD INPUT FIELD OR', index: index }) }} className="btn theme-btn-grey ms-2">OR</Button>
                    </>
                </Col>
            </Row>
        </>
    );
};

export default QueriesInput;
