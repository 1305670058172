import React, { useEffect, useState } from 'react'
import { Form, Button, Tab, Tabs } from 'react-bootstrap'
import { confirmationAlert, swAlert } from '../helpers/alert';
import Axios from '../adapters/xhr';

const RevertChanges = (props) => {

    const [setDeleteVet, setTotalDeleteVet] = useState(0)
    const [setDeletePractice, setTotalDeletePractice] = useState(0)
    const [setDeleteVetPrac, setTotalDeleteVetPrac] = useState(0)
    const [setUpdateVet, setTotalUpdateVet] = useState(0)
    const [setUpdatePractice, setTotalUpdatePractice] = useState(0)
    const [setUpdateVetPrac, setTotalUpdateVetPrac] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        try {
            setLoading(true)
            let response = await Axios.get('/list/revert/changes');
            if (response.status == 200) {
                setTotalDeleteVet(response.data.data.mass_deleted_vets)
                setTotalDeletePractice(response.data.data.mass_deleted_practice)
                setTotalUpdateVet(response.data.data.mass_update_vet)
                setTotalUpdatePractice(response.data.data.mass_update_practice)
            }
            setLoading(false)
        } catch (err) {
            console.log(err.response)
            setLoading(false)
        }
    }, [])

    const handleUpdateRevertChanges = () => {

        confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
            if (result.isConfirmed) {

                await Axios.get(`/revert/update`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if (res.data.status) swAlert(`${res.data.message}`, 'success', true)
                }).catch((err) => {
                    swAlert(`${err.message}`, true)
                })

            }
        })
    }

    const handleDeleteRevertChanges = () => {

        confirmationAlert('Are you sure to perform this action?', 'question').then(async (result) => {
            if (result.isConfirmed) {

                await Axios.get(`/revert/delete`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if (res.data.status) swAlert(`${res.data.message}`, 'success', true)
                }).catch((err) => {
                    swAlert(`${err.message}`, true)
                })

            }
        })
    }

    return (
        <>
            <Form>

                <h4 className="my-3 px-4">
                    <span>Revert back changes after mass update or mass delete</span>
                </h4>

                <span className="px-4 revert-changes mb-3 d-block">This function will only revert the last changes made during mass update/mass delete.</span>


                <Tabs defaultActiveKey="MassUpload" id="uncontrolled-tab-example" className="px-4">

                    <Tab eventKey="MassUpload" title="Mass Update">
                        <div className="list-view-export alice-blue-bg py-2">
                            <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                                Changed individuals in temporary table
                        <span className="btn-sm theme-btn-primary btn">
                                    {setUpdateVet}
                                </span>
                            </p>

                            <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                                Changed practices in temporary table
                        <span className="btn-sm theme-btn-primary btn">
                                    {setUpdatePractice}
                                </span>
                            </p>

                            <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                                Changed Individuals by cross query
                        <span className="btn-sm theme-btn-primary btn">
                                    {setUpdateVetPrac}
                                </span>
                            </p>
                        </div>

                        <div className="mt-4 px-4">
                            <Button className="btn-sm theme-btn-secondary btn w-100" onClick={() => handleUpdateRevertChanges()}>Initiate changes revert</Button>
                        </div>
                    </Tab>

                    <Tab eventKey="MassDelete" title="Mass Delete">
                        <div className="list-view-export alice-blue-bg py-2">
                            <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                                Changed individuals in temporary table
                        <span className="btn-sm theme-btn-primary btn">
                                    {setDeleteVet}
                                </span>
                            </p>

                            <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                                Changed practices in temporary table
                        <span className="btn-sm theme-btn-primary btn">
                                    {setDeletePractice}
                                </span>
                            </p>

                            <p className="d-flex justify-content-between my-2 me-3 align-items-center">
                                Changed Individuals by cross query
                        <span className="btn-sm theme-btn-primary btn">
                                    {setDeleteVetPrac}
                                </span>
                            </p>
                        </div>

                        <div className="mt-4 px-4">
                            <Button className="btn-sm theme-btn-secondary btn w-100" onClick={() => handleDeleteRevertChanges()}>Initiate changes revert</Button>
                        </div>
                    </Tab>

                </Tabs>

            </Form>
        </>
    )
}

export default RevertChanges;
