import React from 'react'

const ErrorMessage = ({ error }) => {
    return (
        <div>
            <span style={{ color: "red" }}><small>{ error }</small></span>
        </div>
    )
}

export default ErrorMessage
