import { useDispatch } from 'react-redux'
import { useEffect } from 'react';
//react-router-dom
import { HashRouter as Router, Switch } from 'react-router-dom'
//routes
import PublicRoute from './routes/PublicRoute'
import PrivateRoute from './routes/PrivateRoute'
//css
import "./App.css";
//components
import AuthScreen from "./screens/AuthScreen";
//adapters
import { setAuthToken } from './adapters/authentication'
//actions
import { refreshToken } from './actions/userActions'
import Dashboard from './screens/Dashboard';

function App() {
  //check for token
  const dispatch = useDispatch()
  useEffect(() => {
    if (localStorage.token) {      
      // Set auth token header auth
      setAuthToken(localStorage.token)
      dispatch(refreshToken())
    }
  }, [dispatch])

  return (
    <Router>
      <Switch>
        <PublicRoute restricted={true} component={AuthScreen} path='/' exact />
        <PrivateRoute component={Dashboard} path="/dashboard" />
      </Switch>
    </Router>
  );
}

export default App;
