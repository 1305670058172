export const selectStyle = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px solid #ebedf0',
      color: state.isSelected ? 'white' : '#212529',
      padding: 5,
      cursor: 'pointer',
      fontSize: '0.8rem',
      backgroundColor: state.isSelected ?  '#458eb9' : 'white',
    }),
    control: () => ({
    display: 'flex',
    width: '100%',
    cursor: 'pointer',
    fontSize: '0.8rem',
    fontweight: '400',
    minHeight: 'calc(1.75rem + 2px)',
    marginBottom: '0.35rem',
    lineHeight: 1.25,
    color: '#212529',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    appearance: 'none',
    borderRadius: '0.25rem',
    }),
    menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }
